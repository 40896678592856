

@font-face {
    font-family: "flaticon_mycollection";
    src: url("https://universitybureau.com/content/themes/kadu/assets/webfonts/flaticon_mycollection.woff2?2ef58e26b6af115306b126b794c5b473") format("woff2"),
    url("https://universitybureau.com/content/themes/kadu/assets/webfonts/flaticon_mycollection.woff?2ef58e26b6af115306b126b794c5b473") format("woff"),
    url("https://universitybureau.com/content/themes/kadu/assets/webfonts/flaticon_mycollection.eot?2ef58e26b6af115306b126b794c5b473#iefix") format("embedded-opentype"),
    url("https://universitybureau.com/content/themes/kadu/assets/webfonts/flaticon_mycollection.ttf?2ef58e26b6af115306b126b794c5b473") format("truetype"),
    url("https://universitybureau.com/content/themes/kadu/assets/webfonts/flaticon_mycollection.svg?2ef58e26b6af115306b126b794c5b473#flaticon_mycollection") format("svg")
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon_mycollection !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-arrow:before {
    content: "\f101";
}
.flaticon-right-up:before {
    content: "\f102";
}
.flaticon-forward:before {
    content: "\f103";
}
.flaticon-exam:before {
    content: "\f104";
}
.flaticon-web-development:before {
    content: "\f105";
}
.flaticon-graphic-design:before {
    content: "\f106";
}
.flaticon-connecy:before {
    content: "\f107";
}
.flaticon-ux-design:before {
    content: "\f108";
}
.flaticon-laptop-code:before {
    content: "\f109";
}
.flaticon-ai:before {
    content: "\f10a";
}
.flaticon-stethoscope:before {
    content: "\f10b";
}
.flaticon-team-leader:before {
    content: "\f10c";
}
.flaticon-social-care:before {
    content: "\f10d";
}
.flaticon-phone-call:before {
    content: "\f10e";
}
.flaticon-star:before {
    content: "\f10f";
}
.flaticon-play-button:before {
    content: "\f110";
}
.flaticon-add-group:before {
    content: "\f111";
}
.flaticon-clock:before {
    content: "\f112";
}
.flaticon-right-arrow:before {
    content: "\f113";
}
.flaticon-open-book:before {
    content: "\f114";
}
.flaticon-online-conference:before {
    content: "\f115";
}
.flaticon-files:before {
    content: "\f116";
}
.flaticon-facebook:before {
    content: "\f117";
}
.flaticon-instagram:before {
    content: "\f118";
}
.flaticon-twitter:before {
    content: "\f119";
}
.flaticon-linkedin:before {
    content: "\f11a";
}
.flaticon-location:before {
    content: "\f11b";
}
.flaticon-calendar:before {
    content: "\f11c";
}
.flaticon-text:before {
    content: "\f11d";
}
.flaticon-arrow-right:before {
    content: "\f11e";
}
.flaticon-right-arrow-1:before {
    content: "\f11f";
}
.flaticon-maps-and-flags:before {
    content: "\f120";
}

