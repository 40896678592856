@font-face {
    font-family: "tutor";
    src: url("https://universitybureau.com/content/plugins/tutor/fonts/tutor.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: block
}

@font-face {
    font-family: "tutor";
    src: url("https://universitybureau.com/content/plugins/tutor/fonts/tutor-v2.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: block
}

[class^=tutor-icon-],
[class*=" tutor-icon-"] {
    font-family: "tutor" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.tutor-icon-privacy:before {
    content: "豈"
}

.tutor-icon-laptop:before {
    content: "更"
}

.tutor-icon-desktop:before {
    content: "車"
}

.tutor-icon-tablet:before {
    content: "賈"
}

.tutor-icon-mobile:before {
    content: "滑"
}

.tutor-icon-map-pin:before {
    content: "串"
}

.tutor-icon-api:before {
    content: "句"
}

.tutor-icon-align-center:before {
    content: "龜"
}

.tutor-icon-align-justify:before {
    content: "龜"
}

.tutor-icon-align-left:before {
    content: "契"
}

.tutor-icon-align-right:before {
    content: "蘿"
}

.tutor-icon-brand-buddypress:before {
    content: ""
}

.tutor-icon-brand-google-meet:before {
    content: ""
}

.tutor-icon-brand-google-calendar:before {
    content: ""
}

.tutor-icon-ban:before {
    content: ""
}

.tutor-icon-brand-woocommerce:before {
    content: ""
}

.tutor-icon-kebab-menu:before {
    content: ""
}

.tutor-icon-add:before {
    content: ""
}

.tutor-icon-add-photo:before {
    content: ""
}

.tutor-icon-file-artboard:before {
    content: ""
}

.tutor-icon-calender:before {
    content: ""
}

.tutor-icon-circle-mark:before {
    content: ""
}

.tutor-icon-certificate-portrait:before {
    content: ""
}

.tutor-icon-brand-zoom:before {
    content: ""
}

.tutor-icon-clipboard:before {
    content: ""
}

.tutor-icon-code:before {
    content: ""
}

.tutor-icon-conversation:before {
    content: ""
}

.tutor-icon-trophy-o:before {
    content: ""
}

.tutor-icon-file-blank-page:before {
    content: ""
}

.tutor-icon-download:before {
    content: ""
}

.tutor-icon-drag:before {
    content: ""
}

.tutor-icon-emoji:before {
    content: ""
}

.tutor-icon-file-import:before {
    content: ""
}

.tutor-icon-gallery:before {
    content: ""
}

.tutor-icon-trash-can:before {
    content: ""
}

.tutor-icon-spectacles:before {
    content: ""
}

.tutor-icon-graduate-student:before {
    content: ""
}

.tutor-icon-grid-o:before {
    content: ""
}

.tutor-icon-user-group:before {
    content: ""
}

.tutor-icon-home-o:before {
    content: ""
}

.tutor-icon-down:before {
    content: ""
}

.tutor-icon-left:before {
    content: ""
}

.tutor-icon-right:before {
    content: ""
}

.tutor-icon-up:before {
    content: ""
}

.tutor-icon-interactive:before {
    content: ""
}

.tutor-icon-level:before {
    content: ""
}

.tutor-icon-times:before {
    content: ""
}

.tutor-icon-magnifying-glass:before {
    content: ""
}

.tutor-icon-hamburger-o:before {
    content: ""
}

.tutor-icon-mortarboard-o:before {
    content: ""
}

.tutor-icon-next:before {
    content: ""
}

.tutor-icon-badge-percent:before {
    content: ""
}

.tutor-icon-book-open-o:before {
    content: ""
}

.tutor-icon-pencil:before {
    content: ""
}

.tutor-icon-previous:before {
    content: ""
}

.tutor-icon-user-professor:before {
    content: ""
}

.tutor-icon-qa:before {
    content: ""
}

.tutor-icon-receipt-line:before {
    content: ""
}

.tutor-icon-refund-o:before {
    content: ""
}

.tutor-icon-page-review:before {
    content: ""
}

.tutor-icon-save-line:before {
    content: ""
}

.tutor-icon-page-search:before {
    content: ""
}

.tutor-icon-share-nodes:before {
    content: ""
}

.tutor-icon-spinner:before {
    content: ""
}

.tutor-icon-mortarboard:before {
    content: ""
}

.tutor-icon-user-student:before {
    content: ""
}

.tutor-icon-badge-percent-o:before {
    content: ""
}

.tutor-icon-money-bag:before {
    content: ""
}

.tutor-icon-badge-star:before {
    content: ""
}

.tutor-icon-update:before {
    content: ""
}

.tutor-icon-upload-file:before {
    content: ""
}

.tutor-icon-upload-o:before {
    content: ""
}

.tutor-icon-upload:before {
    content: ""
}

.tutor-icon-add-group:before {
    content: ""
}

.tutor-icon-add-image:before {
    content: ""
}

.tutor-icon-add-member:before {
    content: ""
}

.tutor-icon-angle-double-left:before {
    content: ""
}

.tutor-icon-angle-double-right:before {
    content: ""
}

.tutor-icon-angle-down:before {
    content: ""
}

.tutor-icon-angle-left:before {
    content: ""
}

.tutor-icon-angle-right:before {
    content: ""
}

.tutor-icon-angle-up:before {
    content: ""
}

.tutor-icon-ruler:before {
    content: ""
}

.tutor-icon-assignment:before {
    content: ""
}

.tutor-icon-paperclip:before {
    content: ""
}

.tutor-icon-volume:before {
    content: ""
}

.tutor-icon-authorized:before {
    content: ""
}

.tutor-icon-trophy:before {
    content: ""
}

.tutor-icon-background:before {
    content: ""
}

.tutor-icon-book-open:before {
    content: ""
}

.tutor-icon-book:before {
    content: ""
}

.tutor-icon-box-open:before {
    content: ""
}

.tutor-icon-browser:before {
    content: ""
}

.tutor-icon-bullet-point:before {
    content: ""
}

.tutor-icon-calender-bold:before {
    content: ""
}

.tutor-icon-calender-line:before {
    content: ""
}

.tutor-icon-camera:before {
    content: ""
}

.tutor-icon-cart-bold:before {
    content: ""
}

.tutor-icon-cart-line:before {
    content: ""
}

.tutor-icon-ribbon:before {
    content: ""
}

.tutor-icon-certificate-landscape:before {
    content: ""
}

.tutor-icon-slider-horizontal:before {
    content: ""
}

.tutor-icon-chart-pie:before {
    content: ""
}

.tutor-icon-circle-info:before {
    content: ""
}

.tutor-icon-clock-bold:before {
    content: ""
}

.tutor-icon-clock-line:before {
    content: ""
}

.tutor-icon-coding:before {
    content: ""
}

.tutor-icon-coins:before {
    content: ""
}

.tutor-icon-mortarboard-o:before {
    content: ""
}

.tutor-icon-comment:before {
    content: ""
}

.tutor-icon-buildings:before {
    content: ""
}

.tutor-icon-clock-line-o:before {
    content: ""
}

.tutor-icon-copy:before {
    content: ""
}

.tutor-icon-export:before {
    content: ""
}

.tutor-icon-import:before {
    content: ""
}

.tutor-icon-brand-learndash:before {
    content: ""
}

.tutor-icon-brand-learnpress:before {
    content: ""
}

.tutor-icon-circle-times-line:before {
    content: ""
}

.tutor-icon-circle-times-bold:before {
    content: ""
}

.tutor-icon-times-o:before {
    content: ""
}

.tutor-icon-crown:before {
    content: ""
}

.tutor-icon-slider-vertical:before {
    content: ""
}

.tutor-icon-dashboard:before {
    content: ""
}

.tutor-icon-trash-can-bold:before {
    content: ""
}

.tutor-icon-trash-can-line:before {
    content: ""
}

.tutor-icon-color-palette:before {
    content: ""
}

.tutor-icon-external-link:before {
    content: ""
}

.tutor-icon-badge-discount:before {
    content: ""
}

.tutor-icon-badge-dollar:before {
    content: ""
}

.tutor-icon-text-add:before {
    content: ""
}

.tutor-icon-document-text:before {
    content: ""
}

.tutor-icon-dollar-slot:before {
    content: ""
}

.tutor-icon-door-open:before {
    content: ""
}

.tutor-icon-caret-up:before {
    content: ""
}

.tutor-icon-download-bold:before {
    content: ""
}

.tutor-icon-sort:before {
    content: ""
}

.tutor-icon-drop:before {
    content: ""
}

.tutor-icon-hourglass-o:before {
    content: ""
}

.tutor-icon-earth:before {
    content: ""
}

.tutor-icon-pencil-o:before {
    content: ""
}

.tutor-icon-edit:before {
    content: ""
}

.tutor-icon-edit-o:before {
    content: ""
}

.tutor-icon-edit-square:before {
    content: ""
}

.tutor-icon-open-book-o:before {
    content: ""
}

.tutor-icon-envelope:before {
    content: ""
}

.tutor-icon-time-expired:before {
    content: ""
}

.tutor-icon-eye-bold:before {
    content: ""
}

.tutor-icon-eye-line:before {
    content: ""
}

.tutor-icon-bookmark-bold:before {
    content: ""
}

.tutor-icon-bookmark-line:before {
    content: ""
}

.tutor-icon-hourglass:before {
    content: ""
}

.tutor-icon-filter-dollar:before {
    content: ""
}

.tutor-icon-filter:before {
    content: ""
}

.tutor-icon-folder:before {
    content: ""
}

.tutor-icon-grade:before {
    content: ""
}

.tutor-icon-grade-book:before {
    content: ""
}

.tutor-icon-grid-bold:before {
    content: ""
}

.tutor-icon-grid-line:before {
    content: ""
}

.tutor-icon-hand-holding-dollar:before {
    content: ""
}

.tutor-icon-heart-bold:before {
    content: ""
}

.tutor-icon-home:before {
    content: ""
}

.tutor-icon-hamburger-menu:before {
    content: ""
}

.tutor-icon-light-bulb:before {
    content: ""
}

.tutor-icon-image-landscape:before {
    content: ""
}

.tutor-icon-images:before {
    content: ""
}

.tutor-icon-image-portrait:before {
    content: ""
}

.tutor-icon-add-image-o:before {
    content: ""
}

.tutor-icon-import-export:before {
    content: ""
}

.tutor-icon-import-o:before {
    content: ""
}

.tutor-icon-flag:before {
    content: ""
}

.tutor-icon-circle-info-o:before {
    content: ""
}

.tutor-icon-ribbon-o:before {
    content: ""
}

.tutor-icon-instructor:before {
    content: ""
}

.tutor-icon-copy-text:before {
    content: ""
}

.tutor-icon-eye-slash-line:before {
    content: ""
}

.tutor-icon-certificate-landscape-o:before {
    content: ""
}

.tutor-icon-layer:before {
    content: ""
}

.tutor-icon-link:before {
    content: ""
}

.tutor-icon-clipboard-list:before {
    content: ""
}

.tutor-icon-lock-bold:before {
    content: ""
}

.tutor-icon-lock-line:before {
    content: ""
}

.tutor-icon-user-bold:before {
    content: ""
}

.tutor-icon-manual:before {
    content: ""
}

.tutor-icon-circle-mark-o:before {
    content: ""
}

.tutor-icon-mark:before {
    content: ""
}

.tutor-icon-mark-unread:before {
    content: ""
}

.tutor-icon-file-star:before {
    content: ""
}

.tutor-icon-file-text:before {
    content: ""
}

.tutor-icon-arrow-right-left:before {
    content: ""
}

.tutor-icon-media-manager:before {
    content: ""
}

.tutor-icon-meeting:before {
    content: ""
}

.tutor-icon-microphone:before {
    content: ""
}

.tutor-icon-minus:before {
    content: ""
}

.tutor-icon-minus-o:before {
    content: ""
}

.tutor-icon-refund:before {
    content: ""
}

.tutor-icon-move:before {
    content: ""
}

.tutor-icon-slider-h:before {
    content: ""
}

.tutor-icon-slider-v:before {
    content: ""
}

.tutor-icon-archive:before {
    content: ""
}

.tutor-icon-important-bold:before {
    content: ""
}

.tutor-icon-important-line:before {
    content: ""
}

.tutor-icon-open-envelope:before {
    content: ""
}

.tutor-icon-message-unread:before {
    content: ""
}

.tutor-icon-double-mark:before {
    content: ""
}

.tutor-icon-bell-bold:before {
    content: ""
}

.tutor-icon-bell-line:before {
    content: ""
}

.tutor-icon-text-width:before {
    content: ""
}

.tutor-icon-order-down:before {
    content: ""
}

.tutor-icon-order-up:before {
    content: ""
}

.tutor-icon-ordering-a-z:before {
    content: ""
}

.tutor-icon-ordering-z-a:before {
    content: ""
}

.tutor-icon-paste:before {
    content: ""
}

.tutor-icon-play-line:before {
    content: ""
}

.tutor-icon-plus-o:before {
    content: ""
}

.tutor-icon-plus:before {
    content: ""
}

.tutor-icon-plus-square:before {
    content: ""
}

.tutor-icon-eye-slash-bold:before {
    content: ""
}

.tutor-icon-print:before {
    content: ""
}

.tutor-icon-purchase-mark:before {
    content: ""
}

.tutor-icon-puzzle:before {
    content: ""
}

.tutor-icon-qr-code:before {
    content: ""
}

.tutor-icon-question:before {
    content: ""
}

.tutor-icon-circle-question-mark:before {
    content: ""
}

.tutor-icon-quiz:before {
    content: ""
}

.tutor-icon-quiz-attempt:before {
    content: ""
}

.tutor-icon-quiz-o:before {
    content: ""
}

.tutor-icon-receipt-bold:before {
    content: ""
}

.tutor-icon-redo:before {
    content: ""
}

.tutor-icon-refresh:before {
    content: ""
}

.tutor-icon-refresh-o:before {
    content: ""
}

.tutor-icon-report-time:before {
    content: ""
}

.tutor-icon-save-bold:before {
    content: ""
}

.tutor-icon-search:before {
    content: ""
}

.tutor-icon-paper-plane:before {
    content: ""
}

.tutor-icon-gear:before {
    content: ""
}

.tutor-icon-shape:before {
    content: ""
}

.tutor-icon-share:before {
    content: ""
}

.tutor-icon-minimize:before {
    content: ""
}

.tutor-icon-shorting-bullet:before {
    content: ""
}

.tutor-icon-circle-notch:before {
    content: ""
}

.tutor-icon-signout:before {
    content: ""
}

.tutor-icon-rocket:before {
    content: ""
}

.tutor-icon-bullhorn:before {
    content: ""
}

.tutor-icon-star-bold:before {
    content: ""
}

.tutor-icon-star-half-bold:before {
    content: ""
}

.tutor-icon-star-line:before {
    content: ""
}

.tutor-icon-stopwatch:before {
    content: ""
}

.tutor-icon-brush:before {
    content: ""
}

.tutor-icon-circle-mark-line:before {
    content: ""
}

.tutor-icon-undo:before {
    content: ""
}

.tutor-icon-university:before {
    content: ""
}

.tutor-icon-unlock-line:before {
    content: ""
}

.tutor-icon-caret-down:before {
    content: ""
}

.tutor-icon-user-line:before {
    content: ""
}

.tutor-icon-user-graduate:before {
    content: ""
}

.tutor-icon-verify-id:before {
    content: ""
}

.tutor-icon-video-camera:before {
    content: ""
}

.tutor-icon-video-camera-o:before {
    content: ""
}

.tutor-icon-wallet:before {
    content: ""
}

.tutor-icon-brand-facebook:before {
    content: ""
}

.tutor-icon-brand-zoom-o:before {
    content: ""
}

.tutor-icon-circle-warning-outline:before {
    content: ""
}

.tutor-icon-website:before {
    content: ""
}

.tutor-icon-circle-half:before {
    content: ""
}

.tutor-icon-zoom-in:before {
    content: ""
}

.tutor-icon-zoom-out:before {
    content: ""
}

.tutor-icon-brand-github:before {
    content: ""
}

.tutor-icon-brand-google:before {
    content: ""
}

.tutor-icon-brand-google-drive:before {
    content: ""
}

.tutor-icon-brand-html5-bold:before {
    content: ""
}

.tutor-icon-brand-html5-line:before {
    content: ""
}

.tutor-icon-brand-instagram:before {
    content: ""
}

.tutor-icon-brand-linkedin:before {
    content: ""
}

.tutor-icon-brand-myspace:before {
    content: ""
}

.tutor-icon-brand-paid-membersip-pro:before {
    content: ""
}

.tutor-icon-brand-reddit:before {
    content: ""
}

.tutor-icon-brand-skype:before {
    content: ""
}

.tutor-icon-brand-snapchat:before {
    content: ""
}

.tutor-icon-brand-soundcloud:before {
    content: ""
}

.tutor-icon-brand-spotify:before {
    content: ""
}

.tutor-icon-brand-tumblr:before {
    content: ""
}

.tutor-icon-brand-twitter:before {
    content: ""
}

.tutor-icon-brand-vimeo-bold:before {
    content: ""
}

.tutor-icon-brand-vimeo-line:before {
    content: ""
}

.tutor-icon-brand-yelp:before {
    content: ""
}

.tutor-icon-brand-youtube-bold:before {
    content: ""
}

.tutor-icon-brand-youtube-line:before {
    content: ""
}

.tutor-icon-grade-o:before {
    content: ""
}

.tutor-icon-file-ai:before {
    content: ""
}

.tutor-icon-file-archive:before {
    content: ""
}

.tutor-icon-file-audio:before {
    content: ""
}

.tutor-icon-file-avi:before {
    content: ""
}

.tutor-icon-file-compress:before {
    content: ""
}

.tutor-icon-file-css:before {
    content: ""
}

.tutor-icon-file-csv:before {
    content: ""
}

.tutor-icon-file-dbf:before {
    content: ""
}

.tutor-icon-file-doc:before {
    content: ""
}

.tutor-icon-file-document:before {
    content: ""
}

.tutor-icon-file-dwg:before {
    content: ""
}

.tutor-icon-file-exe:before {
    content: ""
}

.tutor-icon-file-fla:before {
    content: ""
}

.tutor-icon-file-html:before {
    content: ""
}

.tutor-icon-image-file:before {
    content: ""
}

.tutor-icon-file-iso:before {
    content: ""
}

.tutor-icon-file-json:before {
    content: ""
}

.tutor-icon-file-js:before {
    content: ""
}

.tutor-icon-file-jpg:before {
    content: ""
}

.tutor-icon-file-mp3:before {
    content: ""
}

.tutor-icon-file-mp4:before {
    content: ""
}

.tutor-icon-file-pdf:before {
    content: ""
}

.tutor-icon-file-png:before {
    content: ""
}

.tutor-icon-file-ppt:before {
    content: ""
}

.tutor-icon-file-psd:before {
    content: ""
}

.tutor-icon-file-rtf:before {
    content: ""
}

.tutor-icon-file-spreadsheet:before {
    content: ""
}

.tutor-icon-file-svg:before {
    content: ""
}

.tutor-icon-text-file:before {
    content: ""
}

.tutor-icon-file-txt:before {
    content: ""
}

.tutor-icon-video-file:before {
    content: ""
}

.tutor-icon-file-xls:before {
    content: ""
}

.tutor-icon-file-xml:before {
    content: ""
}

.tutor-icon-file-zip:before {
    content: ""
}

.tutor-icon-circle-info-bold:before {
    content: ""
}

.tutor-icon-warning:before {
    content: ""
}

.tutor-icon-shortcode:before {
    content: ""
}