/* 
    root-veriable-start
*/
:root {
  scroll-behavior: inherit;
  --kd-font-pr-1: "Urbanist", sans-serif;
  --kd-font-sd-1: "Just Another Hand", cursive;
  --kd-font-barlow: "Barlow Semi Condensed", sans-serif;
  --kd-font-fraunces: "Fraunces", serif;
  --kd-clr-pr-1: #e31e24;
  --kd-clr-pr-4: #D70007;
  --kd-clr-sd-1: #ef991f;
  --kd-clr-sd-4: #08D565;
  --kd-clr-h-1: #363539;
  --kd-clr-p-1: #797a84;
  --cube-1: cubic-bezier(.57,.21,.69,1.25);
  --cube-2: cubic-bezier(0.31, -0.105, 0.43, 1.4);
}

.kd-pr-btn-1:is(.has-pr-clr) {
  background: #e31e24;
}

/* 
    root-veriable-end
*/
/* 
    global-css-start
*/
body {
  font-family: var(--kd-font-pr-1);
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  color: var(--kd-clr-p-1);
  overflow-x: hidden;
}

.img, img {
  max-width: 100%;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

a, span {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  text-transform: inherit;
  text-decoration: none;
  transition: inherit;
}

button {
  border: none;
  outline: none;
}

a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-size: 55px;
}

h2 {
  font-size: 55px;
}

h3 {
  font-size: 42px;
}

h4 {
  font-size: 27px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

::-moz-selection {
  background-color: #3065D0;
  color: white;
  text-shadow: none;
}

::-moz-selection {
  background-color: #3065D0;
  color: white;
  text-shadow: none;
}

::selection {
  background-color: #3065D0;
  color: white;
  text-shadow: none;
}

input::placeholder, textarea::placeholder {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  font-family: inherit;
}

input:focus::placeholder, textarea:focus::placeholder {
  opacity: 0;
}

@media (min-width: 1800px), only screen and (min-width: 1600px) and (max-width: 1799px), only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  ::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
    box-shadow: none;
    border: none;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: none;
    border: none;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--kd-clr-pr-1);
    border-radius: 30px;
  }
}
/* 
    global-css-start
*/
/* 
    margin-&-padding_veriable-start
*/
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-135 {
  margin-top: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-145 {
  margin-top: 145px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-155 {
  margin-top: 155px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-165 {
  margin-top: 165px;
}

.mt-170 {
  margin-top: 170px;
}

.mt-175 {
  margin-top: 175px;
}

.mt-180 {
  margin-top: 180px;
}

.mt-185 {
  margin-top: 185px;
}

.mt-190 {
  margin-top: 190px;
}

.mt-195 {
  margin-top: 195px;
}

.mt-200 {
  margin-top: 200px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-135 {
  margin-bottom: 135px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-145 {
  margin-bottom: 145px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-155 {
  margin-bottom: 155px;
}

.mb-160 {
  margin-bottom: 160px;
}

.mb-165 {
  margin-bottom: 165px;
}

.mb-170 {
  margin-bottom: 170px;
}

.mb-175 {
  margin-bottom: 175px;
}

.mb-180 {
  margin-bottom: 180px;
}

.mb-185 {
  margin-bottom: 185px;
}

.mb-190 {
  margin-bottom: 190px;
}

.mb-195 {
  margin-bottom: 195px;
}

.mb-200 {
  margin-bottom: 200px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-65 {
  margin-left: 65px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-85 {
  margin-left: 85px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-105 {
  margin-left: 105px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-115 {
  margin-left: 115px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-125 {
  margin-left: 125px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-135 {
  margin-left: 135px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-145 {
  margin-left: 145px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-155 {
  margin-left: 155px;
}

.ml-160 {
  margin-left: 160px;
}

.ml-165 {
  margin-left: 165px;
}

.ml-170 {
  margin-left: 170px;
}

.ml-175 {
  margin-left: 175px;
}

.ml-180 {
  margin-left: 180px;
}

.ml-185 {
  margin-left: 185px;
}

.ml-190 {
  margin-left: 190px;
}

.ml-195 {
  margin-left: 195px;
}

.ml-200 {
  margin-left: 200px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-55 {
  margin-right: 55px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-65 {
  margin-right: 65px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-75 {
  margin-right: 75px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-85 {
  margin-right: 85px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-95 {
  margin-right: 95px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-105 {
  margin-right: 105px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-115 {
  margin-right: 115px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-125 {
  margin-right: 125px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-135 {
  margin-right: 135px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-145 {
  margin-right: 145px;
}

.mr-150 {
  margin-right: 150px;
}

.mr-155 {
  margin-right: 155px;
}

.mr-160 {
  margin-right: 160px;
}

.mr-165 {
  margin-right: 165px;
}

.mr-170 {
  margin-right: 170px;
}

.mr-175 {
  margin-right: 175px;
}

.mr-180 {
  margin-right: 180px;
}

.mr-185 {
  margin-right: 185px;
}

.mr-190 {
  margin-right: 190px;
}

.mr-195 {
  margin-right: 195px;
}

.mr-200 {
  margin-right: 200px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-145 {
  padding-top: 145px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-160 {
  padding-top: 160px;
}

.pt-165 {
  padding-top: 165px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-175 {
  padding-top: 175px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-185 {
  padding-top: 185px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-195 {
  padding-top: 195px;
}

.pt-200 {
  padding-top: 200px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-155 {
  padding-bottom: 155px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pb-165 {
  padding-bottom: 165px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-185 {
  padding-bottom: 185px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-195 {
  padding-bottom: 195px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pb-205 {
  padding-bottom: 205px;
}

.pb-210 {
  padding-bottom: 210px;
}

.pb-215 {
  padding-bottom: 215px;
}

.pb-220 {
  padding-bottom: 220px;
}

.pb-225 {
  padding-bottom: 225px;
}

.pb-230 {
  padding-bottom: 230px;
}

.pb-235 {
  padding-bottom: 235px;
}

.pb-240 {
  padding-bottom: 240px;
}

.pb-245 {
  padding-bottom: 245px;
}

.pb-250 {
  padding-bottom: 250px;
}

.pb-255 {
  padding-bottom: 255px;
}

.pb-260 {
  padding-bottom: 260px;
}

.pb-265 {
  padding-bottom: 265px;
}

.pb-270 {
  padding-bottom: 270px;
}

.pb-275 {
  padding-bottom: 275px;
}

.pb-280 {
  padding-bottom: 280px;
}

.pb-285 {
  padding-bottom: 285px;
}

.pb-290 {
  padding-bottom: 290px;
}

.pb-295 {
  padding-bottom: 295px;
}

.pb-300 {
  padding-bottom: 300px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-55 {
  padding-left: 55px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-65 {
  padding-left: 65px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-75 {
  padding-left: 75px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-85 {
  padding-left: 85px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-95 {
  padding-left: 95px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-105 {
  padding-left: 105px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-115 {
  padding-left: 115px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-125 {
  padding-left: 125px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-135 {
  padding-left: 135px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-145 {
  padding-left: 145px;
}

.pl-150 {
  padding-left: 150px;
}

.pl-155 {
  padding-left: 155px;
}

.pl-160 {
  padding-left: 160px;
}

.pl-165 {
  padding-left: 165px;
}

.pl-170 {
  padding-left: 170px;
}

.pl-175 {
  padding-left: 175px;
}

.pl-180 {
  padding-left: 180px;
}

.pl-185 {
  padding-left: 185px;
}

.pl-190 {
  padding-left: 190px;
}

.pl-195 {
  padding-left: 195px;
}

.pl-200 {
  padding-left: 200px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-45 {
  padding-right: 45px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-55 {
  padding-right: 55px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-65 {
  padding-right: 65px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-75 {
  padding-right: 75px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-85 {
  padding-right: 85px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-95 {
  padding-right: 95px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-105 {
  padding-right: 105px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-115 {
  padding-right: 115px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-125 {
  padding-right: 125px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-135 {
  padding-right: 135px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-145 {
  padding-right: 145px;
}

.pr-150 {
  padding-right: 150px;
}

.pr-155 {
  padding-right: 155px;
}

.pr-160 {
  padding-right: 160px;
}

.pr-165 {
  padding-right: 165px;
}

.pr-170 {
  padding-right: 170px;
}

.pr-175 {
  padding-right: 175px;
}

.pr-180 {
  padding-right: 180px;
}

.pr-185 {
  padding-right: 185px;
}

.pr-190 {
  padding-right: 190px;
}

.pr-195 {
  padding-right: 195px;
}

.pr-200 {
  padding-right: 200px;
}

/* 
    margin-&-padding_veriable-end
*/
/* 
    all-common-class-start
*/
@media (min-width: 1300px) {
  .container.kd-container-1 {
    max-width: 1275px;
  }
  .container.kd-container-2 {
    max-width: 1315px;
  }
}
.fix {
  overflow: hidden;
}

.img-cover img {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
}

.bg-default {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.flaticon {
  display: inline-block;
  line-height: 0.65;
}

.z-index-3 {
  position: relative;
  z-index: 3;
}

.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
  z-index: 1;
}

.kd-h2-split-text {
  text-transform: unset !important;
  overflow: hidden;
}
.kd-h2-split-text .split-line div div:nth-child(1) {
  text-transform: capitalize;
}

.kd-split-text {
  text-transform: unset !important;
  letter-spacing: -0.5px;
  overflow: hidden;
  perspective: 2000px;
  transform-style: preserve-3d;
  padding-bottom: 4px;
}
.kd-split-text .split-line div div:nth-child(1) {
  text-transform: capitalize;
}

.rtl-home {
  direction: rtl;
}
.rtl-home .split-line div {
  direction: ltr;
}

.kd-font-pr-1 {
  font-family: var(--kd-font-pr-1);
}

.kd-font-sd-1 {
  font-family: var(--kd-font-sd-1);
}

.kd-font-400 {
  font-weight: 400;
}

.kd-font-500 {
  font-weight: 500;
}

.kd-font-600 {
  font-weight: 600;
}

.kd-font-700 {
  font-weight: 700;
}

.kd-font-800 {
  font-weight: 800;
}

.kd-font-900 {
  font-weight: 900;
}

.kd-clr-white {
  color: #fff;
}

.kd-clr-pr-1 {
  color: var(--kd-clr-pr-1);
}

.kd-clr-sd-1 {
  color: var(--kd-clr-sd-1);
}

.kd-clr-h-1 {
  color: var(--kd-clr-h-1);
}

.kd-clr-p-1 {
  color: var(--kd-clr-p-1);
}

.kd-ml-auto {
  margin-left: auto;
}

.kd-mr-auto {
  margin-right: auto;
}

.line-limit {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.line-limit:is(.has-line-1) {
  -webkit-line-clamp: 1;
  line-clamp: 1;
}
.line-limit:is(.has-line-2) {
  -webkit-line-clamp: 2;
  line-clamp: 2;
}
.line-limit:is(.has-line-3) {
  -webkit-line-clamp: 3;
  line-clamp: 3;
}
.line-limit:is(.has-line-4) {
  -webkit-line-clamp: 4;
  line-clamp: 4;
}
.line-limit:is(.has-line-5) {
  -webkit-line-clamp: 5;
  line-clamp: 5;
}
.line-limit:is(.has-line-6) {
  -webkit-line-clamp: 6;
  line-clamp: 6;
}
.line-limit:is(.has-line-7) {
  -webkit-line-clamp: 7;
  line-clamp: 7;
}
.line-limit:is(.has-line-8) {
  -webkit-line-clamp: 8;
  line-clamp: 8;
}
.line-limit:is(.has-line-9) {
  -webkit-line-clamp: 9;
  line-clamp: 9;
}
.line-limit:is(.has-line-10) {
  -webkit-line-clamp: 10;
  line-clamp: 10;
}

/* 
    all-common-class-end
*/
/* 


*/
/* 
    heading-paragraph-css-start
*/
.kd-heading-1 {
  font-family: var(--kd-font-pr-1);
  line-height: 1.09;
  color: var(--kd-clr-h-1);
  text-transform: capitalize;
  margin-bottom: 0px;
}
.kd-heading-1:is(.has-clr-white) {
  color: #fff;
}

.kd-heading-2 {
  font-family: var(--kd-font-fraunces);
  line-height: 1.07;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 0px;
}

.kd-para-1 {
  font-family: var(--kd-font-pr-1);
  font-weight: 400;
  line-height: 32px;
  font-size: 20px;
  color: var(--kd-clr-p-1);
  margin-bottom: 0px;
}
.kd-para-1:is(.has-clr-white) {
  color: #fff;
}

.kd-para-2 {
  font-family: var(--kd-font-barlow);
  font-weight: 400;
  line-height: 30px;
  font-size: 20px;
  color: #fff;
  margin-bottom: 0px;
  opacity: 80%;
}
.kd-para-2:is(.has-para-sm) {
  font-size: 16px;
  line-height: 26px;
}

.kd-subtitle-1 {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  font-size: 42px;
  font-family: var(--kd-font-sd-1);
  line-height: 1;
  margin-bottom: 10px;
  text-transform: capitalize;
  color: var(--kd-clr-pr-1);
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-subtitle-1 {
    font-size: 30px;
  }
}
.kd-subtitle-1:is(.has-clr-2) {
  color: var(--kd-clr-sd-1);
}
.kd-subtitle-1:is(.has-clr-white) {
  color: #fff;
}
.kd-subtitle-1:is(.has-clr-black) {
  color: #000;
}

.kd-subtitle-2 {
  display: inline-block;
  font-size: 20px;
  font-family: var(--kd-font-barlow);
  line-height: 1;
  font-weight: 600;
  margin-bottom: 23px;
  text-transform: uppercase;
  color: var(--kd-clr-sd-4);
}

.kd-section-title-1 {
  font-family: var(--kd-font-pr-1);
  font-size: 55px;
  line-height: 1.09;
  color: var(--kd-clr-h-1);
  text-transform: capitalize;
  font-weight: 900;
  margin-bottom: 13px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .kd-section-title-1 {
    font-size: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-section-title-1 {
    font-size: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-section-title-1 {
    font-size: 38px;
  }
}
@media (max-width: 767px) {
  .kd-section-title-1 {
    font-size: 28px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-section-title-1 {
    font-size: 35px;
  }
}
.kd-section-title-1:is(.has-clr-white) {
  color: #fff;
}

.kd-scn-title-2 {
  font-family: var(--kd-font-fraunces);
  font-size: 65px;
  line-height: 1.07;
  color: #fff;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 30px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .kd-scn-title-2 {
    font-size: 55px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .kd-scn-title-2 {
    font-size: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-scn-title-2 {
    font-size: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-scn-title-2 {
    font-size: 35px;
  }
}
@media (max-width: 767px) {
  .kd-scn-title-2 {
    font-size: 28px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-scn-title-2 {
    font-size: 35px;
  }
}
.kd-scn-title-2:is(.has-clr-white) {
  color: #fff;
}

/* 
    heading-paragraph-css-end
*/
/* 
    button-component-start
*/
/* 
    mobile-menu-btn-1-start
*/
.ftc-mobile-menu-btn-1 {
  border: none;
  outline: none;
  background: transparent;
  flex: 0 0 auto;
  width: 22px;
  height: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 500ms var(--cube-2);
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 0;
}
.ftc-mobile-menu-btn-1 span {
  display: block;
  background: #fff;
  width: 100%;
  height: 2px;
  transition: 500ms var(--cube-2);
}
.ftc-mobile-menu-btn-1:focus span {
  transform: rotate(45deg);
}
.ftc-mobile-menu-btn-1:hover span {
  transform: rotate(45deg);
}

/* 
    mobile-menu-btn-1-end 
*/
/* 
    pr-btn-1-start
*/
.kd-pr-btn-1 {
  display: inline-block;
  font-family: var(--kd-font-pr-1);
  color: #22281e;
  font-weight: 800;
  font-size: 15px;
  line-height: 1;
  background: var(--kd-clr-sd-1);
  border-radius: 30px;
  padding: 20px 31px;
  position: relative;
  z-index: 1;
  transition: 0.3s var(--cube-1);
}
.kd-pr-btn-1 .icon {
  display: inline-block;
  margin-left: 5px;
  transition: 0.3s var(--cube-1);
}
.kd-pr-btn-1 .text {
  display: inline-block;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: -3px;
}
.kd-pr-btn-1 .text::after {
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  display: block;
  transition: 0.5s;
  position: absolute;
  background: transparent;
  color: #fff;
  content: attr(data-back);
  transform: translateX(-100%);
}
.kd-pr-btn-1 .text::before {
  top: 0;
  left: 0;
  opacity: 1;
  display: block;
  transition: 0.5s;
  position: relative;
  background: var(--blta-pr-1);
  color: #000;
  content: attr(data-front);
  transform: translateX(0);
}
.kd-pr-btn-1:hover {
  color: #fff;
  background: var(--kd-clr-pr-1);
}
.kd-pr-btn-1:hover .text::after {
  opacity: 1;
  transform: translateX(0);
}
.kd-pr-btn-1:hover .text::before {
  opacity: 0;
  transform: translateX(100%);
}
.kd-pr-btn-1:is(.has-black) {
  background: #363539;
}
.kd-pr-btn-1:is(.has-black) .text::after {
  color: #000;
}
.kd-pr-btn-1:is(.has-black) .text::before {
  color: #fff;
}
.kd-pr-btn-1:is(.has-black) .icon {
  color: #fff;
}
.kd-pr-btn-1:is(.has-black):hover {
  background: var(--kd-clr-sd-1);
}
.kd-pr-btn-1:is(.has-black):hover .icon {
  color: #000;
}
.kd-pr-btn-1:is(.has-black-2) {
  background: #000;
}
.kd-pr-btn-1:is(.has-black-2) .text::after {
  color: #000;
}
.kd-pr-btn-1:is(.has-black-2) .text::before {
  color: #fff;
}
.kd-pr-btn-1:is(.has-black-2) .icon {
  color: #fff;
}
.kd-pr-btn-1:is(.has-black-2):hover {
  background: var(--kd-clr-sd-1);
}
.kd-pr-btn-1:is(.has-black-2):hover .icon {
  color: #000;
}
.kd-pr-btn-1:is(.has-pr-clr) {
  background: var(--kd-clr-pr-1);
}
.kd-pr-btn-1:is(.has-pr-clr) .text::after {
  color: #000;
}
.kd-pr-btn-1:is(.has-pr-clr) .text::before {
  color: #fff;
}
.kd-pr-btn-1:is(.has-pr-clr) .icon {
  color: #fff;
}
.kd-pr-btn-1:is(.has-pr-clr):hover {
  background: var(--kd-clr-sd-1);
}
.kd-pr-btn-1:is(.has-pr-clr):hover .icon {
  color: #000;
}

/* 
    pr-btn-1-end
*/
/* 
    pr-btn-2-start
*/
.kd-pr-btn-2 {
  display: inline-block;
  font-family: var(--kd-font-fraunces);
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  background: var(--kd-clr-pr-4);
  border-radius: 5px;
  padding: 20px 24px;
  padding-right: 12px;
  position: relative;
  z-index: 1;
  transition: 0.3s var(--cube-1);
  overflow: hidden;
}
.kd-pr-btn-2::after {
  content: "";
  width: 32px;
  height: 100%;
  background: rgba(21, 32, 40, 0.2);
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.kd-pr-btn-2 .icon {
  display: inline-block;
  margin-left: 27px;
  transition: 0.3s var(--cube-1);
}
.kd-pr-btn-2 .text {
  display: inline-block;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: -3px;
}
.kd-pr-btn-2 .text::after {
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  display: block;
  transition: 0.5s;
  position: absolute;
  background: transparent;
  color: #fff;
  content: attr(data-back);
  transform: translateX(-100%);
}
.kd-pr-btn-2 .text::before {
  top: 0;
  left: 0;
  opacity: 1;
  display: block;
  transition: 0.5s;
  position: relative;
  background: var(--blta-pr-1);
  color: #fff;
  content: attr(data-front);
  transform: translateX(0);
}
.kd-pr-btn-2:hover {
  color: #fff;
  background: var(--kd-clr-sd-4);
  padding-right: 24px;
}
.kd-pr-btn-2:hover .icon {
  margin-left: 7px;
}
.kd-pr-btn-2:hover:hover::after {
  width: 100%;
}
.kd-pr-btn-2:hover .text::after {
  opacity: 1;
  transform: translateX(0);
}
.kd-pr-btn-2:hover .text::before {
  opacity: 0;
  transform: translateX(100%);
}
.kd-pr-btn-2:is(.has-black) {
  background: #363539;
}
.kd-pr-btn-2:is(.has-black) .text::after {
  color: #000;
}
.kd-pr-btn-2:is(.has-black) .text::before {
  color: #fff;
}
.kd-pr-btn-2:is(.has-black) .icon {
  color: #fff;
}
.kd-pr-btn-2:is(.has-black):hover {
  background: var(--kd-clr-sd-1);
}
.kd-pr-btn-2:is(.has-black):hover .icon {
  color: #000;
}
.kd-pr-btn-2:is(.has-black-2) {
  background: #000;
}
.kd-pr-btn-2:is(.has-black-2) .text::after {
  color: #000;
}
.kd-pr-btn-2:is(.has-black-2) .text::before {
  color: #fff;
}
.kd-pr-btn-2:is(.has-black-2) .icon {
  color: #fff;
}
.kd-pr-btn-2:is(.has-black-2):hover {
  background: var(--kd-clr-sd-1);
}
.kd-pr-btn-2:is(.has-black-2):hover .icon {
  color: #000;
}
.kd-pr-btn-2:is(.has-pr-clr) {
  background: var(--kd-clr-pr-1);
}
.kd-pr-btn-2:is(.has-pr-clr) .text::after {
  color: #000;
}
.kd-pr-btn-2:is(.has-pr-clr) .text::before {
  color: #fff;
}
.kd-pr-btn-2:is(.has-pr-clr) .icon {
  color: #fff;
}
.kd-pr-btn-2:is(.has-pr-clr):hover {
  background: var(--kd-clr-sd-1);
}
.kd-pr-btn-2:is(.has-pr-clr):hover .icon {
  color: #000;
}
.kd-pr-btn-2:is(.not-icon) {
  padding: 20px 24px;
  padding-right: 24px;
}
.kd-pr-btn-2:is(.not-icon)::after {
  display: none;
}

/* 
    pr-btn-2-end
*/
/* 
    cart-btn-1-start
*/
.kd-cart-btn-1 {
  display: flex;
  flex: 0 0 auto;
  width: 44px;
  height: 44px;
  align-items: center;
  justify-content: center;
  background: var(--kd-clr-pr-1);
  border-radius: 50%;
  font-size: 17px;
  color: #fff;
  transition: 0.3s var(--cube-1);
}
.kd-cart-btn-1:hover {
  color: #fff;
  background: var(--kd-clr-sd-1);
  animation: 1s kd-jello;
}

/* 
    cart-btn-1-end
*/
/* 
    search-btn-1-start
*/
.kd-search-btn-1 {
  display: flex;
  flex: 0 0 auto;
  width: 44px;
  height: 44px;
  align-items: center;
  justify-content: center;
  background: var(--kd-clr-pr-1);
  border-radius: 50%;
  font-size: 17px;
  color: #fff;
  transition: 0.3s var(--cube-1);
}
.kd-search-btn-1:hover {
  background: var(--kd-clr-sd-1);
  animation: 1s kd-jello;
}

/* 
    search-btn-1-end
*/
/* 
    ply-btn-1-start
*/
.kd-plybtn-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #4C40F6;
  color: #fff;
  font-size: 20px;
  position: relative;
  z-index: 1;
  transition: 400ms var(--cube-1);
}
.kd-plybtn-1::after {
  content: "";
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: #4C40F6;
  opacity: 0.5;
  z-index: -1;
  animation: kd-plybtn-1 4s linear infinite;
}
.kd-plybtn-1::before {
  content: "";
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: #4C40F6;
  opacity: 0.5;
  z-index: -1;
  animation: kd-plybtn-1 2s linear infinite;
}
.kd-plybtn-1:hover {
  color: var(--kd-clr-sd-1);
}

@keyframes kd-plybtn-1 {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.5;
  }
  100% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }
}
/* 
    ply-btn-1-end
*/
/* 
    land-btn-1-start
*/
.kd-land-1-btn {
  border-radius: 0;
  border: none;
  font-family: var(--kd-font-pr-1);
  color: #050505;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 16px;
  padding-left: 0;
  line-height: 1;
  height: auto;
  padding-right: 15px;
}
.kd-land-1-btn::after {
  right: 5px;
}
.kd-land-1-btn .current {
  display: flex;
  align-items: center;
  gap: 5px;
}
.kd-land-1-btn .flag {
  display: inline-flex;
  align-content: center;
  justify-content: center;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background: #fff;
  flex: 0 0 auto;
}
.kd-land-1-btn .flag img {
  width: 100%;
  height: 100%;
}
.kd-land-1-btn .list li {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* 
    land-btn-1-end
*/
/* 
    button-component-end
*/
/* 
  mobile-menu-css-start
*/
.mobile-menu {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 9;
  display: none;
}
.mobile-menu ::-webkit-scrollbar {
  display: none;
}
.mobile-menu-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  display: flex;
  flex-direction: column;
}
.mobile-menu-bg span {
  flex-wrap: wrap;
  display: block;
  background-color: var(--kd-clr-pr-1);
  width: 100%;
}
.mobile-menu-bg span:nth-child(1) {
  height: 50%;
}
.mobile-menu-bg span:nth-child(2) {
  height: 50%;
}
.mobile-menu-wrap {
  height: 100%;
  padding: 60px 200px;
  overflow-y: scroll;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .mobile-menu-wrap {
    padding: 60px 100px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .mobile-menu-wrap {
    padding: 60px 50px;
  }
}
@media (max-width: 767px) {
  .mobile-menu-wrap {
    padding: 40px 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mobile-menu-wrap {
    padding: 60px 40px;
  }
}
.mobile-menu-logo-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
.mobile-menu-logo-wrap .mobile-menu-logo {
  max-width: 180px;
}
.mobile-menu-close {
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}
.mobile-menu-close i {
  line-height: 1;
  display: inline-block;
  transition: 300ms ease-in-out;
}
.mobile-menu-close:hover {
  color: var(--kd-clr-sd-1);
}
.mobile-menu-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .mobile-menu-row {
    grid-template-columns: 1fr;
    gap: 100px;
  }
}
.mobile-menu-right {
  height: 460px;
  overflow: scroll;
  padding: 30px 0;
  margin-top: -30px;
}

.mobile-menu-search-form-1 input {
  height: 60px;
  border-radius: 5px;
  padding: 0 30px;
  background: #fff;
  font-family: var(--kd-font-pr-1);
  width: 100%;
  border: none;
  outline: none;
  color: #000;
  margin-bottom: 10px;
}
.mobile-menu-search-form-1 .form-btn {
  border: none;
  outline: none;
  background-color: #fff;
  border-radius: 5px;
  padding: 12px 20px;
  color: #000;
  transition: 300ms var(--cube-1);
  text-transform: capitalize;
  font-family: var(--kd-font-pr-1);
}
.mobile-menu-search-form-1 .form-btn:hover {
  background-color: var(--kd-clr-sd-1);
  color: #000;
  transform: scale(95%);
}

.mobile-menu-social-1 {
  gap: 25px;
}
.mobile-menu-social-1 a {
  display: inline-block;
  font-size: 22px;
  line-height: 1;
  color: #fff;
  opacity: 0.8;
  transition: 300ms var(--cube-1);
}
.mobile-menu-social-1 a:hover {
  color: var(--kd-clr-sd-1);
  opacity: 1;
}

/* 
  header-menu-customazition-start
*/
.mobile-main-navigation .navbar-nav li {
  position: relative;
}
.mobile-main-navigation .navbar-nav li:not(:last-child) {
  margin-bottom: 10px;
}
.mobile-main-navigation .navbar-nav li a {
  color: #fff;
  font-family: var(--kd-font-pr-1);
  font-size: 24px;
  text-transform: capitalize;
  font-weight: 600;
}
.mobile-main-navigation .navbar-nav li a:hover {
  color: var(--kd-clr-sd-1);
}
.mobile-main-navigation .navbar-nav li .dropdown-btn {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.15);
  font-size: 14px;
  color: #fff;
  transition: 400ms ease-in-out;
  border-radius: 5px;
}
.mobile-main-navigation .navbar-nav li .dropdown-btn:hover {
  background-color: var(--kd-clr-sd-1);
}
.mobile-main-navigation .navbar-nav li .dropdown-btn:is(.active) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  background-color: var(--kd-clr-sd-1);
}
.mobile-main-navigation .navbar-nav li .dropdown-menu {
  border-radius: 0;
  background-color: transparent;
  border: none;
  border-radius: 3px;
}
.mobile-main-navigation .navbar-nav li .dropdown-menu li {
  padding-left: 30px;
  margin-bottom: 0;
}
.mobile-main-navigation .navbar-nav li .dropdown-menu li a {
  font-size: 18px;
}
.mobile-main-navigation .navbar-nav li .dropdown-menu li .dropdown-btn {
  position: absolute;
  top: 5px;
  width: 22px;
  height: 22px;
  font-size: 12px;
}

/* 
  header-menu-customazition-start
*/
/* 
  mobile-menu-css-start
*/
.kd-home-1 {
  position: relative;
  z-index: 1;
}
.kd-home-1-bg-line {
  display: flex;
  justify-content: space-around;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.kd-home-1-bg-line-single {
  display: block;
  width: 1px;
  height: 100%;
  background: #F3F5FF;
  position: relative;
}
.kd-home-1-bg-line-single::after {
  content: "";
  width: 5px;
  height: 23px;
  border-radius: 2.5px;
  background-color: #656ae5;
  position: absolute;
  top: 0;
  left: -2px;
  animation: kd-home-1-bg 80s linear infinite;
}
.kd-home-1-bg-line-single:nth-child(1)::after {
  animation-delay: 1s;
}
.kd-home-1-bg-line-single:nth-child(3)::after {
  animation-delay: 2s;
}
.kd-home-1-bg-line-single:nth-child(4)::after {
  animation-delay: 0.5s;
}
.kd-home-1-bg-line-single:nth-child(5)::after {
  animation-delay: 1.5s;
}

@keyframes kd-home-1-bg {
  0% {
    top: 0%;
  }
  50% {
    top: 100%;
  }
  100% {
    top: 0%;
  }
}
.kd-home-4 {
  background-color: #152028;
}

/* 
  spilting-wrating-start
*/
.splitting.animated .char {
  -webkit-animation: fadeInUp 0.4s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeInUp 0.4s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(30ms * var(--char-index));
  animation-delay: calc(30ms * var(--char-index));
}

.splitting .whitespace {
  width: 10px;
}

/* 
  spilting-wrating-end
*/
/* 
  img-ani-1-start
*/
.kd-img-ani-1 {
  transition: transform 500ms;
  transform-origin: center center;
}

/* 
  img-ani-1-end
*/
.kd-home-1-scroll-progress {
  display: block;
  width: 100%;
  height: 5px;
  background-color: var(--kd-clr-pr-1);
}

@-webkit-keyframes wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes kd-jello {
  11.1% {
    transform: none;
  }
  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
  100% {
    transform: none;
  }
}
/* 
	preloader-css-start
*/
#preloader {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 99999999;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  transition: 1000ms var(--cube-1);
}
#preloader .preloader-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
#preloader .preloader-wrap .loading {
  text-align: center;
}
#preloader .preloader-wrap .loading .logo {
  max-width: 200px;
}
#preloader .preloader-wrap .icon-ani {
  width: 240px;
}
#preloader:is(.preloaded) {
  transform: translateY(-100%);
}

/* 
	preloader-css-end
*/
/* 
    search-box-css-start
*/
.popup-search-box {
  background-color: var(--kd-clr-pr-1);
  position: fixed;
  display: flex;
  left: 0;
  top: -100px;
  width: 100%;
  z-index: 9;
  height: 100vh;
  align-items: center;
  transform: scaleY(0);
  transition: 500ms var(--cube-1);
  transform-origin: top;
  visibility: hidden;
}
.popup-search-box-close {
  height: 50px;
  width: 50px;
  font-size: 26px;
  border: none;
  outline: none;
  border-radius: 5px;
  background: #fff;
  color: #000;
  flex: 0 0 auto;
  transition: 300ms var(--cube-1);
  position: absolute;
  right: 50px;
  top: 50px;
  line-height: 1;
}
@media (max-width: 767px) {
  .popup-search-box-close {
    top: 15px;
    right: 15px;
  }
}
.popup-search-box-close i {
  font-weight: 700;
  line-height: 1;
}
.popup-search-box-close:hover {
  background: var(--kd-clr-sd-1);
  color: #000;
}
.popup-search-box-container {
  max-width: 600px;
}
.popup-search-box .title {
  color: #fff;
  margin-bottom: 25px;
  font-size: 40px;
}
@media (max-width: 767px) {
  .popup-search-box .title {
    font-size: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .popup-search-box .title {
    font-size: 32px;
  }
}
.popup-search-box-form {
  display: flex;
}
@media (max-width: 767px) {
  .popup-search-box-form {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .popup-search-box-form {
    flex-wrap: nowrap;
  }
}
@media (max-width: 767px) {
  .popup-search-box {
    height: 100%;
    overflow-y: auto;
  }
}
.popup-search-box.active {
  top: 0;
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
}
.popup-search-box form {
  position: relative;
  width: 100%;
}
@media (max-width: 767px) {
  .popup-search-box form {
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .popup-search-box form {
    margin-bottom: 0px;
  }
}
.popup-search-box-input input {
  width: 100%;
  height: 60px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2392156863);
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 18px;
  font-weight: 700;
  color: #000;
  padding: 0 30px;
  padding-right: 115px;
  font-family: var(--kd-font-pr-1);
}
.popup-search-box-input input::placeholder {
  color: #000;
  font-size: 18px;
}
.popup-search-box button[type=submit] {
  position: absolute;
  right: 0;
  top: 0px;
  bottom: 0;
  padding: 0 30px;
  background: var(--kd-clr-sd-1);
  color: #000;
  font-size: 24px;
  border-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: none;
  transition: 300ms var(--cube-1);
}
.popup-search-box button[type=submit] i {
  font-weight: 700;
}
.popup-search-box button[type=submit]:hover {
  color: #fff;
  background: #000;
}

/* 
    search-box-css-end
*/
.scroll-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 45px;
  cursor: pointer;
  display: block;
  z-index: 6;
  opacity: 1;
  visibility: visible;
  transition: all 300ms linear;
  background: transparent;
  text-align: center;
  color: #fff;
  background: var(--txa-gd-1);
  border-radius: 30px;
  border: 2px solid transparent;
  transition: 300ms var(--cube-2);
  transform: translateY(200px);
}
.scroll-top:hover {
  bottom: 35px;
}

.scroll-top-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 30px;
  padding: 7px 0;
}
.scroll-top-wrap svg {
  fill: var(--txa-pr-1);
  width: 38px;
}
.scroll-top-wrap .text {
  display: inline-block;
  writing-mode: vertical-lr;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.5px;
  background: var(--txa-gd-1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 767px) {
  .scroll-top-wrap .text {
    display: none;
  }
}

.scroll-top.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

/* 


*/
/* 
  header-1-css-start
*/
.kd-header-1-area {
  background: #fff;
}
.kd-header-1-top-row {
  gap: 35px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-header-1-top-row {
    justify-content: space-between;
  }
}
@media (max-width: 767px) {
  .kd-header-1-top-row .kd-pr-btn-1 {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-header-1-top-row .kd-pr-btn-1 {
    display: inline-block;
  }
}
.kd-header-1-top-contact-item {
  gap: 10px;
}
.kd-header-1-top-contact-item .icon {
  flex: 0 0 auto;
  width: 45px;
  height: 45px;
  background: #22281e;
  border-radius: 50%;
  color: #fff;
  font-size: 16px;
  transition: 300ms var(--cube-1);
}
.kd-header-1-top-contact-item .subtitle {
  font-size: 15px;
  color: #363539;
  margin-bottom: -2px;
}
.kd-header-1-top-contact-item .link {
  font-size: 18px;
  font-weight: 800;
  line-height: 1;
  transition: 300ms var(--cube-1);
}
.kd-header-1-top-contact-item .link:hover {
  color: var(--kd-clr-pr-1);
  transform: translateX(3px);
}
.kd-header-1-top-contact-item:hover .icon {
  background: var(--kd-clr-pr-1);
  animation: kd-jello 1s;
}
.kd-header-1-logo-1 {
  max-width: 180px;
}
.kd-header-1-main {
  background: #00675f;
  position: relative;
  z-index: 1;
}
.kd-header-1-main::after {
  content: "";
  width: 34%;
  height: 100%;
  background: #017067;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 7% 100%);
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-header-1-main {
    padding: 10px 0;
  }
}
.kd-header-1-main-row {
  gap: 15px;
}
.kd-header-1-main-home-icon {
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  background: var(--kd-clr-pr-1);
  border-radius: 50%;
  color: #fff;
  font-size: 16px;
}
.kd-header-1-action-link {
  gap: 10px;
}
.kd-header-1-action-link .gap-left {
  margin-left: 35px;
}

.main-navigation .navbar-nav {
  display: inherit;
}
.main-navigation .navbar-nav li {
  display: inline-block;
  position: relative;
}
.main-navigation .navbar-nav li:not(:last-child) {
  margin-right: 45px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-navigation .navbar-nav li:not(:last-child) {
    margin-right: 20px;
  }
}
.main-navigation .navbar-nav li:is(.dropdown) > a {
  padding-right: 15px;
}
.main-navigation .navbar-nav li:is(.dropdown) > a::before {
  /* content: "\f0d7";
  font-family: "Font Awesome 6 Free";
  font-weight: 700;
  position: absolute;
  font-size: 14px;
  right: 0;
  top: 49%;
  transform: translateY(-50%);
  transition: 400ms var(--cube-1); */
}
.main-navigation .navbar-nav li a {
  position: relative;
  z-index: 1;
  font-family: var(--kd-font-pr-1);
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  color: #fff;
  padding: 26px 0;
  display: inline-block;
  text-transform: capitalize;
  line-height: 1;
  transition: 400ms var(--cube-1);
}
.main-navigation .navbar-nav li a:is(.is-active) {
  color: var(--kd-clr-sd-1);
}
.main-navigation .navbar-nav li .dropdown-menu {
  left: 0;
  top: 100%;
  z-index: 100;
  margin: 0px;
  padding: 20px 0px;
  height: auto;
  min-width: 250px;
  display: block;
  border: none;
  border-top: 2px solid var(--kd-clr-sd-1);
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.05);
  border-radius: 0;
  position: absolute;
  background: #017067;
  border-radius: 2px;
  text-align: left;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px) translateZ(0);
  transition: opacity 0.4s cubic-bezier(0.19, 1, 0.22, 1), visibility 0.4s cubic-bezier(0.19, 1, 0.22, 1), transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
.main-navigation .navbar-nav li .dropdown-menu li {
  display: block;
  margin: 0;
  position: relative;
}
.main-navigation .navbar-nav li .dropdown-menu li a {
  color: #fff;
  width: 100%;
  display: block;
  padding: 10px 20px;
  font-size: 16px;
}
.main-navigation .navbar-nav li .dropdown-menu li a:is(.is-active) {
  color: var(--kd-clr-sd-1);
}
.main-navigation .navbar-nav li .dropdown-menu li:is(.dropdown) > a::before {
  right: 20px;
  top: 49%;
}
.main-navigation .navbar-nav li .dropdown-menu li .dropdown-menu {
  top: 0;
  left: 100%;
  min-width: 250px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px) translateZ(0);
  transition: opacity 0.4s cubic-bezier(0.19, 1, 0.22, 1), visibility 0.4s cubic-bezier(0.19, 1, 0.22, 1), transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
.main-navigation .navbar-nav li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px) translateZ(0);
}
.main-navigation .navbar-nav li .dropdown-menu li:hover > a {
  color: var(--kd-clr-sd-1);
}
.main-navigation .navbar-nav li:hover > a {
  color: var(--kd-clr-sd-1);
}
.main-navigation .navbar-nav li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px) translateZ(0);
}

/* 
  header-1-css-end
*/
/* 
  header-2-css-start
*/
.kd-header-2-area {
  position: absolute;
  z-index: 7;
  width: 100%;
  left: 0;
  top: 25px;
}
.kd-header-2-top {
  display: flex;
  gap: 20px;
  padding: 10px 50px;
  background: var(--kd-clr-sd-1);
  border-radius: 100px 100px 0 0;
  max-width: 630px;
  margin-left: auto;
  margin-right: 30px;
}
@media (max-width: 767px) {
  .kd-header-2-top {
    display: none;
  }
}
.kd-header-2-top-contact {
  display: flex;
  align-items: center;
  gap: 10px 20px;
  flex-wrap: wrap;
}
.kd-header-2-top-contact li {
  font-family: var(--kd-font-pr-1);
  color: #131313;
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
}
.kd-header-2-top-contact li i {
  margin-right: 3px;
}
.kd-header-2-top-contact li a {
  display: inline-block;
  transition: 0.3s var(--cube-1);
}
.kd-header-2-top-contact li a:hover {
  color: var(--kd-clr-pr-1);
  transform: translateX(3px);
}
.kd-header-2-main {
  background: #fff;
  margin-left: -18px;
  margin-right: -18px;
  padding: 0 30px;
  border-radius: 53px;
  gap: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-header-2-main {
    padding: 15px 15px;
    margin-left: 0px;
    margin-right: 0px;
  }
}
.kd-header-2-action-link {
  gap: 26px;
}
.kd-header-2-action-link .kd-search-btn-1 {
  width: unset;
  height: unset;
  color: #000;
  background: transparent;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-header-2-action-link .kd-search-btn-1 {
    display: none;
  }
}
.kd-header-2-action-link .kd-search-btn-1:hover {
  color: var(--kd-clr-pr-1);
}
.kd-header-2-action-link .kd-cart-btn-1 {
  width: unset;
  height: unset;
  color: #000;
  background: transparent;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-header-2-action-link .kd-cart-btn-1 {
    display: none;
  }
}
.kd-header-2-action-link .kd-cart-btn-1:hover {
  color: var(--kd-clr-pr-1);
}
.kd-header-2-action-link .ftc-mobile-menu-btn-1 {
  height: 20px;
}
@media (min-width: 1800px), only screen and (min-width: 1600px) and (max-width: 1799px), only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-header-2-action-link .ftc-mobile-menu-btn-1 {
    display: none;
  }
}
.kd-header-2-action-link .ftc-mobile-menu-btn-1 span {
  border-radius: 3px;
  background: var(--kd-clr-pr-1);
  height: 4px;
}
@media (max-width: 767px) {
  .kd-header-2-action-link .kd-pr-btn-1 {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-header-2-action-link .kd-pr-btn-1 {
    display: inline-block;
  }
}
.kd-header-2-bottom {
  margin-left: 25px;
  padding: 10px 50px;
  background: var(--kd-clr-sd-1);
  border-radius: 0 0 100px 100px;
  display: inline-block;
}
@media (max-width: 767px) {
  .kd-header-2-bottom {
    display: none;
  }
}
.kd-header-2-bottom-text {
  font-size: 15px;
  color: #282828;
  font-weight: 600;
}

.kd-header-2-main .main-navigation .navbar-nav li a {
  font-size: 16px;
  font-weight: 800;
  color: #191919;
  padding: 42px 0;
  text-transform: uppercase;
}
.kd-header-2-main .main-navigation .navbar-nav li a:is(.is-active) {
  color: var(--kd-clr-sd-1);
}
.kd-header-2-main .main-navigation .navbar-nav li:is(.dropdown) > a {
  padding-right: 15px;
}
.kd-header-2-main .main-navigation .navbar-nav li .dropdown-menu {
  background: #fff;
  border-color: var(--kd-clr-pr-1);
}
.kd-header-2-main .main-navigation .navbar-nav li .dropdown-menu li a {
  color: #191919;
  font-weight: 600;
}
.kd-header-2-main .main-navigation .navbar-nav li .dropdown-menu li:hover > a {
  color: var(--kd-clr-sd-1);
}
.kd-header-2-main .main-navigation .navbar-nav li:hover a {
  color: var(--kd-clr-sd-1);
}

.kd-social-link-1 {
  display: flex;
  align-items: center;
  gap: 10px 20px;
  flex-wrap: wrap;
}
.kd-social-link-1 .link {
  font-size: 16px;
  color: #131313;
  line-height: 1;
  display: inline-block;
}
.kd-social-link-1 .link:hover {
  color: var(--kd-clr-pr-1);
}

/* 
  header-2-css-end
*/
/* 
  header-3-css-start
*/
.kd-header-3-area {
  position: absolute;
  top: 25px;
  left: 0;
  width: 100%;
  z-index: 2;
}
.kd-header-3-top {
  display: flex;
  position: relative;
  z-index: 1;
  background: #014f4b;
  margin-right: 50px;
  margin-left: 50px;
  padding: 15px 60px;
  border-radius: 200px 200px 0 0;
}
@media (max-width: 767px) {
  .kd-header-3-top {
    display: none;
  }
}
.kd-header-3-top .kd-header-2-top-contact {
  gap: 10px 40px;
}
.kd-header-3-top .kd-header-2-top-contact li {
  color: #fff;
  font-size: 15px;
}
.kd-header-3-top .kd-header-2-top-contact li a:hover {
  color: var(--kd-clr-sd-1);
}
.kd-header-3-top .kd-social-link-1 .link {
  color: #fff;
  transition: 0.3s var(--cube-1);
}
.kd-header-3-top .kd-social-link-1 .link:hover {
  color: var(--kd-clr-sd-1);
  transform: translateX(3px);
}
.kd-header-3-logo-1 {
  max-width: 170px;
}
.kd-header-3-main {
  background: #fff;
  border-radius: 50px;
  padding: 0 60px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-header-3-main {
    padding: 0px 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-header-3-main {
    padding: 15px 30px;
  }
}
.kd-header-3-action-link {
  gap: 30px;
}
@media (max-width: 767px) {
  .kd-header-3-action-link .kd-land-1-btn {
    display: none;
  }
}
@media (min-width: 1800px), only screen and (min-width: 1600px) and (max-width: 1799px), only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-header-3-action-link .ftc-mobile-menu-btn-1 {
    display: none;
  }
}
.kd-header-3-action-link .kd-cart-btn-1 {
  width: auto;
  height: auto;
  color: #0d112f;
  background: transparent;
}
@media (max-width: 767px) {
  .kd-header-3-action-link .kd-cart-btn-1 {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-header-3-action-link .kd-cart-btn-1 {
    display: inline-block;
  }
}
.kd-header-3-action-link .kd-cart-btn-1:hover {
  color: var(--kd-clr-sd-1);
}
.kd-header-3-action-link .kd-search-btn-1 {
  width: auto;
  height: auto;
  color: #0d112f;
  background: transparent;
}
@media (max-width: 767px) {
  .kd-header-3-action-link .kd-search-btn-1 {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-header-3-action-link .kd-search-btn-1 {
    display: inline-block;
  }
}
.kd-header-3-action-link .kd-search-btn-1:hover {
  color: var(--kd-clr-sd-1);
}
.kd-header-3-action-link .ftc-mobile-menu-btn-1 span {
  display: block;
  background: var(--kd-clr-pr-1);
}
.kd-header-3-bottom {
  flex-wrap: wrap;
  gap: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  background: #014f4b;
  margin-right: 50px;
  margin-left: 50px;
  padding: 15px 60px;
  border-radius: 0 0 200px 200px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-header-3-bottom {
    display: none;
  }
}
.kd-header-3-bottom-link {
  font-size: 15px;
  transition: 0.2s var(--cube-1);
}
.kd-header-3-bottom-link i {
  margin-right: 3px;
}
.kd-header-3-bottom-link:hover {
  color: var(--kd-clr-sd-1);
  transform: translateX(3px);
}

.kd-header-3-main .main-navigation .navbar-nav li a {
  font-size: 18px;
  font-weight: 800;
  color: #282828;
  padding: 41px 0;
}
.kd-header-3-main .main-navigation .navbar-nav li a:is(.is-active) {
  color: var(--kd-clr-sd-1);
}
.kd-header-3-main .main-navigation .navbar-nav li:is(.dropdown) > a {
  padding-right: 15px;
}
.kd-header-3-main .main-navigation .navbar-nav li .dropdown-menu li a {
  font-weight: 600;
}
.kd-header-3-main .main-navigation .navbar-nav li:hover > a {
  color: var(--kd-clr-sd-1);
}

/* 
  header-3-css-end
*/
/* 
  header-4-css-start
*/
.kd-header-4-area {
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}
.kd-header-4-area::after {
  content: "";
  width: 28%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--kd-clr-pr-4);
  clip-path: polygon(0 0, 91% 0%, 100% 100%, 0% 100%);
  position: absolute;
  z-index: -1;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .kd-header-4-area::after {
    width: 25%;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .kd-header-4-area::after {
    width: 22%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .kd-header-4-area::after {
    width: 20%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-header-4-area::after {
    width: 22%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-header-4-area::after {
    width: 32%;
  }
}
@media (max-width: 767px) {
  .kd-header-4-area::after {
    width: 68%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-header-4-area::after {
    width: 40%;
  }
}
@media only screen and (min-width: 2000px) and (max-width: 2200px) {
  .kd-header-4-area::after {
    width: 30%;
  }
}
@media only screen and (min-width: 2201px) and (max-width: 2400px) {
  .kd-header-4-area::after {
    width: 32%;
  }
}
@media only screen and (min-width: 2401px) and (max-width: 2600px) {
  .kd-header-4-area::after {
    width: 34%;
  }
}
@media only screen and (min-width: 2601px) and (max-width: 2800px) {
  .kd-header-4-area::after {
    width: 36%;
  }
}
@media only screen and (min-width: 2801px) and (max-width: 3000px) {
  .kd-header-4-area::after {
    width: 38%;
  }
}
@media only screen and (min-width: 3001px) and (max-width: 6000px) {
  .kd-header-4-area::after {
    width: 40%;
  }
}
@media only screen and (min-width: 6001px) and (max-width: 10000px) {
  .kd-header-4-area::after {
    width: 48%;
  }
}
.kd-header-4-row {
  gap: 20px;
  padding: 15px 0;
}
.kd-header-4-logo-1 {
  max-width: 180px;
}
.kd-header-4-action-link {
  gap: 30px;
}
@media (min-width: 1800px), only screen and (min-width: 1600px) and (max-width: 1799px), only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-header-4-action-link .ftc-mobile-menu-btn-1 {
    display: none;
  }
}
@media (max-width: 767px) {
  .kd-header-4-action-link .kd-pr-btn-2 {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-header-4-action-link .kd-pr-btn-2 {
    display: inline-block;
  }
}

.main-navigation:is(.has-header-4) .navbar-nav li:not(:last-child) {
  margin-right: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-navigation:is(.has-header-4) .navbar-nav li:not(:last-child) {
    margin-right: 20px;
  }
}
.main-navigation:is(.has-header-4) .navbar-nav li a {
  font-family: var(--kd-font-barlow);
  text-transform: uppercase;
  padding: 37px 0;
}
.main-navigation:is(.has-header-4) .navbar-nav li a::after {
  content: "";
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: var(--kd-clr-sd-4);
  position: absolute;
  left: -9px;
  top: 51%;
  transform: translateY(-50%) scale(0);
  z-index: -1;
  transition: 0.3s var(--cube-1);
}
.main-navigation:is(.has-header-4) .navbar-nav li a:is(.is-active) {
  color: var(--kd-clr-pr-4) !important;
}
.main-navigation:is(.has-header-4) .navbar-nav li a:is(.is-active)::after {
  transform: translateY(-50%) scale(1);
}
.main-navigation:is(.has-header-4) .navbar-nav li:is(.dropdown) > a {
  padding-right: 15px;
}
.main-navigation:is(.has-header-4) .navbar-nav li .dropdown-menu {
  background: #000;
  border-color: var(--kd-clr-pr-4);
}
.main-navigation:is(.has-header-4) .navbar-nav li .dropdown-menu li {
  margin-right: 0;
}
.main-navigation:is(.has-header-4) .navbar-nav li .dropdown-menu li a {
  font-weight: 600;
}
.main-navigation:is(.has-header-4) .navbar-nav li .dropdown-menu li a::after {
  width: 15px;
  height: 15px;
  left: 15px;
}
.main-navigation:is(.has-header-4) .navbar-nav li .dropdown-menu li:hover > a {
  color: var(--kd-clr-pr-4);
  font-weight: 600;
}
.main-navigation:is(.has-header-4) .navbar-nav li:hover > a {
  color: var(--kd-clr-pr-4);
}
.main-navigation:is(.has-header-4) .navbar-nav li:hover > a::after {
  transform: translateY(-50%) scale(1);
}

/* 
  header-4-css-end
*/
/* 
  sticky-header-css-start
*/
.txa_sticky_header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  transition: 300ms ease-in-out;
  z-index: 8;
}
.txa_sticky_header:is(.kd-header-1-area) {
  top: 6px;
}
.txa_sticky_header:is(.kd-header-1-area):is(.txa_sticky) {
  transform: translateY(-150%);
}
.txa_sticky_header:is(.kd-header-1-area):is(.txa_sticky):is(.txa_sticky_show) {
  top: 0;
  transform: translateY(-98px);
}
.txa_sticky_header:is(.kd-header-2-area) {
  top: 25px;
}
.txa_sticky_header:is(.kd-header-2-area):is(.txa_sticky) {
  transform: translateY(-150%);
}
.txa_sticky_header:is(.kd-header-2-area):is(.txa_sticky):is(.txa_sticky_show) {
  transform: translateY(0%);
}
.txa_sticky_header:is(.kd-header-3-area) {
  top: 25px;
}
.txa_sticky_header:is(.kd-header-3-area):is(.txa_sticky) {
  transform: translateY(-150%);
}
.txa_sticky_header:is(.kd-header-3-area):is(.txa_sticky):is(.txa_sticky_show) {
  transform: translateY(0%);
}
.txa_sticky_header:is(.kd-header-4-area):is(.txa_sticky) {
  transform: translateY(-150%);
  background-color: #152028;
}
.txa_sticky_header:is(.kd-header-4-area):is(.txa_sticky):is(.txa_sticky_show) {
  transform: translateY(0%);
}

/* 
  sticky-header-css-end
*/
/* 
    hero-1-start
*/
.kd-hero-1-area {
  position: relative;
  z-index: 1;
  padding-top: 260px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-hero-1-area {
    background: #e31e24;
  }
}
.kd-hero-1-bg-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.kd-hero-1-bg-img img {
  width: 100%;
  height: 100% !important;
  object-position: bottom;
}
.kd-hero-1-bg-il-2 {
  position: absolute;
  right: -80px;
  top: 50%;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-hero-1-bg-il-2 {
    display: none;
  }
}
.kd-hero-1-bg-il-1 {
  position: absolute;
  left: -370px;
  top: 50%;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-hero-1-bg-il-1 {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-hero-1-item-content {
    margin-bottom: 50px;
  }
}
.kd-hero-1-item-content .kd-subtitle-1 {
  transition: 1s var(--cube-2);
  transform: translateY(-50px);
  opacity: 0;
}
.kd-hero-1-item-content .title {
  font-size: 40px;
  line-height: 1.13;
  color: #fff;
  margin-bottom: 14px;
  text-transform: unset !important;
  letter-spacing: 0.5px !important;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .kd-hero-1-item-content .title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-hero-1-item-content .title {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .kd-hero-1-item-content .title {
    font-size: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-hero-1-item-content .title {
    font-size: 30px;
  }
}
.kd-hero-1-item-content .title div {
  letter-spacing: 0px !important;
}
.kd-hero-1-item-content .disc {
  max-width: 560px;
  margin-bottom: 42px;
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  transition: 2s var(--cube-1);
}
.kd-hero-1-item-content .btn-wrap {
  transition: 1s var(--cube-1);
  transform: translateY(-50px);
  opacity: 0;
  transition-delay: 0s;
}
.kd-hero-1-item-img {
  position: relative;
  z-index: 1;
  margin-left: -50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-hero-1-item-img {
    margin-left: 0px;
  }
}
.kd-hero-1-item-img-1 {
  max-width: 465px;
  height: 600px;
  border-radius: 0 0 140px 140px;
}
@media (max-width: 767px) {
  .kd-hero-1-item-img-1 {
    height: 100%;
  }
}
.kd-hero-1-item-img-1 img {
  transform: translateY(100%);
  transition: 1s ease-in-out;
}
.kd-hero-1-item-img-2 {
  max-width: 435px;
  height: 435px;
  border-radius: 50%;
  position: absolute;
  top: 50px;
  right: 35px;
  z-index: -1;
}
@media (max-width: 767px) {
  .kd-hero-1-item-img-2 {
    display: none;
  }
}
.kd-hero-1-item-img-2::after {
  content: "";
  width: 100%;
  height: 100%;
  background: var(--kd-clr-sd-1);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  mix-blend-mode: multiply;
}
.kd-hero-1-item-img-2 img {
  transform: scale(0);
  transition: 1s var(--cube-1);
  border-radius: 50% !important;
}
.kd-hero-1-item-img-il-1 {
  position: absolute;
  right: 0px;
  top: 70px;
  width: 70%;
  z-index: -2;
  transform: translate(-40px, -50px);
  opacity: 0;
  transition: 1s var(--cube-1);
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-hero-1-item-img-il-1 {
    display: none;
  }
}
.kd-hero-1-item-img-il-2 {
  position: absolute;
  bottom: 0px;
  left: 50px;
  width: 70%;
  z-index: -2;
  transform: rotate(-100deg);
  transition: 1s var(--cube-1);
  opacity: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-hero-1-item-img-il-2 {
    display: none;
  }
}
.kd-hero-1-item-img-il-3 {
  position: absolute;
  left: -210px;
  bottom: 10px;
}
.kd-hero-1-item-img-il-4 {
  position: absolute;
  top: 0;
  right: 12%;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-hero-1-item-img-il-4 {
    display: none;
  }
}
.kd-hero-1-item-img-il-5 {
  position: absolute;
  right: 100px;
  bottom: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-hero-1-item-img-il-5 {
    display: none;
  }
}
.kd-hero-1-bottom {
  margin-left: -200px;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px), only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-hero-1-bottom {
    margin-left: 0;
  }
}
.kd-hero-1-social {
  gap: 10px 40px;
}
.kd-hero-1-social .link {
  position: relative;
  z-index: 1;
  transition: 400ms var(--cube-1);
}
.kd-hero-1-social .link::after {
  content: "";
  width: 0%;
  height: 1px;
  background-color: #fff;
  position: absolute;
  left: auto;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: 400ms var(--cube-1);
}
.kd-hero-1-social .link:hover {
  transform: translateX(3px);
  color: var(--kd-clr-sd-1);
}
.kd-hero-1-social .link:hover::after {
  width: 100%;
  left: 0;
  right: auto;
}
.kd-hero-1-slider {
  margin-right: -35px;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-hero-1-slider {
    margin-right: 0px;
  }
}
.kd-hero-1-slider .swiper-container .swiper-wrapper .swiper-slide:is(.swiper-slide-active) .kd-hero-1-item-content .kd-subtitle-1 {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 1s;
}
.kd-hero-1-slider .swiper-container .swiper-wrapper .swiper-slide:is(.swiper-slide-active) .kd-hero-1-item-content .disc {
  transition-delay: 1s;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}
.kd-hero-1-slider .swiper-container .swiper-wrapper .swiper-slide:is(.swiper-slide-active) .kd-hero-1-item-content .btn-wrap {
  transform: translateY(0);
  opacity: 1;
  transition-delay: 2s;
}
.kd-hero-1-slider .swiper-container .swiper-wrapper .swiper-slide:is(.swiper-slide-active) .kd-hero-1-item-img-1 img {
  transform: translateY(0%);
  transition-delay: 1s;
}
.kd-hero-1-slider .swiper-container .swiper-wrapper .swiper-slide:is(.swiper-slide-active) .kd-hero-1-item-img-2 img {
  transform: scale(1);
  transition-delay: 1s;
}
.kd-hero-1-slider .swiper-container .swiper-wrapper .swiper-slide:is(.swiper-slide-active) .kd-hero-1-item-img-il-2 {
  transform: rotate(0deg);
  transition-delay: 0.5s;
  opacity: 1;
}
.kd-hero-1-slider .swiper-container .swiper-wrapper .swiper-slide:is(.swiper-slide-active) .kd-hero-1-item-img-il-1 {
  transform: translate(0px, 0px);
  opacity: 1;
  transition-delay: 0.5s;
}
.kd-hero-1-slider-btn {
  gap: 40px;
}
.kd-hero-1-slider-btn .slider-btn {
  position: relative;
  z-index: 1;
  transition: 400ms var(--cube-1);
  cursor: pointer;
}
.kd-hero-1-slider-btn .slider-btn::after {
  content: "";
  width: 0%;
  height: 1px;
  background-color: #fff;
  position: absolute;
  left: auto;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: 400ms var(--cube-1);
}
.kd-hero-1-slider-btn .slider-btn:hover {
  transform: translateX(3px);
  color: var(--kd-clr-sd-1);
}
.kd-hero-1-slider-btn .slider-btn:hover::after {
  width: 100%;
  left: 0;
  right: auto;
}

/* 
    hero-1-end
*/
/* 
    hero-2-start
*/
.kd-hero-2-area {
  position: relative;
  z-index: 1;
  padding-top: 260px;
  padding-bottom: 180px;
}
.kd-hero-2-bg-img {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 100%;
  z-index: -1;
}
.kd-hero-2-row {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  gap: 50px;
  margin-right: -25px;
  align-items: center;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-hero-2-row {
    margin-right: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-hero-2-row {
    margin-right: 0px;
    grid-template-columns: 1fr;
  }
}
.kd-hero-2-content {
  max-width: 590px;
}
.kd-hero-2-content .kd-subtitle-1 {
  margin-bottom: 20px;
}
.kd-hero-2-content .title {
  font-size: 80px;
  line-height: 1.06;
  margin-bottom: 24px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .kd-hero-2-content .title {
    font-size: 70px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-hero-2-content .title {
    font-size: 60px;
  }
}
@media (max-width: 767px) {
  .kd-hero-2-content .title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-hero-2-content .title {
    font-size: 50px;
  }
}
.kd-hero-2-content .disc {
  margin-bottom: 47px;
}
.kd-hero-2-img-gird {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
}
.kd-hero-2-img {
  width: 100%;
  height: 260px;
  border-radius: 20px;
}
.kd-hero-2-img:nth-child(1) {
  border-radius: 130px 0 0 130px;
}
.kd-hero-2-img:nth-child(4) {
  border-radius: 0 130px 130px 0;
}
.kd-hero-2-content .nice-select {
  border: none;
}
.kd-hero-2-content .nice-select:after {
  display: none;
}
.kd-hero-2-form {
  background: #fff;
  border-radius: 50px;
  display: grid;
  grid-template-columns: 1.2fr 1fr 1fr 1fr;
  gap: 40px;
  padding: 25px 30px;
  align-items: center;
  margin-right: -230px;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-hero-2-form {
    grid-template-columns: 1fr;
    margin-right: 0px;
    gap: 10px;
    max-width: 300px;
  }
}
.kd-hero-2-form .input-item {
  position: relative;
}
.kd-hero-2-form .input-item:not(:last-child)::after {
  content: "";
  width: 1px;
  height: 50%;
  background-color: #EDEDED;
  position: absolute;
  top: 50%;
  right: -13px;
  transform: translate(-50%, -50%) rotate(-45deg);
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-hero-2-form .input-item:not(:last-child)::after {
    display: none;
  }
}
.kd-hero-2-form .input-item .icon {
  font-size: 21px;
  display: inline-block;
  color: #364a76;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  line-height: 1;
}
.kd-hero-2-form input {
  border: none;
  outline: none;
  font-family: var(--kd-font-pr-1);
  font-weight: 600;
  color: #364a76;
  font-size: 15px;
  padding-left: 30px;
  width: 100%;
  display: block;
  height: 50px;
}
.kd-hero-2-form .option-selector {
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
  color: #364a76;
  font-family: var(--kd-font-pr-1);
  border: none;
  padding: 0;
  padding-right: 25px;
  height: 50px;
  display: flex;
  align-items: center;
}
.kd-hero-2-form-btn {
  padding: 16px 45px;
}

/* 
    hero-2-end
*/
/* 
    hero-3-start
*/
.kd-hero-3-area {
  padding-top: 290px;
  padding-bottom: 260px;
  clip-path: polygon(0 0, 100% 0, 100% 91%, 0% 100%);
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-hero-3-area {
    padding-top: 230px;
  }
}
.kd-hero-3-row {
  display: grid;
  grid-template-columns: 1fr 1.3fr;
  gap: 60px;
  align-items: center;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-hero-3-row {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-hero-3-row {
    grid-template-columns: 1fr;
    gap: 50px;
  }
}
.kd-hero-3-content .title {
  font-size: 65px;
  line-height: 1.09;
  color: #fff;
  margin-bottom: 14px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .kd-hero-3-content .title {
    font-size: 56px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-hero-3-content .title {
    font-size: 54px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-hero-3-content .title {
    font-size: 52px;
  }
}
@media (max-width: 767px) {
  .kd-hero-3-content .title {
    font-size: 35px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-hero-3-content .title {
    font-size: 45px;
  }
}
.kd-hero-3-content .disc {
  color: #afb0b5;
  margin-bottom: 27px;
}
.kd-hero-3-content .subtitle {
  margin-bottom: 30px;
}
.kd-hero-3-right {
  position: relative;
  z-index: 1;
}
.kd-hero-3-img {
  border-radius: 160px;
  height: 605px;
}
.kd-hero-3-img-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 11px;
}
@media (max-width: 767px) {
  .kd-hero-3-img {
    height: 405px;
  }
}
.kd-hero-3-img:nth-child(2) {
  height: 520px;
  margin-top: -30px;
}
@media (max-width: 767px) {
  .kd-hero-3-img:nth-child(2) {
    height: 355px;
  }
}
.kd-hero-3-il-1 {
  width: 28%;
  position: absolute;
  left: 36%;
  transform: translateX(-50%);
  top: 32%;
}
@media (max-width: 767px) {
  .kd-hero-3-il-1 {
    display: none;
  }
}
.kd-hero-3-il-2 {
  width: 150px;
  height: 60px;
  border-radius: 30px;
  overflow: hidden;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  position: absolute;
  bottom: 130px;
  left: -50px;
}
@media (max-width: 767px) {
  .kd-hero-3-il-2 {
    display: none;
  }
}
.kd-hero-3-il-3 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 30px;
  background: #fff;
  max-width: 125px;
  height: 58px;
  overflow: hidden;
  position: absolute;
  bottom: 55px;
  left: -45px;
}
@media (max-width: 767px) {
  .kd-hero-3-il-3 {
    display: none;
  }
}
.kd-hero-3-il-4 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: #fff;
  border-radius: 50px;
  max-width: 212px;
  height: 96px;
  overflow: hidden;
  position: absolute;
  bottom: -15px;
  left: 26%;
  transform: translateX(-50%);
}
@media (max-width: 767px) {
  .kd-hero-3-il-4 {
    display: none;
  }
}
.kd-hero-3-il-5 {
  width: 29%;
  position: absolute;
  top: -50px;
  left: -50px;
  z-index: -1;
}
@media (max-width: 767px) {
  .kd-hero-3-il-5 {
    display: none;
  }
}
.kd-hero-3-il-6 {
  width: 40%;
  position: absolute;
  bottom: 60px;
  right: -100px;
  z-index: -1;
}
@media (max-width: 767px) {
  .kd-hero-3-il-6 {
    display: none;
  }
}

/* 
    hero-3-end
*/
/* 
    hero-4-start
*/
.kd-hero-4-area {
  position: relative;
  z-index: 1;
  padding-top: 120px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-hero-4-area {
    padding-top: 190px;
  }
}
.kd-hero-4-bg-img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.kd-hero-4-content {
  padding-bottom: 215px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-hero-4-content {
    padding-bottom: 65px;
  }
}
.kd-hero-4-content .title {
  font-size: 90px;
  font-weight: 900;
  line-height: 1.11;
  margin-bottom: 30px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .kd-hero-4-content .title {
    font-size: 80px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .kd-hero-4-content .title {
    font-size: 70px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-hero-4-content .title {
    font-size: 50px;
  }
}
@media (max-width: 767px) {
  .kd-hero-4-content .title {
    font-size: 35px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-hero-4-content .title {
    font-size: 45px;
  }
}
.kd-hero-4-content .title .span-1 {
  display: inline-block;
  color: var(--kd-clr-sd-4);
}
.kd-hero-4-content .title .span-2 {
  display: inline-block;
  color: var(--kd-clr-pr-4);
}
.kd-hero-4-content .disc {
  margin-bottom: 50px;
  font-weight: 500;
}
.kd-hero-4-right {
  position: relative;
  z-index: 0;
  padding-left: 70px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-hero-4-right {
    padding-left: 0px;
  }
}
.kd-hero-4-il-1 {
  position: absolute;
  left: 60px;
  top: 60px;
}
@media (max-width: 767px) {
  .kd-hero-4-il-1 {
    left: 0px;
  }
}
.kd-hero-4-img {
  margin-right: -270px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-hero-4-img {
    margin-right: 0px;
  }
}
.tutor-card.tutor-course-card {
  z-index: 1;
  background-color: #fff;
  border: 2px solid rgba(205, 202, 202, 0.2);
  border-radius: 20px;
  margin-bottom: 30px;
  transition: 0.3s var(--cube-1);
  overflow: hidden;
}
h3.tutor-course-name.tutor-fs-5.tutor-fw-medium {
  font-size: 27px;
  line-height: 1.29;
  transition: 0.3s var(--cube-1);
  margin-bottom: 16px;
  transform-origin: left;
  transition: 0.3s var(--cube-1);
  font-weight: 900;
}
.tutor-meta span {
  font-size: 16px;
  position: relative;
  z-index: 1;
}
.tutor-iconic-btn-secondary {
  background-color: var(--kd-clr-sd-1);
  color: #fff;
}
.tutor-wrap.tutor-wrap-parent {
  padding-top: 90px;
  padding-bottom: 90px;
}
.tutor-meta.tutor-mt-auto a {
  font-size: 16px;
  position: relative;
  z-index: 1;
}
.tutor-card-footer:not(.tutor-no-border){
  border-top: 2px solid rgba(205, 202, 202, 0.2);
}
.tutor-course-card .tutor-meta.tutor-mt-auto {
  margin-top: 10px;
}
.tutor-card-footer a {
  display: inline-block;
  font-family: var(--kd-font-pr-1);
  color: #22281e;
  font-weight: 800;
  font-size: 15px;
  line-height: 1;
  background: var(--kd-clr-sd-1);
  border-radius: 30px;
  padding: 20px 31px;
  position: relative;
  z-index: 1;
  transition: 0.3s var(--cube-1);
  border: none;
}
/* 
    hero-4-end
*/
/* 
    categorie-1-start
*/
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-pop-cat-1-left {
    margin-bottom: 50px;
  }
}
.kd-pop-cat-1-scn-title .kd-subtitle-1 {
  font-size: 32px;
}
.kd-pop-cat-1-scn-title .kd-section-title-1 {
  font-size: 35px;
}
.kd-pop-cat-1-scn-title .disc {
  line-height: 28px;
  font-size: 18px;
  max-width: 350px;
}
.kd-pop-cat-1-item {
  position: relative;
  z-index: 1;
}
.kd-pop-cat-1-item .shape {
  position: absolute;
  right: -28px;
  top: 50%;
  transform: translateY(-100%);
}
.kd-pop-cat-1-item .icon {
  background: #f9f9f9;
  height: 170px;
  border-radius: 35px;
  padding: 15px;
}
.kd-pop-cat-1-item .icon img {
  width: 70px;
}
.kd-pop-cat-1-item .title {
  display: inline-block;
  background: #006596;
  font-size: 14px;
  padding: 10px 13px;
  border-radius: 20px;
  transform: translateY(-20px);
  transition: 0.2s var(--cube-1);
}
.kd-pop-cat-1-item .title:is(.has-bg-1) {
  background: #db3c2d;
}
.kd-pop-cat-1-item .title:is(.has-bg-2) {
  background: #0ba73b;
}
.kd-pop-cat-1-item .title:is(.has-bg-1) {
  background: #284edb;
}
.kd-pop-cat-1-item .title:hover {
  color: var(--kd-clr-sd-1);
}
.kd-pop-cat-1-item:hover .icon img {
  animation: 1s kd-jello;
}
.kd-pop-cat-1-slider {
  position: relative;
  z-index: 1;
  margin-left: 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-pop-cat-1-slider {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-pop-cat-1-slider-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding-top: 30px;
  }
}
.kd-pop-cat-1-slider-btn .kd_pop_cat_1_next {
  position: absolute;
  right: -70px;
  top: 50%;
  transform: translateY(-50%);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-pop-cat-1-slider-btn .kd_pop_cat_1_next {
    position: unset;
    transform: unset;
  }
}
.kd-pop-cat-1-slider-btn .kd_pop_cat_1_prev {
  position: absolute;
  left: -70px;
  top: 50%;
  transform: translateY(-50%);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-pop-cat-1-slider-btn .kd_pop_cat_1_prev {
    position: unset;
    transform: unset;
  }
}
.kd-pop-cat-1-line {
  display: block;
  width: 100%;
  height: 1px;
  background: #f8f8f8;
}

.kd-slider-btn-1 {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #ecf0ff;
  font-size: 16px;
  color: #000;
  cursor: pointer;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.kd-slider-btn-1 .icon-1 {
  display: inline-block;
  line-height: 1;
  transition: 0.3s var(--cube-1);
  opacity: 0;
  color: #fff;
}
.kd-slider-btn-1 .icon-2 {
  display: inline-block;
  line-height: 1;
  transition: 0.3s var(--cube-1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #000;
}
.kd-slider-btn-1:hover {
  background: var(--kd-clr-pr-1);
}
.kd-slider-btn-1:hover .icon-1 {
  opacity: 1;
  transition-delay: 0.1s;
}
.kd-slider-btn-1:hover .icon-2 {
  left: -20%;
}
.kd-slider-btn-1:is(.has-next):hover .icon-1 {
  opacity: 1;
  transition-delay: 0.1s;
}
.kd-slider-btn-1:is(.has-next):hover .icon-2 {
  left: 120%;
}

.kd-slider-btn-2 {
  width: 58px;
  height: 58px;
  border-radius: 40%;
  background: transparent;
  border: 3px solid rgba(255, 255, 255, 0.3);
  font-size: 26px;
  color: #fff;
  cursor: pointer;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.kd-slider-btn-2 .icon-1 {
  display: inline-block;
  line-height: 1;
  transition: 0.3s var(--cube-1);
  opacity: 0;
  color: var(--kd-clr-pr-4);
}
.kd-slider-btn-2 .icon-2 {
  display: inline-block;
  line-height: 1;
  transition: 0.3s var(--cube-1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(255, 255, 255, 0.3);
}
.kd-slider-btn-2:hover {
  border-color: var(--kd-clr-pr-4);
}
.kd-slider-btn-2:hover .icon-1 {
  opacity: 1;
  transition-delay: 0.1s;
}
.kd-slider-btn-2:hover .icon-2 {
  left: -20%;
}
.kd-slider-btn-2:is(.has-next):hover .icon-1 {
  opacity: 1;
  transition-delay: 0.1s;
}
.kd-slider-btn-2:is(.has-next):hover .icon-2 {
  left: 120%;
}

/* 
    categorie-1-end
*/
/* 
    categorie-4-start
*/
.kd-category-4-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  border-radius: 0 0 200px 200px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.kd-category-4-il-1 {
  position: absolute;
  right: 110px;
  bottom: 90px;
  z-index: -1;
}
.kd-category-4-scn-title {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
}
.kd-category-4-card-row {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
}
.kd-category-4-card {
  display: flex;
  align-items: center;
  gap: 22px;
  width: 410px;
  background: transparent;
  border: 1px solid;
  border-color: rgba(215, 0, 7, 0.2);
  border-radius: 10px;
  padding: 15px;
  position: relative;
  z-index: 1;
  transition: 0.4s ease;
}
@media (max-width: 767px) {
  .kd-category-4-card {
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-category-4-card {
    width: 410px;
  }
}
.kd-category-4-card::after {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgb(163, 29, 93) 0%, rgb(230, 103, 68) 100%);
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  transform-origin: center center;
  transform: rotate(0deg);
  transition: 0.4s var(--cube-1);
}
.kd-category-4-card::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #152028;
  border-radius: 10px;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  z-index: -1;
}
.kd-category-4-card .icon {
  width: 100px;
  height: 100px;
  background: rgba(215, 0, 7, 0.2);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  color: #D70007;
  flex: 0 0 auto;
  transition: 0.4s ease;
}
.kd-category-4-card .card-title {
  font-size: 22px;
  line-height: 1.6;
  font-weight: 600;
  margin-bottom: 8px;
  transition: 0.3s ease;
}
@media (max-width: 767px) {
  .kd-category-4-card .card-title {
    font-size: 18px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-category-4-card .card-title {
    font-size: 22px;
  }
}
.kd-category-4-card .card-title:hover {
  transform: translateX(3px);
}
.kd-category-4-card .course-count {
  font-size: 18px;
  font-weight: 600;
  color: #D70007;
  line-height: 26px;
}
.kd-category-4-card:hover {
  border-color: rgb(215, 0, 7);
}
.kd-category-4-card:hover .icon {
  background: rgb(215, 0, 7);
  color: #fff;
}
.kd-category-4-card:hover::after {
  transform: rotate(-5deg);
}
.kd-category-4-card:nth-child(2) {
  border-color: rgba(41, 166, 213, 0.2);
}
.kd-category-4-card:nth-child(2) .icon {
  background: rgba(41, 166, 213, 0.15);
  color: #29A6D5;
}
.kd-category-4-card:nth-child(2) .course-count {
  color: #29A6D5;
}
.kd-category-4-card:nth-child(2)::after {
  background: linear-gradient(45deg, rgb(41, 166, 213) 0%, rgb(41, 213, 171) 100%);
}
.kd-category-4-card:nth-child(2):hover {
  border-color: rgb(41, 166, 213);
}
.kd-category-4-card:nth-child(2):hover .icon {
  background: rgb(41, 166, 213);
  color: #fff;
}
.kd-category-4-card:nth-child(3) {
  border-color: rgba(8, 213, 101, 0.2);
}
.kd-category-4-card:nth-child(3) .icon {
  background: rgba(8, 213, 101, 0.15);
  color: #08D565;
}
.kd-category-4-card:nth-child(3) .course-count {
  color: #08D565;
}
.kd-category-4-card:nth-child(3)::after {
  background: linear-gradient(45deg, rgb(8, 213, 101) 0%, rgb(92, 255, 166) 100%);
}
.kd-category-4-card:nth-child(3):hover {
  border-color: rgb(8, 213, 101);
}
.kd-category-4-card:nth-child(3):hover .icon {
  background: rgb(8, 213, 101);
  color: #fff;
}
.kd-category-4-card:nth-child(4) {
  border-color: rgba(249, 217, 11, 0.2);
}
.kd-category-4-card:nth-child(4) .icon {
  background: rgba(249, 217, 11, 0.15);
  color: #F9D90B;
}
.kd-category-4-card:nth-child(4) .course-count {
  color: #F9D90B;
}
.kd-category-4-card:nth-child(4)::after {
  background: linear-gradient(45deg, rgb(249, 217, 11) 0%, rgb(172, 249, 11) 100%);
}
.kd-category-4-card:nth-child(4):hover {
  border-color: rgb(249, 217, 11);
}
.kd-category-4-card:nth-child(4):hover .icon {
  background: rgb(249, 217, 11);
  color: #fff;
}
.kd-category-4-card:nth-child(5) {
  border-color: rgba(227, 26, 207, 0.2);
}
.kd-category-4-card:nth-child(5) .icon {
  background: rgba(227, 26, 207, 0.15);
  color: #E31ACF;
}
.kd-category-4-card:nth-child(5) .course-count {
  color: #E31ACF;
}
.kd-category-4-card:nth-child(5)::after {
  background: linear-gradient(45deg, rgb(227, 26, 207) 0%, rgb(157, 26, 227) 100%);
}
.kd-category-4-card:nth-child(5):hover {
  border-color: rgb(227, 26, 207);
}
.kd-category-4-card:nth-child(5):hover .icon {
  background: rgb(227, 26, 207);
  color: #fff;
}
.kd-category-4-card:nth-child(6) {
  border-color: rgba(24, 109, 235, 0.2);
}
.kd-category-4-card:nth-child(6) .icon {
  background: rgba(24, 109, 235, 0.15);
  color: #186DEB;
}
.kd-category-4-card:nth-child(6) .course-count {
  color: #186DEB;
}
.kd-category-4-card:nth-child(6)::after {
  background: linear-gradient(45deg, rgb(24, 109, 235) 0%, rgb(24, 213, 235) 100%);
}
.kd-category-4-card:nth-child(6):hover {
  border-color: rgb(24, 109, 235);
}
.kd-category-4-card:nth-child(6):hover .icon {
  background: rgb(24, 109, 235);
  color: #fff;
}
.kd-category-4-card:nth-child(8) {
  border-color: rgba(249, 217, 11, 0.2);
}
.kd-category-4-card:nth-child(8) .icon {
  background: rgba(249, 217, 11, 0.15);
  color: #F9D90B;
}
.kd-category-4-card:nth-child(8) .course-count {
  color: #F9D90B;
}
.kd-category-4-card:nth-child(8)::after {
  background: linear-gradient(45deg, rgb(249, 217, 11) 0%, rgb(172, 249, 11) 100%);
}
.kd-category-4-card:nth-child(8):hover {
  border-color: rgb(249, 217, 11);
}
.kd-category-4-card:nth-child(8):hover .icon {
  background: rgb(249, 217, 11);
  color: #fff;
}

/* 
    categorie-4-end
*/
/* 
    about-1-start
*/
.kd-about-1-left {
  padding-right: 15px;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-about-1-left {
    margin-bottom: 50px;
  }
}
.kd-about-1-img-1 {
  height: 540px;
  border-radius: 10px;
}
.kd-about-1-img-2 {
  max-width: 285px;
  height: 295px;
  border-radius: 10px;
  box-shadow: 0px 7px 81px 0px rgba(32, 28, 29, 0.23);
  position: absolute;
  bottom: 30px;
  right: -75px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-about-1-img-2 {
    right: 0px;
  }
}
.kd-about-1-img-certified {
  width: 160px;
  position: absolute;
  top: -40px;
  left: -60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-about-1-img-certified {
    left: 0px;
  }
}
.kd-about-1-expart {
  box-shadow: 0px 7px 38px 0px rgba(32, 28, 29, 0.03);
  display: inline-flex;
  background: #fff;
  border-radius: 50px;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  padding: 20px 30px;
  margin-left: -30px;
  position: relative;
  z-index: 1;
}
.kd-about-1-expart .main-img {
  width: 96px;
}
.kd-about-1-right {
  padding-left: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-about-1-right {
    padding-left: 0px;
  }
}
.kd-about-1-right .inner-div {
  padding-left: 85px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-about-1-right .inner-div {
    padding-left: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-about-1-right .inner-div {
    padding-left: 0px;
  }
}
.kd-about-1-right .kd-list-1 {
  margin-bottom: 37px;
}
.kd-about-1-scn-title .disc {
  max-width: 590px;
}
.kd-about-1-feature-img {
  display: grid;
  grid-template-columns: 1fr 2.5fr 1fr;
  gap: 15px;
  max-width: 490px;
}
@media (max-width: 767px) {
  .kd-about-1-feature-img {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-about-1-feature-img {
    grid-template-columns: 1fr 2.5fr 1fr;
  }
}
.kd-about-1-feature-img .single-img {
  height: 100px;
  border-radius: 50px;
}

.kd-list-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  max-width: 480px;
}
@media (max-width: 767px) {
  .kd-list-1 {
    grid-template-columns: 1fr;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-list-1 {
    grid-template-columns: 1fr 1fr;
  }
}
.kd-list-1 li {
  font-size: 18px;
  color: #0d0d0d;
}
.kd-list-1 li i {
  margin-right: 3px;
  color: var(--kd-clr-pr-1);
}

/* 
    about-1-end
*/
/* 
    about-2-start
*/
.kd-about-2-area {
  position: relative;
  z-index: 1;
}
.kd-about-2-bg-il {
  width: 13%;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.kd-about-2-scn-title .kd-subtitle-1 {
  margin-bottom: 25px;
}
.kd-about-2-left {
  position: relative;
  z-index: 1;
}
.kd-about-2-img-1 {
  max-width: 385px;
  border-radius: 60px;
  height: 400px;
  margin-left: auto;
  position: relative;
  z-index: 1;
}
.kd-about-2-img-2 {
  max-width: 495px;
  height: 420px;
  border-radius: 60px;
  margin-top: -195px;
}
.kd-about-2-img-certified {
  position: absolute;
  top: 100px;
  left: 100px;
  z-index: 1;
}
.kd-about-2-img-il-1 {
  position: absolute;
  bottom: 140px;
  right: 0;
}
.kd-about-2-right {
  padding-left: 45px;
  padding-top: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-about-2-right {
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-about-2-right {
    padding-left: 0px;
  }
}
.kd-about-2-right .contact-item-flex {
  display: flex;
  gap: 35px;
  flex-wrap: wrap;
}

/* 
    about-2-end
*/
/* 
    about-3-start
*/
.kd-about-3-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px 120px;
  align-items: center;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-about-3-row {
    gap: 50px 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-about-3-row {
    grid-template-columns: 1fr;
  }
}
.kd-about-3-img-wrap {
  position: relative;
  z-index: 1;
}
.kd-about-3-img {
  height: 575px;
  border-radius: 20px;
}
.kd-about-3-img-2 {
  width: 280px;
  height: 300px;
  border-radius: 20px;
  position: absolute;
  right: -60px;
  top: 50%;
  transform: translateY(-50%);
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-about-3-img-2 {
    right: 0px;
  }
}
.kd-about-3-content .kd-list-1 {
  margin-bottom: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), md, (max-width: 767px) {
  .kd-about-3-content .kd-list-1 {
    grid-template-columns: 1fr;
  }
}

/* 
    about-3-end
*/
/* 
    about-4-start
*/
.kd-about-4-left {
  margin-left: -75px;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-about-4-left {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-about-4-left {
    margin-left: 0px;
    margin-bottom: 50px;
  }
}
.kd-about-4-img-1 {
  border-radius: 10px;
  height: 750px;
}
@media (max-width: 767px) {
  .kd-about-4-img-1 {
    height: 400px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-about-4-img-1 {
    height: 500px;
  }
}
.kd-about-4-img-2 {
  width: 100%;
  height: 750px;
  border-radius: 10px;
  position: absolute;
  left: 0px;
  top: 0;
  z-index: -1;
  transform: rotate(0deg);
  transform-origin: left bottom;
  transition: 1s ease-in-out;
}
@media (max-width: 767px) {
  .kd-about-4-img-2 {
    height: 400px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-about-4-img-2 {
    height: 500px;
  }
}
.kd-about-4-img-2:is(.active) {
  left: -15px;
  transform: rotate(-4deg);
}
.kd-about-4-cube {
  position: absolute;
  bottom: -100px;
  left: -180px;
  z-index: -2;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-about-4-cube {
    bottom: -170px;
    left: -40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-about-4-cube {
    display: none;
  }
}
.kd-about-4-cube svg {
  animation: 15s kd-a4-cube linear infinite;
}
.kd-about-4-cube svg .cube-1 {
  animation: 40s kd-a4-cube linear infinite;
  transform-origin: center center;
}
.kd-about-4-bg-shape {
  position: absolute;
  right: 0;
  top: -210px;
  width: 46%;
  z-index: -1;
}
.kd-about-4-right {
  padding-left: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-about-4-right {
    padding-left: 0px;
  }
}
.kd-about-4-right-btn {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px 75px;
}
.kd-about-4-phone {
  display: flex;
  align-items: center;
  gap: 30px;
}
.kd-about-4-phone .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: var(--kd-clr-sd-4);
  border-radius: 50%;
  font-size: 18px;
  color: #fff;
  flex: 0 0 auto;
  position: relative;
  z-index: 1;
}
.kd-about-4-phone .icon::after {
  content: "";
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 5px solid rgba(8, 213, 101, 0.2);
  transition: 0.3s ease;
}
.kd-about-4-phone .title {
  font-size: 14px;
  text-transform: capitalize;
  color: var(--kd-clr-sd-4);
  line-height: 26px;
  margin-bottom: 2px;
}
.kd-about-4-phone .links {
  font-size: 26px;
  line-height: 30px;
  transition: 0.3s ease;
}
.kd-about-4-phone .links:hover {
  color: var(--kd-clr-sd-4);
}
.kd-about-4-phone:hover .icon::after {
  border: 5px solid rgb(8, 213, 101);
}
.kd-about-4-according {
  max-width: 620px;
  position: relative;
  z-index: 1;
}
.kd-about-4-according::after {
  content: "";
  width: 1px;
  height: calc(100% - 52px);
  position: absolute;
  top: 25px;
  left: 30px;
  z-index: 1;
  background: var(--kd-clr-pr-4);
}
.kd-about-4-according-item {
  background: transparent;
}
.kd-about-4-according-item .item-title {
  background: transparent;
  font-size: 26px;
  line-height: 26px;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
  text-decoration: none;
  position: relative;
  z-index: 1;
  text-align: left;
}
.kd-about-4-according-item .item-title::after {
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 23px;
  background: var(--kd-clr-pr-4);
  border-radius: 50%;
  z-index: -1;
}
.kd-about-4-according-item .item-title .icon {
  width: 62px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  border-radius: 50%;
  border: 5px solid #152028;
  font-size: 30px;
  color: #fff;
  background: var(--kd-clr-pr-4);
  outline: 1px solid var(--kd-clr-pr-4);
  transition: 0.5s ease;
  opacity: 0;
}
.kd-about-4-according-item .item-title .text {
  opacity: 40%;
  transition: 0.3s ease;
}
.kd-about-4-according-item .item-title:hover .text {
  opacity: 100%;
}
.kd-about-4-according-item .item-title:is(:not(.collapsed)) .icon {
  opacity: 1;
}
.kd-about-4-according-item .item-title:is(:not(.collapsed)) .text {
  opacity: 100%;
  text-decoration: underline;
}
.kd-about-4-according-item .item-body .disc {
  opacity: 100%;
  padding-left: 76px;
}

@keyframes kd-a4-cube {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* 
    about-4-end
*/
/* 
    course-1-start
*/
.tutor-course-details-title {
  font-size: 32px;
  font-family: var(--kd-font-pr-1);
  line-height: 1.25;
  font-weight: 800;
  color: var(--kd-clr-h-1);
  margin-bottom: 15px;
  text-transform: capitalize;
  margin-top: 0;
}

.tutor-meta.tutor-course-details-info span, .tutor-meta.tutor-course-details-info a, .tutor-meta.tutor-course-details-info span, .tutor-meta.tutor-course-details-info div {
  font-family: var(--kd-font-pr-1);
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;
  color: var(--kd-clr-h-1);
  position: relative;
  z-index: 1;
  align-items: center;
  gap: 10px;
}
.kd-course-1-area {
  position: relative;
  z-index: 1;
}
.kd-course-1-bg-img {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  z-index: -1;
  height: calc(100% - 217px);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-course-1-bg-img {
    height: calc(100% - 295px);
  }
}
@media (max-width: 767px) {
  .kd-course-1-bg-img {
    height: calc(100% - 360px);
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-course-1-bg-img {
    height: calc(100% - 327px);
  }
}
.kd-course-1-scn-title {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0 30px;
  align-items: center;
}
.kd-course-1-tabs-card {
  position: relative;
  z-index: 1;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 40px 0px rgba(94, 92, 154, 0.06);
}
.kd-course-1-tabs-card .main-img {
  height: 235px;
  overflow: hidden;
}
.kd-course-1-tabs-card .main-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 500ms;
  transform: perspective(0px) rotateX(0deg) rotateY(0deg) scaleX(1) scaleY(1);
  transform-origin: center center;
}
.kd-course-1-tabs-card .price {
  position: absolute;
  right: 22px;
  top: 22px;
  background: #fff;
  font-size: 16px;
  color: #3e3f5e;
  border-radius: 19px;
  padding: 10px 19px;
}
.kd-course-1-tabs-card .price span {
  display: inline-block;
  color: #00c7d9;
}
.kd-course-1-tabs-card .content {
  padding: 35px 45px;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px), only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-1-tabs-card .content {
    padding: 35px 15px;
  }
}
.kd-course-1-tabs-card .title {
  font-size: 25px;
  margin-bottom: 9px;
  transition: 0.2s var(--cube-1);
  transform-origin: left;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-1-tabs-card .title {
    font-size: 22px;
  }
}
.kd-course-1-tabs-card .title:hover {
  color: var(--kd-clr-sd-1);
  transform: scaleX(102%);
}
.kd-course-1-tabs-card .disc {
  font-size: 18px;
  line-height: 27px;
}
.kd-course-1-tabs-card-feature {
  margin-bottom: 10px;
}
.kd-course-1-tabs-card-feature li {
  font-size: 15px;
  position: relative;
  z-index: 1;
  display: inline-block;
  padding-left: 20px;
}
.kd-course-1-tabs-card-feature li:not(:last-child) {
  margin-right: 20px;
}
.kd-course-1-tabs-card-feature li::after {
  content: "";
  width: 12px;
  height: 12px;
  border: 2px solid #FB4F25;
  position: absolute;
  left: 0;
  top: 1px;
  border-radius: 50%;
}
.kd-course-1-tabs-card .line {
  width: 100%;
  height: 1px;
  background: #EAEAF5;
}
.kd-course-1-tabs-card-author {
  display: flex;
  align-items: center;
  gap: 5px;
}
.kd-course-1-tabs-card-author .author-img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.kd-course-1-tabs-card-author .post {
  font-size: 13px;
  color: #3e3f5e;
}
.kd-course-1-tabs-card-author .name {
  font-size: 15px;
  color: #3e3f5e;
}
.kd-course-1-tabs-card .student-count {
  font-size: 15px;
  position: relative;
  z-index: 1;
  display: inline-block;
  padding-left: 20px;
  color: #3e3f5e;
}
.kd-course-1-tabs-card .student-count::after {
  content: "";
  width: 12px;
  height: 12px;
  border: 2px solid #FB4F25;
  position: absolute;
  left: 0;
  top: 1px;
  border-radius: 50%;
}
.kd-course-1-tabs-card .card-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
@media (max-width: 767px) {
  .kd-course-1-tabs-card .card-bottom {
    gap: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-course-1-tabs-card .card-bottom {
    gap: 20px;
  }
}
.kd-course-1-tabs-card span.line-2 {
  height: 75px;
  width: 1px;
  background: #EAEAF5;
  flex: 0 0 auto;
}
.kd-course-1-tabs-card .right {
  transform: translateY(-5px);
}
.kd-course-1-tabs-card:hover .main-img img {
  transform: perspective(600px) rotateX(0.06deg) rotateY(0deg) scaleX(1.15) scaleY(1.15);
}
.kd-course-1-tabs-btn {
  display: flex;
  align-items: center;
  gap: 10px 50px;
  flex-wrap: wrap;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-1-tabs-btn {
    gap: 10px 30px;
  }
}
.kd-course-1-tabs-btn .nav-item {
  position: relative;
  z-index: 1;
}
.kd-course-1-tabs-btn .nav-item:not(:last-child)::after {
  content: "";
  width: 2.5px;
  height: 13px;
  background-color: #c9c9c9;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -28px;
}
@media (max-width: 767px) {
  .kd-course-1-tabs-btn .nav-item:not(:last-child)::after {
    display: none;
  }
}
.kd-course-1-tabs-btn .nav-item .nav-link {
  font-size: 18px;
  background: transparent;
  color: #0d0d0d;
  padding: 16px 20px;
  border-radius: 26px;
  transition: 400ms var(--cube-1);
}
.kd-course-1-tabs-btn .nav-item .nav-link:hover {
  color: var(--kd-clr-pr-1);
}
.kd-course-1-tabs-btn .nav-item .nav-link:is(.active) {
  background: var(--kd-clr-pr-1);
  color: #fff;
}
.kd-course-1-tabs-content-wrap {
  margin-left: -220px;
  margin-right: -220px;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .kd-course-1-tabs-content-wrap {
    margin-left: -100px;
    margin-right: -100px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-1-tabs-content-wrap {
    margin-left: 0px;
    margin-right: 0px;
  }
}
.kd-course-1-tabs-content-wrap .swiper-container {
  padding-top: 30px;
  padding-bottom: 50px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-1-tabs-slider-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-bottom: 30px;
  }
}
.kd-course-1-tabs-slider-btn .kd_course_1_slider_next {
  position: absolute;
  right: -25px;
  top: 50%;
  transform: translateY(-107%);
  z-index: 1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-1-tabs-slider-btn .kd_course_1_slider_next {
    position: unset;
    transform: unset;
  }
}
.kd-course-1-tabs-slider-btn .kd_course_1_slider_prev {
  position: absolute;
  left: -25px;
  top: 50%;
  transform: translateY(-107%);
  z-index: 1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-1-tabs-slider-btn .kd_course_1_slider_prev {
    position: unset;
    transform: unset;
  }
}
.kd-course-1-tabs-slider-btn .kd-slider-btn-1 .icon-1 {
  color: #000;
}
.kd-course-1-tabs-slider-btn .kd-slider-btn-1 .icon-1 {
  color: #fff;
}
.kd-course-1-tabs-content-all {
  color: #00342c;
  font-weight: 600;
}
.kd-course-1-tabs-content-all a {
  display: inline-block;
  transition: 0.4s var(--cube-1);
}
.kd-course-1-tabs-content-all a:hover {
  color: var(--kd-clr-sd-1);
  transform: translateX(3px);
}

.kd-ratting-1 {
  font-size: 15px;
  gap: 5px;
  color: #ffde00;
}

/* 
    course-1-end
*/
/* 
    course-2-start
*/
.kd-course-2-area {
  position: relative;
  z-index: 1;
}
.kd-course-2-bg-shape-1 {
  width: 12%;
  position: absolute;
  right: 0;
  bottom: 13%;
  z-index: -1;
}
.kd-course-2-line {
  width: 100%;
  height: 2px;
  background: #f5f4f0;
}
.kd-course-2-scn-title {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.kd-course-2-all-btn {
  font-size: 20px;
  color: #00342c;
  padding-top: 20px;
}
.kd-course-2-all-btn a {
  transition: 0.3s var(--cube-1);
}
.kd-course-2-all-btn a i {
  display: inline-block;
  font-size: 18px;
  margin-left: 5px;
  transition: 0.3s var(--cube-1);
}
.kd-course-2-all-btn a:hover {
  color: var(--kd-clr-sd-1);
  text-decoration: none;
}
.kd-course-2-all-btn a:hover i {
  transform: translateX(3px);
}
.kd-course-2-card {
  border: 2px solid rgba(205, 202, 202, 0.2);
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 30px;
  transition: 0.3s var(--cube-1);
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-2-card {
    padding: 20px 10px;
  }
}
.kd-course-2-card .main-img {
  height: 220px;
  border-radius: 20px;
}
.kd-course-2-card .main-img img {
  transition: transform 500ms;
  transform: perspective(0px) rotateX(0deg) rotateY(0deg) scaleX(1) scaleY(1);
  transform-origin: center center;
}
.kd-course-2-card .card-content {
  padding: 25px 30px;
  padding-bottom: 10px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-course-2-card .card-content {
    padding: 25px 10px;
    padding-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .kd-course-2-card .card-content {
    padding: 25px 10px;
    padding-bottom: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-course-2-card .card-content {
    padding: 25px 30px;
    padding-bottom: 10px;
  }
}
.kd-course-2-card .title {
  font-size: 27px;
  line-height: 1.29;
  transition: 0.3s var(--cube-1);
  margin-bottom: 16px;
  transform-origin: left;
  transition: 0.3s var(--cube-1);
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-2-card .title {
    font-size: 22px;
  }
}
.kd-course-2-card .title:hover {
  color: var(--kd-clr-sd-1);
  transform: scaleX(102%);
}
.kd-course-2-card .card-rating-vote {
  display: inline-flex;
  align-items: center;
  gap: 30px;
  padding: 10px 25px;
  border: 1px #CCCCCC;
  border-style: dashed;
  border-radius: 20px;
  position: relative;
  z-index: 1;
}
.kd-course-2-card .card-rating-vote .tutor-ratings-stars {
  font-size: 12px;
  margin: 0;
}

.kd-course-2-card .card-rating-vote .tutor-ratings-stars i {
  margin: 0 2px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-2-card .card-rating-vote {
    padding: 10px 10px;
  }
}
.kd-course-2-card .card-rating-vote::after {
  content: "";
  width: 1px;
  height: 100%;
  background: transparent;
  border: 1px #CCCCCC;
  border-style: dashed;
  position: absolute;
  top: 0;
  left: 62%;
}
.kd-course-2-card .card-vote {
  font-size: 17px;
}
.kd-course-2-card-rating {
  display: flex;
  gap: 9px;
  align-items: center;
}
.kd-course-2-card-rating .rating-number {
  font-size: 17px;
  font-weight: 900;
}
.kd-course-2-card-rating .star-flex {
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 12px;
  color: #f69800;
  line-height: normal;
}
.kd-course-2-card .feature-list {
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}
.kd-course-2-card .feature-list li {
  font-size: 16px;
  position: relative;
  z-index: 1;
}
.kd-course-2-card .feature-list li:not(:last-child)::after {
  content: "";
  width: 1px;
  height: 100%;
  background: #ccc;
  position: absolute;
  top: 0px;
  right: -14px;
}
.kd-course-2-card:hover {
  box-shadow: 0px 7px 81px 0px rgba(32, 28, 29, 0.17);
}
.kd-course-2-card:hover .main-img img {
  transform: perspective(600px) rotateX(0.06deg) rotateY(0deg) scaleX(1.15) scaleY(1.15);
}

/* 
    course-2-end
*/
/* 
    course-3-start
*/
.kd-course-3-area .kd-course-1-tabs-btn .nav-item .nav-link {
  color: #fff;
}
.kd-course-3-area .kd-course-1-tabs-btn .nav-item .nav-link:hover {
  color: var(--kd-clr-sd-1);
}
.kd-course-3-area .kd-course-1-tabs-btn .nav-item .nav-link:is(.active) {
  background-color: var(--kd-clr-sd-1);
  color: #fff;
}
.kd-course-3-area .kd-course-1-scn-title {
  margin-bottom: 20px;
}
.kd-course-3-area .kd-course-1-bg-img {
  height: calc(100% - 187px);
}
.kd-course-3-tabs-content-wrap {
  border-radius: 20px;
  background: #fff;
  padding: 60px 90px;
  box-shadow: 0px 3px 60px 0px rgba(32, 42, 181, 0.11);
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-3-tabs-content-wrap {
    padding: 60px 30px;
  }
}
.kd-course-3-tabs-content {
  display: grid;
  grid-template-columns: 1fr 1.4fr;
  gap: 100px;
  overflow: hidden;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-course-3-tabs-content {
    grid-template-columns: 1fr 1.1fr;
    gap: 50px;
  }
}
@media (max-width: 767px) {
  .kd-course-3-tabs-content {
    grid-template-columns: 1fr;
    gap: 50px;
  }
}
.kd-course-3-tabs-content .item-title {
  font-size: 31px;
  margin-bottom: 14px;
}
.kd-course-3-tabs-content .item-disc {
  font-size: 19px;
  line-height: 28px;
  color: #363539;
  margin-bottom: 45px;
}
.kd-course-3-tabs-content .item-subtitle {
  font-size: 22px;
  margin-bottom: 16px;
}
.kd-course-3-tabs-content .feature-list {
  overflow: hidden;
}
.kd-course-3-tabs-content .feature-list li {
  font-size: 14px;
  line-height: 1;
  background: var(--kd-clr-pr-1);
  border-radius: 20px;
  color: #fff;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  padding: 11px 15px;
  max-width: 260px;
  transform: translate(-100%);
  transition: 1s var(--cube-1);
}
.kd-course-3-tabs-content .feature-list li:not(:last-child) {
  margin-bottom: 6px;
}
.kd-course-3-tabs-content .feature-list li:nth-child(2) {
  max-width: 350px;
}
.kd-course-3-tabs-content .opnity-list {
  border-radius: 0 60px 60px 0;
  overflow: hidden;
  max-width: 350px;
}
.kd-course-3-tabs-content .opnity-list li {
  font-size: 16px;
  line-height: 1;
  background: #F6F6F6;
  padding: 12px 30px;
  transform: translate(-100%);
  transition: 1s var(--cube-1);
}
.kd-course-3-tabs-content .opnity-list li:nth-child(2) {
  background-color: #ECECEC;
}
.kd-course-3-tabs-content-video {
  height: 460px;
  border-radius: 30px;
  position: relative;
  z-index: 1;
}
.kd-course-3-tabs-content-video .btn-position {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.kd-course-3-tabs-content-action {
  background: var(--kd-clr-sd-1);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 50px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
  margin-top: -150px;
  transform: translateY(50%);
  opacity: 0;
  transition: 1s var(--cube-1);
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-3-tabs-content-action {
    padding: 40px 10px;
  }
}
.kd-course-3-tabs-content-action .action-link {
  line-height: 1.5;
  font-size: 18px;
  margin-bottom: 23px;
}
.kd-course-3-tabs-content-action .kd-pr-btn-1 {
  background: #fff;
}
.kd-course-3-tabs-content-action .kd-pr-btn-1:hover {
  background: var(--kd-clr-pr-1);
}
.kd-course-3-tabs-content .right-content {
  position: relative;
  z-index: 1;
}
.kd-course-3-tabs-content-il-1 {
  width: 30%;
  position: absolute;
  bottom: 0;
  left: -120px;
  z-index: 1;
}
@media (max-width: 767px) {
  .kd-course-3-tabs-content-il-1 {
    display: none;
  }
}
.kd-course-3-tabs-content-wrap .tab-pane:is(.show) .kd-course-3-tabs-content .feature-list li {
  transform: translate(0%);
}
.kd-course-3-tabs-content-wrap .tab-pane:is(.show) .kd-course-3-tabs-content .opnity-list li {
  transform: translate(0%);
}
.kd-course-3-tabs-content-wrap .tab-pane:is(.show) .kd-course-3-tabs-content .opnity-list li:nth-child(2) {
  transition-delay: 0.3s;
}
.kd-course-3-tabs-content-wrap .tab-pane:is(.show) .kd-course-3-tabs-content-action {
  transform: translateY(0%);
  opacity: 1;
}

/* 
    course-3-end
*/
/* 
    course-4-start
*/
.kd-course-4-item .kd-course-2-card {
  margin-bottom: 0;
}
.kd-course-4-item .kd-course-2-card .main-img {
  height: 200px;
  border-radius: 15px;
  position: relative;
  z-index: 1;
}
.kd-course-4-item .kd-course-2-card .item-duration {
  display: inline-block;
  position: absolute;
  top: 25px;
  left: 25px;
  background: #ebebeb;
  font-size: 13px;
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 13px;
}
.kd-course-4-item .kd-course-2-card .item-student {
  display: inline-block;
  position: absolute;
  bottom: 25px;
  left: 25px;
  background: #ebebeb;
  font-size: 13px;
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 13px;
}
.kd-course-4-item .kd-course-2-card .title {
  font-size: 24px;
  margin-bottom: 0px;
}
.kd-course-4-item .kd-course-2-card .item-disc {
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 500;
  color: var(--kd-clr-pr-1);
}
.kd-course-4-item .kd-course-2-card .item-btn {
  display: inline-block;
  font-size: 15px;
  color: var(--kd-clr-h-1);
  transition: 0.2s var(--cube-1);
}
.kd-course-4-item .kd-course-2-card .item-btn i {
  margin-right: 3px;
  color: var(--kd-clr-pr-1);
  transition: 0.2s var(--cube-1);
}
.kd-course-4-item .kd-course-2-card .item-btn:hover {
  color: var(--kd-clr-sd-1);
}
.kd-course-4-item .kd-course-2-card .item-btn:hover i {
  transform: translateX(5px);
}
.kd-course-4-item .kd-course-2-card:hover {
  box-shadow: none;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-4-slider-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding-top: 30px;
  }
}
.kd-course-4-slider-btn .kd-slider-btn-1 {
  background: #363539;
  color: #fff;
}
.kd-course-4-slider-btn .kd-slider-btn-1 .icon-1 {
  color: #fff;
}
.kd-course-4-slider-btn .kd-slider-btn-1 .icon-2 {
  color: #fff;
}
.kd-course-4-slider-btn .kd-slider-btn-1:hover {
  background: var(--kd-clr-sd-1);
}
.kd-course-4-slider-btn .kd_course_4_slider_next {
  position: absolute;
  right: -85px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  display: inline-block;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-4-slider-btn .kd_course_4_slider_next {
    position: unset;
    transform: unset;
  }
}
.kd-course-4-slider-btn .kd_course_4_slider_prev {
  position: absolute;
  left: -85px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  display: inline-block;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-4-slider-btn .kd_course_4_slider_prev {
    position: unset;
    transform: unset;
  }
}

/* 
    course-4-end
*/
/* 
    course-5-start
*/
.kd-course-5-item .kd-course-2-card {
  padding: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
.kd-course-5-item .kd-course-2-card .main-img {
  height: 260px;
}
.kd-course-5-item .kd-course-2-card .card-categorie {
  display: inline-block;
  position: absolute;
  top: 25px;
  left: 25px;
  background: white;
  font-size: 15px;
  font-weight: 700;
  color: #232323;
  border-radius: 15px;
  padding: 9px 13px;
}
.kd-course-5-item .kd-course-2-card .card-rating {
  display: inline-block;
  position: absolute;
  right: 25px;
  bottom: 25px;
  background: var(--kd-clr-sd-1);
  font-size: 15px;
  font-weight: 700;
  color: #232323;
  border-radius: 15px;
  padding: 9px 13px;
}
.kd-course-5-item .kd-course-2-card .card-content {
  padding: 30px 40px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .kd-course-5-item .kd-course-2-card .card-content {
    padding: 30px 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-5-item .kd-course-2-card .card-content {
    padding: 30px 15px;
  }
}
.kd-course-5-item .kd-course-2-card .feature-list {
  margin-bottom: 16px;
}
.kd-course-5-item .kd-course-2-card .title {
  font-size: 25px;
  line-height: 1.4;
  color: #3e3f5e;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-5-item .kd-course-2-card .title {
    font-size: 22px;
  }
}
.kd-course-5-item .kd-course-2-card .title:hover {
  color: var(--kd-clr-sd-1);
}
.kd-course-5-item .kd-course-2-card .card-author {
  display: flex;
  gap: 15px;
  align-items: center;
}
.kd-course-5-item .kd-course-2-card .card-author .author-img {
  flex: 0 0 auto;
  width: 41px;
  height: 41px;
  border-radius: 50%;
}
.kd-course-5-item .kd-course-2-card .card-author .author-title {
  font-size: 16px;
  font-weight: 800;
}
.kd-course-5-item .kd-course-2-card .author-and-price {
  display: flex;
  align-items: center;
  background: #f8f8f8;
  border-radius: 26px;
  padding-left: 7px;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.kd-course-5-item .kd-course-2-card .card-price {
  background: var(--kd-clr-pr-1);
  font-size: 19px;
  border-radius: 26px;
  padding: 15px 31px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-5-item .kd-course-2-card .card-price {
    padding: 7px 10px;
  }
}
.kd-course-5-item .kd-course-2-card:hover {
  box-shadow: none;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-5-slider-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding-top: 30px;
  }
}
.kd-course-5-slider-btn .kd-slider-btn-1 {
  background: #363539;
  color: #fff;
}
.kd-course-5-slider-btn .kd-slider-btn-1 .icon-1 {
  color: #fff;
}
.kd-course-5-slider-btn .kd-slider-btn-1 .icon-2 {
  color: #fff;
}
.kd-course-5-slider-btn .kd-slider-btn-1:hover {
  background: var(--kd-clr-sd-1);
}
.kd-course-5-slider-btn .kd_course_5_slider_next {
  position: absolute;
  right: -85px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  display: inline-block;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-5-slider-btn .kd_course_5_slider_next {
    position: unset;
    transform: unset;
  }
}
.kd-course-5-slider-btn .kd_course_5_slider_prev {
  position: absolute;
  left: -85px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  display: inline-block;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-5-slider-btn .kd_course_5_slider_prev {
    position: unset;
    transform: unset;
  }
}

/* 
    course-5-end
*/
/* 
    course-6-start
*/
.kd-course-4-bg {
  position: absolute;
  width: calc(100% - 90px);
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  border-left: 6px solid #29A6D5;
  border-radius: 260px 0 0 0;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-course-4-bg {
    width: calc(100% - 10px);
    border-radius: 60px 0 0 0;
  }
}
@media (max-width: 767px) {
  .kd-course-4-bg {
    width: calc(100% - 10px);
    border-radius: 60px 0 0 0;
    border-left: 1px solid #29A6D5;
  }
}
.kd-course-4-il {
  position: absolute;
  right: 20px;
  top: 35%;
  z-index: -1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-4-il {
    display: none;
  }
}
.kd-course-4-scn-title {
  gap: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-4-scn-title {
    flex-wrap: wrap;
  }
}
.kd-course-4-card {
  background: rgba(255, 255, 255, 0.04);
  padding: 15px;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  border: 1px solid;
  border-color: transparent;
  transition: 0.4s ease;
  margin-bottom: 30px;
}
.kd-course-4-card::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: center center;
  z-index: -2;
  background: linear-gradient(129deg, rgb(215, 0, 7) 0%, rgb(215, 0, 203) 100%);
  border-radius: 10px;
  opacity: 0;
  transition: 0.4s ease;
}
.kd-course-4-card::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: #28343E;
  border-radius: 10px;
}
.kd-course-4-card .item-img {
  height: 295px;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  margin-bottom: 22px;
}
.kd-course-4-card .item-categories {
  position: absolute;
  top: 15px;
  left: 15px;
  background: var(--kd-clr-pr-4);
  border-radius: 5px;
  text-transform: capitalize;
  line-height: 1;
  font-size: 18px;
  font-weight: 500;
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 11px 19px;
  z-index: 1;
}
.kd-course-4-card .item-rating {
  position: absolute;
  right: 15px;
  top: 18px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  border-radius: 5px;
  font-size: 18px;
  line-height: 1;
  z-index: 1;
  padding: 6px 8px;
}
.kd-course-4-card .item-rating i {
  margin-right: 5px;
  color: #FFC107;
}
.kd-course-4-card .item-author {
  position: absolute;
  bottom: 0;
  left: 30px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}
.kd-course-4-card .item-author-img {
  height: 62px;
  width: 58px;
  border-radius: 100px 100px 0 0;
  border: 5px solid #202E37;
}
.kd-course-4-card .item-author-name {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
}
.kd-course-4-card-feature {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}
.kd-course-4-card-feature li {
  font-size: 16px;
  font-weight: 500;
  font-family: var(--kd-font-barlow);
  color: rgba(255, 255, 255, 0.8);
  line-height: 26px;
}
.kd-course-4-card-feature li .icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 50%;
  font-size: 16px;
  margin-right: 5px;
  color: #F0901E;
}
.kd-course-4-card-feature li:nth-child(2) .icon {
  color: var(--kd-clr-pr-4);
}
.kd-course-4-card-feature li:nth-child(3) .icon {
  color: #29A6D5;
}
.kd-course-4-card .item-title {
  font-size: 24px;
  line-height: 1.33;
  margin-bottom: 22px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-4-card .item-title {
    font-size: 20px;
  }
}
.kd-course-4-card .item-title a {
  background-image: linear-gradient(0deg, #fff 0%, #fff 100%);
  background-size: 0% 1px;
  background-repeat: no-repeat;
  background-position: left bottom;
  transition: 0.3s var(--cube-1);
}
.kd-course-4-card .item-title:hover a {
  background-size: 100% 1px;
}
.kd-course-4-card .card-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  flex-wrap: wrap;
}
.kd-course-4-card .item-price {
  font-size: 22px;
  line-height: 34px;
  font-weight: 700;
  color: var(--kd-clr-sd-4);
}
.kd-course-4-card .item-price span {
  color: #fff;
}
.kd-course-4-card-btn {
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  padding: 2px 12px;
  position: relative;
  z-index: 1;
  border-radius: 100px;
  overflow: hidden;
  color: #fff;
}
.kd-course-4-card-btn i {
  margin-left: 17px;
}
.kd-course-4-card-btn::after {
  content: "";
  background: var(--kd-clr-pr-4);
  position: absolute;
  top: 0;
  right: 0;
  width: 27%;
  height: 100%;
  z-index: -1;
  transition: 0.5s var(--cube-1);
}
.kd-course-4-card-btn:hover {
  color: #fff;
}
.kd-course-4-card-btn:hover::after {
  width: 100%;
}
.kd-course-4-card .card-line {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
  margin-bottom: 10px;
}
.kd-course-4-card .card-content {
  padding: 0 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-course-4-card .card-content {
    padding: 0 0px;
  }
}
.kd-course-4-card:hover {
  border-color: var(--kd-clr-pr-4);
  box-shadow: 0 4px 40px 4px rgba(215, 0, 7, 0.3);
}
.kd-course-4-card:hover::after {
  transform: rotate(-5deg);
  opacity: 1;
}
.kd-course-4-card:hover::before {
  opacity: 1;
}
.kd-course-4-tabs-btn {
  display: flex;
  gap: 20px;
  flex: 0 0 auto;
}
@media (max-width: 767px) {
  .kd-course-4-tabs-btn {
    flex-wrap: wrap;
    flex: unset;
  }
}
.kd-course-4-tabs-btn .nav-link {
  background: #17252F;
  border: 2px solid rgba(255, 255, 255, 0.1);
  font-size: 20px;
  font-weight: 600;
  flex: 0 0 auto;
  padding: 19px 19px;
  border-radius: 5px;
  transition: 0.4s ease;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-4-tabs-btn .nav-link {
    font-size: 16px;
    padding: 10px 9px;
  }
}
.kd-course-4-tabs-btn .nav-link:hover {
  background: var(--kd-clr-pr-4);
  color: #fff;
}
.kd-course-4-tabs-btn .nav-link:is(.active) {
  background: var(--kd-clr-pr-4);
  color: #fff;
}

/* 
    course-6-end
*/
/* 
    choose-us-1-start
*/
.kd-choose-us-1-area {
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-choose-us-1-area {
    padding-bottom: 60px;
  }
}
.kd-choose-us-1-bg-shape {
  width: 77%;
  height: 360px;
  background: #241442;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 158px 0 0 158px;
  z-index: -1;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-choose-us-1-bg-shape {
    width: 100%;
    height: 500px;
    border-radius: 0;
  }
}
.kd-choose-us-1-left {
  padding-right: 80px;
  position: relative;
  z-index: 1;
  margin-left: -110px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-choose-us-1-left {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-choose-us-1-left {
    margin-left: 0px;
    padding-right: 0;
    margin-bottom: 50px;
  }
}
.kd-choose-us-1-img {
  border-radius: 50%;
  border: 12px solid #fff;
}
.kd-choose-us-1-img-il-1 {
  position: absolute;
  right: 30px;
  top: 20px;
  box-shadow: 0px 3px 40px 0px rgba(0, 0, 0, 0.06);
  width: 34%;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-choose-us-1-img-il-1 {
    right: 0px;
  }
}
.kd-choose-us-1-img-il-2 {
  box-shadow: 0px 3px 40px 0px rgba(0, 0, 0, 0.06);
  position: absolute;
  bottom: 100px;
  left: -50px;
  width: 47%;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-choose-us-1-img-il-2 {
    left: 0px;
  }
}
.kd-choose-us-1-bg-shape-1 {
  position: absolute;
  top: -20px;
  left: -20px;
  z-index: -1;
  width: 42%;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-choose-us-1-bg-shape-1 {
    left: 0px;
  }
}
.kd-choose-us-1-right {
  margin-left: -20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-choose-us-1-right {
    margin-left: 0px;
  }
}
.kd-choose-us-1-right .inner-div {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  gap: 30px;
  align-items: center;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-choose-us-1-right .inner-div {
    grid-template-columns: 1fr;
  }
}
.kd-choose-us-1-scn-title {
  margin-bottom: 100px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-choose-us-1-scn-title {
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-choose-us-1-feature-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
}
.kd-choose-us-1-feature {
  display: flex;
  gap: 18px;
}
.kd-choose-us-1-feature:not(:last-child) {
  margin-bottom: 40px;
}
.kd-choose-us-1-feature .icon {
  width: 65px;
}
.kd-choose-us-1-feature .title {
  font-size: 24px;
  margin-bottom: 6px;
}
@media (max-width: 767px) {
  .kd-choose-us-1-feature .title {
    font-size: 20px;
  }
}
.kd-choose-us-1-feature .disc {
  font-size: 17px;
  line-height: 27px;
  color: #9192a6;
}
.kd-choose-us-1-feature:hover .icon img {
  animation: 1s kd-jello;
}

/* 
    choose-us-1-end
*/
/* 
    choose-us-2-start
*/
.kd-choose-us-3-img {
  border-radius: 0 0 500px 500px;
  text-align: center;
  position: relative;
  z-index: 1;
}
.kd-choose-us-3-img::after {
  content: "";
  width: 100%;
  height: 92%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 420px;
  background: #ffffff;
  z-index: -1;
  transform: translateY(100%);
  transition: 1s var(--cube-1);
}
.kd-choose-us-3-img img {
  transform: translateY(100%);
  transition: 1s ease-in-out;
}
.kd-choose-us-3-img:is(.active)::after {
  transform: translateY(0);
}
.kd-choose-us-3-img:is(.active) img {
  transform: translateY(0);
  transition-delay: 0.5s;
}
.kd-choose-us-3-left {
  position: relative;
  z-index: 1;
  padding-left: 70px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-choose-us-3-left {
    margin-bottom: 70px;
  }
}
.kd-choose-us-3-tabs-btn {
  background: var(--kd-clr-sd-1);
  box-shadow: 0px 3px 95px 0px rgba(0, 0, 0, 0.2);
  border-radius: 80px;
  padding: 70px 35px;
  display: inline-block;
  position: absolute;
  bottom: -20px;
  left: 0;
  z-index: 1;
}
.kd-choose-us-3-tabs-btn .nav-link {
  font-size: 18px;
  color: #000;
  background: transparent;
  transition: 0.3s var(--cube-1);
}
.kd-choose-us-3-tabs-btn .nav-link i {
  margin-right: 3px;
  transition: 0.3s var(--cube-1);
}
.kd-choose-us-3-tabs-btn .nav-link:not(:last-child) {
  margin-bottom: 16px;
}
.kd-choose-us-3-tabs-btn .nav-link:is(.active) {
  color: var(--kd-clr-pr-1);
}
.kd-choose-us-3-tabs-btn .nav-link:hover {
  color: var(--kd-clr-pr-1);
}
.kd-choose-us-3-tabs-btn .nav-link:hover i {
  transform: translateX(3px);
}
.kd-choose-us-3-tabs-content {
  padding-left: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-choose-us-3-tabs-content {
    padding-left: 0px;
  }
}
.kd-choose-us-3-tabs-content-single .disc {
  max-width: 580px;
  margin-bottom: 32px;
  color: #d7d7d7;
}
.kd-choose-us-3-tabs-content-single .list-title {
  color: var(--kd-clr-sd-1);
  font-size: 24px;
  margin-bottom: 20px;
}
.kd-choose-us-3-tabs-content-single .list-title img {
  width: 22px;
  margin-right: 3px;
}
.kd-choose-us-3-tabs-content-single .kd-list-1 {
  margin-bottom: 40px;
}
.kd-choose-us-3-tabs-content-single .kd-list-1 li {
  color: #fff;
  display: flex;
  align-items: center;
  gap: 7px;
}
.kd-choose-us-3-tabs-content-single .kd-list-1 li i {
  font-size: 24px;
  color: #000;
}

/* 
    choose-us-2-end
*/
/* 
    choose-us-4-start
*/
.kd-choose-4-il {
  position: absolute;
  left: 12%;
  top: 15%;
  z-index: -1;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px) {
  .kd-choose-4-il {
    left: 0%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-choose-4-il {
    display: none;
  }
}
.kd-choose-4-scn-title {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.kd-choose-4-row {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
  gap: 50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-choose-4-row {
    grid-template-columns: 1fr;
  }
}
.kd-choose-4-disc {
  font-weight: 500;
  opacity: 1;
  margin-bottom: 95px;
}
.kd-choose-4-item .item-icon {
  width: 78px;
  height: 84px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  color: var(--kd-clr-sd-4);
  font-size: 45px;
  margin-bottom: 25px;
}
.kd-choose-4-item .item-icon:is(.has-red) {
  color: #ff00e4;
}
.kd-choose-4-item .item-icon:is(.has-green) {
  color: #08BCD5;
}
.kd-choose-4-item .item-title {
  font-size: 22px;
  line-height: 1.27;
  transition: 0.2s ease;
  margin-bottom: 16px;
}
.kd-choose-4-item .item-title:hover {
  color: var(--kd-clr-sd-4);
}
.kd-choose-4-item:hover .item-icon i {
  animation: 1s kd-jello;
}

/* 
    choose-us-4-end
*/
/* 
    services-1-start
*/
.kd-services-1-scn-title .disc {
  max-width: 400px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-services-1-left {
    margin-bottom: 50px;
  }
}
.kd-services-1-card-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
@media (max-width: 767px) {
  .kd-services-1-card-wrap {
    grid-template-columns: 1fr;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-services-1-card-wrap {
    grid-template-columns: 1fr 1fr;
  }
}
.kd-services-1-card-wrap .gird-col-2 {
  padding-top: 50px;
}
@media (max-width: 767px) {
  .kd-services-1-card-wrap .gird-col-2 {
    padding-top: 0px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-services-1-card-wrap .gird-col-2 {
    padding-top: 50px;
  }
}
.kd-services-1-card {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(238, 238, 238);
  border-radius: 20px;
  padding: 50px 60px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 40px 0px rgba(94, 92, 154, 0.06);
  background-color: #fff;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .kd-services-1-card {
    padding: 40px 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-services-1-card {
    padding: 40px 20px;
  }
}
.kd-services-1-card .icon {
  width: 70px;
  margin-bottom: 21px;
}
.kd-services-1-card .title {
  font-size: 26px;
  margin-bottom: 16px;
  transition: 0.2s var(--cube-1);
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-services-1-card .title {
    font-size: 22px;
  }
}
.kd-services-1-card .title:hover {
  color: var(--kd-clr-sd-1);
  letter-spacing: 0.3px;
}
.kd-services-1-card .disc {
  font-size: 17px;
  line-height: 27px;
}
.kd-services-1-card:hover .icon {
  animation: 1s kd-jello;
}

/* 
    services-1-end
*/
/* 
    services-2-start
*/
.kd-services-2-content {
  margin-bottom: 30px;
}
.kd-services-2-scn-btn {
  display: inline-block;
  font-family: var(--kd-font-pr-1);
  text-transform: capitalize;
  font-weight: 800;
  color: var(--kd-clr-h-1);
  font-size: 18px;
  transition: 0.2s var(--cube-1);
}
.kd-services-2-scn-btn i {
  margin-left: 3px;
  transition: 0.2s var(--cube-1);
}
.kd-services-2-scn-btn:hover {
  color: var(--kd-clr-sd-1);
}
.kd-services-2-scn-btn:hover i {
  transform: translateX(3px);
}
.kd-services-2-card {
  background: #fff;
  box-shadow: 0px 7px 46px 0px rgba(32, 28, 29, 0.03);
  border-radius: 20px;
  padding: 50px 50px;
  padding-bottom: 35px;
  margin-bottom: 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-services-2-card {
    padding: 50px 30px;
  }
}
.kd-services-2-card .icon-wrap {
  gap: 20px;
  margin-bottom: 32px;
}
.kd-services-2-card .icon {
  width: 62px;
}
.kd-services-2-card .course {
  display: inline-block;
  font-size: 14px;
  color: #282828;
  background: #f3fffe;
  border-radius: 21px;
  padding: 15px 26px;
}
.kd-services-2-card .card-title {
  font-size: 23px;
  transition: 0.2s var(--cube-1);
  transform-origin: left;
  margin-bottom: 6px;
}
.kd-services-2-card .card-title:hover {
  color: var(--kd-clr-sd-1);
  transform: scaleX(103%);
}
.kd-services-2-card .disc {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}
.kd-services-2-card:hover .icon {
  animation: 1s kd-jello;
}

/* 
    services-2-end
*/
/* 
    services-3-start
*/
.kd-services-3-item {
  border-radius: 25px;
  box-shadow: 0px 7px 54px 0px rgba(32, 28, 29, 0.05);
  text-align: center;
  padding: 60px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-services-3-item {
    padding: 60px 20px;
  }
}
.kd-services-3-item .icon {
  width: 80px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 42px;
}
.kd-services-3-item .title {
  font-size: 24px;
  transition: 0.2s var(--cube-1);
  margin-bottom: 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-services-3-item .title {
    font-size: 22px;
  }
}
.kd-services-3-item .title:hover {
  color: var(--kd-clr-sd-1);
  transform: scaleX(102%);
}
.kd-services-3-item .disc {
  font-size: 17px;
  line-height: 26px;
  color: #797a84;
}
.kd-services-3-item:hover .icon img {
  animation: 1s kd-jello;
}
.kd-services-3-slider {
  position: relative;
  z-index: 1;
}
.kd-services-3-slider .swiper-container {
  margin-right: -40px;
  margin-left: -40px;
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
}
.kd-services-3-slider .swiper-container .swiper-slide {
  transition: 1s;
}
.kd-services-3-slider .swiper-container .swiper-slide:is(.swiper-slide-prev) {
  opacity: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-services-3-slider-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
}
.kd-services-3-slider-btn .kd-slider-btn-1 {
  background: #363539;
  color: #fff;
}
.kd-services-3-slider-btn .kd-slider-btn-1 .icon-1 {
  color: #fff;
}
.kd-services-3-slider-btn .kd-slider-btn-1 .icon-2 {
  color: #fff;
}
.kd-services-3-slider-btn .kd-slider-btn-1:hover {
  background: var(--kd-clr-sd-1);
}
.kd-services-3-slider-btn .kd_services_3_slider_next {
  position: absolute;
  right: -85px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  display: inline-block;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-services-3-slider-btn .kd_services_3_slider_next {
    position: unset;
    transform: unset;
  }
}
.kd-services-3-slider-btn .kd_services_3_slider_prev {
  position: absolute;
  left: -85px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  display: inline-block;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-services-3-slider-btn .kd_services_3_slider_prev {
    position: unset;
    transform: unset;
  }
}

/* 
    services-3-end
*/
/* 
    services-4-start
*/
.kd-services-4-area {
  position: relative;
}
.kd-services-4-il-1 {
  position: absolute;
  right: 30px;
  top: 100px;
  z-index: -1;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-services-4-il-1 {
    display: none;
  }
}
.kd-services-4-il-2 {
  position: absolute;
  bottom: 90px;
  left: 0;
  z-index: -1;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-services-4-il-2 {
    display: none;
  }
}
.kd-services-4-scn-title {
  gap: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-services-4-scn-title {
    flex-wrap: wrap;
    gap: 0px;
    margin-bottom: 30px;
  }
}
.kd-services-4-scn-title .scn-disc {
  max-width: 520px;
}
.kd-services-4-card {
  height: 495px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  background: #152028;
  border: 1px solid #1E2B33;
  transition: 0.5s ease-in-out;
}
@media (max-width: 767px) {
  .kd-services-4-card {
    height: 425px;
  }
}
.kd-services-4-card .card-img {
  opacity: 0%;
  transition: 0.5s ease-in-out;
  width: 100%;
  height: 100%;
}
.kd-services-4-card .card-icon {
  width: 84px;
  height: 84px;
  background: var(--kd-clr-pr-4);
  border-radius: 5px;
  font-size: 40px;
  color: #fff;
  position: absolute;
  top: -50px;
  left: 40px;
  opacity: 0;
  transition: 0.5s var(--cube-1);
}
.kd-services-4-card .card-content {
  position: absolute;
  top: 0;
  left: 0;
  padding: 50px 40px;
  width: 100%;
  transition: 0.4s ease-in-out;
}
@media (max-width: 767px) {
  .kd-services-4-card .card-content {
    padding: 50px 20px;
  }
}
.kd-services-4-card .card-title {
  font-size: 30px;
  line-height: 1.2;
  margin-bottom: 22px;
  transition: 0.2s ease-in-out;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-services-4-card .card-title {
    font-size: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-services-4-card .card-title {
    font-size: 24px;
  }
}
.kd-services-4-card .card-title:hover {
  color: var(--kd-clr-pr-4);
}
.kd-services-4-card .card-number {
  font-size: 140px;
  line-height: 1;
  position: absolute;
  right: 30px;
  bottom: 0;
  font-weight: 900;
  color: #1E2B33;
  transition: 0.4s ease-in-out;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-services-4-card .card-number {
    font-size: 100px;
  }
}
.kd-services-4-card:hover {
  border-color: var(--kd-clr-pr-4);
}
.kd-services-4-card:hover .card-icon {
  top: 50px;
  opacity: 1;
}
.kd-services-4-card:hover .card-img {
  opacity: 30%;
}
.kd-services-4-card:hover .card-content {
  top: 200px;
}
@media (max-width: 767px) {
  .kd-services-4-card:hover .card-content {
    top: 150px;
  }
}
.kd-services-4-card:hover .card-number {
  bottom: -100px;
  opacity: 0;
}
.kd-services-4-slider {
  margin-right: -350px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-services-4-slider {
    margin-right: 0px;
  }
}
.kd-services-4-slider .swiper-container .swiper-wrapper .swiper-slide {
  transition: 0.5s;
}
.kd-services-4-slider .swiper-container .swiper-wrapper .swiper-slide:nth-child(odd) {
  margin-top: 60px;
}
.kd-services-4-slider-btn {
  gap: 30px;
}

/* 
    services-4-end
*/
/* 
    testimonial-1-start
*/
.kd-testimonial-1-area {
  position: relative;
  z-index: 1;
  padding-top: 120px;
  padding-bottom: 200px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-testimonial-1-left {
    margin-bottom: 50px;
  }
}
.kd-testimonial-1-item {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  align-items: center;
  border-radius: 20px;
  overflow: hidden;
  background: #fff;
}
@media (max-width: 767px) {
  .kd-testimonial-1-item {
    grid-template-columns: 1fr;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-testimonial-1-item {
    grid-template-columns: 1fr 1.2fr;
  }
}
.kd-testimonial-1-item .main-img {
  height: 415px;
}
.kd-testimonial-1-item .item-content {
  position: relative;
  z-index: 1;
  padding: 30px 60px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-testimonial-1-item .item-content {
    padding: 30px 20px;
  }
}
@media (max-width: 767px) {
  .kd-testimonial-1-item .item-content {
    padding: 30px 20px;
  }
}
.kd-testimonial-1-item .item-content .icon {
  font-size: 80px;
  line-height: 1;
  color: #000;
  position: absolute;
  right: 80px;
  top: -10px;
}
.kd-testimonial-1-item .kd-ratting-1 {
  font-size: 12px;
  color: #fff;
  background: #ef991f;
  padding: 10px 20px;
  border-radius: 17px;
  margin-bottom: 20px;
  transform: translateX(100px);
  opacity: 0;
  transition: 0.5s var(--cube-1);
}
.kd-testimonial-1-item .item-comment {
  font-size: 19px;
  line-height: 27px;
  font-weight: 600;
  color: #9192a6;
  margin-bottom: 35px;
  transform: translateX(100px);
  opacity: 0;
  transition: 0.5s var(--cube-1);
}
.kd-testimonial-1-item-author {
  gap: 15px;
  transform: translateX(100px);
  opacity: 0;
  transition: 0.5s var(--cube-1);
}
.kd-testimonial-1-item-author .author-img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
}
.kd-testimonial-1-item-author .name {
  font-size: 20px;
}
.kd-testimonial-1-item-author .bio {
  font-size: 16px;
  line-height: 27px;
}
.kd-testimonial-1-slider {
  padding-left: 40px;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-testimonial-1-slider {
    padding-left: 0px;
  }
}
.kd-testimonial-1-slider .swiper-container .swiper-wrapper .swiper-slide:is(.swiper-slide-active) .kd-testimonial-1-item .kd-ratting-1 {
  transform: translateX(0);
  opacity: 1;
  transition-delay: 0.3s;
}
.kd-testimonial-1-slider .swiper-container .swiper-wrapper .swiper-slide:is(.swiper-slide-active) .kd-testimonial-1-item .item-comment {
  transform: translateX(0);
  opacity: 1;
  transition-delay: 0.5s;
}
.kd-testimonial-1-slider .swiper-container .swiper-wrapper .swiper-slide:is(.swiper-slide-active) .kd-testimonial-1-item-author {
  transform: translateX(0);
  opacity: 1;
  transition-delay: 0.7s;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-testimonial-1-slider-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding-top: 30px;
  }
}
.kd-testimonial-1-slider-btn .kd-slider-btn-1 {
  background: #363539;
  color: #fff;
}
.kd-testimonial-1-slider-btn .kd-slider-btn-1 .icon-1 {
  color: #fff;
}
.kd-testimonial-1-slider-btn .kd-slider-btn-1 .icon-2 {
  color: #fff;
}
.kd-testimonial-1-slider-btn .kd-slider-btn-1:hover {
  background: var(--kd-clr-sd-1);
}
.kd-testimonial-1-slider-btn .kd_testimonial_1_slider_next {
  position: absolute;
  right: -25px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  display: inline-block;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-testimonial-1-slider-btn .kd_testimonial_1_slider_next {
    position: unset;
    transform: unset;
  }
}
.kd-testimonial-1-slider-btn .kd_testimonial_1_slider_prev {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  display: inline-block;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-testimonial-1-slider-btn .kd_testimonial_1_slider_prev {
    position: unset;
    transform: unset;
  }
}

/* 
    testimonial-1-end
*/
/* 
    testimonial-2-start
*/
.kd-t-child-1-area {
  margin-top: -70px;
  position: relative;
  z-index: 1;
}
.kd-t-child-1-wrap {
  background: #f5f4f0;
  border-radius: 90px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  padding: 50px 70px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .kd-t-child-1-wrap {
    border-radius: 70px;
    gap: 40px;
    padding: 50px 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-t-child-1-wrap {
    border-radius: 30px;
    gap: 30px;
    padding: 50px 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-t-child-1-wrap {
    border-radius: 50px;
    grid-template-columns: 1fr;
    gap: 50px;
    padding: 50px 40px;
  }
}
@media (max-width: 767px) {
  .kd-t-child-1-wrap {
    border-radius: 50px;
    grid-template-columns: 1fr;
    gap: 50px;
    padding: 50px 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-t-child-1-wrap {
    border-radius: 50px;
    grid-template-columns: 1fr;
    gap: 50px;
    padding: 50px 40px;
  }
}
.kd-t-child-1-content .title {
  font-size: 38px;
  line-height: 1.31;
  margin-bottom: 17px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-t-child-1-content .title {
    font-size: 30px;
  }
}
.kd-t-child-1-content .subtitle {
  font-size: 20px;
  margin-bottom: 18px;
}
.kd-t-child-1-brand {
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}
.kd-t-child-1-brand .item {
  width: 140px;
}
.kd-t-child-1-reating-item-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
  align-items: start;
}
@media (max-width: 767px) {
  .kd-t-child-1-reating-item-wrap {
    grid-template-columns: 1fr;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-t-child-1-reating-item-wrap {
    grid-template-columns: 1fr 1fr;
  }
}
.kd-t-child-1-reating-item {
  background: #fff;
  border-radius: 60px;
  padding: 50px 50px;
  padding-bottom: 35px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .kd-t-child-1-reating-item {
    padding: 50px 30px;
    padding-bottom: 35px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-t-child-1-reating-item {
    padding: 50px 20px;
    padding-bottom: 35px;
  }
}
@media (max-width: 767px) {
  .kd-t-child-1-reating-item {
    padding: 50px 20px;
    padding-bottom: 35px;
  }
}
.kd-t-child-1-reating-item .number {
  font-size: 75px;
  line-height: 1;
  margin-bottom: 17px;
}
.kd-t-child-1-reating-item .reating {
  font-size: 15px;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 6px;
  color: #fc462b;
  margin-bottom: 17px;
}
.kd-t-child-1-reating-item .reating:is(.has-clr-yollow) {
  color: #F69900;
}
.kd-t-child-1-reating-item .title {
  font-size: 20px;
  margin-bottom: 0px;
}
.kd-t-child-1-reating-item .disc {
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  color: #282828;
}

/*
    testimonial-2-end
*/
/*
    testimonial-4-start
*/
.kd-testimonial-4-il {
  position: absolute;
  right: 5%;
  top: 10%;
  z-index: -1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-testimonial-4-il {
    display: none;
  }
}
.kd-testimonial-4-il-2 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-testimonial-4-img {
    margin-bottom: 50px;
  }
}
.kd-testimonial-4-slider {
  padding-left: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-testimonial-4-slider {
    padding-left: 0;
  }
}
.kd-testimonial-4-item {
  max-width: 610px;
}
.kd-testimonial-4-item .icon {
  font-size: 70px;
  line-height: 1;
  color: var(--kd-clr-pr-4);
  margin-bottom: 20px;
  transform: translateY(-50%);
  opacity: 0;
  transition: 1s var(--cube-1);
}
.kd-testimonial-4-item .title {
  font-size: 34px;
  line-height: 1.17;
  margin-bottom: 22px;
  transform: translateY(-50%);
  opacity: 0;
  transition: 1s var(--cube-1);
}
@media (max-width: 767px) {
  .kd-testimonial-4-item .title {
    font-size: 26px;
  }
}
.kd-testimonial-4-item .comment {
  font-size: 22px;
  line-height: 34px;
  margin-bottom: 45px;
  transform: translateY(-50%);
  opacity: 0;
  transition: 1s var(--cube-1);
}
.kd-testimonial-4-item-author {
  transform: translateY(-50%);
  opacity: 0;
  transition: 1s var(--cube-1);
}
.kd-testimonial-4-item-author .author-name {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 8px;
}
.kd-testimonial-4-item-author .author-bio {
  font-size: 18px;
  line-height: 26px;
  color: var(--kd-clr-sd-4);
}
.kd-testimonial-4-slider-btn-wrap {
  display: flex;
  align-items: center;
  gap: 20px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
}
@media (max-width: 767px) {
  .kd-testimonial-4-slider-btn-wrap {
    position: unset;
    padding-top: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-testimonial-4-slider-btn-wrap {
    position: absolute;
    padding-top: 0px;
  }
}
.kd-testimonial-4-slider-btn {
  width: 54px;
  height: 54px;
  border: 1px solid var(--kd-clr-pr-4);
  background: transparent;
  font-size: 20px;
  color: var(--kd-clr-pr-4);
  flex: 0 0 auto;
  transition: 0.5s ease;
  cursor: pointer;
}
.kd-testimonial-4-slider-btn:hover {
  background: var(--kd-clr-pr-4);
  color: #fff;
}
.kd-testimonial-4-slider .swiper-container .swiper-wrapper .swiper-slide:is(.swiper-slide-active) .kd-testimonial-4-item .icon {
  transform: translateY(0);
  opacity: 1;
  transition-delay: 0.5s;
}
.kd-testimonial-4-slider .swiper-container .swiper-wrapper .swiper-slide:is(.swiper-slide-active) .kd-testimonial-4-item .title {
  transform: translateY(0);
  opacity: 1;
  transition-delay: 0.5s;
}
.kd-testimonial-4-slider .swiper-container .swiper-wrapper .swiper-slide:is(.swiper-slide-active) .kd-testimonial-4-item .comment {
  transform: translateY(0);
  opacity: 1;
  transition-delay: 0.5s;
}
.kd-testimonial-4-slider .swiper-container .swiper-wrapper .swiper-slide:is(.swiper-slide-active) .kd-testimonial-4-item .kd-testimonial-4-item-author {
  transform: translateY(0);
  opacity: 1;
  transition-delay: 0.8s;
}

/*
    testimonial-4-end
*/
/* 
    cta-1-start
*/
.kd-cta-1-area {
  margin-top: -130px;
  position: relative;
  z-index: 1;
}
.kd-cta-1-wrap {
  border-radius: 20px;
  overflow: hidden;
  background: #282568;
  display: grid;
  grid-template-columns: 1fr 1fr 0.8fr;
  gap: 50px;
  align-items: center;
  padding-left: 60px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .kd-cta-1-wrap {
    padding-left: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-cta-1-wrap {
    padding-left: 30px;
    gap: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-cta-1-wrap {
    grid-template-columns: 1fr;
    padding-left: 0px;
    padding: 50px 30px;
    gap: 30px;
  }
}
.kd-cta-1-wrap .title {
  font-size: 36px;
  line-height: 1.11;
  margin-bottom: 23px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-cta-1-wrap .title {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .kd-cta-1-wrap .title {
    font-size: 26px;
  }
}
.kd-cta-1-wrap .main-img {
  -webkit-clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
  clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
  height: 250px;
}
.kd-cta-1-action {
  display: flex;
  align-items: center;
  gap: 10px;
}
.kd-cta-1-action .icon {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background: var(--kd-clr-sd-1);
  color: #fff;
  font-size: 18px;flex: 0 0 auto;
}
.kd-cta-1-action .title {
  font-size: 15px;
  line-height: 1;
  margin-bottom: 5px;
}
.kd-cta-1-action .action-link {
  font-size: 25px;
  line-height: 1;
  transition: 0.2s var(--cube-1);
  line-break: anywhere;
}
.kd-cta-1-action .action-link:hover {
  color: var(--kd-clr-sd-1);
  letter-spacing: 0.5px;
}
.kd-cta-1-action:hover .icon {
  animation: 1s kd-jello;
}

.kd-counter-1-item-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-counter-1-item-wrap {
    max-width: 400px;
  }
}
.kd-counter-1-item-wrap::after {
  content: "";
  width: 2px;
  height: 100%;
  background-color: #7874fe;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 767px) {
  .kd-counter-1-item-wrap::after {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-counter-1-item-wrap::after {
    display: block;
  }
}
.kd-counter-1-item .item-number {
  font-size: 60px;
  line-height: 1;
  margin-bottom: 3px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-counter-1-item .item-number {
    font-size: 40px;
  }
}
.kd-counter-1-item .disc {
  font-size: 16px;
  line-height: 27px;
}

/* 
    cta-1-end
*/
/* 
    cta-2-start
*/
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-cta-2-content {
    margin-bottom: 50px;
  }
}
.kd-cta-2-content .kd-list-1 {
  grid-template-columns: 1fr;
  margin-bottom: 40px;
}
.kd-cta-2-content .contact-item-flex {
  gap: 40px;
}
.kd-cta-2-form{
  border-radius: 20px;
}
.kd-cta-2-form .wpcf7-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 14px;
  padding: 60px 55px;
  align-items: flex-start;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-cta-2-form .wpcf7-form {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 767px) {
  .kd-cta-2-form .wpcf7-form {
    grid-template-columns: 1fr;
    padding: 60px 25px;
  }
}
.kd-cta-2-form-input {
  font-family: var(--kd-font-pr-1);
  font-weight: 700;
  font-size: 15px;
  color: #fff;
  background: #00625c;
  border-radius: 30px;
  border: none;
  outline: none;
  height: 58px;
  padding: 0 33px;
  width: 100%;
}
.kd-cta-2-form input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
.kd-cta-2-form-btn {
  background: #191717;
  height: 58px;
}
.kd-cta-2-form .has-full-width {
  grid-column: 1/-1;
}

/* 
    cta-2-end
*/
/* 
    cta-4-start
*/
.kd-cta-4-wrap {
  padding: 75px 15px;
  border-radius: 20px;
}
.kd-cta-4-wrap .title {
  font-size: 48px;
  line-height: 1.2;
  margin-bottom: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-cta-4-wrap .title {
    font-size: 42px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-cta-4-wrap .title {
    font-size: 36px;
  }
}
@media (max-width: 767px) {
  .kd-cta-4-wrap .title {
    font-size: 32px;
  }
}
.kd-cta-4-wrap .disc {
  font-weight: 500;
  opacity: 100%;
  margin-bottom: 42px;
  max-width: 795px;
  margin-left: auto;
  margin-right: auto;
}
.kd-cta-4-wrap .btn-wrap {
  gap: 35px;
}

.kd-cta-btn-1 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: var(--kd-font-barlow);
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  background: var(--kd-clr-pr-4);
  border-radius: 5px;
  padding: 17px 30px;
  transition: 0.4s ease-in-out;
}
.kd-cta-btn-1 i {
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 50%;
  color: var(--kd-clr-pr-4);
  font-size: 12px;
  transition: 0.4s ease-in-out;
}
.kd-cta-btn-1:hover {
  background: #fff;
  color: var(--kd-clr-pr-4);
}
.kd-cta-btn-1:hover i {
  background: var(--kd-clr-pr-4);
  color: #fff;
}
.kd-cta-btn-1:is(.has-border) {
  background: transparent;
  border: 2px solid;
  border-color: #fff;
  padding: 16px 29px;
}
.kd-cta-btn-1:is(.has-border):hover {
  background: #fff;
}

/* 
    cta-4-end
*/
/* 
    video-course-1-start
*/
.kd-video-course-1-video {
  position: relative;
  z-index: 1;
  border-radius: 20px;
}
.kd-video-course-1-video img {
  transition: transform 500ms;
  transform: perspective(0px) rotateX(0deg) rotateY(0deg) scaleX(1) scaleY(1);
  transform-origin: center center;
}
.kd-video-course-1-video::after {
  content: "";
  width: 100%;
  height: 100%;
  background: var(--kd-clr-pr-1);
  position: absolute;
  top: 0;
  left: 0;
  mix-blend-mode: multiply;
  opacity: 0.3;
}
.kd-video-course-1-video .btn-position {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.kd-video-course-1-video:hover img {
  transform: perspective(600px) rotateX(0.06deg) rotateY(0deg) scaleX(1.15) scaleY(1.15);
}
.kd-video-course-1-tabs-content {
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-video-course-1-slider-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding-top: 30px;
  }
}
.kd-video-course-1-slider-btn .kd-slider-btn-1 {
  background: #363539;
  color: #fff;
}
.kd-video-course-1-slider-btn .kd-slider-btn-1 .icon-1 {
  color: #fff;
}
.kd-video-course-1-slider-btn .kd-slider-btn-1 .icon-2 {
  color: #fff;
}
.kd-video-course-1-slider-btn .kd-slider-btn-1:hover {
  background: var(--kd-clr-sd-1);
}
.kd-video-course-1-slider-btn .kd_video-course_1_slider_next {
  position: absolute;
  right: -70px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  display: inline-block;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-video-course-1-slider-btn .kd_video-course_1_slider_next {
    position: unset;
    transform: unset;
  }
}
.kd-video-course-1-slider-btn .kd_video-course_1_slider_prev {
  position: absolute;
  left: -70px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  display: inline-block;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-video-course-1-slider-btn .kd_video-course_1_slider_prev {
    position: unset;
    transform: unset;
  }
}
.kd-video-course-1-tabs-btn {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-video-course-1-tabs-btn {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 767px) {
  .kd-video-course-1-tabs-btn {
    grid-template-columns: 1fr;
  }
}
.kd-video-course-1-trainer {
  background: transparent;
}
.kd-video-course-1-trainer .item-line {
  display: block;
  width: 100%;
  height: 2px;
  background: #DBDBDB;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}
.kd-video-course-1-trainer .item-line::after {
  content: "";
  width: 0%;
  height: 100%;
  background: var(--kd-clr-pr-1);
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s var(--cube-1);
}
.kd-video-course-1-trainer .trainer-wrap {
  gap: 18px;
}
.kd-video-course-1-trainer .trainer-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  flex: 0 0 auto;
}
.kd-video-course-1-trainer .trainer-content {
  text-align: left;
}
.kd-video-course-1-trainer .name {
  font-size: 26px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-video-course-1-trainer .name {
    font-size: 22px;
  }
}
.kd-video-course-1-trainer .disc {
  font-size: 16px;
  line-height: 27px;
  color: #797a84;
}
.kd-video-course-1-trainer:is(.active) .item-line::after {
  width: 100%;
}
.kd-video-course-1-trainer:hover .trainer-img {
  animation: 1s kd-jello;
}

/* 
    video-course-1-end
*/
/* 
    video-course-2-start
*/
.kd-video-course-3-content {
  margin-bottom: 30px;
}
.kd-video-course-3-item {
  height: 380px;
  border-radius: 20px;
  margin-bottom: 30px;
}
.kd-video-course-3-item img {
  transition: transform 500ms;
  transform: perspective(0px) rotateX(0deg) rotateY(0deg) scaleX(1) scaleY(1);
  transform-origin: center center;
}
.kd-video-course-3-item .btn-position {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.kd-video-course-3-item:hover img {
  transform: perspective(600px) rotateX(0.06deg) rotateY(0deg) scaleX(1.15) scaleY(1.15);
}

/* 
    video-course-2-end
*/
/* 
    blog-1-start
*/

/* Widgets
--------------------------------------------- */
.widget {
	margin: 0 0 1.5em;
}

.widget select {
	max-width: 100%;
}

/* Media
--------------------------------------------- */
.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
	border: none;
	margin-bottom: 0;
	margin-top: 0;
	padding: 0;
}

/* Make sure logo link wraps around logo image. */
.custom-logo-link {
	display: inline-block;
}

/* Captions
--------------------------------------------- */
.wp-caption {
	margin-bottom: 1.5em;
	max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.wp-caption .wp-caption-text {
	margin: 0.8075em 0;
}

.wp-caption-text {
	text-align: center;
}

/* Galleries
--------------------------------------------- */
.gallery {
	margin-bottom: 1.5em;
	display: grid;
	grid-gap: 1.5em;
}

.gallery-item {
	display: inline-block;
	text-align: center;
	width: 100%;
}

.gallery-columns-2 {
	grid-template-columns: repeat(2, 1fr);
}

.gallery-columns-3 {
	grid-template-columns: repeat(3, 1fr);
}

.gallery-columns-4 {
	grid-template-columns: repeat(4, 1fr);
}

.gallery-columns-5 {
	grid-template-columns: repeat(5, 1fr);
}

.gallery-columns-6 {
	grid-template-columns: repeat(6, 1fr);
}

.gallery-columns-7 {
	grid-template-columns: repeat(7, 1fr);
}

.gallery-columns-8 {
	grid-template-columns: repeat(8, 1fr);
}

.gallery-columns-9 {
	grid-template-columns: repeat(9, 1fr);
}

.gallery-caption {
	display: block;
}

/*--------------------------------------------------------------
# Plugins
--------------------------------------------------------------*/

/* Jetpack infinite scroll
--------------------------------------------- */

/* Hide the Posts Navigation and the Footer when Infinite Scroll is in use. */
.infinite-scroll .posts-navigation,
.infinite-scroll.neverending .site-footer {
	display: none;
}

/* Re-display the Theme Footer when Infinite Scroll has reached its end. */
.infinity-end.neverending .site-footer {
	display: block;
}

/*--------------------------------------------------------------
# Utilities
--------------------------------------------------------------*/

/* Accessibility
--------------------------------------------- */

/* Text meant only for screen readers. */
.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important;
}

.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	clip-path: none;
	color: #21759b;
	display: block;
	font-size: 0.875rem;
	font-weight: 700;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000;
}

/* Do not show the outline on the skip link target. */
#primary[tabindex="-1"]:focus {
	outline: 0;
}

/* Alignments
--------------------------------------------- */
.alignleft {

	/*rtl:ignore*/
	float: left;

	/*rtl:ignore*/
	margin-right: 1.5em;
	margin-bottom: 1.5em;
}

.alignright {

	/*rtl:ignore*/
	float: right;

	/*rtl:ignore*/
	margin-left: 1.5em;
	margin-bottom: 1.5em;
}

.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 1.5em;
}
/* Comments
--------------------------------------------- */
.comment-content a {
	word-wrap: break-word;
}

.bypostauthor {
	display: block;
}
.kd-blog-1-area {
  position: relative;
  z-index: 1;
}
.kd-blog-1-bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 310px);
  z-index: -1;
}
.kd-blog-1-card {
  overflow: hidden;
  border-radius: 20px;
  margin-bottom: 30px;
  box-shadow: 0px 7px 81px 0px rgba(32, 28, 29, 0.07);
}
.kd-blog-1-card .main-img {
  height: 265px;
}
.kd-blog-1-card .main-img img {
  transition: transform 500ms;
  transform: perspective(0px) rotateX(0deg) rotateY(0deg) scaleX(1) scaleY(1);
  transform-origin: center center;
}
.kd-blog-1-card .author-img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  flex: 0 0 auto;
}
.kd-blog-1-card .card-content {
  background: #fff;
  padding: 40px 40px;
  padding-top: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-blog-1-card .card-content {
    padding: 40px 15px;
  }
}
.kd-blog-1-card-meta {
  background: #f8fafa;
  padding: 15px 23px;
  border-radius: 35px;
  margin-bottom: 17px;
  margin-top: -20px;
  position: relative;
  z-index: 1;
}
.kd-blog-1-card-meta-author {
  display: flex;
  align-items: center;
  gap: 6px;
}
.kd-blog-1-card-meta-author .author-name {
  font-size: 18px;
}
.kd-blog-1-card-meta-love {
  font-size: 18px;
}
.kd-blog-1-card-meta-love i {
  margin-right: 5px;
}
.kd-blog-1-card .card-title {
  font-size: 27px;
  line-height: 1.29;
  margin-bottom: 5px;
  transform-origin: left;
  transition: 0.2s var(--cube-1);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .kd-blog-1-card .card-title {
    font-size: 24px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-blog-1-card .card-title {
    font-size: 22px;
  }
}
.kd-blog-1-card .card-title:hover {
  color: var(--kd-clr-sd-1);
  transform: scaleX(103%);
}
.kd-blog-1-card .card-disc {
  font-weight: 500;
  font-size: 19px;
  line-height: 27px;
  color: #797a84;
}
.kd-blog-1-card:hover .main-img img {
  transform: perspective(600px) rotateX(0.06deg) rotateY(0deg) scaleX(1.15) scaleY(1.15);
}

/* 
    blog-1-end
*/
/* 
    blog-2-start
*/
.kd-blog-2-card {
  box-shadow: 0px 7px 81px 0px rgba(32, 28, 29, 0.07);
  border-radius: 20px;
  background: #fff;
  margin-bottom: 30px;
}
.kd-blog-2-card-author {
  padding: 20px 30px;
  display: flex;
  align-items: center;
  gap: 7px;
  flex-wrap: wrap;
}
.kd-blog-2-card-author .author-img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  flex: 0 0 auto;
}
.kd-blog-2-card-author .author-name {
  font-size: 18px;
}
.kd-blog-2-card-author .author-love {
  font-size: 16px;
  margin-left: auto;
}
.kd-blog-2-card .main-img {
  height: 265px;
}
.kd-blog-2-card .main-img img {
  transition: transform 500ms;
  transform: perspective(0px) rotateX(0deg) rotateY(0deg) scaleX(1) scaleY(1);
  transform-origin: center center;
}
.kd-blog-2-card .card-title {
  font-size: 27px;
  line-height: 1.29;
  transition: 0.2s var(--cube-1);
  transform-origin: left;
  margin-bottom: 8px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-blog-2-card .card-title {
    font-size: 24px;
  }
}
.kd-blog-2-card .card-title:hover {
  color: var(--kd-clr-sd-1);
  transform: scaleX(103%);
  -webkit-transform: scaleX(103%);
  -moz-transform: scaleX(103%);
  -ms-transform: scaleX(103%);
  -o-transform: scaleX(103%);
}
.kd-blog-2-card .card-content {
  padding: 35px 50px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-blog-2-card .card-content {
    padding: 35px 20px;
  }
}
.kd-blog-2-card .card-disc {
  font-size: 19px;
  line-height: 26px;
  font-weight: 600;
  color: #797a84;
}
.kd-blog-2-card:hover .main-img img {
  transform: perspective(600px) rotateX(0.06deg) rotateY(0deg) scaleX(1.15) scaleY(1.15);
}

/* 
    blog-2-end
*/
/* 
    blog-3-start
*/
.kd-blog-3-content {
  margin-bottom: 30px;
}
.kd-blog-3-card {
  box-shadow: 0px 30px 160px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  border-radius: 20px;
  margin-bottom: 30px;
}
.kd-blog-3-card .blog-img {
  height: 250px;
}
.kd-blog-3-card .blog-img img {
  transition: transform 500ms;
  transform: perspective(0px) rotateX(0deg) rotateY(0deg) scaleX(1) scaleY(1);
  transform-origin: center center;
}
.kd-blog-3-card .blog-title {
  font-size: 24px;
  line-height: 1.45;
  color: #252525;
  margin-bottom: 14px;
  transition: 0.2s var(--cube-1);
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-blog-3-card .blog-title {
    font-size: 22px;
  }
}
.kd-blog-3-card .blog-title:hover {
  color: var(--kd-clr-sd-1);
}
.kd-blog-3-card .card-content {
  padding: 30px 35px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-blog-3-card .card-content {
    padding: 30px 15px;
  }
}
.kd-blog-3-card .blog-author {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px 30px;
  background: transparent;
  padding: 12px 0px;
  border-radius: 20px;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
}
.kd-blog-3-card .blog-author::after {
  content: "";
  width: 100%;
  height: 100%;
  background: #ebebeb;
  position: absolute;
  left: -50px;
  top: 0;
  z-index: -1;
  border-radius: 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .kd-blog-3-card .blog-author::after {
    width: 110%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-blog-3-card .blog-author::after {
    width: 115%;
  }
}
@media (max-width: 767px) {
  .kd-blog-3-card .blog-author::after {
    width: 115%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-blog-3-card .blog-author::after {
    width: 100%;
  }
}
.kd-blog-3-card .blog-author li {
  font-size: 15px;
  font-weight: 700;
  color: #8799a3;
}
.kd-blog-3-card .blog-author li:nth-child(1) {
  color: #4c40f6;
}
.kd-blog-3-card .blog-author li i {
  margin-right: 3px;
}
.kd-blog-3-card .blog-disc {
  font-size: 19px;
  line-height: 28px;
  color: var(--kd-clr-h-1);
  font-weight: 500;
}
.kd-blog-3-card:hover img {
  transform: perspective(600px) rotateX(0.06deg) rotateY(0deg) scaleX(1.15) scaleY(1.15);
}

/* 
    blog-3-end
*/
/* 
    blog-4-start
*/
.kd-blog-4-scn-title {
  gap: 0 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-blog-4-scn-title {
    flex-wrap: wrap;
    margin-bottom: 50px;
  }
}
.kd-blog-4-scn-title .left {
  max-width: 560px;
}
.kd-blog-4-scn-title .scn-disc {
  max-width: 550px;
}
.kd-blog-4-row {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-blog-4-row {
    grid-template-columns: 1fr;
  }
}
.kd-blog-4-card {
  margin-bottom: 30px;
}
.kd-blog-4-card .item-img {
  height: 180px;
  margin-bottom: 18px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-blog-4-card .item-img {
    height: 300px;
  }
}
.kd-blog-4-card .item-img img {
  transition: transform 500ms;
  transform: perspective(0px) rotateX(0deg) rotateY(0deg) scaleX(1) scaleY(1);
  transform-origin: center center;
}
.kd-blog-4-card .item-title {
  font-size: 22px;
  line-height: 1.36;
  margin-bottom: 15px;
  transition: 0.2s ease;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-blog-4-card .item-title {
    font-size: 20px;
  }
}
.kd-blog-4-card .item-title:hover {
  color: var(--kd-clr-pr-4);
}
.kd-blog-4-card .item-disc {
  margin-bottom: 15px;
}
.kd-blog-4-card .item-line {
  width: 100%;
  height: 1px;
  background: #D9D9D9;
  opacity: 10%;
  margin-bottom: 12px;
}
.kd-blog-4-card .item-author {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: var(--kd-clr-sd-4);
}
.kd-blog-4-card .item-author span {
  color: #fff;
  margin-right: 5px;
}
.kd-blog-4-card .btn-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}
.kd-blog-4-card .item-btn {
  display: inline-flex;
  align-items: center;
  flex: 0 0 auto;
  gap: 20px;
  font-family: var(--kd-font-barlow);
  text-transform: uppercase;
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
  padding: 2px 10px;
  background: transparent;
  color: #fff;
  border-radius: 100px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s ease;
}
.kd-blog-4-card .item-btn i {
  color: #000;
  display: inline-block;
  transition: 0.5s ease;
}
.kd-blog-4-card .item-btn::after {
  content: "";
  width: 28px;
  height: 100%;
  background: #fff;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  transition: 0.5s ease;
}
.kd-blog-4-card .item-btn:hover {
  color: #fff;
}
.kd-blog-4-card .item-btn:hover i {
  color: #fff;
}
.kd-blog-4-card .item-btn:hover::after {
  background: var(--kd-clr-pr-4);
  width: 100%;
}
.kd-blog-4-card:is(.has-big-card) .item-img {
  height: 485px;
  margin-bottom: 30px;
}
.kd-blog-4-card:is(.has-big-card) .item-title {
  font-size: 40px;
  line-height: 1.25;
  margin-bottom: 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-blog-4-card:is(.has-big-card) .item-title {
    font-size: 36px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-blog-4-card:is(.has-big-card) .item-title {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .kd-blog-4-card:is(.has-big-card) .item-title {
    font-size: 22px;
  }
}
.kd-blog-4-card:is(.has-big-card) .item-title:hover {
  color: var(--kd-clr-pr-4);
}
.kd-blog-4-card:is(.has-big-card) .item-disc {
  margin-bottom: 25px;
}
.kd-blog-4-card:hover .item-img img {
  transform: perspective(600px) rotateX(0.06deg) rotateY(0deg) scaleX(1.15) scaleY(1.15);
}

/* 
    blog-4-end
*/
/* 
    client-1-start
*/
.kd-client-1-wrap .title {
  font-size: 30px;
  margin-bottom: 43px;
}
.kd-client-1-item-wrap {
  max-width: 1085px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 18px;
}
@media (max-width: 767px) {
  .kd-client-1-item-wrap {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-client-1-item-wrap {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.kd-client-1-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95px;
  border: 1px solid #c8c8c8;
  border-radius: 30px;
  padding: 15px;
  background: #fff;
}

/* 
    client-1-end
*/
/* 
    client-2-start
*/
.kd-client-2-slider .swiper-container .swiper-wrapper {
  justify-content: space-between;
}
.kd-client-2-item {
  width: 100%;
  height: 100px;
  padding: 20px;
}
.kd-client-2-item img {
  flex: 0 0 auto;
  width: 35%;
  opacity: 0.5;
  transition: 0.3s var(--cube-1);
}
.kd-client-2-item:hover img {
  animation: 1s kd-jello;
  opacity: 1;
}

/* 
    client-2-end
*/
/* 
    client-3-start
*/
.kd-client-3-area {
  position: relative;
  z-index: 1;
  margin-top: -165px;
}
.kd-client-3-row {
  background: #fff;
  border-radius: 110px;
  padding: 30px 65px;
  display: grid;
  grid-template-columns: 1fr 2.8fr;
  gap: 60px;
  align-content: center;
  box-shadow: 0px 3px 60px 0px rgba(32, 42, 181, 0.11);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .kd-client-3-row {
    grid-template-columns: 1fr 2.2fr;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-client-3-row {
    grid-template-columns: 1fr 2fr;
    gap: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-client-3-row {
    border-radius: 80px;
    padding: 50px 45px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 50px;
  }
}
@media (max-width: 767px) {
  .kd-client-3-row {
    border-radius: 80px;
    padding: 50px 45px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 50px;
    padding: 30px 20px;
    border-radius: 50px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-client-3-row {
    border-radius: 80px;
    padding: 50px 45px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 50px;
    padding: 30px 65px;
    border-radius: 110px;
  }
}
.kd-client-3-content {
  align-self: center;
}
.kd-client-3-content .kd-section-title-1 {
  font-size: 35px;
  line-height: 1.09;
  margin-bottom: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-client-3-content .kd-section-title-1 {
    font-size: 30px;
  }
}
.kd-client-3-logo {
  height: 80px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #F4F4F4;
  border-right: 1px solid #F4F4F4;
  transition: 0.3s var(--cube-1);
}
.kd-client-3-logo-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  overflow: hidden;
}
@media (max-width: 767px) {
  .kd-client-3-logo-row {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-client-3-logo-row {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 767px) {
  .kd-client-3-logo {
    border-top: 1px solid #F4F4F4;
    border-left: 1px solid #F4F4F4;
  }
}
.kd-client-3-logo img {
  opacity: 0.5;
  transition: 0.3s var(--cube-1);
}
@media (min-width: 1800px), only screen and (min-width: 1600px) and (max-width: 1799px), only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-client-3-logo:is(.border-right-none) {
    border-right: none;
  }
}
@media (min-width: 1800px), only screen and (min-width: 1600px) and (max-width: 1799px), only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-client-3-logo:is(.border-bottom-none) {
    border-bottom: none;
  }
}
.kd-client-3-logo:hover img {
  opacity: 1;
  animation: 1s kd-jello;
}

/* 
    client-3-end
*/
/* 
    price-1-start
*/
.kd-price-1-area {
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-price-1-area {
    padding-top: 80px;
  }
}
.kd-price-1-bg-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 145px);
  z-index: -1;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-price-1-bg-img {
    height: calc(100% - 0px);
  }
}
.kd-price-1-row {
  display: grid;
  grid-template-columns: 1.8fr 1fr;
  gap: 45px;
  margin-left: -220px;
  align-items: end;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .kd-price-1-row {
    margin-left: -100px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .kd-price-1-row {
    margin-left: 0px;
    grid-template-columns: 1.5fr 1fr;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-price-1-row {
    margin-left: 0px;
    grid-template-columns: 1fr;
  }
}
.kd-price-1-left {
  display: flex;
  align-items: center;
  gap: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-price-1-left {
    flex-wrap: wrap;
  }
}
.kd-price-1-tabs-btn {
  flex: 0 0 auto;
}
.kd-price-1-tabs-btn .nav-item:not(:last-child) {
  margin-bottom: 28px;
}
.kd-price-1-tabs-btn .nav-link {
  background: transparent;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  font-size: 20px;
  gap: 10px;
  transition: 0.3s var(--cube-1);
}
.kd-price-1-tabs-btn .nav-link:is(.active) {
  color: var(--kd-clr-sd-1);
}
.kd-price-1-tabs-btn .nav-link:hover {
  color: var(--kd-clr-sd-1);
}
.kd-price-1-tabs-content-wrap {
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-price-1-tabs-content-wrap {
    display: grid;
    grid-template-columns: 1fr auto;
  }
}
.kd-price-1-tabs-content-wrap .left-img {
  max-width: 470px;
  overflow: hidden;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .kd-price-1-tabs-content-wrap .left-img {
    max-width: 380px;
  }
}
.kd-price-1-tabs-content-wrap .left-img img {
  transform: translateY(100%);
  transition: 1s ease-in-out;
}
.kd-price-1-tabs-content-wrap .table-position {
  position: absolute;
  left: 72%;
  top: 50%;
  width: 100%;
  transition: 1s ease-in-out;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .kd-price-1-tabs-content-wrap .table-position {
    left: 55%;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .kd-price-1-tabs-content-wrap .table-position {
    left: 43%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-price-1-tabs-content-wrap .table-position {
    left: 43%;
    position: unset;
  }
}
.kd-price-1-tabs-content .tab-pane:is(.show) .left-img img {
  transform: translateY(0%);
}
.kd-price-1-tabs-content .tab-pane:is(.show) .table-position {
  top: 10%;
}
.kd-price-1-table {
  max-width: 370px;
  background: #222237;
  padding: 35px 45px;
  border-radius: 15px;
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .kd-price-1-table {
    padding: 35px 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-price-1-table {
    padding: 35px 45px;
  }
}
.kd-price-1-table::after {
  content: "";
  width: 50px;
  height: 50px;
  background: #222237;
  position: absolute;
  top: 100%;
  left: 70px;
  clip-path: polygon(0 0, 100% 0, 0% 100%);
}
.kd-price-1-table .table-subtitle {
  font-size: 14px;
  color: #fff;
  background: #2a2a43;
  display: inline-block;
  border-radius: 15px;
  padding: 7px 19px;
  margin-bottom: 3px;
}
.kd-price-1-table .table-title {
  font-size: 24px;
  line-height: 1.41;
  border-bottom: 1px solid #373754;
  padding-bottom: 10px;
  margin-bottom: 14px;
}
.kd-price-1-table .table-plan {
  font-size: 18px;
  margin-bottom: 11px;
}
.kd-price-1-table-feature-item {
  border-radius: 20px;
  border: 3px solid;
  border-color: transparent;
  padding: 15px 40px;
  display: flex;
  gap: 9px;
  align-items: self-start;
  background: #2a2a43;
  cursor: pointer;
  transition: 0.5s var(--cube-1);
}
@media (max-width: 767px) {
  .kd-price-1-table-feature-item {
    padding: 15px 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-price-1-table-feature-item {
    padding: 15px 40px;
  }
}
.kd-price-1-table-feature-item:not(:last-child) {
  margin-bottom: 10px;
}
.kd-price-1-table-feature-item .feature-item-redius {
  display: block;
  flex: 0 0 auto;
  width: 21px;
  height: 21px;
  border: 2px solid var(--kd-clr-sd-1);
  border-radius: 50%;
  position: relative;
  z-index: 1;
  transition: 0.5s var(--cube-1);
}
.kd-price-1-table-feature-item .feature-item-redius::after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: transparent;
  position: absolute;
  top: 4.5px;
  left: 4.5px;
  transition: 0.5s var(--cube-1);
}
.kd-price-1-table-feature-item .item-title {
  font-size: 18px;
  margin-bottom: 9px;
}
.kd-price-1-table-feature-item .item-title span {
  background: var(--kd-clr-sd-1);
  padding: 0px 5px;
  border-radius: 5px;
}
.kd-price-1-table-feature-item .month {
  font-size: 16px;
}
.kd-price-1-table-feature-item .vat {
  font-size: 12px;
  line-height: 1;
  margin-top: 10px;
}
.kd-price-1-table-feature-item:is(.active) {
  border-color: var(--kd-clr-sd-1);
  background: transparent;
}
.kd-price-1-table-feature-item:is(.active) .feature-item-redius::after {
  background: var(--kd-clr-sd-1);
}
.kd-price-1-right {
  padding: 80px 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .kd-price-1-right {
    padding: 80px 0;
    padding-top: 0;
  }
}

/* 
    price-1-end
*/
/* 
    price-4-start
*/
.kd-price-4-content-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  z-index: -1;
}
.kd-price-4-content-wrap {
  padding: 70px 75px;
  border-radius: 20px;
  padding-bottom: 160px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-price-4-content-wrap {
    padding: 70px 25px;
    padding-bottom: 160px;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 767px) {
  .kd-price-4-content-wrap {
    padding: 70px 25px;
    padding-bottom: 160px;
    grid-template-columns: 1fr;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-price-4-content-wrap {
    padding: 70px 25px;
    padding-bottom: 160px;
    grid-template-columns: 1.2fr 1fr;
  }
}
.kd-price-4-content .title {
  font-size: 55px;
  line-height: 1.27;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-price-4-content .title {
    font-size: 40px;
  }
}
.kd-price-4-content .disc {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
}
.kd-price-4-content .price {
  font-size: 60px;
  margin-bottom: 25px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-price-4-content .price {
    font-size: 50px;
  }
}
.kd-price-4-content .price span {
  font-size: 18px;
  font-weight: 500;
}
.kd-price-4-content .kd-pr-btn-1 {
  background: #000;
}
.kd-price-4-content .kd-pr-btn-1 .text::before {
  color: #fff;
}
.kd-price-4-content .kd-pr-btn-1 .text::after {
  color: #000;
}
.kd-price-4-content .kd-pr-btn-1:hover {
  background: var(--kd-clr-sd-1);
}
.kd-price-4-content-img {
  height: 285px;
  border-radius: 20px;
}
.kd-price-4-content-list {
  padding-top: 20px;
}
@media (max-width: 767px) {
  .kd-price-4-content-list {
    padding-top: 0px;
  }
}
.kd-price-4-content-list .title {
  font-size: 24px;
  margin-bottom: 30px;
}
.kd-price-4-content-list .kd-list-1 {
  grid-template-columns: 1fr;
}
.kd-price-4-content-list .kd-list-1 li {
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
}
.kd-price-4-content-list .kd-list-1 li i {
  margin-right: 0px;
  font-size: 22px;
}

/* 
    price-4-end
*/
/* 
    team-1-start
*/
.kd-team-1-membar {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px 40px;
  align-items: end;
  padding-bottom: 30px;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .kd-team-1-membar {
    grid-template-columns: 1fr;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-team-1-membar {
    grid-template-columns: 1fr 1fr;
  }
}
.kd-team-1-membar::after {
  content: "";
  width: 75%;
  height: 73%;
  position: absolute;
  right: 0;
  bottom: 0;
  background: #f2f2f2;
  z-index: -1;
  border-radius: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-team-1-membar::after {
    height: 100%;
  }
}
@media (max-width: 767px) {
  .kd-team-1-membar::after {
    width: 100%;
    height: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-team-1-membar::after {
    height: 85%;
    width: 75%;
  }
}
.kd-team-1-membar::before {
  content: "";
  width: 42px;
  height: 73%;
  position: absolute;
  left: 13%;
  bottom: 0;
  background: var(--kd-clr-pr-1);
  z-index: -1;
  border-radius: 20px;
}
.kd-team-1-membar .main-img {
  border-radius: 20px;
  height: 295px;
}
.kd-team-1-membar .main-img img {
  transition: transform 500ms;
  transform: perspective(0px) rotateX(0deg) rotateY(0deg) scaleX(1) scaleY(1);
  transform-origin: center center;
}
@media (max-width: 767px) {
  .kd-team-1-membar .content {
    padding: 0 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-team-1-membar .content {
    padding: 0 0px;
  }
}
.kd-team-1-membar .membar {
  font-size: 30px;
  margin-bottom: 6px;
  transition: 0.2s var(--cube-1);
  transform-origin: left;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-team-1-membar .membar {
    font-size: 24px;
  }
}
.kd-team-1-membar .membar:hover {
  color: var(--kd-clr-sd-1);
  transform: scaleX(103%);
}
.kd-team-1-membar .membar-bio {
  font-size: 18px;
  margin-bottom: 18px;
}
.kd-team-1-membar .membar-link li {
  font-family: var(--kd-font-pr-1);
  font-weight: 800;
  color: var(--kd-clr-h-1);
  font-size: 16px;
  background: #fff;
  border-radius: 21px;
  padding: 4px 9px;
  display: inline-block;
}
.kd-team-1-membar .membar-link li:not(:last-child) {
  margin-bottom: 8px;
}
.kd-team-1-membar .membar-link li .icon {
  width: 36px;
  height: 36px;
  background: var(--kd-clr-pr-1);
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  margin-right: 5px;
}
.kd-team-1-membar .membar-link li a {
  display: inline-block;
  transition: 0.3s var(--cube-1);
}
.kd-team-1-membar .membar-link li a:hover {
  color: var(--kd-clr-sd-1);
}
.kd-team-1-membar .membar-link li:hover .icon {
  animation: 1s kd-jello;
}
.kd-team-1-membar:hover .main-img img {
  transform: perspective(600px) rotateX(0.06deg) rotateY(0deg) scaleX(1.15) scaleY(1.15);
}

/* 
    team-1-end
*/
/* 
    team-4-start
*/
.kd-team-4-il-1 {
  position: absolute;
  right: 0;
  bottom: 150px;
  z-index: -1;
}
.kd-team-4-il-2 {
  position: absolute;
  right: 0;
  bottom: 100px;
  z-index: -1;
}
.kd-team-4-il-3 {
  position: absolute;
  left: 10%;
  top: 0;
  z-index: -1;
}
.kd-team-4-scn-title {
  padding-left: 70px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-team-4-scn-title {
    padding-left: 0px;
  }
}
.kd-team-4-slide-main {
  position: relative;
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-team-4-slide-main {
    margin-bottom: 50px;
  }
}
.kd-team-4-slide-main-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 30px;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  height: 700px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .kd-team-4-slide-main-item {
    height: 600px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-team-4-slide-main-item {
    height: 530px;
  }
}
@media (max-width: 767px) {
  .kd-team-4-slide-main-item {
    height: 500px;
    justify-content: flex-end;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-team-4-slide-main-item {
    height: 500px;
    justify-content: flex-start;
  }
}
.kd-team-4-slide-main-item img {
  transition: 1s ease-in-out;
  transform: translateY(100%);
}
.kd-team-4-slide-main-item-social {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;
  top: 200px;
  left: 26px;
  z-index: 1;
  opacity: 0;
  transition: 0.5s var(--cube-1);
}
.kd-team-4-slide-main-item-social .link {
  display: flex;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background: #152028;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  color: #fff;
  outline: 3px solid rgba(255, 255, 255, 0.2);
  transition: 0.3s ease;
}
.kd-team-4-slide-main-item-social .link:hover {
  background: var(--kd-clr-sd-4);
}
.kd-team-4-slide-main-item .item-content {
  background: rgba(21, 32, 40, 0.5);
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  border-radius: 12px;
  text-align: center;
  position: absolute;
  bottom: -250px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 30px);
  margin-right: 15px;
  padding: 35px 10px;
  transition: 0.5s var(--cube-1);
}
.kd-team-4-slide-main-item .item-name {
  font-size: 32px;
  text-transform: uppercase;
  margin-bottom: 15px;
  transition: 0.2s ease;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-team-4-slide-main-item .item-name {
    font-size: 24px;
  }
}
.kd-team-4-slide-main-item .item-name:hover {
  color: var(--kd-clr-sd-4);
}
.kd-team-4-slide-main-item .item-bio {
  font-weight: 500;
  color: var(--kd-clr-sd-4);
}
.kd-team-4-slide-main .swiper-container .swiper-wrapper .swiper-slide:is(.swiper-slide-active) .kd-team-4-slide-main-item img {
  transform: translateY(0);
}
.kd-team-4-slide-main .swiper-container .swiper-wrapper .swiper-slide:is(.swiper-slide-active) .kd-team-4-slide-main-item .item-content {
  bottom: 15px;
}
.kd-team-4-slide-main .swiper-container .swiper-wrapper .swiper-slide:is(.swiper-slide-active) .kd-team-4-slide-main-item .kd-team-4-slide-main-item-social {
  opacity: 1;
  top: 25px;
  transition-delay: 0.5s;
}
.kd-team-4-slide-prview {
  margin-right: -290px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-team-4-slide-prview {
    margin-right: 0px;
  }
}
.kd-team-4-slide-prview-item {
  background-color: #1c2a35;
  padding-top: 20px;
  text-align: center;
  height: 390px;
  border-radius: 10px;
  border: 1px solid var(--kd-clr-sd-4);
  height: 377px;
  display: flex;
  align-items: flex-start;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-team-4-slide-prview-item {
    height: unset;
  }
}

/* 
    team-4-end
*/
.kd-accordion-1-item {
  background: #064745;
  border-radius: 30px;
}
.kd-accordion-1-item .item-title {
  font-size: 20px;
  background: #085653;
  text-align: left;
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px;
  padding-left: 40px;
  border-radius: 30px;
  color: #fff;
}
.kd-accordion-1-item .item-title .icon {
  display: inline-flex;
  flex: 0 0 auto;
  width: 42px;
  height: 42px;
  background: #5f8f87;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  line-height: 1;
  color: #fff;
}
.kd-accordion-1-item .item-title:hover {
  color: #fff;
}
.kd-accordion-1-item .item-body .disc {
  font-size: 18px;
  line-height: 32px;
  padding-left: 70px;
  padding-bottom: 15px;
  padding-top: 15px;
  padding-right: 45px;
}

/* 
    counter-4-start
*/
.kd-counter-4-area {
  margin-top: -70px;
}
.kd-counter-4-item-wrap {
  background: #ef9602;
  padding: 53px 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 70px;
  border-radius: 95px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .kd-counter-4-item-wrap {
    padding: 53px 40px;
    gap: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-counter-4-item-wrap {
    padding: 53px 30px;
    gap: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-counter-4-item-wrap {
    padding: 53px 40px;
    gap: 40px;
    flex-wrap: wrap;
  }
}
.kd-counter-4-item-wrap-shape-1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background: #f69b04;
  border-radius: 95px;
  z-index: -1;
  transition: 1s var(--cube-1);
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-counter-4-item-wrap-shape-1 {
    display: none;
  }
}
.kd-counter-4-item-wrap-shape-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background: #e79103;
  border-radius: 95px;
  z-index: -2;
  transition: 1s var(--cube-1);
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-counter-4-item-wrap-shape-2 {
    display: none;
  }
}
.kd-counter-4-item-wrap-shape-3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background: #de8b00;
  border-radius: 95px;
  z-index: -3;
  transition: 1s var(--cube-1);
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-counter-4-item-wrap-shape-3 {
    display: none;
  }
}
.kd-counter-4-item-wrap:is(.active) .kd-counter-4-item-wrap-shape-1 {
  width: 29%;
}
.kd-counter-4-item-wrap:is(.active) .kd-counter-4-item-wrap-shape-2 {
  width: 52%;
}
.kd-counter-4-item-wrap:is(.active) .kd-counter-4-item-wrap-shape-3 {
  width: 76%;
}
.kd-counter-4-item {
  display: flex;
  gap: 20px;
}
.kd-counter-4-item .icon {
  width: 52px;
  height: 52px;
  background: #082d3d;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 22px;
  flex: 0 0 auto;
}
.kd-counter-4-item .item-number {
  font-size: 60px;
  line-height: 1;
  color: #082d3d;
  margin-bottom: 5px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-counter-4-item .item-number {
    font-size: 45px;
  }
}
.kd-counter-4-item .item-title {
  font-size: 19px;
  line-height: 20px;
}
.kd-counter-4-item:hover .icon {
  animation: 1s kd-jello;
}

/* 
    counter-4-end
*/
/* 
    event-4-start
*/
.kd-event-4-il {
  position: absolute;
  top: 14%;
  left: 6%;
  z-index: -1;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-event-4-il {
    display: none;
  }
}
.kd-event-4-scn-title {
  gap: 0 30px;
}
@media (max-width: 767px) {
  .kd-event-4-scn-title {
    flex-wrap: wrap;
  }
}
.kd-event-4-item {
  background: #27333B;
  border-radius: 10px;
  border: 2px solid;
  border-color: transparent;
  transition: 0.3s ease;
  position: relative;
  z-index: 1;
  transform-origin: top;
}
.kd-event-4-item .item-content {
  padding: 25px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-event-4-item .item-content {
    padding: 15px;
  }
}
.kd-event-4-item .item-line {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
}
.kd-event-4-item .item-title {
  font-size: 24px;
  line-height: 1.33;
  transition: 0.2s ease;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-event-4-item .item-title {
    font-size: 20px;
  }
}
.kd-event-4-item .item-title:hover {
  color: var(--kd-clr-sd-4);
}
.kd-event-4-item .item-img {
  width: 100%;
  height: 360px;
  position: relative;
  z-index: 1;
  transition: 0.5s ease;
  border-radius: 0 0 10px 10px;
}
.kd-event-4-item .item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0 0 10px 10px;
}
.kd-event-4-item-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  flex-wrap: wrap;
}
.kd-event-4-item-date li {
  font-family: var(--kd-font-barlow);
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  line-height: 24px;
}
.kd-event-4-item-date li i {
  font-size: 18px;
  margin-right: 5px;
  color: var(--kd-clr-sd-4);
}
.kd-event-4-item .item-time {
  position: absolute;
  top: 0;
  left: 25px;
  font-size: 18px;
  line-height: 20px;
  background: var(--kd-clr-sd-4);
  color: #152028;
  padding: 11px 22px;
  border-radius: 0 0 5px 5px;
}
.kd-event-4-item .item-time i {
  margin-right: 3px;
}
.kd-event-4-item-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 0 10px 10px;
  position: absolute;
  left: 0;
  top: 88%;
  z-index: -1;
  transition: 0.5s ease;
}
.kd-event-4-item:hover {
  border-color: var(--kd-clr-pr-4);
}
.kd-event-4-item:hover .item-img {
  border-radius: 0;
}
.kd-event-4-item:hover .kd-event-4-item-btn {
  top: calc(100% - 2px);
}
.kd-event-4-slider {
  margin-right: -440px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-event-4-slider {
    margin-right: 0px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-event-4-slider-btn {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    padding-top: 30px;
  }
}
.kd-event-4-slider-btn .kd_event_4_slider_prev {
  position: absolute;
  left: -28px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-event-4-slider-btn .kd_event_4_slider_prev {
    position: unset;
    transform: unset;
  }
}
.kd-event-4-slider-btn .kd_event_4_slider_next {
  position: absolute;
  right: 411px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px) {
  .kd-event-4-slider-btn .kd_event_4_slider_next {
    right: -30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-event-4-slider-btn .kd_event_4_slider_next {
    position: unset;
    transform: unset;
  }
}
.kd-event-4-slider-btn .kd-slider-btn-2 {
  border: 3px solid rgb(255, 255, 255);
}
.kd-event-4-slider-btn .kd-slider-btn-2 i {
  color: #fff;
}
.kd-event-4-slider-btn .kd-slider-btn-2:hover {
  background: #fff;
}
.kd-event-4-slider-btn .kd-slider-btn-2:hover i {
  color: var(--kd-clr-pr-4);
}

/* 
    event-4-end
*/
/* 
    event-5-start
*/
.kd-event-5-item {
  padding: 20px 0;
  display: grid;
  grid-template-columns: 0.4fr 1fr 2fr auto;
  align-items: center;
  gap: 50px;
  border-bottom: 2px solid;
  border-color: rgba(0, 94, 78, 0.1);
  transition: 0.3s ease;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .kd-event-5-item {
    grid-template-columns: 0.5fr 1fr 2fr auto;
    gap: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-event-5-item {
    gap: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-event-5-item {
    gap: 30px;
    grid-template-columns: 1.5fr 1fr;
  }
}
@media (max-width: 767px) {
  .kd-event-5-item {
    gap: 30px;
    grid-template-columns: 1fr;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-event-5-item {
    gap: 30px;
    grid-template-columns: 1.5fr 1fr;
  }
}
.kd-event-5-item:nth-child(1) {
  border-top: 2px solid;
  border-color: rgba(0, 94, 78, 0.1);
}
.kd-event-5-item .item-img {
  height: 144px;
  border-radius: 10px;
}
.kd-event-5-item .item-img img {
  transition: transform 500ms;
  transform: perspective(0px) rotateX(0deg) rotateY(0deg) scaleX(1) scaleY(1);
  transform-origin: center center;
}
.kd-event-5-item .item-date {
  font-size: 40px;
}
.kd-event-5-item .item-date span {
  font-size: 18px;
  display: block;
  font-weight: 500;
  color: var(--kd-clr-p-1);
}
.kd-event-5-item .item-title {
  font-size: 24px;
  line-height: 1.33;
  margin-bottom: 12px;
  transition: 0.2s ease;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-event-5-item .item-title {
    font-size: 20px;
  }
}
.kd-event-5-item .item-title:hover {
  color: var(--kd-clr-pr-1);
}
.kd-event-5-item .item-info {
  display: flex;
  gap: 10px 30px;
  flex-wrap: wrap;
}
.kd-event-5-item .item-info li {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.kd-event-5-item .item-info li i {
  display: inline-block;
  color: var(--kd-clr-pr-1);
  margin-right: 5px;
}
.kd-event-5-item .item-disc {
  font-size: 16px;
  line-height: 26px;
}
.kd-event-5-item .item-btn {
  background: var(--kd-clr-pr-1);
  color: #fff;
}
.kd-event-5-item .item-btn .text::before {
  color: #fff;
}
.kd-event-5-item .item-btn .text::after {
  color: #000;
}
.kd-event-5-item .item-btn:hover {
  background: var(--kd-clr-sd-1);
  color: #000;
}
.kd-event-5-item:hover {
  border-bottom-color: var(--kd-clr-pr-1);
}
.kd-event-5-item:hover .item-img img {
  transform: perspective(600px) rotateX(0.06deg) rotateY(0deg) scaleX(1.15) scaleY(1.15);
}

/* 
    event-5-end
*/
/* 
    breadcrumb-start
*/
.breadcrumb-area {
  padding-top: 300px;
}
.breadcrumb-title {
  font-size: 80px;
  color: #fff;
  margin-bottom: 30px;
  padding-bottom: 5px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .breadcrumb-title {
    font-size: 70px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .breadcrumb-title {
    font-size: 60px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-title {
    font-size: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-title {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .breadcrumb-title {
    font-size: 26px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .breadcrumb-title {
    font-size: 35px;
  }
}
.breadcrumb-list a, .breadcrumb-list span {
  font-weight: 700;
  font-size: 20px;
  font-family: var(--kd-font-pr-1);
  line-height: 1;
  color: #fff;
  text-decoration: none;
  display: inline-block;
}
@media (max-width: 767px) {
  .breadcrumb-list a, .breadcrumb-list span {
    font-size: 18px;
  }
}
.breadcrumb-list a {
  padding-right: 25px;
  position: relative;
  margin-right: 10px;
  transition: 400ms ease;
}
.breadcrumb-list a:hover {
  color: var(--kd-clr-sd-1);
}
.breadcrumb-list a::after {
  content: "\f101";
  position: absolute;
  font-family: "Font Awesome 6 Free";
  right: 0;
  top: 12px;
  transform: translateY(-50%);
  font-size: 18px;
  color: var(--gly-pr-1);
}
.breadcrumb-list span {
  position: relative;
  text-transform: capitalize;
  color: #fff;
}
.breadcrumb-list span::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--clr-common-white);
  bottom: -2px;
  left: 0;
}

.breadcrumb-list {
  display: inline-block;
  overflow: hidden;
  position: relative;
  padding: 14px 28px;
  border: 2px solid var(--kd-clr-sd-1);
  border-radius: 40px;
  background: transparent;
}

.breadcrumb-wrap {
  position: relative;
  z-index: 1;
}

.has-breadcrumb-overlay {
  position: relative;
}
.has-breadcrumb-overlay::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.4;
}
.kd-course-details-content .cd-video-accordion-item-video .duration span {
    color: var(--kd-clr-h-1);
}
/* 
    breadcrumb-end
*/
/* 
    course-details-start
*/
.kd-course-details-content {
  margin-bottom: 30px;
}
.kd-course-details-content .cd-video-accordion-item-video .duration span {
  color: var(--kd-clr-h-1);
}
.kd-course-details-content .main-img {
  height: 450px;
  overflow: hidden;
  border-radius: 15px;
  margin-bottom: 22px;
}
.kd-course-details-content .main-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.kd-course-details-content .cd-tabs-btn {
  background: rgba(0, 94, 78, 0.08);
  border-radius: 10px;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 40px;
}
.kd-course-details-content .cd-tabs-btn .nav-link {
  font-family: var(--kd-font-pr-1);
  font-weight: 500;
  text-transform: capitalize;
  text-align: left;
  color: var(--kd-clr-h-1);
  font-size: 18px;
  line-height: 32px;
  border: 1px solid rgba(0, 94, 78, 0.3);
  border-radius: 10px;
  padding: 14px 58px;
  transition: 0.3s ease;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-course-details-content .cd-tabs-btn .nav-link {
    padding: 14px 26px;
  }
}
@media (max-width: 767px) {
  .kd-course-details-content .cd-tabs-btn .nav-link {
    padding: 8px 15px;
  }
}
.kd-course-details-content .cd-tabs-btn .nav-link:hover {
  background: #ddd;
}
.kd-course-details-content .cd-tabs-btn .nav-link:is(.is-active) {
  background: var(--kd-clr-pr-1);
  color: #fff;
}
.tutor-course-details-page .tutor-course-details-tab .tutor-is-sticky{
  z-index: 1;
}
.kd-course-details-content .cd-title {
  font-size: 32px;
  font-family: var(--kd-font-pr-1);
  line-height: 1.25;
  font-weight: 800;
  color: var(--kd-clr-h-1);
  margin-bottom: 25px;
  text-transform: capitalize;
}
@media (max-width: 767px) {
  .kd-course-details-content .cd-title {
    font-size: 22px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-course-details-content .cd-title {
    font-size: 26px;
  }
}
.kd-course-details-content p {
  font-family: var(--kd-font-pr-1);
  font-size: 20px;
  line-height: 32px;
  color: var(--kd-clr-p-1);
  margin-bottom: 20px;
}
.kd-course-details-content .cd-subtitle, .tutor-course-details-content h2 {
  text-transform: capitalize;
  font-size: 26px;
  font-family: var(--kd-font-pr-1);
  line-height: 1.38;
  font-weight: 800;
  color: var(--kd-clr-h-1);
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .kd-course-details-content .cd-subtitle, .tutor-course-details-content h2 {
    font-size: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-course-details-content .cd-subtitle, .tutor-course-details-content h2 {
    font-size: 24px;
  }
}
.kd-course-details-content .cd-disc-list, .kd-course-details-content ul {
  margin-bottom: 30px;
}
.kd-course-details-content .cd-disc-list li, .tutor-course-details-content li {
  font-family: var(--kd-font-pr-1);
  font-weight: 400;
  color: var(--kd-clr-h-1);
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: baseline;
  gap: 10px;
  position: relative;
  padding-left: 24px;
}
.tutor-course-details-content li:after {
  content: "\f058";
  font-family: "Font Awesome 6 Free";
  position: absolute;
  left: 0;
  top: 0;
  font-size: 16px;
  color: var(--kd-clr-pr-1);
  font-weight: 900;
}
.kd-course-details-content .cd-disc-list li:not(:last-child), .tutor-course-details-content li:not(:last-child) {
  margin-bottom: 15px;
}
.kd-course-details-content .cd-disc-list li i {
  display: inline-block;
  color: var(--kd-clr-pr-1);
  font-size: 16px;
  transform: translateY(-1px);
}
.kd-course-details-content .cd-video-accordion {
  margin-bottom: 30px;
}
.kd-course-details-content .cd-video-accordion-item {
  background: transparent;
  border-radius: 10px;
}
.kd-course-details-content .cd-video-accordion-item:not(:last-child) {
  margin-bottom: 20px;
}
.kd-course-details-content .cd-video-accordion-item .item-title {
  font-family: var(--kd-font-pr-1);
  font-size: 24px;
  line-height: 1.33;
  font-weight: 600;
  padding: 18px 30px;
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
  text-decoration: none;
  text-align: left;
  color: var(--kd-clr-h-1);
  background: transparent;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid;
  border-color: #A5C6C0;
  transition: 0.5s ease;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-course-details-content .cd-video-accordion-item .item-title {
    padding: 18px 20px;
    font-size: 22px;
  }
}
@media (max-width: 767px) {
  .kd-course-details-content .cd-video-accordion-item .item-title {
    padding: 18px 15px;
    font-size: 18px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-course-details-content .cd-video-accordion-item .item-title {
    padding: 18px 15px;
    font-size: 20px;
  }
}
.kd-course-details-content .cd-video-accordion-item .item-title .count {
  display: inline-block;
  margin-left: auto;
}
.kd-course-details-content .cd-video-accordion-item .item-title:hover {
  color: var(--kd-clr-pr-1);
  background: #F2F7F6;
}
.kd-course-details-content .cd-video-accordion-item .item-title:is(:not(.collapsed)) {
  border-radius: 10px 10px 0px 0px;
  border-color: transparent;
  background: #A5C6C0;
}
.kd-course-details-content .cd-video-accordion-item .item-body {
  padding: 5px 30px;
  background: #F2F7F6;
  border-radius: 0 0 10px 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-course-details-content .cd-video-accordion-item .item-body {
    padding: 5px 20px;
  }
}
@media (max-width: 767px) {
  .kd-course-details-content .cd-video-accordion-item .item-body {
    padding: 5px 15px;
  }
}
.kd-course-details-content .cd-video-accordion-item-video {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;
  flex-wrap: wrap;
  font-family: var(--kd-font-pr-1);
  color: var(--kd-clr-h-1);
  font-size: 18px;
  text-transform: capitalize;
  padding: 15px 0;
  outline: none;
  background: transparent;
  cursor: no-drop;
  text-align: left;
}
.kd-course-details-content .cd-video-accordion-item-video:not(:last-child) {
  border-bottom: 1px solid #D4E0DE;
}
.kd-course-details-content .cd-video-accordion-item-video .icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  width: 42px;
  height: 42px;
  background: rgba(0, 94, 78, 0.05);
  border-radius: 50%;
  color: var(--kd-clr-pr-1);
}
.kd-course-details-content .cd-video-accordion-item-video .duration {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  background: #B7CDC9;
  padding: 0px 15px;
  border-radius: 5px;
  margin-left: auto;
}
.kd-course-details-content .cd-video-accordion-item-video .tutor-color-muted {
  display: inline-block;
  color: var(--kd-clr-sd-1);
}
.tutor-accordion-item-header::after{
  color: var(--kd-clr-h-1);
}
.tutor-course-content-list-item-title {
  color: var(--kd-clr-h-1);
  font-size: 18px;
}
.kd-course-details-content .cd-video-accordion-item-video:is(.unlock-video) {
  cursor: pointer;
}
.kd-course-details-content .cd-video-accordion-item-video:is(.unlock-video) .lock {
  color: var(--kd-clr-pr-1);
}
.kd-course-details-content .cd-video-accordion-item-video:is(.unlock-video) .lock i::before {
  content: "\f3c1";
}
.kd-course-details-content .cd-reviews-box {
  background: #F2F7F6;
  border-radius: 10px;
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr 1px 2fr;
  gap: 50px;
  align-items: center;
  margin-bottom: 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-course-details-content .cd-reviews-box {
    grid-template-columns: 1fr 1px 1.5fr;
    gap: 30px;
  }
}
@media (max-width: 767px) {
  .kd-course-details-content .cd-reviews-box {
    grid-template-columns: 1fr;
    gap: 50px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-course-details-content .cd-reviews-box {
    grid-template-columns: 1fr 1px 2fr;
    gap: 30px;
  }
}
.kd-course-details-content .cd-reviews-box .box-line {
  display: block;
  width: 1px;
  height: 100%;
  background: rgba(121, 122, 132, 0.1);
}
@media (max-width: 767px) {
  .kd-course-details-content .cd-reviews-box .box-line {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-course-details-content .cd-reviews-box .box-line {
    display: block;
  }
}
.kd-course-details-content .cd-reviews-points {
  text-align: center;
}
.kd-course-details-content .cd-reviews-points .point {
  font-family: var(--kd-font-pr-1);
  font-weight: 800;
  font-size: 90px;
  line-height: 1;
  color: var(--kd-clr-h-1);
  margin-bottom: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-details-content .cd-reviews-points .point {
    font-size: 60px;
  }
}
.kd-course-details-content .cd-reviews-points .average {
  font-family: var(--kd-font-pr-1);
  font-size: 22px;
  color: var(--kd-clr-p-1);
  font-weight: 400;
  margin-bottom: 0;
  line-height: 1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-details-content .cd-reviews-points .average {
    font-size: 18px;
  }
}
.kd-course-details-content .cd-reviews-points .average b {
  font-weight: 600;
  color: var(--kd-clr-h-1);
}
.kd-course-details-content .cd-reviews-points .cd-reviews-star {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #EF9920;
  gap: 3px;
  flex-wrap: wrap;
  margin-bottom: 25px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-details-content .cd-reviews-points .cd-reviews-star {
    font-size: 20px;
  }
}
.kd-course-details-content .cd-reviews-progress-single:not(:last-child) {
  margin-bottom: 12px;
}
.kd-course-details-content .cd-reviews-progress-single .number {
  font-family: var(--kd-font-pr-1);
  font-size: 18px;
  font-weight: 600;
  color: var(--kd-clr-h-1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.kd-course-details-content .cd-reviews-progress-single .number .icon {
  display: inline-block;
  color: var(--kd-clr-p-1);
}
.kd-course-details-content .cd-reviews-progress-single .number .icon i {
  color: #EF9920;
  margin-right: 5px;
}
.kd-course-details-content .cd-reviews-progress-single .progress-item {
  width: 100%;
  height: 12px;
  background: #fff;
  border-radius: 24px;
  overflow: hidden;
}
.kd-course-details-content .cd-reviews-progress-single .progress-item-line {
  display: block;
  height: 100%;
  background: #EF9920;
  border-radius: 24px;
  transform: translateX(-100%);
  transition: 2s ease-in-out;
}
.kd-course-details-content .cd-review-author {
  border: 1px solid rgba(0, 94, 78, 0.3);
  border-radius: 5px;
  padding: 30px 40px;
  display: flex;
  gap: 25px;
}
.kd-course-details-content .cd-review-author-wrap {
  margin-bottom: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-course-details-content .cd-review-author {
    padding: 30px 20px;
  }
}
@media (max-width: 767px) {
  .kd-course-details-content .cd-review-author {
    padding: 30px 15px;
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-course-details-content .cd-review-author {
    padding: 30px 15px;
    flex-wrap: nowrap;
  }
}
.kd-course-details-content .cd-review-author:not(:last-child) {
  margin-bottom: 12px;
}
.kd-course-details-content .cd-review-author .author-img {
  width: 78px;
  height: 78px;
  flex: 0 0 auto;
  border-radius: 50%;
  overflow: hidden;
}
.kd-course-details-content .cd-review-author .author-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.kd-course-details-content .cd-review-author .cd-reviews-star {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #EF9920;
  gap: 5px;
  margin-bottom: 10px;
}
.kd-course-details-content .cd-review-author .author-name {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 15px;
  color: var(--kd-clr-h-1);
}
.kd-course-details-content .cd-review-author .author-name span {
  font-size: 16px;
  font-weight: 500;
  color: var(--kd-clr-p-1);
}
.kd-course-details-content .cd-review-author .author-bio {
  font-family: var(--kd-font-pr-1);
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 0;
  color: var(--kd-clr-p-1);
}
.kd-course-details-content .cd-review-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px 15px;
}
.kd-course-details-content .cd-review-form-wrap {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
  padding: 35px 30px;
  margin-bottom: 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-course-details-content .cd-review-form-wrap {
    padding: 35px 20px;
  }
}
@media (max-width: 767px) {
  .kd-course-details-content .cd-review-form-wrap {
    padding: 35px 15px;
  }
}
.kd-course-details-content .cd-review-form-wrap .title {
  font-family: var(--kd-font-pr-1);
  font-weight: 700;
  font-size: 28px;
  line-height: 1.2;
  color: var(--kd-clr-h-1);
  margin-bottom: 15px;
}
.kd-course-details-content .cd-review-form-wrap .disc {
  font-family: var(--kd-font-pr-1);
  color: var(--kd-clr-h-1);
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 10px;
}
.kd-course-details-content .cd-review-form-rating-1 {
  display: inline-block;
  margin-bottom: 25px;
}
.kd-course-details-content .cd-review-form-rating-1 > input {
  display: none;
}
.kd-course-details-content .cd-review-form-rating-1 > input:not(:checked) ~ label:hover {
  color: #FFC107;
}
.kd-course-details-content .cd-review-form-rating-1 > input:not(:checked) ~ label:hover ~ label {
  color: #FFC107;
}
.kd-course-details-content .cd-review-form-rating-1 > input:checked ~ label {
  color: #FFC107;
}
.kd-course-details-content .cd-review-form-rating-1 label {
  font-size: 22px;
  color: #888888;
  transition: 400ms var(--txa-pr-1);
  float: right;
}
.kd-course-details-content .cd-review-form-rating-1 label:not(:first-child) {
  margin-left: 4px;
}
.kd-course-details-content .cd-review-form-rating-1 label i {
  line-height: 1;
}
@media (max-width: 767px) {
  .kd-course-details-content .cd-review-form {
    grid-template-columns: 1fr;
  }
}
.kd-course-details-content .cd-review-form > input, .kd-course-details-content .cd-review-form textarea {
  font-family: var(--kd-font-pr-1);
  width: 100%;
  height: 62px;
  border-radius: 100px;
  outline: none;
  border: none;
  background: #F2F7F6;
  font-size: 18px;
  font-weight: 400;
  color: #797A84;
  padding: 0 30px;
}
.kd-course-details-content .cd-review-form .check-item {
  position: relative;
  grid-column: 1/-1;
  display: flex;
  align-items: center;
}
.kd-course-details-content .cd-review-form .check-item label {
  cursor: pointer;
  padding-left: 20px;
  margin-top: -2px;
  font-family: var(--kd-font-pr-1);
  font-size: 18px;
  line-height: 30px;
  color: var(--kd-clr-h-1);
}
.kd-course-details-content .cd-review-form .check-item label::before {
  content: "";
  background-color: #fff;
  border: 1px solid var(--kd-clr-h-1);
  border-radius: 50%;
  cursor: pointer;
  height: 22px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 22px;
}
.kd-course-details-content .cd-review-form .check-item label::after {
  border: none;
  border-radius: 50%;
  content: "";
  background: var(--kd-clr-h-1);
  height: 10px;
  width: 10px;
  left: 6px;
  top: 9px;
  opacity: 0;
  position: absolute;
}
.kd-course-details-content .cd-review-form .check-item input {
  visibility: hidden;
}
.kd-course-details-content .cd-review-form .check-item input:checked + label .number {
  color: #6C0157;
}
.kd-course-details-content .cd-review-form .check-item input:checked + label:after {
  opacity: 1;
}
.kd-course-details-content .cd-review-form textarea {
  height: 178px;
  padding-top: 15px;
  border-radius: 10px;
  grid-column-start: 1;
  grid-column-end: -1;
}
.kd-course-details-content .cd-review-form .btn-wrap {
  grid-column: 1/-1;
  text-align: center;
}
.kd-course-details-content .cd-review-form .btn-wrap .form-btn {
  display: inline-block;
  font-family: var(--kd-font-pr-1);
  color: #22281e;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  background: var(--kd-clr-pr-1);
  border-radius: 30px;
  padding: 22px 31px;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
  transition: 0.3s var(--cube-1);
  width: 100%;
  text-align: center;
}
.kd-course-details-content .cd-review-form .btn-wrap .form-btn .text {
  display: inline-block;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: -3px;
}
.kd-course-details-content .cd-review-form .btn-wrap .form-btn .text::after {
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  display: block;
  transition: 0.5s;
  position: absolute;
  background: transparent;
  color: #000;
  content: attr(data-back);
  transform: translateX(-100%);
}
.kd-course-details-content .cd-review-form .btn-wrap .form-btn .text::before {
  top: 0;
  left: 0;
  opacity: 1;
  display: block;
  transition: 0.5s;
  position: relative;
  background: var(--blta-pr-1);
  color: #fff;
  content: attr(data-front);
  transform: translateX(0);
}
.kd-course-details-content .cd-review-form .btn-wrap .form-btn:hover {
  color: #fff;
  background: var(--kd-clr-sd-1);
}
.kd-course-details-content .cd-review-form .btn-wrap .form-btn:hover .text::after {
  opacity: 1;
  transform: translateX(0);
}
.kd-course-details-content .cd-review-form .btn-wrap .form-btn:hover .text::before {
  opacity: 0;
  transform: translateX(100%);
}
.kd-course-details-content .cd-course-ins {
  border: 1px solid rgba(0, 94, 78, 0.3);
  border-radius: 10px;
  padding: 25px 25px;
  display: grid;
  grid-template-columns: 1fr 2.2fr;
  gap: 30px;
  align-items: center;
}
@media (max-width: 767px) {
  .kd-course-details-content .cd-course-ins {
    grid-template-columns: 1fr;
  }
}
.kd-course-details-content .cd-course-ins .ins-img {
  width: 100%;
  height: 232px;
  overflow: hidden;
  border-radius: 12px;
}
.kd-course-details-content .cd-course-ins .ins-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.kd-course-details-content .cd-course-ins .ins-name-wrap {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15px;
}
.kd-course-details-content .cd-course-ins .ins-name {
  font-family: var(--kd-font-pr-1);
  font-weight: 800;
  text-transform: capitalize;
  color: #000;
  font-size: 30px;
  line-height: 1.2;
  margin-bottom: 8px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-course-details-content .cd-course-ins .ins-name {
    font-size: 24px;
  }
}
.kd-course-details-content .cd-course-ins .ins-bio {
  font-family: var(--kd-font-pr-1);
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: var(--kd-clr-pr-1);
  margin-bottom: 0;
}
.kd-course-details-content .cd-course-ins .ins-disc {
  font-family: var(--kd-font-pr-1);
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  color: var(--kd-clr-p-1);
  margin-bottom: 0;
}
.kd-course-details-content .cd-course-ins .ins-social {
  display: flex;
  gap: 10px 14px;
  flex-wrap: wrap;
}
.kd-course-details-content .cd-course-ins .ins-social .link {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  width: 36px;
  height: 36px;
  border: 1px solid #797A84;
  border-radius: 50%;
  font-size: 16px;
  color: #797A84;
  transition: 0.3s ease;
}
.kd-course-details-content .cd-course-ins .ins-social .link:hover {
  background: var(--kd-clr-pr-1);
  color: #fff;
  border-color: transparent;
}
.kd-course-details-content .tab-content .tab-pane:is(.show) .cd-reviews-progress-single .progress-item-line {
  transform: translateX(0%);
}

.kd-course-details-sidebar {
  border-radius: 10px;
  padding: 25px 25px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  margin-bottom: 30px;
}
.kd-course-details-sidebar .cd-sidebar-video {
  width: 100%;
  height: 175px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  border-radius: 10px 10px 0 0;
  margin-bottom: 20px;
}
.kd-course-details-sidebar .cd-sidebar-video img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.kd-course-details-sidebar .cd-sidebar-video .btn-position {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.kd-course-details-sidebar .cd-sidebar-video .video-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--kd-clr-pr-1);
  color: #fff;
  font-size: 20px;
  position: relative;
  transition: 400ms var(--cube-1);
}
.kd-course-details-sidebar .cd-sidebar-video .video-btn::after {
  content: "";
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: white;
  opacity: 0.5;
  z-index: -1;
  animation: kd-plybtn-1 4s linear infinite;
}
.kd-course-details-sidebar .cd-sidebar-video .video-btn::before {
  content: "";
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: white;
  opacity: 0.5;
  z-index: -1;
  animation: kd-plybtn-1 2s linear infinite;
}
.kd-course-details-sidebar .cd-sidebar-video .video-btn:hover {
  color: var(--kd-clr-sd-1);
}
.kd-course-details-sidebar .cd-sidebar-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  font-family: var(--kd-font-pr-1);
  font-weight: 800;
  font-size: 30px;
  line-height: 1.2;
  color: var(--kd-clr-pr-1);
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .kd-course-details-sidebar .cd-sidebar-price {
    font-size: 24px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-course-details-sidebar .cd-sidebar-price {
    font-size: 28px;
  }
}
.kd-course-details-sidebar .cd-sidebar-price span {
  display: inline-block;
  color: var(--kd-clr-h-1);
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
}
.kd-course-details-sidebar .cd-sidebar-buy-btn {
  display: inline-block;
  font-family: var(--kd-font-pr-1);
  color: #22281e;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  background: var(--kd-clr-pr-1);
  border-radius: 30px;
  padding: 18px 31px;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
  transition: 0.3s var(--cube-1);
  width: 100%;
  text-align: center;
}
.kd-course-2-card .feature-list li i {
  margin-right: 4px;
}
button.tutor-btn.tutor-btn-outline-primary.tutor-btn-block, .tutor-sidebar-card .tutor-add-to-cart-button {
  display: inline-block;
  font-family: var(--kd-font-pr-1);
  color: #22281e;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  background: transparent;
  border-radius: 30px;
  padding: 18px 31px;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
  transition: 0.3s var(--cube-1);
  width: 100%;
  text-align: center;
  border: 1px solid var(--kd-clr-pr-1);
}
.kd-course-details-content .tutor-course-content-list {
  margin-bottom: 0;
}
.tutor-card-inner {
  margin-bottom: 30px;
}
.kd-course-details-sidebar .cd-sidebar-buy-btn .text {
  display: inline-block;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: -3px;
}
.kd-course-details-sidebar .cd-sidebar-buy-btn .text::after {
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  display: block;
  transition: 0.5s;
  position: absolute;
  background: transparent;
  color: #000;
  content: attr(data-back);
  transform: translateX(-100%);
}
.kd-course-details-sidebar .cd-sidebar-buy-btn .text::before {
  top: 0;
  left: 0;
  opacity: 1;
  display: block;
  transition: 0.5s;
  position: relative;
  background: var(--blta-pr-1);
  color: #fff;
  content: attr(data-front);
  transform: translateX(0);
}
.kd-course-details-sidebar .cd-sidebar-buy-btn:hover {
  color: #fff;
  background: var(--kd-clr-sd-1);
}
.kd-course-details-sidebar .cd-sidebar-buy-btn:hover .text::after {
  opacity: 1;
  transform: translateX(0);
}
.kd-course-details-sidebar .cd-sidebar-buy-btn:hover .text::before {
  opacity: 0;
  transform: translateX(100%);
}
.kd-course-details-sidebar .cd-sidebar-feature {
  margin-bottom: 25px;
}
.kd-course-details-sidebar .cd-sidebar-feature li {
  font-family: var(--kd-font-pr-1);
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 600;
  color: var(--kd-clr-h-1);
  line-height: 1.5;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 18px;
}
.kd-course-details-sidebar .cd-sidebar-feature li:not(:last-child) {
  margin-bottom: 18px;
}
.kd-course-details-sidebar .cd-sidebar-feature li span {
  font-weight: 400;
  color: var(--kd-clr-p-1);
  margin-left: auto;
}
.kd-course-details-sidebar .cd-sidebar-feature li::after {
  content: "";
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(90deg, rgba(127, 57, 127, 0) 0%, rgb(177, 176, 177) 50%, rgba(127, 57, 127, 0) 100%);
  z-index: -1;
  transition: 400ms var(--cube-1);
  opacity: 0.5;
}
.kd-course-details-sidebar .cd-sidebar-share {
  display: flex;
  align-items: center;
  gap: 0 10px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.kd-course-details-sidebar .cd-sidebar-share .title {
  font-family: var(--kd-font-pr-1);
  text-transform: capitalize;
  font-weight: 600;
  color: var(--kd-clr-h-1);
  font-size: 22px;
  line-height: 1.45;
  margin-bottom: 0;
}
.kd-course-details-sidebar .cd-sidebar-share-link {
  display: flex;
  gap: 10px 14px;
  flex-wrap: wrap;
}
.kd-course-details-sidebar .cd-sidebar-share-link .link {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  width: 36px;
  height: 36px;
  border: 1px solid #797A84;
  border-radius: 50%;
  font-size: 16px;
  color: #797A84;
  transition: 0.3s ease;
}
.kd-course-details-sidebar .cd-sidebar-share-link .link:hover {
  background: var(--kd-clr-pr-1);
  color: #fff;
  border-color: transparent;
}
.related_course.kd-course-details-area {
  padding-top: 30px;
  padding-bottom: 130px;
}
.kd-course-details-area {
  padding-top: 130px;
}
.kd-course-details-area .retated-section-title {
  font-size: 42px;
  font-weight: 800;
  line-height: 1.19;
  margin-bottom: 30px;
  padding-bottom: 0;
  color: var(--kd-clr-h-1);
}
.tutor-dashboard-content .tutor-card {
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  margin-bottom: 0;
}
.tutor-wrap.tutor-wrap-parent.tutor-dashboard.tutor-frontend-dashboard.tutor-dashboard-student {
  padding-top: 130px;
}
.tutor-btn-outline-primary{
  border-color: var(--kd-clr-pr-1);
}
.tutor-btn-outline-primary:hover, .tutor-btn-outline-primary:focus, .tutor-btn-outline-primary:active{
  background: var(--kd-clr-pr-1);
  border-color: var(--kd-clr-pr-1);
}
.tutor-btn{
  color: var(--kd-clr-pr-1);
}
.tutor-dashboard-content .tutor-card .tutor-round-box, .tutor-dashboard .tutor-dashboard-left-menu .tutor-dashboard-menu-item-icon {
  color: var(--kd-clr-pr-1);
}
.tutor-dashboard .tutor-dashboard-left-menu .tutor-dashboard-permalinks li.active a{
  background: var(--kd-clr-pr-1);
}
.tutor-dashboard .tutor-dashboard-left-menu .tutor-dashboard-permalinks li.active a:hover {
  background-color: var(--kd-clr-sd-1);
}
.tutor-option-field textarea, .tutor-option-field input[type=text], .tutor-option-field input[type=number], .tutor-option-field input[type="pas.tutor-dashboard-content-innersword"], .tutor-form-group textarea, .tutor-form-group input[type=text], .tutor-form-group input[type=number], .tutor-form-group input[type=password]{
  width: 100%;
  height: 62px;
  border-radius: 100px;
  outline: none;
  border: none;
  background: #F7F7F7;
  font-size: 18px;
  font-weight: 500;
  color: var(--kd-clr-p-1);
  text-transform: capitalize;
  padding: 0 30px;
}
.tutor-option-field-row label, .tutor-form-group label {
  color: var(--kd-clr-h-1);
  font-weight: 500;
}
.tutor-login-form-wrapper, #tutor-registration-form {
  padding-bottom: 0;
  padding-top: 0;
}
#tutor-registration-wrap button.tutor-btn {
  display: inline-block;
  font-family: var(--kd-font-pr-1);
  color: #22281e;
  font-weight: 800;
  font-size: 15px;
  line-height: 1;
  background: var(--kd-clr-sd-1);
  border-radius: 30px;
  padding: 20px 31px;
  position: relative;
  z-index: 1;
  transition: 0.3s var(--cube-1);
  border:none
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-course-details-area .retated-section-title {
    font-size: 36px;
  }
}
@media (max-width: 767px) {
  .kd-course-details-area .retated-section-title {
    font-size: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-course-details-area .retated-section-title {
    font-size: 34px;
  }
}

/* 
    course-details-end
*/
/* 
    instructor-details-1-satrt
*/
.kd-instructor-details-area .instructor-inner-title {
  font-family: var(--kd-font-pr-1);
  font-weight: 800;
  text-transform: capitalize;
  font-size: 42px;
  line-height: 1.19;
  margin-bottom: 30px;
  color: var(--kd-clr-h-1);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-instructor-details-area .instructor-inner-title {
    font-size: 36px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-instructor-details-area .instructor-inner-title {
    font-size: 34px;
  }
}
@media (max-width: 767px) {
  .kd-instructor-details-area .instructor-inner-title {
    font-size: 28px;
  }
}
.kd-instructor-details-single {
  background: rgba(0, 94, 78, 0.05);
  border-radius: 20px;
  padding: 45px 45px;
  display: grid;
  grid-template-columns: 1fr 1.1fr;
  gap: 50px;
  margin-bottom: 130px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-instructor-details-single {
    gap: 30px;
    padding: 45px 25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-instructor-details-single {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 767px) {
  .kd-instructor-details-single {
    grid-template-columns: 1fr;
    padding: 45px 15px;
  }
}
.kd-instructor-details-single .instrutor-img {
  height: 525px;
  border-radius: 18px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .kd-instructor-details-single .instrutor-img {
    height: 345px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-instructor-details-single .instrutor-img {
    height: 525px;
  }
}
.kd-instructor-details-single .instrutor-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.kd-instructor-details-single .instructor-bio {
  font-family: var(--kd-font-pr-1);
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: var(--kd-clr-pr-1);
  margin-bottom: 15px;
  text-transform: uppercase;
}
.kd-instructor-details-single .instructor-name {
  font-family: var(--kd-font-pr-1);
  font-weight: 700;
  text-transform: capitalize;
  font-size: 52px;
  line-height: 1.19;
  color: var(--kd-clr-h-1);
  margin-bottom: 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-instructor-details-single .instructor-name {
    font-size: 45px;
  }
}
@media (max-width: 767px) {
  .kd-instructor-details-single .instructor-name {
    font-size: 28px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-instructor-details-single .instructor-name {
    font-size: 32px;
  }
}
.kd-instructor-details-single .instroctor-disc {
  font-family: var(--kd-font-pr-1);
  font-size: 18px;
  line-height: 30px;
  color: var(--kd-clr-p-1);
  margin-bottom: 25px;
}
.kd-instructor-details-single .instructor-contact {
  margin-bottom: 30px;
}
.kd-instructor-details-single .instructor-contact li {
  display: flex;
  align-items: baseline;
  gap: 18px;
  font-family: var(--kd-font-pr-1);
  color: var(--kd-clr-h-1);
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}
.kd-instructor-details-single .instructor-contact li:not(:last-child) {
  margin-bottom: 20px;
}
.kd-instructor-details-single .instructor-contact li a {
  display: flex;
  align-items: baseline;
  gap: 18px;
  transition: 0.4s ease;
}
.kd-instructor-details-single .instructor-contact li a:hover {
  color: var(--kd-clr-sd-1);
}
.kd-instructor-details-single .instructor-contact li a:hover i {
  animation: 1s kd-jello;
  color: var(--kd-clr-sd-1);
  border-color: var(--kd-clr-sd-1);
}
.kd-instructor-details-single .instructor-contact li i {
  display: flex;
  flex: 0 0 auto;
  width: 48px;
  height: 48px;
  border: 1px solid var(--kd-clr-pr-1);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: var(--kd-clr-pr-1);
}
.kd-instructor-details-single .instructor-social {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}
.kd-instructor-details-single .instructor-social .link {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: var(--kd-font-pr-1);
  text-transform: capitalize;
  color: var(--kd-clr-h-1);
  border: 1px solid rgba(0, 94, 78, 0.2);
  border-radius: 10px;
  padding: 5px 14px;
  transition: 0.4s ease;
  font-size: 18px;
}
.kd-instructor-details-single .instructor-social .link i {
  display: inline-block;
  color: var(--kd-clr-pr-1);
  transition: 0.4s ease;
}
.kd-instructor-details-single .instructor-social .link:hover {
  background: var(--kd-clr-pr-1);
  color: #fff;
}
.kd-instructor-details-single .instructor-social .link:hover i {
  color: #fff;
}

/* 
    instructor-details-1-ened
*/
/* 
    event-details-start
*/
.event-details-content .content-title {
  font-family: var(--kd-font-pr-1);
  font-weight: 900;
  font-size: 42px;
  color: var(--kd-clr-h-1);
  margin-bottom: 20px;
  line-height: 1.23;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .event-details-content .content-title {
    font-size: 36px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .event-details-content .content-title {
    font-size: 34px;
  }
}
@media (max-width: 767px) {
  .event-details-content .content-title {
    font-size: 28px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .event-details-content .content-title {
    font-size: 30px;
  }
}
.event-details-content .content-disc {
  font-family: var(--kd-font-pr-1);
  font-size: 20px;
  line-height: 32px;
  color: var(--kd-clr-p-1);
  margin-bottom: 20px;
}
.event-details-content .content-main-img {
  height: 520px;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 30px;
  margin-top: 25px;
  margin-right: -10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .event-details-content .content-main-img {
    margin-right: 0px;
  }
}
@media (max-width: 767px) {
  .event-details-content .content-main-img {
    margin-right: 0px;
    height: 220px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .event-details-content .content-main-img {
    margin-right: 0px;
    height: 450px;
  }
}
.event-details-content .content-main-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.event-details-content .content-subtitle {
  font-family: var(--kd-font-pr-1);
  font-weight: 800;
  font-size: 26px;
  color: var(--kd-clr-h-1);
  margin-bottom: 20px;
  line-height: 1.38;
  margin-top: 30px;
}
.event-details-content .content-list {
  margin-bottom: 20px;
}
.event-details-content .content-list li {
  font-family: var(--kd-font-pr-1);
  font-size: 20px;
  line-height: 32px;
  color: var(--kd-clr-h-1);
  display: flex;
  align-items: baseline;
  gap: 8px;
}
.event-details-content .content-list li:not(:last-child) {
  margin-bottom: 15px;
}
.event-details-content .content-list li i {
  display: inline-block;
  color: var(--kd-clr-pr-1);
  font-size: 16px;
  transform: translateY(-2px);
}
.event-details-content .content-video {
  height: 245px;
  overflow: hidden;
  border-radius: 12px;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
}
.event-details-content .content-video img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.event-details-content .content-video .btn-position {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.event-details-content .content-video-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: var(--kd-clr-pr-1);
  color: #fff;
  font-size: 16px;
  position: relative;
  transition: 400ms var(--cube-1);
}
.event-details-content .content-video-btn::after {
  content: "";
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: #fff;
  opacity: 0.5;
  z-index: -1;
  animation: kd-plybtn-1 4s linear infinite;
}
.event-details-content .content-video-btn::before {
  content: "";
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: #fff;
  opacity: 0.5;
  z-index: -1;
  animation: kd-plybtn-1 2s linear infinite;
}
.event-details-content .content-video-btn:hover {
  color: var(--kd-clr-sd-1);
}
.event-details-content .content-sidebar {
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  padding: 30px 25px;
  margin-top: -175px;
  background: #fff;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .event-details-content .content-sidebar {
    margin-top: 40px;
  }
}
.event-details-content .content-sidebar .sidebar-title {
  font-size: 26px;
  font-family: var(--kd-font-pr-1);
  color: var(--kd-clr-h-1);
  font-weight: 800;
  margin-bottom: 20px;
}
.event-details-content .content-sidebar-list {
  margin-bottom: 30px;
}
.event-details-content .content-sidebar-list li {
  font-family: var(--kd-font-pr-1);
  color: var(--kd-clr-p-1);
  font-size: 20px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  position: relative;
  z-index: 1;
  padding: 18px 0;
  text-transform: capitalize;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .event-details-content .content-sidebar-list li {
    font-size: 18px;
  }
}
.event-details-content .content-sidebar-list li span {
  color: var(--kd-clr-h-1);
  font-weight: 600;
}
.event-details-content .content-sidebar-list li span i {
  color: var(--kd-clr-pr-1);
}
.event-details-content .content-sidebar-list li::after {
  content: "";
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(90deg, rgba(127, 57, 127, 0) 0%, rgb(177, 176, 177) 50%, rgba(127, 57, 127, 0) 100%);
  z-index: -1;
  transition: 400ms var(--cube-1);
  opacity: 0.5;
}
.event-details-content .content-sidebar .sidebar-buy-btn {
  display: inline-block;
  font-family: var(--kd-font-pr-1);
  color: #22281e;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  background: var(--kd-clr-pr-1);
  border-radius: 30px;
  padding: 18px 31px;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
  transition: 0.3s var(--cube-1);
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}
.event-details-content .content-sidebar .sidebar-buy-btn .text {
  display: inline-block;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: -3px;
}
.event-details-content .content-sidebar .sidebar-buy-btn .text::after {
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  display: block;
  transition: 0.5s;
  position: absolute;
  background: transparent;
  color: #000;
  content: attr(data-back);
  transform: translateX(-100%);
}
.event-details-content .content-sidebar .sidebar-buy-btn .text::before {
  top: 0;
  left: 0;
  opacity: 1;
  display: block;
  transition: 0.5s;
  position: relative;
  background: var(--blta-pr-1);
  color: #fff;
  content: attr(data-front);
  transform: translateX(0);
}
.event-details-content .content-sidebar .sidebar-buy-btn:hover {
  color: #fff;
  background: var(--kd-clr-sd-1);
}
.event-details-content .content-sidebar .sidebar-buy-btn:hover .text::after {
  opacity: 1;
  transform: translateX(0);
}
.event-details-content .content-sidebar .sidebar-buy-btn:hover .text::before {
  opacity: 0;
  transform: translateX(100%);
}
.event-details-content .content-sidebar .sidebar-price {
  font-family: var(--kd-font-pr-1);
  font-weight: 500;
  font-size: 20px;
  color: var(--kd-clr-h-1);
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 0;
  align-items: center;
}
.event-details-content .content-sidebar .sidebar-price span {
  font-size: 30px;
  font-weight: 800;
  color: var(--kd-clr-pr-1);
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .event-details-content .content-sidebar .sidebar-price span {
    font-size: 26px;
  }
}

/* 
    event-details-end
*/
/* 
    gallery-start
*/
.kd-gallery-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: repeat(4, 350px);
  gap: 20px 20px;
  grid-template-areas: "item-1 item-2 item-2 item-3" "item-4 item-5 item-6 item-3" "item-7 item-8 item-9 item-10" "item-7 item-11 item-11 item-12";
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-gallery-grid {
    grid-template-rows: repeat(4, 250px);
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-gallery-grid {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(5, 250px);
    grid-template-areas: "item-1 item-2 item-3" "item-4 item-5 item-3" "item-6 item-7 item-8" "item-6 item-9 item-10" "item-11 item-12 item-12";
  }
}
@media (max-width: 767px) {
  .kd-gallery-grid {
    grid-template-columns: 1fr;
    grid-template-rows: 250px;
    grid-template-areas: "item-1" "item-2  " "item-3 " "item-4 " "item-5 " "item-6 " "item-7 " "item-8 " "item-9 " "item-10 " "item-11 " "item-12 ";
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-gallery-grid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 250px;
    grid-template-areas: "item-1 item-2" "item-3 item-4 " "item-5 item-6" "item-7 item-8" "item-9 item-10" "item-11 item-12";
  }
}
.kd-gallery-grid .item-1 {
  grid-area: item-1;
}
.kd-gallery-grid .item-2 {
  grid-area: item-2;
}
.kd-gallery-grid .item-3 {
  grid-area: item-3;
}
.kd-gallery-grid .item-4 {
  grid-area: item-4;
}
.kd-gallery-grid .item-5 {
  grid-area: item-5;
}
.kd-gallery-grid .item-6 {
  grid-area: item-6;
}
.kd-gallery-grid .item-7 {
  grid-area: item-7;
}
.kd-gallery-grid .item-8 {
  grid-area: item-8;
}
.kd-gallery-grid .item-9 {
  grid-area: item-9;
}
.kd-gallery-grid .item-10 {
  grid-area: item-10;
}
.kd-gallery-grid .item-11 {
  grid-area: item-11;
}
.kd-gallery-grid .item-12 {
  grid-area: item-12;
}

.kd-gallery-item {
  border-radius: 10px;
  position: relative;
  z-index: 1;
}
.kd-gallery-item::after {
  content: "";
  width: calc(100% - 15px);
  height: calc(100% - 15px);
  background: rgba(0, 94, 79, 0.7);
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 10px;
  transform: translate(-50%, -50%);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  transition: 0.5s ease-in-out;
  opacity: 0;
}
.kd-gallery-item-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 62px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #fff;
  z-index: 1;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
  z-index: 1;
  transition: 0.5s ease-in-out;
  opacity: 0;
}
.kd-gallery-item-btn:hover {
  border-color: transparent;
  background: var(--kd-clr-sd-1);
  color: #fff;
}
.kd-gallery-item:hover::after {
  opacity: 1;
}
.kd-gallery-item:hover .kd-gallery-item-btn {
  opacity: 1;
}

/* 
    gallery-end
*/
/* 
    blog-page-start
*/
.blog-page-area .item-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  padding-top: 30px;
}
.blog-page-area .item-pagination .pagination-number, .blog-page-area .item-pagination .pagination-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  width: 54px;
  height: 54px;
  border: 1px solid;
  border-color: rgba(22, 29, 57, 0.15);
  border-radius: 50%;
  font-size: 20px;
  color: var(--kd-clr-h-1);
  font-weight: 600;
  transition: 0.4s ease;
}
@media (max-width: 767px) {
  .blog-page-area .item-pagination .pagination-number, .blog-page-area .item-pagination .pagination-btn {
    width: 42px;
    height: 42px;
    font-size: 16px;
  }
}
.blog-page-area .item-pagination .pagination-number:hover {
  background-color: var(--kd-clr-pr-1);
  color: #fff;
}
.blog-page-area .item-pagination .pagination-number:is(.active) {
  background-color: var(--kd-clr-pr-1);
  color: #fff;
}
.blog-page-area .item-pagination .pagination-btn:hover {
  border-color: var(--kd-clr-pr-1);
  color: var(--kd-clr-pr-1);
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .blog-page-sidebar {
    padding-top: 50px;
  }
}
.blog-page-sidebar .sidebar-widget {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  padding: 25px 25px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-page-sidebar .sidebar-widget {
    padding: 25px 15px;
  }
}
@media (max-width: 767px) {
  .blog-page-sidebar .sidebar-widget {
    padding: 25px 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-page-sidebar .sidebar-widget {
    padding: 25px 25px;
  }
}
.blog-page-sidebar .sidebar-widget:not(:last-child) {
  margin-bottom: 30px;
}
.blog-page-sidebar .sidebar-widget .widget-title {
  font-family: var(--kd-font-pr-1);
  text-transform: capitalize;
  font-weight: 600;
  color: var(--kd-clr-h-1);
  position: relative;
  z-index: 1;
  line-height: 1.18;
  display: inline-block;
  padding-bottom: 5px;
  margin-bottom: 25px;
}
.blog-page-sidebar .sidebar-widget .widget-title::after {
  content: "";
  width: 50%;
  height: 3px;
  border-radius: 20px;
  background: var(--kd-clr-pr-1);
  position: absolute;
  left: 0;
  bottom: 0;
}
.blog-page-sidebar .sidebar-search {
  position: relative;
  z-index: 1;
}
.blog-page-sidebar .sidebar-search-input {
  width: 100%;
  height: 60px;
  border-radius: 100px;
  outline: none;
  border: none;
  padding-left: 20px;
  padding-right: 70px;
  background: #F8F8F8;
  font-size: 15px;
  color: var(--kd-clr-p-1);
}
.blog-page-sidebar .sidebar-search-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 100%;
  background: var(--kd-clr-pr-1);
  color: #fff;
  font-size: 18px;
  border-radius: 10px 100px 100px 10px;
  transition: 0.4s ease;
}
.blog-page-sidebar .sidebar-search-btn:hover {
  background: var(--kd-clr-sd-1);
}
.blog-page-sidebar .sidebar-category .inks,
.widget_categories ul li a {
  display: flex;
  align-items: center;
  font-family: var(--kd-font-pr-1);
  font-size: 18px;
  text-transform: capitalize;
  color: var(--kd-clr-h-1);
  font-weight: 500;
  line-height: 26px;
  gap: 8px;
  padding: 17px 25px;
  background: #F8F8F8;
  border-radius: 100px;
  transition: 0.4s ease;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-page-sidebar .sidebar-category .inks, .widget_categories ul li a {
    padding: 17px 15px;
  }
}
@media (max-width: 767px) {
  .blog-page-sidebar .sidebar-category .inks, .widget_categories ul li a {
    padding: 17px 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-page-sidebar .sidebar-category .inks, .widget_categories ul li a {
    padding: 17px 25px;
  }
}
.blog-page-sidebar .sidebar-category .inks:not(:last-child), .widget_categories ul li:not(:last-child) {
  margin-bottom: 6px;
}
.blog-page-sidebar .sidebar-category .inks .count {
  display: inline-block;
  color: var(--kd-clr-pr-1);
  transition: 0.4s ease;
}
.blog-page-sidebar .sidebar-category .inks i {
  margin-left: auto;
  transition: 0.4s ease;
  color: #fff;
  transform: translateX(-20px);
  opacity: 0;
}
.blog-page-sidebar .sidebar-category .inks:hover,  .widget_categories ul li a:hover{
  background: var(--kd-clr-pr-1);
  color: #fff;
}
.blog-page-sidebar .sidebar-category .inks:hover .count {
  color: #fff;
}
.blog-page-sidebar .sidebar-category .inks:hover i {
  opacity: 1;
  transform: translateX(0);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-page-sidebar .sidebar-recent-post {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 30px;
  }
}
@media (max-width: 767px) {
  .blog-page-sidebar .sidebar-recent-post {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-page-sidebar .sidebar-recent-post {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 30px;
  }
}
.blog-page-sidebar .recent-post-item:not(:last-child) {
  margin-bottom: 15px;
  border-bottom: 1px solid #F0F0F0;
  padding-bottom: 15px;
}
.blog-page-sidebar .recent-post-item .item-img {
  width: 100%;
  height: 100px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-bottom: 12px;
}
.blog-page-sidebar .recent-post-item .item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blog-page-sidebar .recent-post-item .item-img .item-date {
  position: absolute;
  left: 10px;
  bottom: 10px;
  display: inline-block;
  background: var(--kd-clr-pr-1);
  font-size: 10px;
  font-family: var(--kd-font-pr-1);
  text-transform: uppercase;
  color: #fff;
  line-height: 18px;
  padding: 3px 10px;
  border-radius: 4px;
}
.blog-page-sidebar .recent-post-item .item-link {
  font-family: var(--kd-font-pr-1);
  text-transform: capitalize;
  font-weight: 700;
  font-size: 18px;
  color: var(--kd-clr-h-1);
  line-height: 26px;
  margin-bottom: 0;
  transition: 0.3s ease;
}
.blog-page-sidebar .recent-post-item .item-link:hover {
  color: var(--kd-clr-pr-1);
}
.blog-page-sidebar .sidebar-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.blog-page-sidebar .sidebar-tags .tags-link, .tagcloud a {
  display: inline-block;
  font-family: var(--kd-clr-pr-1);
  color: var(--kd-clr-p-1);
  font-size: 16px !important;
  line-height: 20px;
  background: #F8F8F8;
  border-radius: 30px;
  padding: 7px 20px;
  flex: 0 0 auto;
  transition: 0.3s ease;
  margin-bottom: 10px;
}
.blog-page-sidebar .sidebar-tags .tags-link:hover, .tagcloud a:hover {
  background-color: var(--kd-clr-pr-1);
  color: #fff;
}

.blog-page-item-2 {
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
  margin-bottom: 30px;
}
.blog-page-item-2 .main-img {
  height: 360px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .blog-page-item-2 .main-img {
    height: 260px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-page-item-2 .main-img {
    height: 360px;
  }
}
.blog-page-item-2 .main-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 500ms;
  transform: perspective(0px) rotateX(0deg) rotateY(0deg) scaleX(1) scaleY(1);
  transform-origin: center center;
}
.blog-page-item-2 .item-content {
  padding: 30px 35px;
  padding-top: 15px;
}
@media (max-width: 767px) {
  .blog-page-item-2 .item-content {
    padding: 30px 15px;
    padding-top: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-page-item-2 .item-content {
    padding: 30px 35px;
    padding-top: 15px;
  }
}
.blog-page-item-2 .meta-author {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  padding: 10px 0;
  border-bottom: 1px solid #E6E6E8;
  margin-bottom: 22px;
}
.blog-page-item-2 .meta-author li {
  font-family: var(--kd-font-pr-1);
  color: var(--kd-clr-h-1);
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
}
.blog-page-item-2 .meta-author li i {
  display: inline-block;
  color: var(--kd-clr-pr-1);
  margin-right: 8px;
}
.blog-page-item-2 .item-title {
  font-family: var(--kd-font-pr-1);
  font-weight: 900;
  font-size: 35px;
  line-height: 1.28;
  margin-bottom: 20px;
  color: var(--kd-clr-h-1);
  transition: 0.2s linear;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .blog-page-item-2 .item-title {
    font-size: 32px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-page-item-2 .item-title {
    font-size: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-page-item-2 .item-title {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .blog-page-item-2 .item-title {
    font-size: 22px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-page-item-2 .item-title {
    font-size: 26px;
  }
}
.blog-page-item-2 .item-title:hover {
  color: var(--kd-clr-sd-1);
}
.blog-page-item-2 .item-disc {
  font-family: kd-font-pr-1;
  font-size: 20px;
  line-height: 32px;
  color: var(--kd-clr-p-1);
  margin-bottom: 20px;
}
.blog-page-item-2 .item-btn {
  font-family: var(--kd-font-pr-1);
  text-transform: uppercase;
  font-weight: 800;
  color: var(--kd-clr-pr-1);
  line-height: 30px;
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: 0.4s ease;
}
.blog-page-item-2 .item-btn i {
  display: inline-flex;
  width: 24px;
  height: 24px;
  background: var(--kd-clr-pr-1);
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease;
}
.blog-page-item-2 .item-btn:hover {
  color: var(--kd-clr-sd-1);
}
.blog-page-item-2 .item-btn:hover i {
  background: var(--kd-clr-sd-1);
  color: #000;
}
.blog-page-item-2:hover .main-img img {
  transform: perspective(600px) rotateX(0.06deg) rotateY(0deg) scaleX(1.15) scaleY(1.15);
}

.blog-details-content .main-img {
  overflow: hidden;
  height: 445px;
  border-radius: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-content .main-img {
    height: 400px;
  }
}
@media (max-width: 767px) {
  .blog-details-content .main-img {
    height: 250px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-details-content .main-img {
    height: 325px;
  }
}
.blog-details-content .main-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blog-details-content .meta-author {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  padding: 10px 0;
  border-bottom: 1px solid #E6E6E8;
  margin-bottom: 22px;
}
.blog-details-content .meta-author li {
  font-family: var(--kd-font-pr-1);
  color: var(--kd-clr-h-1);
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
}
.blog-details-content .meta-author li i {
  display: inline-block;
  color: var(--kd-clr-pr-1);
  margin-right: 8px;
}
.blog-details-content .blog-title {
  font-family: var(--kd-font-pr-1);
  font-weight: 900;
  font-size: 35px;
  line-height: 1.28;
  margin-bottom: 20px;
  color: var(--kd-clr-h-1);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .blog-details-content .blog-title {
    font-size: 32px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-content .blog-title {
    font-size: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-content .blog-title {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .blog-details-content .blog-title {
    font-size: 22px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-details-content .blog-title {
    font-size: 26px;
  }
}
.blog-details-content .blog-disc p {
  font-family: kd-font-pr-1;
  font-size: 20px;
  line-height: 32px;
  color: var(--kd-clr-p-1);
  margin-bottom: 20px;
}
.blog-details-content .blog-video {
  height: 280px;
  overflow: hidden;
  border-radius: 12px;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}
.blog-details-content .blog-video img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blog-details-content .blog-video .btn-position {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.blog-details-content .blog-video-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: var(--kd-clr-pr-1);
  color: #fff;
  font-size: 16px;
  position: relative;
  transition: 400ms var(--cube-1);
}
.blog-details-content .blog-video-btn::after {
  content: "";
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: #fff;
  opacity: 0.5;
  z-index: -1;
  animation: kd-plybtn-1 4s linear infinite;
}
.blog-details-content .blog-video-btn::before {
  content: "";
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: #fff;
  opacity: 0.5;
  z-index: -1;
  animation: kd-plybtn-1 2s linear infinite;
}
.blog-details-content .blog-video-btn:hover {
  color: var(--kd-clr-sd-1);
}
blockquote {
  background: rgba(0, 94, 78, 0.06);
  padding: 30px 35px;
  border-radius: 12px;
  margin-bottom: 30px;
}
blockquote {
  font-family: var(--kd-font-pr-1);
  font-weight: 700;
  color: var(--kd-clr-h-1);
  font-size: 20px;
  line-height: 1.45;
  margin-bottom: 20px;
}
blockquote i {
  display: inline-block;
  font-size: 40px;
  color: var(--kd-clr-pr-1);
}
blockquote cite {
  font-family: var(--kd-font-1);
  text-transform: uppercase;
  font-weight: 900;
  color: var(--kd-clr-pr-1);
  font-size: 18px;
  line-height: 30px;
  margin-top: 20px;
}
:where(.wp-block-columns.is-layout-flex){
  gap: 1em;
}
figure.wp-block-image.size-full img {
  height: 269px;
  border-radius: 10px;
  object-fit: cover;
}
.wp-block-heading {
  font-family: var(--kd-font-pr-1);
  font-weight: 900;
  font-size: 26px;
  line-height: 1.38;
  margin-bottom: 20px;
  color: var(--kd-clr-h-1);
}
.wp-block-columns.is-layout-flex.wp-container-core-columns-is-layout-1.wp-block-columns-is-layout-flex {
  margin-bottom: 10px;
}
.blog-details-content .blog-subtitle {
  font-family: var(--kd-font-pr-1);
  font-weight: 900;
  font-size: 26px;
  line-height: 1.38;
  margin-bottom: 20px;
  color: var(--kd-clr-h-1);
}
@media (max-width: 767px) {
  .blog-details-content .blog-subtitle {
    font-size: 22px;
  }
}
.blog-details-content .blog-tag {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.blog-details-content .blog-tag .title {
  font-family: var(--kd-font-pr-1);
  text-transform: capitalize;
  font-weight: 700;
  color: var(--kd-clr-h-1);
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 0;
  flex: 0 0 auto;
}
.blog-details-content .blog-tag a {
  background: #F7F7F7;
  border-radius: 100px;
  color: var(--kd-clr-p-1);
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: inline-block;
  padding: 7px 25px;
  transition: 300ms ease;
  font-family: var(--kd-font-pr-1);
  flex: 0 0 auto;
}
.blog-details-content .blog-tag a:hover {
  background: var(--kd-clr-pr-1);
  color: #fff;
}
.blog-details-content .blog-tag-share {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 25px 0;
  border-bottom: 1px solid rgba(17, 16, 16, 0.1);
  margin-bottom: 30px;
}
.blog-details-content .blog-share {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.blog-details-content .blog-share .title {
  font-family: var(--kd-font-pr-1);
  text-transform: capitalize;
  font-weight: 700;
  color: var(--kd-clr-h-1);
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 0;
  flex: 0 0 auto;
}
.blog-details-content .blog-share .link {
  font-family: var(--kd-font-pr-1);
  text-transform: capitalize;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: var(--kd-clr-p-1);
  transition: 0.3s ease;
}
.blog-details-content .blog-share .link:hover {
  color: var(--kd-clr-pr-1);
}
.blog-details-content .blog-comment-item {
  background: #F7F7F7;
  border: 1px solid rgba(6, 13, 55, 0.08);
  border-radius: 5px;
  padding: 30px;
  display: flex;
  gap: 30px;
}
@media (max-width: 767px) {
  .blog-details-content .blog-comment-item {
    flex-wrap: wrap;
  }
}
.blog-details-content .blog-comment-item .img-wrap {
  flex: 0 0 auto;
  width: 140px;
  height: 140px;
  overflow: hidden;
  border-radius: 50%;
}
.blog-details-content .blog-comment-item .img-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blog-details-content .blog-comment-item .name {
  font-family: var(--kd-font-pr-1);
  font-size: 24px;
  line-height: 26px;
  text-transform: capitalize;
  font-weight: 900;
  color: var(--kd-clr-h-1);
  margin-bottom: 15px;
  display: inline-block;
}
.blog-details-content .blog-comment-item .comment-text {
  font-family: var(--kd-font-pr-1);
  font-size: 18px;
  font-weight: 400;
  color: var(--kd-clr-p-1);
  line-height: 30px;
  margin-bottom: 20px;
}
.blog-details-content .blog-comment-item .social-link li {
  display: inline-block;
  margin-right: 10px;
}
.blog-details-content .blog-comment-item .social-link li a {
  font-size: 20px;
  line-height: 1;
  color: var(--kd-clr-pr-1);
  transition: 0.3s ease;
}
.blog-details-content .blog-comment-item .social-link li a:hover {
  color: var(--kd-clr-sd-1);
}
.blog-details-content .blog-post-next-btn {
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
  border-bottom: 1px solid rgba(17, 16, 16, 0.1);
  gap: 15px;
  flex-wrap: wrap;
}
.blog-details-content .blog-post-next-btn .next-prv-btn {
  font-family: var(--kd-font-pr-1);
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  color: var(--kd-clr-p-1);
  text-transform: uppercase;
  transition: 400ms ease;
}
.blog-details-content .blog-post-next-btn .next-prv-btn i {
  margin: 0 5px;
  font-weight: 600;
}
.blog-details-content .blog-post-next-btn .next-prv-btn:hover {
  transform: scale(97%);
  color: var(--kd-clr-pr-1);
}
.blog-details-content .blog-comments-wrap {
  padding: 40px 0;
  border-bottom: 1px solid rgba(17, 16, 16, 0.1);
  margin-bottom: 30px;
}
.blog-details-content .blog-comments-wrap .comments-title {
  font-family: var(--kd-font-pr-1);
  font-weight: 900;
  font-size: 32px;
  line-height: 1.25;
  color: var(--kd-clr-h-1);
  margin-bottom: 25px;
}
.blog-details-content .comments-box-single {
  display: flex;
  gap: 25px;
  padding: 30px;
}
@media (max-width: 767px) {
  .blog-details-content .comments-box-single {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-details-content .comments-box-single {
    flex-wrap: nowrap;
  }
}
.blog-details-content .comments-box-single:not(:last-child) {
  border-bottom: 1px solid rgba(17, 16, 16, 0.1);
}
.blog-details-content .comments-box-single .person-img {
  flex: 0 0 auto;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
}
.blog-details-content .comments-box-single .person-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blog-details-content .comments-box-single .heading-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.blog-details-content .comments-box-single .name {
  font-family: var(--kd-font-pr-1);
  display: inline-block;
  font-size: 20px;
  font-weight: 800;
  line-height: 30px;
  text-transform: uppercase;
  color: var(--kd-clr-h-1);
  margin-bottom: 5px;
}
.blog-details-content .comments-box-single .date {
  font-family: var(--kd-font-pr-1);
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: var(--kd-clr-pr-1);
  opacity: 1;
}
.blog-details-content .comments-box-single .reply-btn {
  font-family: var(--kd-font-pr-1);
  font-size: 16px;
  font-weight: 900;
  line-height: 20px;
  text-transform: capitalize;
  color: var(--kd-clr-h-1);
  transition: 200ms ease;
}
.blog-details-content .comments-box-single .reply-btn:hover {
  color: var(--kd-clr-sd-1);
}
.blog-details-content .comments-box-single .comment-text {
  font-family: var(--kd-font-pr-1);
  font-size: 18px;
  line-height: 30px;
  color: var(--kd-clr-p-1);
  font-weight: 400;
  margin-bottom: 0;
  width: 100%;
}
.blog-details-content .comments-box-wrap {
  background: #F7F7F7;
  border-radius: 5px;
}
.blog-details-content .comments-box-wrap:not(:last-child) {
  margin-bottom: 20px;
}
.blog-details-content .comments-box-wrap .children {
  padding-left: 130px;
}
@media (max-width: 767px) {
  .blog-details-content .comments-box-wrap .children {
    padding-left: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-details-content .comments-box-wrap .children {
    padding-left: 50px;
  }
}
.blog-details-content .comments-box-wrap .children .comments-box-single {
  border-bottom: none;
}
.blog-details-content .contact-form-wrap, .comments-box-wrap .comment-respond {
  background: #F7F7F7;
  border-radius: 10px;
  padding: 40px 40px;
}
@media (max-width: 767px) {
  .blog-details-content .contact-form-wrap {
    padding: 45px 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-details-content .contact-form-wrap {
    padding: 45px 40px;
  }
}
.blog-details-content .contact-form-wrap .title, .comment-reply-title {
  font-family: var(--kd-font-pr-1);
  font-size: 35px;
  font-weight: 800;
  text-transform: capitalize;
  color: var(--kd-clr-h-1);
  line-height: 1.14;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .blog-details-content .contact-form-wrap .title, .comment-reply-title {
    font-size: 25px;
  }
}

.blog-details-content .contact-form input, 
.comments-box-wrap input, 
.blog-details-content .contact-form .option-selector, 
.blog-details-content .contact-form textarea,
.comments-box-wrap textarea {
  width: 100%;
  height: 62px;
  border-radius: 100px;
  outline: none;
  border: none;
  background: #fff;
  font-size: 18px;
  font-weight: 500;
  color: var(--kd-clr-p-1);
  text-transform: capitalize;
  padding: 0 30px;
  margin-bottom: 20px;
}
.comment-form-cookies-consent {
  display: none;
}
.contact-form-wrap .form-submit, p.form-submit {
  display: none;
}
a#cancel-comment-reply-link {
  font-size: 16px;
  margin-left: 15px;
}
.blog-details-content .contact-form textarea {
  height: 178px;
  padding-top: 15px;
  border-radius: 10px;
  grid-column-start: 1;
  grid-column-end: -1;
}
.blog-details-content .contact-form .btn-wrap {
  grid-column-start: 1;
  grid-column-end: -1;
}
.blog-details-content .contact-form-btn {
  display: inline-block;
  font-family: var(--kd-font-pr-1);
  color: #fff;
  font-weight: 800;
  font-size: 15px;
  line-height: 1;
  background: var(--kd-clr-pr-1);
  border-radius: 30px;
  padding: 20px 31px;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
  transition: 0.3s var(--cube-1);
}
.blog-details-content .contact-form-btn .icon {
  display: inline-block;
  margin-left: 5px;
  transition: 0.3s var(--cube-1);
}
.blog-details-content .contact-form-btn .text {
  display: inline-block;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: -3px;
}
.blog-details-content .contact-form-btn .text::after {
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  display: block;
  transition: 0.5s;
  position: absolute;
  background: transparent;
  color: #000;
  content: attr(data-back);
  transform: translateX(-100%);
}
.blog-details-content .contact-form-btn .text::before {
  top: 0;
  left: 0;
  opacity: 1;
  display: block;
  transition: 0.5s;
  position: relative;
  background: var(--blta-pr-1);
  color: #fff;
  content: attr(data-front);
  transform: translateX(0);
}
.blog-details-content .contact-form-btn:hover {
  color: #fff;
  background: var(--kd-clr-sd-1);
}
.blog-details-content .contact-form-btn:hover .icon {
  color: #000;
}
.blog-details-content .contact-form-btn:hover .text::after {
  opacity: 1;
  transform: translateX(0);
}
.blog-details-content .contact-form-btn:hover .text::before {
  opacity: 0;
  transform: translateX(100%);
}

/* 
    blog-page-end
*/
/* 
    contact-us-start
*/
.kd-contact-us-img {
  position: relative;
  z-index: 1;
  margin-left: -80px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-contact-us-img {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-contact-us-img {
    margin-left: 0px;
    margin-bottom: 50px;
  }
}
.kd-contact-us-img .il-img {
  position: absolute;
  left: -70px;
  top: 0;
  width: 40%;
}
.kd-contact-us-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 30px;
}
@media (max-width: 767px) {
  .kd-contact-us-form {
    grid-template-columns: 1fr;
  }
}
.kd-contact-us-form input, .kd-contact-us-form textarea {
  width: 100%;
  height: 62px;
  border-radius: 100px;
  outline: none;
  border: none;
  background: #F7F7F7;
  font-size: 18px;
  font-weight: 500;
  color: var(--kd-clr-p-1);
  text-transform: capitalize;
  padding: 0 30px;
}
.kd-contact-us-form textarea {
  height: 178px;
  padding-top: 15px;
  border-radius: 10px;
  grid-column-start: 1;
  grid-column-end: -1;
}
.kd-contact-us-form .btn-wrap {
  grid-column-start: 1;
  grid-column-end: -1;
}
.kd-contact-us-form-btn {
  display: inline-block;
  font-family: var(--kd-font-pr-1);
  color: #fff;
  font-weight: 800;
  font-size: 15px;
  line-height: 1;
  background: var(--kd-clr-pr-1);
  border-radius: 30px;
  padding: 20px 31px;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
  transition: 0.3s var(--cube-1);
}
.kd-contact-us-form-btn .icon {
  display: inline-block;
  margin-left: 5px;
  transition: 0.3s var(--cube-1);
}
.kd-contact-us-form-btn .text {
  display: inline-block;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: -3px;
}
.kd-contact-us-form-btn .text::after {
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  display: block;
  transition: 0.5s;
  position: absolute;
  background: transparent;
  color: #000;
  content: attr(data-back);
  transform: translateX(-100%);
}
.kd-contact-us-form-btn .text::before {
  top: 0;
  left: 0;
  opacity: 1;
  display: block;
  transition: 0.5s;
  position: relative;
  background: var(--blta-pr-1);
  color: #fff;
  content: attr(data-front);
  transform: translateX(0);
}
.kd-contact-us-form-btn:hover {
  color: #fff;
  background: var(--kd-clr-sd-1);
}
.kd-contact-us-form-btn:hover .icon {
  color: #000;
}
.kd-contact-us-form-btn:hover .text::after {
  opacity: 1;
  transform: translateX(0);
}
.kd-contact-us-form-btn:hover .text::before {
  opacity: 0;
  transform: translateX(100%);
}

.kd-contact-us-map {
  width: 100%;
  height: 545px;
}
.kd-contact-us-map iframe {
  width: 100%;
  height: 100%;
}

/* 
    contact-us-end
*/
/* 
    privacy-page-start
*/
.privacy-page-content .title {
  font-family: var(--kd-font-pr-1);
  font-size: 30px;
  line-height: 1.33;
  font-weight: 900;
  color: var(--kd-clr-h-1);
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .privacy-page-content .title {
    font-size: 22px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .privacy-page-content .title {
    font-size: 26px;
  }
}
.privacy-page-content .disc {
  font-family: var(--kd-font-pr-1);
  font-size: 20px;
  line-height: 32px;
  color: var(--kd-clr-p-1);
  margin-bottom: 25px;
}
.privacy-page-content .list-text {
  margin-bottom: 25px;
}
.privacy-page-content .list-text li {
  font-family: var(--kd-font-pr-1);
  font-size: 20px;
  line-height: 32px;
  color: var(--kd-clr-p-1);
  position: relative;
  z-index: 1;
  padding-left: 20px;
}
.privacy-page-content .list-text li:not(:last-child) {
  margin-bottom: 16px;
}
.privacy-page-content .list-text li::after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--kd-clr-pr-1);
  position: absolute;
  left: 0;
  top: 12px;
}

/* 
    privacy-page-end
*/
/* 
    error-start
*/
.error-page-content {
  text-align: center;
}
.error-page-content img {
  margin-bottom: 50px;
}
.error-page-content .title {
  font-family: var(--kd-font-pr-1);
  font-size: 55px;
  line-height: 1.18;
  color: var(--kd-clr-h-1);
  font-weight: 900;
  margin-bottom: 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .error-page-content .title {
    font-size: 45px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-page-content .title {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .error-page-content .title {
    font-size: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .error-page-content .title {
    font-size: 35px;
  }
}
.error-page-content .error-btn {
  display: inline-block;
  font-family: var(--kd-font-pr-1);
  color: #fff;
  font-weight: 800;
  font-size: 15px;
  line-height: 1;
  background: var(--kd-clr-pr-1);
  border-radius: 30px;
  padding: 20px 31px;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
  transition: 0.3s var(--cube-1);
}
.error-page-content .error-btn .icon {
  display: inline-block;
  margin-left: 5px;
  transition: 0.3s var(--cube-1);
}
.error-page-content .error-btn .text {
  display: inline-block;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: -3px;
}
.error-page-content .error-btn .text::after {
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  display: block;
  transition: 0.5s;
  position: absolute;
  background: transparent;
  color: #000;
  content: attr(data-back);
  transform: translateX(-100%);
}
.error-page-content .error-btn .text::before {
  top: 0;
  left: 0;
  opacity: 1;
  display: block;
  transition: 0.5s;
  position: relative;
  background: var(--blta-pr-1);
  color: #fff;
  content: attr(data-front);
  transform: translateX(0);
}
.error-page-content .error-btn:hover {
  color: #fff;
  background: var(--kd-clr-sd-1);
}
.error-page-content .error-btn:hover .icon {
  color: #000;
}
.error-page-content .error-btn:hover .text::after {
  opacity: 1;
  transform: translateX(0);
}
.error-page-content .error-btn:hover .text::before {
  opacity: 0;
  transform: translateX(100%);
}

/* 
    error-end
*/
/* 
   footer-1-start
*/
.kd-footer-1-top {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: flex-end;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-footer-1-top {
    grid-template-columns: 1fr;
    justify-items: self-start;
    gap: 30px;
  }
}
.kd-footer-1-top-subtitle {
  font-size: 18px;
  margin-bottom: 14px;
}
.kd-footer-1-top-title {
  font-size: 35px;
  line-height: 1.14;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-footer-1-top-title {
    font-size: 26px;
  }
}
.kd-footer-1-top-app-store {
  width: 515px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 15px;
}
@media (max-width: 767px) {
  .kd-footer-1-top-app-store {
    width: 100%;
  }
}
.kd-footer-1-top-app-store .link {
  display: block;
  width: 100%;
  background: #fff;
  border-radius: 50px;
  transition: 0.4s var(--cube-1);
}
.kd-footer-1-top-app-store .link .txaa-tilt_scale {
  display: block;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  height: 90px;
  border-radius: 50px;
  padding: 15px;
}
.kd-footer-1-top-app-store .link:nth-child(2) .txaa-tilt_scale {
  background: #000;
}
.kd-footer-1-top-app-store .link:hover {
  transform: scale(95%);
}
.kd-footer-1-line {
  display: block;
  width: 100%;
  height: 2px;
  background: #01322f;
}
.kd-footer-1-widget-wrap {
  display: grid;
  gap: 100px;
  grid-template-columns: 1fr 1.4fr 1fr;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-footer-1-widget-wrap {
    gap: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-footer-1-widget-wrap {
    gap: 50px 30px;
    grid-template-columns: 1fr 1.4fr;
  }
}
@media (max-width: 767px) {
  .kd-footer-1-widget-wrap {
    gap: 50px 30px;
    grid-template-columns: 1fr;
  }
}
.kd-footer-1-widget-title {
  font-size: 27px;
  margin-bottom: 45px;
}
.kd-footer-1-widget-disc {
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 20px;
}
.kd-footer-1-widget-update {
  font-size: 16px;
  font-weight: 700;
}
.kd-footer-1-contact .item {
  font-family: var(--kd-font-pr-1);
  font-weight: 700;
  color: #fff;
  font-size: 16px;
  line-height: 25px;
  display: flex;
  gap: 10px;
}
.kd-footer-1-contact .item:not(:last-child) {
  margin-bottom: 18px;
}
.kd-footer-1-contact .item .icon {
  width: 37px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #456c48;
  border-radius: 50%;
  font-size: 14px;
  border-style: dashed;
  flex: 0 0 auto;
}
.kd-footer-1-contact .item a {
  align-items: center;
  gap: 10px;
  transition: 0.2s var(--cube-1);
}
.kd-footer-1-contact .item a:hover {
  color: var(--kd-clr-sd-1);
}
.kd-footer-1-contact .item a:hover .icon {
  animation: 1s var(--cube-1);
}
.kd-footer-1-menu {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 767px) {
  .kd-footer-1-menu {
    grid-template-columns: 1fr;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-footer-1-menu {
    grid-template-columns: 1fr 1fr;
  }
}
.kd-footer-1-menu li:not(:last-child) {
  margin-bottom: 5px;
}
.kd-footer-1-menu li a {
  font-family: var(--kd-font-pr-1);
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  color: #fff;
  line-height: 1;
  transition: 0.2s var(--cube-1);
}
.kd-footer-1-menu li a i {
  font-size: 14px;
  display: inline-block;
  margin-right: 10px;
}
.kd-footer-1-menu li a:hover {
  color: var(--kd-clr-sd-1);
}
.kd-footer-1-menu li a:hover i {
  animation: 1s kd-jello;
}
.kd-footer-1-form-input {
  width: 100%;
  height: 50px;
  border-radius: 40px;
  border: none;
  outline: none;
  font-size: 16px;
  color: var(--kd-clr-pr-1);
  font-weight: 500;
  padding: 0 30px;
  margin-bottom: 8px;
}
.kd-footer-1-form-btn {
  width: 100%;
  height: 50px;
  text-transform: capitalize;
  font-size: 17px;
  padding: 0;
}
.kd-footer-1-copyright {
  background: #01312e;
}
.kd-footer-1-copyright-text {
  font-size: 15px;
  font-weight: 500;
}
.kd-footer-1-copyright-text a {
  display: inline-block;
  transition: 0.2s var(--cube-1);
}
.kd-footer-1-copyright-text a:hover {
  color: var(--kd-clr-sd-1);
}

/* 
   footer-1-end
*/
/* 
   footer-2-start
*/
.kd-footer-2-widget-wrap {
  display: grid;
  grid-template-columns: 1fr auto 1fr auto 1.3fr;
  gap: 50px 70px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .kd-footer-2-widget-wrap {
    gap: 50px 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-footer-2-widget-wrap {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-footer-2-widget-wrap {
    grid-template-columns: 1.2fr 1fr;
    gap: 50px 30px;
  }
}
@media (max-width: 767px) {
  .kd-footer-2-widget-wrap {
    grid-template-columns: 1fr;
    gap: 50px 30px;
  }
}
.kd-footer-2-widget-line {
  display: block;
  width: 2px;
  height: 100%;
  background: #015657;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-footer-2-widget-line {
    display: none;
  }
}
.kd-footer-2-line {
  display: block;
  width: 100%;
  height: 2px;
  background: #015657;
}
.kd-footer-2-social {
  gap: 10px;
}
.kd-footer-2-social-link {
  width: 44px;
  height: 44px;
  background: #fff;
  border-radius: 50%;
  color: #26263e;
  font-size: 18px;
  transition: 0.3s var(--cube-1);
}
.kd-footer-2-social-link:hover {
  background: var(--kd-clr-sd-1);
  color: #000;
}

/* 
   footer-2-end
*/
/* 
   footer-3-end
*/
.kd-gallery-3-item-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.kd-gallery-3-item {
  height: 130px;
  width: 180px;
  position: relative;
  z-index: 1;
}
.kd-gallery-3-item .item-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 94, 79, 0.7098039216);
  position: absolute;
  top: 0;
  left: 0;
  font-size: 30px;
  color: #fff;
  transition: 0.3s ease-in-out;
  transform: scale(1.5);
  opacity: 0;
}
.kd-gallery-3-item:hover .item-btn {
  transform: scale(1);
  opacity: 1;
}

.kd-footer-3-top {
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  gap: 30px 65px;
  align-items: center;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-footer-3-top {
    gap: 30px 35px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-footer-3-top {
    gap: 30px 25px;
  }
}
@media (max-width: 767px) {
  .kd-footer-3-top {
    grid-template-columns: 1fr;
    padding-top: 34px;
  }
}
.kd-footer-3-top .line {
  width: 1px;
  height: 100%;
  background: #005b56;
}
@media (max-width: 767px) {
  .kd-footer-3-top .line {
    display: none;
  }
}
.kd-footer-3-top-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  flex-wrap: wrap;
}
.kd-footer-3-top-left .kd-footer-2-social {
  gap: 35px;
}
.kd-footer-3-top-left .kd-footer-2-social-link {
  width: unset;
  height: unset;
  background: transparent;
  border-radius: unset;
  color: #fff;
  font-size: 18px;
  transition: 0.3s var(--cube-1);
}
.kd-footer-3-top-left .kd-footer-2-social-link:hover {
  color: var(--kd-clr-sd-1);
}
.kd-footer-3-form {
  position: relative;
  z-index: 1;
}
.kd-footer-3-form-box {
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  gap: 10px;
  padding: 34px 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-footer-3-form-box {
    grid-template-columns: 1fr;
  }
}
.kd-footer-3-form-box .form-title {
  font-size: 20px;
}
.kd-footer-3-form-input {
  height: 57px;
  background: var(--kd-clr-pr-1);
  border-radius: 30px;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--kd-font-pr-1);
  color: #fff;
  padding-left: 35px;
  padding-right: 130px;
  width: 100%;
}
.kd-footer-3-form-btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background: #027972;
  padding: 20px 36px;
}
.kd-footer-3-form-btn .text::before {
  color: #fff;
}
.kd-footer-3-form-btn .text::after {
  color: #000;
}
.kd-footer-3-form-btn:hover {
  background: var(--kd-clr-sd-1);
}
.kd-footer-3-line {
  display: block;
  width: 100%;
  height: 1px;
  background: #005b56;
}
.kd-footer-3-logo {
  display: inline-block;
  margin-bottom: 25px;
}
.kd-footer-3-widget-wrap {
  display: grid;
  grid-template-columns: 1.6fr 1fr 1fr 0.7fr;
  gap: 50px 70px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-footer-3-widget-wrap {
    gap: 50px 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-footer-3-widget-wrap {
    grid-template-columns: 1.5fr 1fr;
    gap: 50px 30px;
  }
}
@media (max-width: 767px) {
  .kd-footer-3-widget-wrap {
    grid-template-columns: 1fr;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-footer-3-widget-wrap {
    grid-template-columns: 1.5fr 1fr;
    gap: 50px 30px;
  }
}
.kd-footer-3-widget-title {
  font-size: 22px;
  position: relative;
  z-index: 1;
  display: inline-block;
  margin-bottom: 38px;
}
.kd-footer-3-widget-title::after {
  content: "";
  width: 100%;
  height: 1px;
  background: #fff;
  position: absolute;
  left: 0;
  bottom: -1px;
}
.kd-footer-3-widget .widget-disc {
  font-size: 18px;
  line-height: 28px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 25px;
}
.kd-footer-3-menu li:not(:last-child) {
  margin-bottom: 12px;
}
.kd-footer-3-menu li a {
  font-size: 18px;
  font-family: var(--kd-font-pr-1);
  color: #d1d1d1;
  font-weight: 500;
  text-transform: capitalize;
  display: inline-block;
  position: relative;
  z-index: 1;
  transition: 0.3s var(--cube-1);
}
.kd-footer-3-menu li a::after {
  content: "";
  width: 0%;
  height: 1px;
  background-color: var(--kd-clr-sd-1);
  position: absolute;
  left: auto;
  right: 0;
  bottom: 4px;
  transition: 0.3s var(--cube-1);
}
.kd-footer-3-menu li a:hover {
  transform: translate(5px);
  color: var(--kd-clr-sd-1);
}
.kd-footer-3-menu li a:hover::after {
  width: 100%;
  right: auto;
  left: 0;
}
.kd-footer-3-call {
  display: flex;
  align-items: center;
  gap: 10px;
}
.kd-footer-3-call .icon {
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  font-size: 20px;
  color: var(--kd-clr-sd-1);
}
.kd-footer-3-call .subtitle {
  color: var(--kd-clr-sd-1);
  font-size: 18px;
}
.kd-footer-3-call .link {
  color: #fff;
  font-size: 22px;
  display: inline-block;
  transition: 0.3s ease-in-out;
}
.kd-footer-3-call .link:hover {
  color: var(--kd-clr-sd-1);
  transform: translateX(3px);
}
.kd-footer-3-call:hover .icon {
  animation: 1s kd-jello;
}

/* 
   footer-3-end
*/
/* 
   footer-4-start
*/
.kd-footer-4-area:is(.has-mt-minus) {
  margin-top: -280px;
  padding-top: 280px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-footer-4-area:is(.has-mt-minus) {
    padding-top: 330px;
  }
}
.kd-footer-4-widget {
  padding-top: 90px;
  padding-bottom: 75px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-footer-4-widget {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
.kd-footer-4-widget-wrap {
  display: grid;
  grid-template-columns: 1fr auto 1.5fr 0.8fr 1fr;
  gap: 50px 65px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .kd-footer-4-widget-wrap {
    gap: 50px 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kd-footer-4-widget-wrap {
    gap: 50px 30px;
    grid-template-columns: 1fr auto 1.2fr 0.8fr 1fr;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kd-footer-4-widget-wrap {
    gap: 50px 30px;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .kd-footer-4-widget-wrap {
    gap: 50px 30px;
    grid-template-columns: 1fr;
    padding-bottom: 50px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kd-footer-4-widget-wrap {
    gap: 50px 30px;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 50px;
  }
}
.kd-footer-4-widget-wrap .line {
  display: block;
  width: 1px;
  height: 100%;
  background: #fff;
  opacity: 15%;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .kd-footer-4-widget-wrap .line {
    display: none;
  }
}
.kd-footer-4-widget-title {
  font-size: 24px;
  line-height: 1.58;
  margin-bottom: 30px;
}
.kd-footer-4-widget .widget-disc {
  margin-bottom: 30px;
}
.kd-footer-4-widget .kd-footer-3-form {
  max-width: 274px;
}
.kd-footer-4-widget .kd-footer-3-form-input {
  height: 46px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 0;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  font-family: var(--kd-font-barlow);
  color: #fff;
  padding-left: 20px;
  padding-right: 70px;
  width: 100%;
}
.kd-footer-4-widget .kd-footer-3-form-btn {
  background: var(--kd-clr-pr-4);
  padding: 0px 13px;
  color: #fff;
  transition: 0.3s ease;
}
.kd-footer-4-widget .kd-footer-3-form-btn:hover {
  background: var(--kd-clr-sd-4);
}
.kd-footer-4-widget .kd-footer-2-social-link {
  background: transparent;
  color: #fff;
  font-size: 18px;
  border: 1px solid #fff;
  opacity: 80%;
}
.kd-footer-4-widget .kd-footer-2-social-link:hover {
  border-color: transparent;
  background: var(--kd-clr-pr-4);
  opacity: 100%;
}
.kd-footer-4-widget .kd-footer-1-contact .item {
  font-family: var(--kd-font-barlow);
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  line-height: 26px;
  align-items: baseline;
}
.kd-footer-4-widget .kd-footer-1-contact .item .icon {
  color: var(--kd-clr-pr-4);
  width: unset;
  height: unset;
  display: flex;
  align-items: unset;
  justify-content: unset;
  border: 0;
  border-radius: unset;
  font-size: 14px;
  border-style: none;
  flex: 0 0 auto;
}
.kd-footer-4-widget .kd-footer-1-contact .item a:hover {
  color: var(--kd-clr-pr-4);
}
.kd-footer-4-menu li {
  margin-bottom: 12px;
}
.kd-footer-4-menu li a {
  font-family: var(--kd-font-barlow);
  font-size: 18px;
  text-transform: capitalize;
  display: inline-block;
  color: #fff;
  opacity: 80%;
  line-height: 30px;
  position: relative;
  z-index: 1;
  transition: 0.3s var(--cube-1);
}
.kd-footer-4-menu li a::after {
  content: "";
  width: 0%;
  height: 1px;
  background-color: var(--kd-clr-pr-4);
  position: absolute;
  left: auto;
  right: 0;
  bottom: 4px;
  transition: 0.3s var(--cube-1);
}
.kd-footer-4-menu li a:hover {
  transform: translate(5px);
  color: var(--kd-clr-pr-4);
  opacity: 100%;
}
.kd-footer-4-menu li a:hover::after {
  width: 100%;
  right: auto;
  left: 0;
}
.kd-footer-4-open-hours .title {
  opacity: 100%;
  font-weight: 500;
  text-decoration: underline;
  margin-bottom: 12px;
}
.kd-footer-4-open-hours li {
  font-size: 18px;
  font-family: var(--kd-font-barlow);
  text-transform: capitalize;
  line-height: 26px;
  color: #fff;
  opacity: 80%;
}
.kd-footer-4-line {
  display: block;
  width: 100%;
  height: 1px;
  background: #fff;
  opacity: 15%;
}
.kd-footer-4-copyright {
  gap: 10px 30px;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .kd-footer-4-copyright {
    justify-content: center;
  }
}
.kd-footer-4-copyright-text {
  font-size: 16px;
}
.kd-footer-4-copyright a {
  display: inline-block;
  transition: 0.3s ease;
}
.kd-footer-4-copyright a:hover {
  color: var(--kd-clr-pr-4);
}
.kd-footer-4-link {
  display: flex;
  align-items: center;
  gap: 10px 30px;
  flex-wrap: wrap;
}
.kd-footer-4-link li a {
  font-family: var(--kd-font-barlow);
  text-transform: capitalize;
  font-size: 16px;
  line-height: 26px;
  transition: 0.3s ease;
  color: #fff;
}
.kd-footer-4-link li a:hover {
  color: var(--kd-clr-pr-4);
}

/* 
   footer-4-end
*/
@media (max-width: 767px){
    .mob-d-none{
      display: none !important;
    }
    .breadcrumb-area {
      padding: 0 !important;
      height: 107px !important;
      margin-top: 146px !important;
    }
    .blog-page-sidebar .sidebar-category .inks, .widget_categories ul li a {
        border-radius: 0 !important;
    }
    .kd-price-1-tabs-btn .nav-link{
      font-size: 20px !important;
    }
    .kd-price-1-left{
      padding: 12px !important;
    }
    .elementor-column.elementor-col-50.elementor-top-column.elementor-element.elementor-element-4203749{
      padding-left: 0 !important;
    }
    .breadcrumb-title {
      font-size: 50px;
      margin-top: 20px;
  }
  }

  