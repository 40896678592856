.dialog-widget-content {
    background-color: var(--e-a-bg-default);
    position: absolute;
    border-radius: 3px;
    box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, .2);
    overflow: hidden
}

.dialog-message {
    line-height: 1.5;
    box-sizing: border-box
}

.dialog-close-button {
    cursor: pointer;
    position: absolute;
    margin-block-start: 15px;
    right: 15px;
    color: var(--e-a-color-txt);
    font-size: 15px;
    line-height: 1;
    transition: var(--e-a-transition-hover)
}

.dialog-close-button:hover {
    color: var(--e-a-color-txt-hover)
}

.dialog-prevent-scroll {
    overflow: hidden;
    max-height: 100vh
}

.dialog-type-lightbox {
    position: fixed;
    height: 100%;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .8);
    z-index: 9999;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.elementor-editor-active .elementor-popup-modal {
    background-color: initial
}

.dialog-type-alert .dialog-widget-content,
.dialog-type-confirm .dialog-widget-content {
    margin: auto;
    width: 400px;
    padding: 20px
}

.dialog-type-alert .dialog-header,
.dialog-type-confirm .dialog-header {
    font-size: 15px;
    font-weight: 500
}

.dialog-type-alert .dialog-header:after,
.dialog-type-confirm .dialog-header:after {
    content: "";
    display: block;
    border-block-end: var(--e-a-border);
    padding-block-end: 10px;
    margin-block-end: 10px;
    margin-inline-start: -20px;
    margin-inline-end: -20px
}

.dialog-type-alert .dialog-message,
.dialog-type-confirm .dialog-message {
    min-height: 50px
}

.dialog-type-alert .dialog-buttons-wrapper,
.dialog-type-confirm .dialog-buttons-wrapper {
    padding-block-start: 10px;
    display: flex;
    justify-content: flex-end;
    gap: 15px
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;
    padding: 8px 16px;
    outline: none;
    border: none;
    border-radius: var(--e-a-border-radius);
    background-color: var(--e-a-btn-bg);
    color: var(--e-a-btn-color-invert);
    transition: var(--e-a-transition-hover)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:hover {
    border: none
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:hover {
    background-color: var(--e-a-btn-bg-hover);
    color: var(--e-a-btn-color-invert)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button:active,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:active {
    background-color: var(--e-a-btn-bg-active)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button:not([disabled]),
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:not([disabled]) {
    cursor: pointer
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button:disabled,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:disabled {
    background-color: var(--e-a-btn-bg-disabled);
    color: var(--e-a-btn-color-disabled)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button:not(.elementor-button-state) .elementor-state-icon,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:not(.elementor-button-state) .elementor-state-icon {
    display: none
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-cancel,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-btn-txt,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-cancel,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-btn-txt {
    background: transparent;
    color: var(--e-a-color-txt)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-cancel:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-cancel:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-btn-txt:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-btn-txt:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-cancel:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-cancel:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-btn-txt:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-btn-txt:hover {
    background: var(--e-a-bg-hover);
    color: var(--e-a-color-txt-hover)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-cancel:disabled,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-btn-txt:disabled,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-cancel:disabled,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-btn-txt:disabled {
    background: transparent;
    color: var(--e-a-color-txt-disabled)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-btn-txt-border,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-btn-txt-border {
    border: 1px solid var(--e-a-color-txt-muted)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-success,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.elementor-button-success,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-success,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.elementor-button-success {
    background-color: var(--e-a-btn-bg-success)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-success:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-success:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.elementor-button-success:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.elementor-button-success:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-success:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-success:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.elementor-button-success:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.elementor-button-success:hover {
    background-color: var(--e-a-btn-bg-success-hover)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-ok,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-take_over,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-primary,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-ok,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-take_over,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-primary {
    background-color: var(--e-a-btn-bg-primary);
    color: var(--e-a-btn-color)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-ok:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-ok:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-take_over:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-take_over:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-primary:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-primary:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-ok:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-ok:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-take_over:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-take_over:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-primary:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-primary:hover {
    background-color: var(--e-a-btn-bg-primary-hover);
    color: var(--e-a-btn-color)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-cancel.dialog-take_over,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-ok.dialog-cancel,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-btn-txt.dialog-ok,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-btn-txt.dialog-take_over,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-primary.dialog-cancel,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-primary.e-btn-txt,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-cancel.dialog-take_over,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-ok.dialog-cancel,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-btn-txt.dialog-ok,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-btn-txt.dialog-take_over,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-primary.dialog-cancel,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-primary.e-btn-txt {
    background: transparent;
    color: var(--e-a-color-primary-bold)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-cancel.dialog-take_over:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-cancel.dialog-take_over:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-ok.dialog-cancel:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-ok.dialog-cancel:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-btn-txt.dialog-ok:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-btn-txt.dialog-ok:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-btn-txt.dialog-take_over:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-btn-txt.dialog-take_over:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-primary.dialog-cancel:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-primary.dialog-cancel:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-primary.e-btn-txt:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-primary.e-btn-txt:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-cancel.dialog-take_over:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-cancel.dialog-take_over:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-ok.dialog-cancel:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-ok.dialog-cancel:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-btn-txt.dialog-ok:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-btn-txt.dialog-ok:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-btn-txt.dialog-take_over:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-btn-txt.dialog-take_over:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-primary.dialog-cancel:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-primary.dialog-cancel:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-primary.e-btn-txt:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-primary.e-btn-txt:hover {
    background: var(--e-a-bg-primary)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-accent,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.go-pro,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-accent,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.go-pro {
    background-color: var(--e-a-btn-bg-accent)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-accent:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-accent:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.go-pro:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.go-pro:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-accent:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-accent:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.go-pro:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.go-pro:hover {
    background-color: var(--e-a-btn-bg-accent-hover)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-accent:active,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.go-pro:active,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-accent:active,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.go-pro:active {
    background-color: var(--e-a-btn-bg-accent-active)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-info,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.elementor-button-info,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-info,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.elementor-button-info {
    background-color: var(--e-a-btn-bg-info)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-info:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-info:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.elementor-button-info:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.elementor-button-info:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-info:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-info:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.elementor-button-info:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.elementor-button-info:hover {
    background-color: var(--e-a-btn-bg-info-hover)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-warning,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.elementor-button-warning,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-warning,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.elementor-button-warning {
    background-color: var(--e-a-btn-bg-warning)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-warning:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-warning:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.elementor-button-warning:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.elementor-button-warning:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-warning:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-warning:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.elementor-button-warning:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.elementor-button-warning:hover {
    background-color: var(--e-a-btn-bg-warning-hover)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-danger,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.elementor-button-danger,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-danger,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.elementor-button-danger {
    background-color: var(--e-a-btn-bg-danger)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-danger:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-danger:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.elementor-button-danger:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.elementor-button-danger:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-danger:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-danger:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.elementor-button-danger:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.elementor-button-danger:hover {
    background-color: var(--e-a-btn-bg-danger-hover)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button i,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button i {
    margin-inline-end: 5px
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button:visited,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:visited {
    color: initial
}

.flatpickr-calendar {
    width: 280px
}

.flatpickr-calendar .flatpickr-current-month span.cur-month {
    font-weight: 300
}

.flatpickr-calendar .dayContainer {
    width: 280px;
    min-width: 280px;
    max-width: 280px
}

.flatpickr-calendar .flatpickr-days {
    width: 280px
}

.flatpickr-calendar .flatpickr-day {
    max-width: 37px;
    height: 37px;
    line-height: 37px
}

.elementor-hidden {
    display: none
}

.elementor-screen-only,
.screen-reader-text,
.screen-reader-text span,
.ui-helper-hidden-accessible {
    position: absolute;
    top: -10000em;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0
}

.elementor-clearfix:after {
    content: "";
    display: block;
    clear: both;
    width: 0;
    height: 0
}

.e-logo-wrapper {
    background: var(--e-a-bg-logo);
    display: inline-block;
    padding: .75em;
    border-radius: 50%;
    line-height: 1
}

.e-logo-wrapper i {
    color: var(--e-a-color-logo);
    font-size: 1em
}

.elementor *,
.elementor :after,
.elementor :before {
    box-sizing: border-box
}

.elementor a {
    box-shadow: none;
    text-decoration: none
}

.elementor hr {
    margin: 0;
    background-color: transparent
}

.elementor img {
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none
}

.elementor .elementor-widget:not(.elementor-widget-text-editor):not(.elementor-widget-theme-post-content) figure {
    margin: 0
}

.elementor embed,
.elementor iframe,
.elementor object,
.elementor video {
    max-width: 100%;
    width: 100%;
    margin: 0;
    line-height: 1;
    border: none
}

.elementor .elementor-background,
.elementor .elementor-background-holder,
.elementor .elementor-background-video-container {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    overflow: hidden;
    z-index: 0;
    direction: ltr
}

.elementor .elementor-background-video-container {
    transition: opacity 1s;
    pointer-events: none
}

.elementor .elementor-background-video-container.elementor-loading {
    opacity: 0
}

.elementor .elementor-background-video-embed {
    max-width: none
}

.elementor .elementor-background-video,
.elementor .elementor-background-video-embed,
.elementor .elementor-background-video-hosted {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.elementor .elementor-background-video {
    max-width: none
}

.elementor .elementor-html5-video {
    -o-object-fit: cover;
    object-fit: cover
}

.elementor .elementor-background-overlay,
.elementor .elementor-background-slideshow {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute
}

.elementor .elementor-background-slideshow {
    z-index: 0
}

.elementor .elementor-background-slideshow__slide__image {
    width: 100%;
    height: 100%;
    background-position: 50%;
    background-size: cover
}

.e-con-inner>.elementor-element.elementor-absolute,
.e-con>.elementor-element.elementor-absolute,
.elementor-widget-wrap>.elementor-element.elementor-absolute {
    position: absolute
}

.e-con-inner>.elementor-element.elementor-fixed,
.e-con>.elementor-element.elementor-fixed,
.elementor-widget-wrap>.elementor-element.elementor-fixed {
    position: fixed
}

.elementor-widget-wrap .elementor-element.elementor-widget__width-auto,
.elementor-widget-wrap .elementor-element.elementor-widget__width-initial {
    max-width: 100%
}

@media (max-width:1024px) {

    .elementor-widget-wrap .elementor-element.elementor-widget-tablet__width-auto,
    .elementor-widget-wrap .elementor-element.elementor-widget-tablet__width-initial {
        max-width: 100%
    }
}

@media (max-width:767px) {

    .elementor-widget-wrap .elementor-element.elementor-widget-mobile__width-auto,
    .elementor-widget-wrap .elementor-element.elementor-widget-mobile__width-initial {
        max-width: 100%
    }
}

.elementor-element {
    --flex-direction: initial;
    --flex-wrap: initial;
    --justify-content: initial;
    --align-items: initial;
    --align-content: initial;
    --gap: initial;
    --flex-basis: initial;
    --flex-grow: initial;
    --flex-shrink: initial;
    --order: initial;
    --align-self: initial;
    flex-basis: var(--flex-basis);
    flex-grow: var(--flex-grow);
    flex-shrink: var(--flex-shrink);
    order: var(--order);
    align-self: var(--align-self)
}

.elementor-element.elementor-absolute,
.elementor-element.elementor-fixed {
    z-index: 1
}

.elementor-element:where(.e-con-full, .elementor-widget) {
    flex-direction: var(--flex-direction);
    flex-wrap: var(--flex-wrap);
    justify-content: var(--justify-content);
    align-items: var(--align-items);
    align-content: var(--align-content);
    gap: var(--gap)
}

.elementor-invisible {
    visibility: hidden
}

.elementor-align-center {
    text-align: center
}

.elementor-align-center .elementor-button {
    width: auto
}

.elementor-align-right {
    text-align: right
}

.elementor-align-right .elementor-button {
    width: auto
}

.elementor-align-left {
    text-align: left
}

.elementor-align-left .elementor-button {
    width: auto
}

.elementor-align-justify .elementor-button {
    width: 100%
}

.elementor-custom-embed-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.elementor-custom-embed-play i {
    font-size: 100px;
    color: #fff;
    text-shadow: 1px 0 6px rgba(0, 0, 0, .3)
}

.elementor-custom-embed-play svg {
    height: 100px;
    width: 100px;
    fill: #fff;
    filter: drop-shadow(1px 0 6px rgba(0, 0, 0, .3))
}

.elementor-custom-embed-play i,
.elementor-custom-embed-play svg {
    opacity: .8;
    transition: all .5s
}

.elementor-custom-embed-play.elementor-playing i {
    font-family: eicons
}

.elementor-custom-embed-play.elementor-playing i:before {
    content: "\e8fb"
}

.elementor-custom-embed-play.elementor-playing i,
.elementor-custom-embed-play.elementor-playing svg {
    animation: eicon-spin 2s linear infinite
}

.elementor-tag {
    display: inline-flex
}

.elementor-ken-burns {
    transition-property: transform;
    transition-duration: 10s;
    transition-timing-function: linear
}

.elementor-ken-burns--out {
    transform: scale(1.3)
}

.elementor-ken-burns--active {
    transition-duration: 20s
}

.elementor-ken-burns--active.elementor-ken-burns--out {
    transform: scale(1)
}

.elementor-ken-burns--active.elementor-ken-burns--in {
    transform: scale(1.3)
}

@media (min-width:1) {
    .elementor-widescreen-align-center {
        text-align: center
    }

    .elementor-widescreen-align-center .elementor-button {
        width: auto
    }

    .elementor-widescreen-align-right {
        text-align: right
    }

    .elementor-widescreen-align-right .elementor-button {
        width: auto
    }

    .elementor-widescreen-align-left {
        text-align: left
    }

    .elementor-widescreen-align-left .elementor-button {
        width: auto
    }

    .elementor-widescreen-align-justify .elementor-button {
        width: 100%
    }
}

@media (max-width:1366px) {
    .elementor-laptop-align-center {
        text-align: center
    }

    .elementor-laptop-align-center .elementor-button {
        width: auto
    }

    .elementor-laptop-align-right {
        text-align: right
    }

    .elementor-laptop-align-right .elementor-button {
        width: auto
    }

    .elementor-laptop-align-left {
        text-align: left
    }

    .elementor-laptop-align-left .elementor-button {
        width: auto
    }

    .elementor-laptop-align-justify .elementor-button {
        width: 100%
    }
}

@media (max-width:1200px) {
    .elementor-tablet_extra-align-center {
        text-align: center
    }

    .elementor-tablet_extra-align-center .elementor-button {
        width: auto
    }

    .elementor-tablet_extra-align-right {
        text-align: right
    }

    .elementor-tablet_extra-align-right .elementor-button {
        width: auto
    }

    .elementor-tablet_extra-align-left {
        text-align: left
    }

    .elementor-tablet_extra-align-left .elementor-button {
        width: auto
    }

    .elementor-tablet_extra-align-justify .elementor-button {
        width: 100%
    }
}

@media (max-width:1024px) {
    .elementor-tablet-align-center {
        text-align: center
    }

    .elementor-tablet-align-center .elementor-button {
        width: auto
    }

    .elementor-tablet-align-right {
        text-align: right
    }

    .elementor-tablet-align-right .elementor-button {
        width: auto
    }

    .elementor-tablet-align-left {
        text-align: left
    }

    .elementor-tablet-align-left .elementor-button {
        width: auto
    }

    .elementor-tablet-align-justify .elementor-button {
        width: 100%
    }
}

@media (max-width:880px) {
    .elementor-mobile_extra-align-center {
        text-align: center
    }

    .elementor-mobile_extra-align-center .elementor-button {
        width: auto
    }

    .elementor-mobile_extra-align-right {
        text-align: right
    }

    .elementor-mobile_extra-align-right .elementor-button {
        width: auto
    }

    .elementor-mobile_extra-align-left {
        text-align: left
    }

    .elementor-mobile_extra-align-left .elementor-button {
        width: auto
    }

    .elementor-mobile_extra-align-justify .elementor-button {
        width: 100%
    }
}

@media (max-width:767px) {
    .elementor-mobile-align-center {
        text-align: center
    }

    .elementor-mobile-align-center .elementor-button {
        width: auto
    }

    .elementor-mobile-align-right {
        text-align: right
    }

    .elementor-mobile-align-right .elementor-button {
        width: auto
    }

    .elementor-mobile-align-left {
        text-align: left
    }

    .elementor-mobile-align-left .elementor-button {
        width: auto
    }

    .elementor-mobile-align-justify .elementor-button {
        width: 100%
    }
}

:root {
    --page-title-display: block
}

.elementor-page-title,
h1.entry-title {
    display: var(--page-title-display)
}

@keyframes eicon-spin {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(359deg)
    }
}

.eicon-animation-spin {
    animation: eicon-spin 2s linear infinite
}

.elementor-section {
    position: relative
}

.elementor-section .elementor-container {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative
}

@media (max-width:1024px) {
    .elementor-section .elementor-container {
        flex-wrap: wrap
    }
}

.elementor-section.elementor-section-boxed>.elementor-container {
    max-width: 1140px
}

.elementor-section.elementor-section-stretched {
    position: relative;
    width: 100%
}

.elementor-section.elementor-section-items-top>.elementor-container {
    align-items: flex-start
}

.elementor-section.elementor-section-items-middle>.elementor-container {
    align-items: center
}

.elementor-section.elementor-section-items-bottom>.elementor-container {
    align-items: flex-end
}

@media (min-width:768px) {
    .elementor-section.elementor-section-height-full {
        height: 100vh
    }

    .elementor-section.elementor-section-height-full>.elementor-container {
        height: 100%
    }
}

.elementor-bc-flex-widget .elementor-section-content-top>.elementor-container>.elementor-column>.elementor-widget-wrap {
    align-items: flex-start
}

.elementor-bc-flex-widget .elementor-section-content-middle>.elementor-container>.elementor-column>.elementor-widget-wrap {
    align-items: center
}

.elementor-bc-flex-widget .elementor-section-content-bottom>.elementor-container>.elementor-column>.elementor-widget-wrap {
    align-items: flex-end
}

.elementor-row {
    width: 100%;
    display: flex
}

@media (max-width:1024px) {
    .elementor-row {
        flex-wrap: wrap
    }
}

.elementor-widget-wrap {
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    align-content: flex-start
}

.elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap {
    display: flex
}

.elementor-widget-wrap>.elementor-element {
    width: 100%
}

.elementor-widget-wrap.e-swiper-container {
    width: calc(100% - (var(--e-column-margin-left, 0px) + var(--e-column-margin-right, 0px)))
}

.elementor-widget {
    position: relative
}

.elementor-widget:not(:last-child) {
    margin-bottom: 20px
}

.elementor-widget:not(:last-child).elementor-absolute,
.elementor-widget:not(:last-child).elementor-widget__width-auto,
.elementor-widget:not(:last-child).elementor-widget__width-initial {
    margin-bottom: 0
}

.elementor-column {
    position: relative;
    min-height: 1px;
    display: flex
}

.elementor-column-wrap {
    width: 100%;
    position: relative;
    display: flex
}

.elementor-column-gap-narrow>.elementor-column>.elementor-element-populated {
    padding: 5px
}

.elementor-column-gap-default>.elementor-column>.elementor-element-populated {
    padding: 10px
}

.elementor-column-gap-extended>.elementor-column>.elementor-element-populated {
    padding: 15px
}

.elementor-column-gap-wide>.elementor-column>.elementor-element-populated {
    padding: 20px
}

.elementor-column-gap-wider>.elementor-column>.elementor-element-populated {
    padding: 30px
}

.elementor-inner-section .elementor-column-gap-no .elementor-element-populated {
    padding: 0
}

@media (min-width:768px) {

    .elementor-column.elementor-col-10,
    .elementor-column[data-col="10"] {
        width: 10%
    }

    .elementor-column.elementor-col-11,
    .elementor-column[data-col="11"] {
        width: 11.111%
    }

    .elementor-column.elementor-col-12,
    .elementor-column[data-col="12"] {
        width: 12.5%
    }

    .elementor-column.elementor-col-14,
    .elementor-column[data-col="14"] {
        width: 14.285%
    }

    .elementor-column.elementor-col-16,
    .elementor-column[data-col="16"] {
        width: 16.666%
    }

    .elementor-column.elementor-col-20,
    .elementor-column[data-col="20"] {
        width: 20%
    }

    .elementor-column.elementor-col-25,
    .elementor-column[data-col="25"] {
        width: 25%
    }

    .elementor-column.elementor-col-30,
    .elementor-column[data-col="30"] {
        width: 30%
    }

    .elementor-column.elementor-col-33,
    .elementor-column[data-col="33"] {
        width: 33.333%
    }

    .elementor-column.elementor-col-40,
    .elementor-column[data-col="40"] {
        width: 40%
    }

    .elementor-column.elementor-col-50,
    .elementor-column[data-col="50"] {
        width: 50%
    }

    .elementor-column.elementor-col-60,
    .elementor-column[data-col="60"] {
        width: 60%
    }

    .elementor-column.elementor-col-66,
    .elementor-column[data-col="66"] {
        width: 66.666%
    }

    .elementor-column.elementor-col-70,
    .elementor-column[data-col="70"] {
        width: 70%
    }

    .elementor-column.elementor-col-75,
    .elementor-column[data-col="75"] {
        width: 75%
    }

    .elementor-column.elementor-col-80,
    .elementor-column[data-col="80"] {
        width: 80%
    }

    .elementor-column.elementor-col-83,
    .elementor-column[data-col="83"] {
        width: 83.333%
    }

    .elementor-column.elementor-col-90,
    .elementor-column[data-col="90"] {
        width: 90%
    }

    .elementor-column.elementor-col-100,
    .elementor-column[data-col="100"] {
        width: 100%
    }
}

@media (max-width:479px) {
    .elementor-column.elementor-xs-10 {
        width: 10%
    }

    .elementor-column.elementor-xs-11 {
        width: 11.111%
    }

    .elementor-column.elementor-xs-12 {
        width: 12.5%
    }

    .elementor-column.elementor-xs-14 {
        width: 14.285%
    }

    .elementor-column.elementor-xs-16 {
        width: 16.666%
    }

    .elementor-column.elementor-xs-20 {
        width: 20%
    }

    .elementor-column.elementor-xs-25 {
        width: 25%
    }

    .elementor-column.elementor-xs-30 {
        width: 30%
    }

    .elementor-column.elementor-xs-33 {
        width: 33.333%
    }

    .elementor-column.elementor-xs-40 {
        width: 40%
    }

    .elementor-column.elementor-xs-50 {
        width: 50%
    }

    .elementor-column.elementor-xs-60 {
        width: 60%
    }

    .elementor-column.elementor-xs-66 {
        width: 66.666%
    }

    .elementor-column.elementor-xs-70 {
        width: 70%
    }

    .elementor-column.elementor-xs-75 {
        width: 75%
    }

    .elementor-column.elementor-xs-80 {
        width: 80%
    }

    .elementor-column.elementor-xs-83 {
        width: 83.333%
    }

    .elementor-column.elementor-xs-90 {
        width: 90%
    }

    .elementor-column.elementor-xs-100 {
        width: 100%
    }
}

@media (max-width:767px) {
    .elementor-column.elementor-sm-10 {
        width: 10%
    }

    .elementor-column.elementor-sm-11 {
        width: 11.111%
    }

    .elementor-column.elementor-sm-12 {
        width: 12.5%
    }

    .elementor-column.elementor-sm-14 {
        width: 14.285%
    }

    .elementor-column.elementor-sm-16 {
        width: 16.666%
    }

    .elementor-column.elementor-sm-20 {
        width: 20%
    }

    .elementor-column.elementor-sm-25 {
        width: 25%
    }

    .elementor-column.elementor-sm-30 {
        width: 30%
    }

    .elementor-column.elementor-sm-33 {
        width: 33.333%
    }

    .elementor-column.elementor-sm-40 {
        width: 40%
    }

    .elementor-column.elementor-sm-50 {
        width: 50%
    }

    .elementor-column.elementor-sm-60 {
        width: 60%
    }

    .elementor-column.elementor-sm-66 {
        width: 66.666%
    }

    .elementor-column.elementor-sm-70 {
        width: 70%
    }

    .elementor-column.elementor-sm-75 {
        width: 75%
    }

    .elementor-column.elementor-sm-80 {
        width: 80%
    }

    .elementor-column.elementor-sm-83 {
        width: 83.333%
    }

    .elementor-column.elementor-sm-90 {
        width: 90%
    }

    .elementor-column.elementor-sm-100 {
        width: 100%
    }
}

@media (min-width:768px) and (max-width:1024px) {
    .elementor-column.elementor-md-10 {
        width: 10%
    }

    .elementor-column.elementor-md-11 {
        width: 11.111%
    }

    .elementor-column.elementor-md-12 {
        width: 12.5%
    }

    .elementor-column.elementor-md-14 {
        width: 14.285%
    }

    .elementor-column.elementor-md-16 {
        width: 16.666%
    }

    .elementor-column.elementor-md-20 {
        width: 20%
    }

    .elementor-column.elementor-md-25 {
        width: 25%
    }

    .elementor-column.elementor-md-30 {
        width: 30%
    }

    .elementor-column.elementor-md-33 {
        width: 33.333%
    }

    .elementor-column.elementor-md-40 {
        width: 40%
    }

    .elementor-column.elementor-md-50 {
        width: 50%
    }

    .elementor-column.elementor-md-60 {
        width: 60%
    }

    .elementor-column.elementor-md-66 {
        width: 66.666%
    }

    .elementor-column.elementor-md-70 {
        width: 70%
    }

    .elementor-column.elementor-md-75 {
        width: 75%
    }

    .elementor-column.elementor-md-80 {
        width: 80%
    }

    .elementor-column.elementor-md-83 {
        width: 83.333%
    }

    .elementor-column.elementor-md-90 {
        width: 90%
    }

    .elementor-column.elementor-md-100 {
        width: 100%
    }
}

@media (min-width:1) {
    .elementor-reverse-widescreen>.elementor-container>:first-child {
        order: 10
    }

    .elementor-reverse-widescreen>.elementor-container>:nth-child(2) {
        order: 9
    }

    .elementor-reverse-widescreen>.elementor-container>:nth-child(3) {
        order: 8
    }

    .elementor-reverse-widescreen>.elementor-container>:nth-child(4) {
        order: 7
    }

    .elementor-reverse-widescreen>.elementor-container>:nth-child(5) {
        order: 6
    }

    .elementor-reverse-widescreen>.elementor-container>:nth-child(6) {
        order: 5
    }

    .elementor-reverse-widescreen>.elementor-container>:nth-child(7) {
        order: 4
    }

    .elementor-reverse-widescreen>.elementor-container>:nth-child(8) {
        order: 3
    }

    .elementor-reverse-widescreen>.elementor-container>:nth-child(9) {
        order: 2
    }

    .elementor-reverse-widescreen>.elementor-container>:nth-child(10) {
        order: 1
    }
}

@media (min-width:1025px) and (max-width:1366px) {
    .elementor-reverse-laptop>.elementor-container>:first-child {
        order: 10
    }

    .elementor-reverse-laptop>.elementor-container>:nth-child(2) {
        order: 9
    }

    .elementor-reverse-laptop>.elementor-container>:nth-child(3) {
        order: 8
    }

    .elementor-reverse-laptop>.elementor-container>:nth-child(4) {
        order: 7
    }

    .elementor-reverse-laptop>.elementor-container>:nth-child(5) {
        order: 6
    }

    .elementor-reverse-laptop>.elementor-container>:nth-child(6) {
        order: 5
    }

    .elementor-reverse-laptop>.elementor-container>:nth-child(7) {
        order: 4
    }

    .elementor-reverse-laptop>.elementor-container>:nth-child(8) {
        order: 3
    }

    .elementor-reverse-laptop>.elementor-container>:nth-child(9) {
        order: 2
    }

    .elementor-reverse-laptop>.elementor-container>:nth-child(10) {
        order: 1
    }
}

@media (min-width:1201px) and (max-width:1366px) {
    .elementor-reverse-laptop>.elementor-container>:first-child {
        order: 10
    }

    .elementor-reverse-laptop>.elementor-container>:nth-child(2) {
        order: 9
    }

    .elementor-reverse-laptop>.elementor-container>:nth-child(3) {
        order: 8
    }

    .elementor-reverse-laptop>.elementor-container>:nth-child(4) {
        order: 7
    }

    .elementor-reverse-laptop>.elementor-container>:nth-child(5) {
        order: 6
    }

    .elementor-reverse-laptop>.elementor-container>:nth-child(6) {
        order: 5
    }

    .elementor-reverse-laptop>.elementor-container>:nth-child(7) {
        order: 4
    }

    .elementor-reverse-laptop>.elementor-container>:nth-child(8) {
        order: 3
    }

    .elementor-reverse-laptop>.elementor-container>:nth-child(9) {
        order: 2
    }

    .elementor-reverse-laptop>.elementor-container>:nth-child(10) {
        order: 1
    }
}

@media (min-width:1025px) and (max-width:1200px) {

    .elementor-reverse-laptop>.elementor-container>:first-child,
    .elementor-reverse-laptop>.elementor-container>:nth-child(2),
    .elementor-reverse-laptop>.elementor-container>:nth-child(3),
    .elementor-reverse-laptop>.elementor-container>:nth-child(4),
    .elementor-reverse-laptop>.elementor-container>:nth-child(5),
    .elementor-reverse-laptop>.elementor-container>:nth-child(6),
    .elementor-reverse-laptop>.elementor-container>:nth-child(7),
    .elementor-reverse-laptop>.elementor-container>:nth-child(8),
    .elementor-reverse-laptop>.elementor-container>:nth-child(9),
    .elementor-reverse-laptop>.elementor-container>:nth-child(10) {
        order: 0
    }

    .elementor-reverse-tablet_extra>.elementor-container>:first-child {
        order: 10
    }

    .elementor-reverse-tablet_extra>.elementor-container>:nth-child(2) {
        order: 9
    }

    .elementor-reverse-tablet_extra>.elementor-container>:nth-child(3) {
        order: 8
    }

    .elementor-reverse-tablet_extra>.elementor-container>:nth-child(4) {
        order: 7
    }

    .elementor-reverse-tablet_extra>.elementor-container>:nth-child(5) {
        order: 6
    }

    .elementor-reverse-tablet_extra>.elementor-container>:nth-child(6) {
        order: 5
    }

    .elementor-reverse-tablet_extra>.elementor-container>:nth-child(7) {
        order: 4
    }

    .elementor-reverse-tablet_extra>.elementor-container>:nth-child(8) {
        order: 3
    }

    .elementor-reverse-tablet_extra>.elementor-container>:nth-child(9) {
        order: 2
    }

    .elementor-reverse-tablet_extra>.elementor-container>:nth-child(10) {
        order: 1
    }
}

@media (min-width:768px) and (max-width:1024px) {
    .elementor-reverse-tablet>.elementor-container>:first-child {
        order: 10
    }

    .elementor-reverse-tablet>.elementor-container>:nth-child(2) {
        order: 9
    }

    .elementor-reverse-tablet>.elementor-container>:nth-child(3) {
        order: 8
    }

    .elementor-reverse-tablet>.elementor-container>:nth-child(4) {
        order: 7
    }

    .elementor-reverse-tablet>.elementor-container>:nth-child(5) {
        order: 6
    }

    .elementor-reverse-tablet>.elementor-container>:nth-child(6) {
        order: 5
    }

    .elementor-reverse-tablet>.elementor-container>:nth-child(7) {
        order: 4
    }

    .elementor-reverse-tablet>.elementor-container>:nth-child(8) {
        order: 3
    }

    .elementor-reverse-tablet>.elementor-container>:nth-child(9) {
        order: 2
    }

    .elementor-reverse-tablet>.elementor-container>:nth-child(10) {
        order: 1
    }
}

@media (min-width:881px) and (max-width:1024px) {
    .elementor-reverse-tablet>.elementor-container>:first-child {
        order: 10
    }

    .elementor-reverse-tablet>.elementor-container>:nth-child(2) {
        order: 9
    }

    .elementor-reverse-tablet>.elementor-container>:nth-child(3) {
        order: 8
    }

    .elementor-reverse-tablet>.elementor-container>:nth-child(4) {
        order: 7
    }

    .elementor-reverse-tablet>.elementor-container>:nth-child(5) {
        order: 6
    }

    .elementor-reverse-tablet>.elementor-container>:nth-child(6) {
        order: 5
    }

    .elementor-reverse-tablet>.elementor-container>:nth-child(7) {
        order: 4
    }

    .elementor-reverse-tablet>.elementor-container>:nth-child(8) {
        order: 3
    }

    .elementor-reverse-tablet>.elementor-container>:nth-child(9) {
        order: 2
    }

    .elementor-reverse-tablet>.elementor-container>:nth-child(10) {
        order: 1
    }
}

@media (min-width:768px) and (max-width:880px) {

    .elementor-reverse-tablet>.elementor-container>:first-child,
    .elementor-reverse-tablet>.elementor-container>:nth-child(2),
    .elementor-reverse-tablet>.elementor-container>:nth-child(3),
    .elementor-reverse-tablet>.elementor-container>:nth-child(4),
    .elementor-reverse-tablet>.elementor-container>:nth-child(5),
    .elementor-reverse-tablet>.elementor-container>:nth-child(6),
    .elementor-reverse-tablet>.elementor-container>:nth-child(7),
    .elementor-reverse-tablet>.elementor-container>:nth-child(8),
    .elementor-reverse-tablet>.elementor-container>:nth-child(9),
    .elementor-reverse-tablet>.elementor-container>:nth-child(10) {
        order: 0
    }

    .elementor-reverse-mobile_extra>.elementor-container>:first-child {
        order: 10
    }

    .elementor-reverse-mobile_extra>.elementor-container>:nth-child(2) {
        order: 9
    }

    .elementor-reverse-mobile_extra>.elementor-container>:nth-child(3) {
        order: 8
    }

    .elementor-reverse-mobile_extra>.elementor-container>:nth-child(4) {
        order: 7
    }

    .elementor-reverse-mobile_extra>.elementor-container>:nth-child(5) {
        order: 6
    }

    .elementor-reverse-mobile_extra>.elementor-container>:nth-child(6) {
        order: 5
    }

    .elementor-reverse-mobile_extra>.elementor-container>:nth-child(7) {
        order: 4
    }

    .elementor-reverse-mobile_extra>.elementor-container>:nth-child(8) {
        order: 3
    }

    .elementor-reverse-mobile_extra>.elementor-container>:nth-child(9) {
        order: 2
    }

    .elementor-reverse-mobile_extra>.elementor-container>:nth-child(10) {
        order: 1
    }
}

@media (max-width:767px) {
    .elementor-reverse-mobile>.elementor-container>:first-child {
        order: 10
    }

    .elementor-reverse-mobile>.elementor-container>:nth-child(2) {
        order: 9
    }

    .elementor-reverse-mobile>.elementor-container>:nth-child(3) {
        order: 8
    }

    .elementor-reverse-mobile>.elementor-container>:nth-child(4) {
        order: 7
    }

    .elementor-reverse-mobile>.elementor-container>:nth-child(5) {
        order: 6
    }

    .elementor-reverse-mobile>.elementor-container>:nth-child(6) {
        order: 5
    }

    .elementor-reverse-mobile>.elementor-container>:nth-child(7) {
        order: 4
    }

    .elementor-reverse-mobile>.elementor-container>:nth-child(8) {
        order: 3
    }

    .elementor-reverse-mobile>.elementor-container>:nth-child(9) {
        order: 2
    }

    .elementor-reverse-mobile>.elementor-container>:nth-child(10) {
        order: 1
    }

    .elementor-column {
        width: 100%
    }
}

ul.elementor-icon-list-items.elementor-inline-items {
    display: flex;
    flex-wrap: wrap
}

ul.elementor-icon-list-items.elementor-inline-items .elementor-inline-item {
    word-break: break-word
}

.elementor-grid {
    display: grid;
    grid-column-gap: var(--grid-column-gap);
    grid-row-gap: var(--grid-row-gap)
}

.elementor-grid .elementor-grid-item {
    min-width: 0
}

.elementor-grid-0 .elementor-grid {
    display: inline-block;
    width: 100%;
    word-spacing: var(--grid-column-gap);
    margin-bottom: calc(-1 * var(--grid-row-gap))
}

.elementor-grid-0 .elementor-grid .elementor-grid-item {
    display: inline-block;
    margin-bottom: var(--grid-row-gap);
    word-break: break-word
}

.elementor-grid-1 .elementor-grid {
    grid-template-columns: repeat(1, 1fr)
}

.elementor-grid-2 .elementor-grid {
    grid-template-columns: repeat(2, 1fr)
}

.elementor-grid-3 .elementor-grid {
    grid-template-columns: repeat(3, 1fr)
}

.elementor-grid-4 .elementor-grid {
    grid-template-columns: repeat(4, 1fr)
}

.elementor-grid-5 .elementor-grid {
    grid-template-columns: repeat(5, 1fr)
}

.elementor-grid-6 .elementor-grid {
    grid-template-columns: repeat(6, 1fr)
}

.elementor-grid-7 .elementor-grid {
    grid-template-columns: repeat(7, 1fr)
}

.elementor-grid-8 .elementor-grid {
    grid-template-columns: repeat(8, 1fr)
}

.elementor-grid-9 .elementor-grid {
    grid-template-columns: repeat(9, 1fr)
}

.elementor-grid-10 .elementor-grid {
    grid-template-columns: repeat(10, 1fr)
}

.elementor-grid-11 .elementor-grid {
    grid-template-columns: repeat(11, 1fr)
}

.elementor-grid-12 .elementor-grid {
    grid-template-columns: repeat(12, 1fr)
}

@media (min-width:1) {
    .elementor-grid-widescreen-0 .elementor-grid {
        display: inline-block;
        width: 100%;
        word-spacing: var(--grid-column-gap);
        margin-bottom: calc(-1 * var(--grid-row-gap))
    }

    .elementor-grid-widescreen-0 .elementor-grid .elementor-grid-item {
        display: inline-block;
        margin-bottom: var(--grid-row-gap);
        word-break: break-word
    }

    .elementor-grid-widescreen-1 .elementor-grid {
        grid-template-columns: repeat(1, 1fr)
    }

    .elementor-grid-widescreen-2 .elementor-grid {
        grid-template-columns: repeat(2, 1fr)
    }

    .elementor-grid-widescreen-3 .elementor-grid {
        grid-template-columns: repeat(3, 1fr)
    }

    .elementor-grid-widescreen-4 .elementor-grid {
        grid-template-columns: repeat(4, 1fr)
    }

    .elementor-grid-widescreen-5 .elementor-grid {
        grid-template-columns: repeat(5, 1fr)
    }

    .elementor-grid-widescreen-6 .elementor-grid {
        grid-template-columns: repeat(6, 1fr)
    }

    .elementor-grid-widescreen-7 .elementor-grid {
        grid-template-columns: repeat(7, 1fr)
    }

    .elementor-grid-widescreen-8 .elementor-grid {
        grid-template-columns: repeat(8, 1fr)
    }

    .elementor-grid-widescreen-9 .elementor-grid {
        grid-template-columns: repeat(9, 1fr)
    }

    .elementor-grid-widescreen-10 .elementor-grid {
        grid-template-columns: repeat(10, 1fr)
    }

    .elementor-grid-widescreen-11 .elementor-grid {
        grid-template-columns: repeat(11, 1fr)
    }

    .elementor-grid-widescreen-12 .elementor-grid {
        grid-template-columns: repeat(12, 1fr)
    }
}

@media (max-width:1366px) {
    .elementor-grid-laptop-0 .elementor-grid {
        display: inline-block;
        width: 100%;
        word-spacing: var(--grid-column-gap);
        margin-bottom: calc(-1 * var(--grid-row-gap))
    }

    .elementor-grid-laptop-0 .elementor-grid .elementor-grid-item {
        display: inline-block;
        margin-bottom: var(--grid-row-gap);
        word-break: break-word
    }

    .elementor-grid-laptop-1 .elementor-grid {
        grid-template-columns: repeat(1, 1fr)
    }

    .elementor-grid-laptop-2 .elementor-grid {
        grid-template-columns: repeat(2, 1fr)
    }

    .elementor-grid-laptop-3 .elementor-grid {
        grid-template-columns: repeat(3, 1fr)
    }

    .elementor-grid-laptop-4 .elementor-grid {
        grid-template-columns: repeat(4, 1fr)
    }

    .elementor-grid-laptop-5 .elementor-grid {
        grid-template-columns: repeat(5, 1fr)
    }

    .elementor-grid-laptop-6 .elementor-grid {
        grid-template-columns: repeat(6, 1fr)
    }

    .elementor-grid-laptop-7 .elementor-grid {
        grid-template-columns: repeat(7, 1fr)
    }

    .elementor-grid-laptop-8 .elementor-grid {
        grid-template-columns: repeat(8, 1fr)
    }

    .elementor-grid-laptop-9 .elementor-grid {
        grid-template-columns: repeat(9, 1fr)
    }

    .elementor-grid-laptop-10 .elementor-grid {
        grid-template-columns: repeat(10, 1fr)
    }

    .elementor-grid-laptop-11 .elementor-grid {
        grid-template-columns: repeat(11, 1fr)
    }

    .elementor-grid-laptop-12 .elementor-grid {
        grid-template-columns: repeat(12, 1fr)
    }
}

@media (max-width:1200px) {
    .elementor-grid-tablet_extra-0 .elementor-grid {
        display: inline-block;
        width: 100%;
        word-spacing: var(--grid-column-gap);
        margin-bottom: calc(-1 * var(--grid-row-gap))
    }

    .elementor-grid-tablet_extra-0 .elementor-grid .elementor-grid-item {
        display: inline-block;
        margin-bottom: var(--grid-row-gap);
        word-break: break-word
    }

    .elementor-grid-tablet_extra-1 .elementor-grid {
        grid-template-columns: repeat(1, 1fr)
    }

    .elementor-grid-tablet_extra-2 .elementor-grid {
        grid-template-columns: repeat(2, 1fr)
    }

    .elementor-grid-tablet_extra-3 .elementor-grid {
        grid-template-columns: repeat(3, 1fr)
    }

    .elementor-grid-tablet_extra-4 .elementor-grid {
        grid-template-columns: repeat(4, 1fr)
    }

    .elementor-grid-tablet_extra-5 .elementor-grid {
        grid-template-columns: repeat(5, 1fr)
    }

    .elementor-grid-tablet_extra-6 .elementor-grid {
        grid-template-columns: repeat(6, 1fr)
    }

    .elementor-grid-tablet_extra-7 .elementor-grid {
        grid-template-columns: repeat(7, 1fr)
    }

    .elementor-grid-tablet_extra-8 .elementor-grid {
        grid-template-columns: repeat(8, 1fr)
    }

    .elementor-grid-tablet_extra-9 .elementor-grid {
        grid-template-columns: repeat(9, 1fr)
    }

    .elementor-grid-tablet_extra-10 .elementor-grid {
        grid-template-columns: repeat(10, 1fr)
    }

    .elementor-grid-tablet_extra-11 .elementor-grid {
        grid-template-columns: repeat(11, 1fr)
    }

    .elementor-grid-tablet_extra-12 .elementor-grid {
        grid-template-columns: repeat(12, 1fr)
    }
}

@media (max-width:1024px) {
    .elementor-grid-tablet-0 .elementor-grid {
        display: inline-block;
        width: 100%;
        word-spacing: var(--grid-column-gap);
        margin-bottom: calc(-1 * var(--grid-row-gap))
    }

    .elementor-grid-tablet-0 .elementor-grid .elementor-grid-item {
        display: inline-block;
        margin-bottom: var(--grid-row-gap);
        word-break: break-word
    }

    .elementor-grid-tablet-1 .elementor-grid {
        grid-template-columns: repeat(1, 1fr)
    }

    .elementor-grid-tablet-2 .elementor-grid {
        grid-template-columns: repeat(2, 1fr)
    }

    .elementor-grid-tablet-3 .elementor-grid {
        grid-template-columns: repeat(3, 1fr)
    }

    .elementor-grid-tablet-4 .elementor-grid {
        grid-template-columns: repeat(4, 1fr)
    }

    .elementor-grid-tablet-5 .elementor-grid {
        grid-template-columns: repeat(5, 1fr)
    }

    .elementor-grid-tablet-6 .elementor-grid {
        grid-template-columns: repeat(6, 1fr)
    }

    .elementor-grid-tablet-7 .elementor-grid {
        grid-template-columns: repeat(7, 1fr)
    }

    .elementor-grid-tablet-8 .elementor-grid {
        grid-template-columns: repeat(8, 1fr)
    }

    .elementor-grid-tablet-9 .elementor-grid {
        grid-template-columns: repeat(9, 1fr)
    }

    .elementor-grid-tablet-10 .elementor-grid {
        grid-template-columns: repeat(10, 1fr)
    }

    .elementor-grid-tablet-11 .elementor-grid {
        grid-template-columns: repeat(11, 1fr)
    }

    .elementor-grid-tablet-12 .elementor-grid {
        grid-template-columns: repeat(12, 1fr)
    }
}

@media (max-width:880px) {
    .elementor-grid-mobile_extra-0 .elementor-grid {
        display: inline-block;
        width: 100%;
        word-spacing: var(--grid-column-gap);
        margin-bottom: calc(-1 * var(--grid-row-gap))
    }

    .elementor-grid-mobile_extra-0 .elementor-grid .elementor-grid-item {
        display: inline-block;
        margin-bottom: var(--grid-row-gap);
        word-break: break-word
    }

    .elementor-grid-mobile_extra-1 .elementor-grid {
        grid-template-columns: repeat(1, 1fr)
    }

    .elementor-grid-mobile_extra-2 .elementor-grid {
        grid-template-columns: repeat(2, 1fr)
    }

    .elementor-grid-mobile_extra-3 .elementor-grid {
        grid-template-columns: repeat(3, 1fr)
    }

    .elementor-grid-mobile_extra-4 .elementor-grid {
        grid-template-columns: repeat(4, 1fr)
    }

    .elementor-grid-mobile_extra-5 .elementor-grid {
        grid-template-columns: repeat(5, 1fr)
    }

    .elementor-grid-mobile_extra-6 .elementor-grid {
        grid-template-columns: repeat(6, 1fr)
    }

    .elementor-grid-mobile_extra-7 .elementor-grid {
        grid-template-columns: repeat(7, 1fr)
    }

    .elementor-grid-mobile_extra-8 .elementor-grid {
        grid-template-columns: repeat(8, 1fr)
    }

    .elementor-grid-mobile_extra-9 .elementor-grid {
        grid-template-columns: repeat(9, 1fr)
    }

    .elementor-grid-mobile_extra-10 .elementor-grid {
        grid-template-columns: repeat(10, 1fr)
    }

    .elementor-grid-mobile_extra-11 .elementor-grid {
        grid-template-columns: repeat(11, 1fr)
    }

    .elementor-grid-mobile_extra-12 .elementor-grid {
        grid-template-columns: repeat(12, 1fr)
    }
}

@media (max-width:767px) {
    .elementor-grid-mobile-0 .elementor-grid {
        display: inline-block;
        width: 100%;
        word-spacing: var(--grid-column-gap);
        margin-bottom: calc(-1 * var(--grid-row-gap))
    }

    .elementor-grid-mobile-0 .elementor-grid .elementor-grid-item {
        display: inline-block;
        margin-bottom: var(--grid-row-gap);
        word-break: break-word
    }

    .elementor-grid-mobile-1 .elementor-grid {
        grid-template-columns: repeat(1, 1fr)
    }

    .elementor-grid-mobile-2 .elementor-grid {
        grid-template-columns: repeat(2, 1fr)
    }

    .elementor-grid-mobile-3 .elementor-grid {
        grid-template-columns: repeat(3, 1fr)
    }

    .elementor-grid-mobile-4 .elementor-grid {
        grid-template-columns: repeat(4, 1fr)
    }

    .elementor-grid-mobile-5 .elementor-grid {
        grid-template-columns: repeat(5, 1fr)
    }

    .elementor-grid-mobile-6 .elementor-grid {
        grid-template-columns: repeat(6, 1fr)
    }

    .elementor-grid-mobile-7 .elementor-grid {
        grid-template-columns: repeat(7, 1fr)
    }

    .elementor-grid-mobile-8 .elementor-grid {
        grid-template-columns: repeat(8, 1fr)
    }

    .elementor-grid-mobile-9 .elementor-grid {
        grid-template-columns: repeat(9, 1fr)
    }

    .elementor-grid-mobile-10 .elementor-grid {
        grid-template-columns: repeat(10, 1fr)
    }

    .elementor-grid-mobile-11 .elementor-grid {
        grid-template-columns: repeat(11, 1fr)
    }

    .elementor-grid-mobile-12 .elementor-grid {
        grid-template-columns: repeat(12, 1fr)
    }
}

@media (min-width:1367px) {
    #elementor-device-mode:after {
        content: "desktop"
    }
}

@media (min-width:1) {
    #elementor-device-mode:after {
        content: "widescreen"
    }
}

@media (max-width:1366px) {
    #elementor-device-mode:after {
        content: "laptop"
    }
}

@media (max-width:1200px) {
    #elementor-device-mode:after {
        content: "tablet_extra"
    }
}

@media (max-width:1024px) {
    #elementor-device-mode:after {
        content: "tablet"
    }
}

@media (max-width:880px) {
    #elementor-device-mode:after {
        content: "mobile_extra"
    }
}

@media (max-width:767px) {
    #elementor-device-mode:after {
        content: "mobile"
    }
}

.e-con {
    --border-radius: 0;
    --border-top-width: 0px;
    --border-right-width: 0px;
    --border-bottom-width: 0px;
    --border-left-width: 0px;
    --border-style: initial;
    --border-color: initial;
    --container-widget-width: 100%;
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --content-width: min(100%, var(--container-max-width, 1140px));
    --width: 100%;
    --min-height: initial;
    --height: auto;
    --text-align: initial;
    --margin-top: 0px;
    --margin-right: 0px;
    --margin-bottom: 0px;
    --margin-left: 0px;
    --padding-top: var(--container-default-padding-top, 10px);
    --padding-right: var(--container-default-padding-right, 10px);
    --padding-bottom: var(--container-default-padding-bottom, 10px);
    --padding-left: var(--container-default-padding-left, 10px);
    --position: relative;
    --z-index: revert;
    --overflow: visible;
    --gap: var(--widgets-spacing, 20px);
    --overlay-mix-blend-mode: initial;
    --overlay-opacity: 1;
    --overlay-transition: 0.3s;
    --e-con-grid-template-columns: repeat(3, 1fr);
    --e-con-grid-template-rows: repeat(2, 1fr);
    position: var(--position);
    width: var(--width);
    min-width: 0;
    min-height: var(--min-height);
    height: var(--height);
    border-radius: var(--border-radius);
    z-index: var(--z-index);
    overflow: var(--overflow);
    transition: background var(--background-transition, .3s), border var(--border-transition, .3s), box-shadow var(--border-transition, .3s), transform var(--e-con-transform-transition-duration, .4s);
    --flex-wrap-mobile: wrap;
    margin-block-start: var(--margin-block-start);
    margin-inline-end: var(--margin-inline-end);
    margin-block-end: var(--margin-block-end);
    margin-inline-start: var(--margin-inline-start);
    padding-inline-start: var(--padding-inline-start);
    padding-inline-end: var(--padding-inline-end);
    --margin-block-start: var(--margin-top);
    --margin-block-end: var(--margin-bottom);
    --margin-inline-start: var(--margin-left);
    --margin-inline-end: var(--margin-right);
    --padding-inline-start: var(--padding-left);
    --padding-inline-end: var(--padding-right);
    --padding-block-start: var(--padding-top);
    --padding-block-end: var(--padding-bottom);
    --border-block-start-width: var(--border-top-width);
    --border-block-end-width: var(--border-bottom-width);
    --border-inline-start-width: var(--border-left-width);
    --border-inline-end-width: var(--border-right-width)
}

body.rtl .e-con {
    --padding-inline-start: var(--padding-right);
    --padding-inline-end: var(--padding-left);
    --margin-inline-start: var(--margin-right);
    --margin-inline-end: var(--margin-left);
    --border-inline-start-width: var(--border-right-width);
    --border-inline-end-width: var(--border-left-width)
}

.e-con.e-flex {
    --flex-direction: column;
    --flex-basis: auto;
    --flex-grow: 0;
    --flex-shrink: 1;
    flex: var(--flex-grow) var(--flex-shrink) var(--flex-basis)
}

.e-con-full,
.e-con>.e-con-inner {
    text-align: var(--text-align);
    padding-block-start: var(--padding-block-start);
    padding-block-end: var(--padding-block-end)
}

.e-con-full.e-flex,
.e-con.e-flex>.e-con-inner {
    flex-direction: var(--flex-direction)
}

.e-con,
.e-con>.e-con-inner {
    display: var(--display)
}

.e-con.e-grid {
    --grid-justify-content: start;
    --grid-align-content: start;
    --grid-auto-flow: row
}

.e-con.e-grid,
.e-con.e-grid>.e-con-inner {
    grid-template-columns: var(--e-con-grid-template-columns);
    grid-template-rows: var(--e-con-grid-template-rows);
    justify-content: var(--grid-justify-content);
    align-content: var(--grid-align-content);
    grid-auto-flow: var(--grid-auto-flow);
    justify-items: var(--justify-items);
    align-items: var(--align-items)
}

.e-con-boxed.e-flex {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: normal;
    align-items: normal;
    align-content: normal
}

.e-con-boxed.e-grid {
    justify-items: legacy;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr
}

.e-con-boxed {
    text-align: initial;
    gap: initial
}

.e-con.e-flex>.e-con-inner {
    flex-wrap: var(--flex-wrap);
    justify-content: var(--justify-content);
    align-items: var(--align-items);
    align-content: var(--align-content);
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: auto
}

.e-con.e-grid>.e-con-inner {
    justify-items: var(--justify-items);
    align-items: var(--align-items)
}

.e-con>.e-con-inner {
    gap: var(--gap);
    width: 100%;
    max-width: var(--content-width);
    margin: 0 auto;
    padding-inline-start: 0;
    padding-inline-end: 0;
    height: 100%
}

:is(.elementor-section-wrap, [data-elementor-id])>.e-con {
    --margin-left: auto;
    --margin-right: auto;
    max-width: min(100%, var(--width))
}

.e-con .elementor-widget.elementor-widget {
    margin-block-end: 0
}

.e-con:before,
.e-con>.elementor-background-slideshow:before,
.e-con>.elementor-motion-effects-container>.elementor-motion-effects-layer:before,
:is(.e-con, .e-con>.e-con-inner)>.elementor-background-video-container:before {
    content: var(--background-overlay);
    display: block;
    position: absolute;
    mix-blend-mode: var(--overlay-mix-blend-mode);
    opacity: var(--overlay-opacity);
    transition: var(--overlay-transition, .3s);
    border-radius: var(--border-radius);
    border-style: var(--border-style);
    border-color: var(--border-color);
    border-block-start-width: var(--border-block-start-width);
    border-inline-end-width: var(--border-inline-end-width);
    border-block-end-width: var(--border-block-end-width);
    border-inline-start-width: var(--border-inline-start-width);
    top: calc(0px - var(--border-top-width));
    left: calc(0px - var(--border-left-width));
    width: max(100% + var(--border-left-width) + var(--border-right-width), 100%);
    height: max(100% + var(--border-top-width) + var(--border-bottom-width), 100%)
}

.e-con:before {
    transition: background var(--overlay-transition, .3s), border-radius var(--border-transition, .3s), opacity var(--overlay-transition, .3s)
}

.e-con>.elementor-background-slideshow,
:is(.e-con, .e-con>.e-con-inner)>.elementor-background-video-container {
    border-radius: var(--border-radius);
    border-style: var(--border-style);
    border-color: var(--border-color);
    border-block-start-width: var(--border-block-start-width);
    border-inline-end-width: var(--border-inline-end-width);
    border-block-end-width: var(--border-block-end-width);
    border-inline-start-width: var(--border-inline-start-width);
    top: calc(0px - var(--border-top-width));
    left: calc(0px - var(--border-left-width));
    width: max(100% + var(--border-left-width) + var(--border-right-width), 100%);
    height: max(100% + var(--border-top-width) + var(--border-bottom-width), 100%)
}

@media (max-width:767px) {
    :is(.e-con, .e-con>.e-con-inner)>.elementor-background-video-container.elementor-hidden-phone {
        display: none
    }
}

:is(.e-con, .e-con>.e-con-inner)>.elementor-background-video-container:before {
    z-index: 1
}

:is(.e-con, .e-con>.e-con-inner)>.elementor-background-slideshow:before {
    z-index: 2
}

.e-con .elementor-widget {
    min-width: 0
}

.e-con .elementor-widget-empty,
.e-con .elementor-widget-google_maps,
.e-con .elementor-widget-video,
.e-con .elementor-widget.e-widget-swiper {
    width: 100%
}

.e-con>.e-con-inner>.elementor-widget>.elementor-widget-container,
.e-con>.elementor-widget>.elementor-widget-container {
    height: 100%
}

.e-con.e-con>.e-con-inner>.elementor-widget,
.elementor.elementor .e-con>.elementor-widget {
    max-width: 100%
}

@media (max-width:767px) {
    .e-con.e-flex {
        --width: 100%;
        --flex-wrap: var(--flex-wrap-mobile)
    }
}

.elementor-form-fields-wrapper {
    display: flex;
    flex-wrap: wrap
}

.elementor-form-fields-wrapper.elementor-labels-above .elementor-field-group .elementor-field-subgroup,
.elementor-form-fields-wrapper.elementor-labels-above .elementor-field-group>.elementor-select-wrapper,
.elementor-form-fields-wrapper.elementor-labels-above .elementor-field-group>input,
.elementor-form-fields-wrapper.elementor-labels-above .elementor-field-group>textarea {
    flex-basis: 100%;
    max-width: 100%
}

.elementor-form-fields-wrapper.elementor-labels-inline>.elementor-field-group .elementor-select-wrapper,
.elementor-form-fields-wrapper.elementor-labels-inline>.elementor-field-group>input {
    flex-grow: 1
}

.elementor-field-group {
    flex-wrap: wrap;
    align-items: center
}

.elementor-field-group.elementor-field-type-submit {
    align-items: flex-end
}

.elementor-field-group .elementor-field-textual {
    width: 100%;
    max-width: 100%;
    border: 1px solid #69727d;
    background-color: transparent;
    color: #1f2124;
    vertical-align: middle;
    flex-grow: 1
}

.elementor-field-group .elementor-field-textual:focus {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .1);
    outline: 0
}

.elementor-field-group .elementor-field-textual::-moz-placeholder {
    color: inherit;
    font-family: inherit;
    opacity: .6
}

.elementor-field-group .elementor-field-textual::placeholder {
    color: inherit;
    font-family: inherit;
    opacity: .6
}

.elementor-field-group .elementor-select-wrapper {
    display: flex;
    position: relative;
    width: 100%
}

.elementor-field-group .elementor-select-wrapper select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    font-style: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    flex-basis: 100%;
    padding-inline-end: 20px
}

.elementor-field-group .elementor-select-wrapper:before {
    content: "\e92a";
    font-family: eicons;
    font-size: 15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    pointer-events: none;
    text-shadow: 0 0 3px rgba(0, 0, 0, .3)
}

.elementor-field-group.elementor-field-type-select-multiple .elementor-select-wrapper:before {
    content: ""
}

.elementor-field-subgroup {
    display: flex;
    flex-wrap: wrap
}

.elementor-field-subgroup .elementor-field-option label {
    display: inline-block
}

.elementor-field-subgroup.elementor-subgroup-inline .elementor-field-option {
    padding-inline-end: 10px
}

.elementor-field-subgroup:not(.elementor-subgroup-inline) .elementor-field-option {
    flex-basis: 100%
}

.elementor-field-type-acceptance .elementor-field-subgroup .elementor-field-option input,
.elementor-field-type-acceptance .elementor-field-subgroup .elementor-field-option label,
.elementor-field-type-checkbox .elementor-field-subgroup .elementor-field-option input,
.elementor-field-type-checkbox .elementor-field-subgroup .elementor-field-option label,
.elementor-field-type-radio .elementor-field-subgroup .elementor-field-option input,
.elementor-field-type-radio .elementor-field-subgroup .elementor-field-option label {
    display: inline
}

.elementor-field-label {
    cursor: pointer
}

.elementor-mark-required .elementor-field-label:after {
    content: "*";
    color: red;
    padding-inline-start: .2em
}

.elementor-field-textual {
    line-height: 1.4;
    font-size: 15px;
    min-height: 40px;
    padding: 5px 14px;
    border-radius: 3px
}

.elementor-field-textual.elementor-size-xs {
    font-size: 13px;
    min-height: 33px;
    padding: 4px 12px;
    border-radius: 2px
}

.elementor-field-textual.elementor-size-md {
    font-size: 16px;
    min-height: 47px;
    padding: 6px 16px;
    border-radius: 4px
}

.elementor-field-textual.elementor-size-lg {
    font-size: 18px;
    min-height: 59px;
    padding: 7px 20px;
    border-radius: 5px
}

.elementor-field-textual.elementor-size-xl {
    font-size: 20px;
    min-height: 72px;
    padding: 8px 24px;
    border-radius: 6px
}

.elementor-button-align-stretch .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button {
    flex-basis: 100%
}

.elementor-button-align-stretch .e-form__buttons__wrapper {
    flex-basis: 50%;
    flex-grow: 1
}

.elementor-button-align-stretch .e-form__buttons__wrapper__button {
    flex-basis: 100%
}

.elementor-button-align-center .e-form__buttons,
.elementor-button-align-center .elementor-field-type-submit {
    justify-content: center
}

.elementor-button-align-start .e-form__buttons,
.elementor-button-align-start .elementor-field-type-submit {
    justify-content: flex-start
}

.elementor-button-align-end .e-form__buttons,
.elementor-button-align-end .elementor-field-type-submit {
    justify-content: flex-end
}

.elementor-button-align-center .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button,
.elementor-button-align-end .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button,
.elementor-button-align-start .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button {
    flex-basis: auto
}

.elementor-button-align-center .e-form__buttons__wrapper,
.elementor-button-align-end .e-form__buttons__wrapper,
.elementor-button-align-start .e-form__buttons__wrapper {
    flex-grow: 0
}

.elementor-button-align-center .e-form__buttons__wrapper,
.elementor-button-align-center .e-form__buttons__wrapper__button,
.elementor-button-align-end .e-form__buttons__wrapper,
.elementor-button-align-end .e-form__buttons__wrapper__button,
.elementor-button-align-start .e-form__buttons__wrapper,
.elementor-button-align-start .e-form__buttons__wrapper__button {
    flex-basis: auto
}

@media screen and (max-width:1024px) {
    .elementor-tablet-button-align-stretch .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button {
        flex-basis: 100%
    }

    .elementor-tablet-button-align-stretch .e-form__buttons__wrapper {
        flex-basis: 50%;
        flex-grow: 1
    }

    .elementor-tablet-button-align-stretch .e-form__buttons__wrapper__button {
        flex-basis: 100%
    }

    .elementor-tablet-button-align-center .e-form__buttons,
    .elementor-tablet-button-align-center .elementor-field-type-submit {
        justify-content: center
    }

    .elementor-tablet-button-align-start .e-form__buttons,
    .elementor-tablet-button-align-start .elementor-field-type-submit {
        justify-content: flex-start
    }

    .elementor-tablet-button-align-end .e-form__buttons,
    .elementor-tablet-button-align-end .elementor-field-type-submit {
        justify-content: flex-end
    }

    .elementor-tablet-button-align-center .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button,
    .elementor-tablet-button-align-end .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button,
    .elementor-tablet-button-align-start .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button {
        flex-basis: auto
    }

    .elementor-tablet-button-align-center .e-form__buttons__wrapper,
    .elementor-tablet-button-align-end .e-form__buttons__wrapper,
    .elementor-tablet-button-align-start .e-form__buttons__wrapper {
        flex-grow: 0
    }

    .elementor-tablet-button-align-center .e-form__buttons__wrapper,
    .elementor-tablet-button-align-center .e-form__buttons__wrapper__button,
    .elementor-tablet-button-align-end .e-form__buttons__wrapper,
    .elementor-tablet-button-align-end .e-form__buttons__wrapper__button,
    .elementor-tablet-button-align-start .e-form__buttons__wrapper,
    .elementor-tablet-button-align-start .e-form__buttons__wrapper__button {
        flex-basis: auto
    }
}

@media screen and (max-width:767px) {
    .elementor-mobile-button-align-stretch .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button {
        flex-basis: 100%
    }

    .elementor-mobile-button-align-stretch .e-form__buttons__wrapper {
        flex-basis: 50%;
        flex-grow: 1
    }

    .elementor-mobile-button-align-stretch .e-form__buttons__wrapper__button {
        flex-basis: 100%
    }

    .elementor-mobile-button-align-center .e-form__buttons,
    .elementor-mobile-button-align-center .elementor-field-type-submit {
        justify-content: center
    }

    .elementor-mobile-button-align-start .e-form__buttons,
    .elementor-mobile-button-align-start .elementor-field-type-submit {
        justify-content: flex-start
    }

    .elementor-mobile-button-align-end .e-form__buttons,
    .elementor-mobile-button-align-end .elementor-field-type-submit {
        justify-content: flex-end
    }

    .elementor-mobile-button-align-center .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button,
    .elementor-mobile-button-align-end .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button,
    .elementor-mobile-button-align-start .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button {
        flex-basis: auto
    }

    .elementor-mobile-button-align-center .e-form__buttons__wrapper,
    .elementor-mobile-button-align-end .e-form__buttons__wrapper,
    .elementor-mobile-button-align-start .e-form__buttons__wrapper {
        flex-grow: 0
    }

    .elementor-mobile-button-align-center .e-form__buttons__wrapper,
    .elementor-mobile-button-align-center .e-form__buttons__wrapper__button,
    .elementor-mobile-button-align-end .e-form__buttons__wrapper,
    .elementor-mobile-button-align-end .e-form__buttons__wrapper__button,
    .elementor-mobile-button-align-start .e-form__buttons__wrapper,
    .elementor-mobile-button-align-start .e-form__buttons__wrapper__button {
        flex-basis: auto
    }
}

.elementor-error .elementor-field {
    border-color: #d9534f
}

.elementor-error .help-inline {
    color: #d9534f;
    font-size: .9em
}

.elementor-message {
    margin: 10px 0;
    font-size: 1em;
    line-height: 1
}

.elementor-message:before {
    content: "\e90e";
    display: inline-block;
    font-family: eicons;
    font-weight: 400;
    font-style: normal;
    vertical-align: middle;
    margin-inline-end: 5px
}

.elementor-message.elementor-message-danger {
    color: #d9534f
}

.elementor-message.elementor-message-danger:before {
    content: "\e87f"
}

.elementor-message.form-message-success {
    color: #5cb85c
}

.elementor-form .elementor-button {
    padding-block-start: 0;
    padding-block-end: 0;
    border: none
}

.elementor-form .elementor-button-content-wrapper,
.elementor-form .elementor-button>span {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 5px
}

.elementor-form .elementor-button.elementor-size-xs {
    min-height: 33px
}

.elementor-form .elementor-button.elementor-size-sm {
    min-height: 40px
}

.elementor-form .elementor-button.elementor-size-md {
    min-height: 47px
}

.elementor-form .elementor-button.elementor-size-lg {
    min-height: 59px
}

.elementor-form .elementor-button.elementor-size-xl {
    min-height: 72px
}

.elementor-element .elementor-widget-container {
    transition: background .3s, border .3s, border-radius .3s, box-shadow .3s, transform var(--e-transform-transition-duration, .4s)
}

.elementor-button {
    display: inline-block;
    line-height: 1;
    background-color: #69727d;
    font-size: 15px;
    padding: 12px 24px;
    border-radius: 3px;
    color: #fff;
    fill: #fff;
    text-align: center;
    transition: all .3s
}

.elementor-button:focus,
.elementor-button:hover,
.elementor-button:visited {
    color: #fff
}

.elementor-button-content-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 5px
}

.elementor-button-icon svg {
    width: 1em;
    height: auto
}

.elementor-button-icon .e-font-icon-svg {
    height: 1em
}

.elementor-button-text {
    display: inline-block
}

.elementor-button.elementor-size-xs {
    font-size: 13px;
    padding: 10px 20px;
    border-radius: 2px
}

.elementor-button.elementor-size-md {
    font-size: 16px;
    padding: 15px 30px;
    border-radius: 4px
}

.elementor-button.elementor-size-lg {
    font-size: 18px;
    padding: 20px 40px;
    border-radius: 5px
}

.elementor-button.elementor-size-xl {
    font-size: 20px;
    padding: 25px 50px;
    border-radius: 6px
}

.elementor-button .elementor-align-icon-right {
    order: 15
}

.elementor-button .elementor-align-icon-left {
    order: 5
}

.elementor-button span {
    text-decoration: inherit
}

.elementor-element.elementor-button-info .elementor-button {
    background-color: #5bc0de
}

.elementor-element.elementor-button-success .elementor-button {
    background-color: #5cb85c
}

.elementor-element.elementor-button-warning .elementor-button {
    background-color: #f0ad4e
}

.elementor-element.elementor-button-danger .elementor-button {
    background-color: #d9534f
}

.elementor-widget-button .elementor-button .elementor-button-info {
    background-color: #5bc0de
}

.elementor-widget-button .elementor-button .elementor-button-success {
    background-color: #5cb85c
}

.elementor-widget-button .elementor-button .elementor-button-warning {
    background-color: #f0ad4e
}

.elementor-widget-button .elementor-button .elementor-button-danger {
    background-color: #d9534f
}

.elementor-tab-title a {
    color: inherit
}

.elementor-view-stacked .elementor-icon {
    padding: .5em;
    background-color: #69727d;
    color: #fff;
    fill: #fff
}

.elementor-view-framed .elementor-icon {
    padding: .5em;
    color: #69727d;
    border: 3px solid #69727d;
    background-color: transparent
}

.elementor-icon {
    display: inline-block;
    line-height: 1;
    transition: all .3s;
    color: #69727d;
    font-size: 50px;
    text-align: center
}

.elementor-icon:hover {
    color: #69727d
}

.elementor-icon i,
.elementor-icon svg {
    width: 1em;
    height: 1em;
    position: relative;
    display: block
}

.elementor-icon i:before,
.elementor-icon svg:before {
    position: absolute;
    left: 50%;
    transform: translateX(-50%)
}

.elementor-icon i.fad {
    width: auto
}

.elementor-shape-circle .elementor-icon {
    border-radius: 50%
}

.e-transform .elementor-widget-container {
    transform: perspective(var(--e-transform-perspective, 0)) rotate(var(--e-transform-rotateZ, 0)) rotateX(var(--e-transform-rotateX, 0)) rotateY(var(--e-transform-rotateY, 0)) translate(var(--e-transform-translate, 0)) translateX(var(--e-transform-translateX, 0)) translateY(var(--e-transform-translateY, 0)) scaleX(calc(var(--e-transform-flipX, 1) * var(--e-transform-scaleX, var(--e-transform-scale, 1)))) scaleY(calc(var(--e-transform-flipY, 1) * var(--e-transform-scaleY, var(--e-transform-scale, 1)))) skewX(var(--e-transform-skewX, 0)) skewY(var(--e-transform-skewY, 0));
    transform-origin: var(--e-transform-origin-y) var(--e-transform-origin-x)
}

.e-con.e-transform {
    transform: perspective(var(--e-con-transform-perspective, 0)) rotate(var(--e-con-transform-rotateZ, 0)) rotateX(var(--e-con-transform-rotateX, 0)) rotateY(var(--e-con-transform-rotateY, 0)) translate(var(--e-con-transform-translate, 0)) translateX(var(--e-con-transform-translateX, 0)) translateY(var(--e-con-transform-translateY, 0)) scaleX(calc(var(--e-con-transform-flipX, 1) * var(--e-con-transform-scaleX, var(--e-con-transform-scale, 1)))) scaleY(calc(var(--e-con-transform-flipY, 1) * var(--e-con-transform-scaleY, var(--e-con-transform-scale, 1)))) skewX(var(--e-con-transform-skewX, 0)) skewY(var(--e-con-transform-skewY, 0));
    transform-origin: var(--e-con-transform-origin-y) var(--e-con-transform-origin-x)
}

.elementor-element,
.elementor-lightbox {
    --swiper-theme-color: #000;
    --swiper-navigation-size: 44px;
    --swiper-pagination-bullet-size: 6px;
    --swiper-pagination-bullet-horizontal-gap: 6px
}

.elementor-element .swiper-container .swiper-slide figure,
.elementor-element .swiper .swiper-slide figure,
.elementor-lightbox .swiper-container .swiper-slide figure,
.elementor-lightbox .swiper .swiper-slide figure {
    line-height: 0
}

.elementor-element .swiper-container .elementor-lightbox-content-source,
.elementor-element .swiper .elementor-lightbox-content-source,
.elementor-lightbox .swiper-container .elementor-lightbox-content-source,
.elementor-lightbox .swiper .elementor-lightbox-content-source {
    display: none
}

.elementor-element .swiper-container .elementor-swiper-button,
.elementor-element .swiper-container~.elementor-swiper-button,
.elementor-element .swiper .elementor-swiper-button,
.elementor-element .swiper~.elementor-swiper-button,
.elementor-lightbox .swiper-container .elementor-swiper-button,
.elementor-lightbox .swiper-container~.elementor-swiper-button,
.elementor-lightbox .swiper .elementor-swiper-button,
.elementor-lightbox .swiper~.elementor-swiper-button {
    position: absolute;
    display: inline-flex;
    z-index: 1;
    cursor: pointer;
    font-size: 25px;
    color: hsla(0, 0%, 93.3%, .9);
    top: 50%;
    transform: translateY(-50%)
}

.elementor-element .swiper-container .elementor-swiper-button svg,
.elementor-element .swiper-container~.elementor-swiper-button svg,
.elementor-element .swiper .elementor-swiper-button svg,
.elementor-element .swiper~.elementor-swiper-button svg,
.elementor-lightbox .swiper-container .elementor-swiper-button svg,
.elementor-lightbox .swiper-container~.elementor-swiper-button svg,
.elementor-lightbox .swiper .elementor-swiper-button svg,
.elementor-lightbox .swiper~.elementor-swiper-button svg {
    fill: hsla(0, 0%, 93.3%, .9);
    height: 1em;
    width: 1em
}

.elementor-element .swiper-container .elementor-swiper-button-prev,
.elementor-element .swiper-container~.elementor-swiper-button-prev,
.elementor-element .swiper .elementor-swiper-button-prev,
.elementor-element .swiper~.elementor-swiper-button-prev,
.elementor-lightbox .swiper-container .elementor-swiper-button-prev,
.elementor-lightbox .swiper-container~.elementor-swiper-button-prev,
.elementor-lightbox .swiper .elementor-swiper-button-prev,
.elementor-lightbox .swiper~.elementor-swiper-button-prev {
    left: 10px
}

.elementor-element .swiper-container .elementor-swiper-button-next,
.elementor-element .swiper-container~.elementor-swiper-button-next,
.elementor-element .swiper .elementor-swiper-button-next,
.elementor-element .swiper~.elementor-swiper-button-next,
.elementor-lightbox .swiper-container .elementor-swiper-button-next,
.elementor-lightbox .swiper-container~.elementor-swiper-button-next,
.elementor-lightbox .swiper .elementor-swiper-button-next,
.elementor-lightbox .swiper~.elementor-swiper-button-next {
    right: 10px
}

.elementor-element .swiper-container .elementor-swiper-button.swiper-button-disabled,
.elementor-element .swiper-container~.elementor-swiper-button.swiper-button-disabled,
.elementor-element .swiper .elementor-swiper-button.swiper-button-disabled,
.elementor-element .swiper~.elementor-swiper-button.swiper-button-disabled,
.elementor-lightbox .swiper-container .elementor-swiper-button.swiper-button-disabled,
.elementor-lightbox .swiper-container~.elementor-swiper-button.swiper-button-disabled,
.elementor-lightbox .swiper .elementor-swiper-button.swiper-button-disabled,
.elementor-lightbox .swiper~.elementor-swiper-button.swiper-button-disabled {
    opacity: .3
}

.elementor-element .swiper-container .swiper-image-stretch .swiper-slide .swiper-slide-image,
.elementor-element .swiper .swiper-image-stretch .swiper-slide .swiper-slide-image,
.elementor-lightbox .swiper-container .swiper-image-stretch .swiper-slide .swiper-slide-image,
.elementor-lightbox .swiper .swiper-image-stretch .swiper-slide .swiper-slide-image {
    width: 100%
}

.elementor-element .swiper-container .swiper-horizontal>.swiper-pagination-bullets,
.elementor-element .swiper-container .swiper-pagination-bullets.swiper-pagination-horizontal,
.elementor-element .swiper-container .swiper-pagination-custom,
.elementor-element .swiper-container .swiper-pagination-fraction,
.elementor-element .swiper-container~.swiper-pagination-bullets.swiper-pagination-horizontal,
.elementor-element .swiper-container~.swiper-pagination-custom,
.elementor-element .swiper-container~.swiper-pagination-fraction,
.elementor-element .swiper .swiper-horizontal>.swiper-pagination-bullets,
.elementor-element .swiper .swiper-pagination-bullets.swiper-pagination-horizontal,
.elementor-element .swiper .swiper-pagination-custom,
.elementor-element .swiper .swiper-pagination-fraction,
.elementor-element .swiper~.swiper-pagination-bullets.swiper-pagination-horizontal,
.elementor-element .swiper~.swiper-pagination-custom,
.elementor-element .swiper~.swiper-pagination-fraction,
.elementor-lightbox .swiper-container .swiper-horizontal>.swiper-pagination-bullets,
.elementor-lightbox .swiper-container .swiper-pagination-bullets.swiper-pagination-horizontal,
.elementor-lightbox .swiper-container .swiper-pagination-custom,
.elementor-lightbox .swiper-container .swiper-pagination-fraction,
.elementor-lightbox .swiper-container~.swiper-pagination-bullets.swiper-pagination-horizontal,
.elementor-lightbox .swiper-container~.swiper-pagination-custom,
.elementor-lightbox .swiper-container~.swiper-pagination-fraction,
.elementor-lightbox .swiper .swiper-horizontal>.swiper-pagination-bullets,
.elementor-lightbox .swiper .swiper-pagination-bullets.swiper-pagination-horizontal,
.elementor-lightbox .swiper .swiper-pagination-custom,
.elementor-lightbox .swiper .swiper-pagination-fraction,
.elementor-lightbox .swiper~.swiper-pagination-bullets.swiper-pagination-horizontal,
.elementor-lightbox .swiper~.swiper-pagination-custom,
.elementor-lightbox .swiper~.swiper-pagination-fraction {
    bottom: 5px
}

.elementor-element .swiper-container.swiper-cube .elementor-swiper-button,
.elementor-element .swiper-container.swiper-cube~.elementor-swiper-button,
.elementor-element .swiper.swiper-cube .elementor-swiper-button,
.elementor-element .swiper.swiper-cube~.elementor-swiper-button,
.elementor-lightbox .swiper-container.swiper-cube .elementor-swiper-button,
.elementor-lightbox .swiper-container.swiper-cube~.elementor-swiper-button,
.elementor-lightbox .swiper.swiper-cube .elementor-swiper-button,
.elementor-lightbox .swiper.swiper-cube~.elementor-swiper-button {
    transform: translate3d(0, -50%, 1px)
}

.elementor-element :where(.swiper-container-horizontal)~.swiper-pagination-bullets,
.elementor-lightbox :where(.swiper-container-horizontal)~.swiper-pagination-bullets {
    bottom: 5px;
    left: 0;
    width: 100%
}

.elementor-element :where(.swiper-container-horizontal)~.swiper-pagination-bullets .swiper-pagination-bullet,
.elementor-lightbox :where(.swiper-container-horizontal)~.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px)
}

.elementor-element :where(.swiper-container-horizontal)~.swiper-pagination-progressbar,
.elementor-lightbox :where(.swiper-container-horizontal)~.swiper-pagination-progressbar {
    width: 100%;
    height: 4px;
    left: 0;
    top: 0
}

.elementor-element.elementor-pagination-position-outside .swiper,
.elementor-element.elementor-pagination-position-outside .swiper-container,
.elementor-lightbox.elementor-pagination-position-outside .swiper,
.elementor-lightbox.elementor-pagination-position-outside .swiper-container {
    padding-bottom: 30px
}

.elementor-element.elementor-pagination-position-outside .swiper-container .elementor-swiper-button,
.elementor-element.elementor-pagination-position-outside .swiper-container~.elementor-swiper-button,
.elementor-element.elementor-pagination-position-outside .swiper .elementor-swiper-button,
.elementor-element.elementor-pagination-position-outside .swiper~.elementor-swiper-button,
.elementor-lightbox.elementor-pagination-position-outside .swiper-container .elementor-swiper-button,
.elementor-lightbox.elementor-pagination-position-outside .swiper-container~.elementor-swiper-button,
.elementor-lightbox.elementor-pagination-position-outside .swiper .elementor-swiper-button,
.elementor-lightbox.elementor-pagination-position-outside .swiper~.elementor-swiper-button {
    top: calc(50% - 30px / 2)
}

.elementor-element .elementor-swiper,
.elementor-lightbox .elementor-swiper {
    position: relative
}

.elementor-element .elementor-main-swiper,
.elementor-lightbox .elementor-main-swiper {
    position: static
}

.elementor-element.elementor-arrows-position-outside .swiper,
.elementor-element.elementor-arrows-position-outside .swiper-container,
.elementor-lightbox.elementor-arrows-position-outside .swiper,
.elementor-lightbox.elementor-arrows-position-outside .swiper-container {
    width: calc(100% - 60px)
}

.elementor-element.elementor-arrows-position-outside .swiper-container .elementor-swiper-button-prev,
.elementor-element.elementor-arrows-position-outside .swiper-container~.elementor-swiper-button-prev,
.elementor-element.elementor-arrows-position-outside .swiper .elementor-swiper-button-prev,
.elementor-element.elementor-arrows-position-outside .swiper~.elementor-swiper-button-prev,
.elementor-lightbox.elementor-arrows-position-outside .swiper-container .elementor-swiper-button-prev,
.elementor-lightbox.elementor-arrows-position-outside .swiper-container~.elementor-swiper-button-prev,
.elementor-lightbox.elementor-arrows-position-outside .swiper .elementor-swiper-button-prev,
.elementor-lightbox.elementor-arrows-position-outside .swiper~.elementor-swiper-button-prev {
    left: 0
}

.elementor-element.elementor-arrows-position-outside .swiper-container .elementor-swiper-button-next,
.elementor-element.elementor-arrows-position-outside .swiper-container~.elementor-swiper-button-next,
.elementor-element.elementor-arrows-position-outside .swiper .elementor-swiper-button-next,
.elementor-element.elementor-arrows-position-outside .swiper~.elementor-swiper-button-next,
.elementor-lightbox.elementor-arrows-position-outside .swiper-container .elementor-swiper-button-next,
.elementor-lightbox.elementor-arrows-position-outside .swiper-container~.elementor-swiper-button-next,
.elementor-lightbox.elementor-arrows-position-outside .swiper .elementor-swiper-button-next,
.elementor-lightbox.elementor-arrows-position-outside .swiper~.elementor-swiper-button-next {
    right: 0
}

.elementor-lightbox {
    --lightbox-ui-color: hsla(0, 0%, 93.3%, 0.9);
    --lightbox-ui-color-hover: #fff;
    --lightbox-text-color: var(--lightbox-ui-color);
    --lightbox-header-icons-size: 20px;
    --lightbox-navigation-icons-size: 25px
}

.elementor-lightbox:not(.elementor-popup-modal) .dialog-header,
.elementor-lightbox:not(.elementor-popup-modal) .dialog-message {
    text-align: center
}

.elementor-lightbox .dialog-header {
    display: none
}

.elementor-lightbox .dialog-widget-content {
    background: none;
    box-shadow: none;
    width: 100%;
    height: 100%
}

.elementor-lightbox .dialog-message {
    animation-duration: .3s;
    height: 100%
}

.elementor-lightbox .dialog-message.dialog-lightbox-message {
    padding: 0
}

.elementor-lightbox .dialog-lightbox-close-button {
    cursor: pointer;
    position: absolute;
    font-size: var(--lightbox-header-icons-size);
    right: .75em;
    margin-top: 13px;
    padding: .25em;
    z-index: 2;
    line-height: 1;
    display: flex
}

.elementor-lightbox .dialog-lightbox-close-button svg {
    height: 1em;
    width: 1em
}

.elementor-lightbox .dialog-lightbox-close-button,
.elementor-lightbox .elementor-swiper-button {
    color: var(--lightbox-ui-color);
    transition: all .3s;
    opacity: 1
}

.elementor-lightbox .dialog-lightbox-close-button svg,
.elementor-lightbox .elementor-swiper-button svg {
    fill: var(--lightbox-ui-color)
}

.elementor-lightbox .dialog-lightbox-close-button:hover,
.elementor-lightbox .elementor-swiper-button:hover {
    color: var(--lightbox-ui-color-hover)
}

.elementor-lightbox .dialog-lightbox-close-button:hover svg,
.elementor-lightbox .elementor-swiper-button:hover svg {
    fill: var(--lightbox-ui-color-hover)
}

.elementor-lightbox .swiper,
.elementor-lightbox .swiper-container {
    height: 100%
}

.elementor-lightbox .elementor-lightbox-item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 70px;
    box-sizing: border-box;
    height: 100%;
    margin: auto
}

@media (max-width:767px) {
    .elementor-lightbox .elementor-lightbox-item {
        padding: 70px 0
    }
}

.elementor-lightbox .elementor-lightbox-image {
    max-height: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.elementor-lightbox .elementor-lightbox-image,
.elementor-lightbox .elementor-lightbox-image:hover {
    opacity: 1;
    filter: none;
    border: none
}

.elementor-lightbox .elementor-lightbox-image {
    box-shadow: 0 0 30px rgba(0, 0, 0, .3), 0 0 8px -5px rgba(0, 0, 0, .3);
    border-radius: 2px
}

.elementor-lightbox .elementor-video-container {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.elementor-lightbox .elementor-video-container .elementor-video-landscape,
.elementor-lightbox .elementor-video-container .elementor-video-portrait,
.elementor-lightbox .elementor-video-container .elementor-video-square {
    width: 100%;
    height: 100%;
    margin: auto
}

.elementor-lightbox .elementor-video-container .elementor-video-landscape iframe,
.elementor-lightbox .elementor-video-container .elementor-video-portrait iframe,
.elementor-lightbox .elementor-video-container .elementor-video-square iframe {
    border: 0;
    background-color: #000
}

.elementor-lightbox .elementor-video-container .elementor-video-landscape iframe,
.elementor-lightbox .elementor-video-container .elementor-video-landscape video,
.elementor-lightbox .elementor-video-container .elementor-video-portrait iframe,
.elementor-lightbox .elementor-video-container .elementor-video-portrait video,
.elementor-lightbox .elementor-video-container .elementor-video-square iframe,
.elementor-lightbox .elementor-video-container .elementor-video-square video {
    aspect-ratio: var(--video-aspect-ratio, 1.77777)
}

@supports not (aspect-ratio:1/1) {

    .elementor-lightbox .elementor-video-container .elementor-video-landscape,
    .elementor-lightbox .elementor-video-container .elementor-video-portrait,
    .elementor-lightbox .elementor-video-container .elementor-video-square {
        position: relative;
        overflow: hidden;
        height: 0;
        padding-bottom: calc(100% / var(--video-aspect-ratio, 1.77777))
    }

    .elementor-lightbox .elementor-video-container .elementor-video-landscape iframe,
    .elementor-lightbox .elementor-video-container .elementor-video-landscape video,
    .elementor-lightbox .elementor-video-container .elementor-video-portrait iframe,
    .elementor-lightbox .elementor-video-container .elementor-video-portrait video,
    .elementor-lightbox .elementor-video-container .elementor-video-square iframe,
    .elementor-lightbox .elementor-video-container .elementor-video-square video {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0
    }
}

.elementor-lightbox .elementor-video-container .elementor-video-square iframe,
.elementor-lightbox .elementor-video-container .elementor-video-square video {
    width: min(90vh, 90vw);
    height: min(90vh, 90vw)
}

.elementor-lightbox .elementor-video-container .elementor-video-landscape iframe,
.elementor-lightbox .elementor-video-container .elementor-video-landscape video {
    width: 100%;
    max-height: 90vh
}

.elementor-lightbox .elementor-video-container .elementor-video-portrait iframe,
.elementor-lightbox .elementor-video-container .elementor-video-portrait video {
    height: 100%;
    max-width: 90vw
}

@media (min-width:1025px) {
    .elementor-lightbox .elementor-video-container .elementor-video-landscape {
        width: 85vw;
        max-height: 85vh
    }

    .elementor-lightbox .elementor-video-container .elementor-video-portrait {
        height: 85vh;
        max-width: 85vw
    }
}

@media (max-width:1024px) {
    .elementor-lightbox .elementor-video-container .elementor-video-landscape {
        width: 95vw;
        max-height: 95vh
    }

    .elementor-lightbox .elementor-video-container .elementor-video-portrait {
        height: 95vh;
        max-width: 95vw
    }
}

.elementor-lightbox .swiper-container .elementor-swiper-button-prev,
.elementor-lightbox .swiper .elementor-swiper-button-prev {
    left: 0
}

.elementor-lightbox .swiper-container .elementor-swiper-button-next,
.elementor-lightbox .swiper .elementor-swiper-button-next {
    right: 0
}

.elementor-lightbox .swiper-container .swiper-pagination-fraction,
.elementor-lightbox .swiper .swiper-pagination-fraction {
    width: -moz-max-content;
    width: max-content;
    color: #fff
}

.elementor-lightbox .elementor-swiper-button:focus {
    outline-width: 1px
}

.elementor-lightbox .elementor-swiper-button-next,
.elementor-lightbox .elementor-swiper-button-prev {
    height: 100%;
    display: flex;
    align-items: center;
    width: 15%;
    justify-content: center;
    font-size: var(--lightbox-navigation-icons-size)
}

@media (max-width:767px) {
    .elementor-lightbox .elementor-swiper-button:focus {
        outline: none
    }

    .elementor-lightbox .elementor-swiper-button-next,
    .elementor-lightbox .elementor-swiper-button-prev {
        width: 20%
    }

    .elementor-lightbox .elementor-swiper-button-next i,
    .elementor-lightbox .elementor-swiper-button-prev i {
        padding: 10px;
        background-color: rgba(0, 0, 0, .5)
    }

    .elementor-lightbox .elementor-swiper-button-prev {
        left: 0;
        justify-content: flex-start
    }

    .elementor-lightbox .elementor-swiper-button-next {
        right: 0;
        justify-content: flex-end
    }
}

.elementor-slideshow__counter {
    color: currentColor;
    font-size: .75em;
    width: -moz-max-content;
    width: max-content
}

.elementor-slideshow__footer,
.elementor-slideshow__header {
    position: absolute;
    left: 0;
    width: 100%;
    padding: 15px 20px;
    transition: .3s
}

.elementor-slideshow__footer {
    color: var(--lightbox-text-color)
}

.elementor-slideshow__header {
    color: var(--lightbox-ui-color);
    display: flex;
    flex-direction: row-reverse;
    font-size: var(--lightbox-header-icons-size);
    padding-inline-start: 1em;
    padding-inline-end: 2.6em;
    top: 0;
    align-items: center;
    z-index: 10
}

.elementor-slideshow__header>i,
.elementor-slideshow__header>svg {
    cursor: pointer;
    padding: .25em;
    margin: 0 .35em
}

.elementor-slideshow__header>i {
    font-size: inherit
}

.elementor-slideshow__header>i:hover {
    color: var(--lightbox-ui-color-hover)
}

.elementor-slideshow__header>svg {
    box-sizing: content-box;
    fill: var(--lightbox-ui-color);
    height: 1em;
    width: 1em
}

.elementor-slideshow__header>svg:hover {
    fill: var(--lightbox-ui-color-hover)
}

.elementor-slideshow__header .elementor-slideshow__counter {
    margin-inline-end: auto
}

.elementor-slideshow__header .elementor-icon-share {
    z-index: 5
}

.elementor-slideshow__share-menu {
    background-color: transparent;
    width: 0;
    height: 0;
    position: absolute;
    overflow: hidden;
    transition: background-color .4s
}

.elementor-slideshow__share-menu .elementor-slideshow__share-links a {
    color: #0c0d0e
}

.elementor-slideshow__share-links {
    display: block;
    position: absolute;
    min-width: 200px;
    right: 2.8em;
    top: 3em;
    background-color: #fff;
    border-radius: 3px;
    padding: 14px 20px;
    transform: scale(0);
    opacity: 0;
    transform-origin: 90% 10%;
    transition: all .25s .1s;
    box-shadow: 0 4px 15px rgba(0, 0, 0, .3)
}

.elementor-slideshow__share-links a {
    text-align: start;
    color: #3f444b;
    font-size: 12px;
    line-height: 2.5;
    display: block;
    opacity: 0;
    transition: opacity .5s .1s
}

.elementor-slideshow__share-links a:hover {
    color: #000
}

.elementor-slideshow__share-links a i,
.elementor-slideshow__share-links a svg {
    margin-inline-end: .75em
}

.elementor-slideshow__share-links a i {
    font-size: 1.25em
}

.elementor-slideshow__share-links a svg {
    height: 1.25em;
    width: 1.25em
}

.elementor-slideshow__share-links:before {
    content: "";
    display: block;
    position: absolute;
    top: 1px;
    right: .5em;
    border: .45em solid transparent;
    border-bottom-color: #fff;
    transform: translateY(-100%) scaleX(.7)
}

.elementor-slideshow__footer {
    bottom: 0;
    z-index: 5;
    position: fixed
}

.elementor-slideshow__description,
.elementor-slideshow__title {
    margin: 0
}

.elementor-slideshow__title {
    font-size: 16px;
    font-weight: 700
}

.elementor-slideshow__description {
    font-size: 14px
}

.elementor-slideshow--ui-hidden .elementor-slideshow__footer,
.elementor-slideshow--ui-hidden .elementor-slideshow__header {
    opacity: 0;
    pointer-events: none
}

.elementor-slideshow--ui-hidden .elementor-swiper-button-next,
.elementor-slideshow--ui-hidden .elementor-swiper-button-prev {
    opacity: 0
}

.elementor-slideshow--fullscreen-mode .elementor-video-container {
    width: 100%
}

.elementor-slideshow--zoom-mode .elementor-slideshow__footer,
.elementor-slideshow--zoom-mode .elementor-slideshow__header {
    background-color: rgba(0, 0, 0, .5)
}

.elementor-slideshow--zoom-mode .elementor-swiper-button-next,
.elementor-slideshow--zoom-mode .elementor-swiper-button-prev {
    opacity: 0;
    pointer-events: none
}

.elementor-slideshow--share-mode .elementor-slideshow__share-menu {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 1;
    cursor: default;
    background-color: rgba(0, 0, 0, .5)
}

.elementor-slideshow--share-mode .elementor-slideshow__share-links {
    transform: scale(1)
}

.elementor-slideshow--share-mode .elementor-slideshow__share-links,
.elementor-slideshow--share-mode .elementor-slideshow__share-links a {
    opacity: 1
}

.elementor-slideshow--share-mode .elementor-slideshow__share-links .eicon-twitter {
    color: #1da1f2
}

.elementor-slideshow--share-mode .elementor-slideshow__share-links .eicon-facebook {
    color: #3b5998
}

.elementor-slideshow--share-mode .elementor-slideshow__share-links .eicon-pinterest {
    color: #bd081c
}

.elementor-slideshow--share-mode .elementor-slideshow__share-links .eicon-download-bold {
    color: #9da5ae
}

.elementor-slideshow--share-mode .elementor-slideshow__share-links .e-eicon-twitter {
    fill: #1da1f2
}

.elementor-slideshow--share-mode .elementor-slideshow__share-links .e-eicon-facebook {
    fill: #3b5998
}

.elementor-slideshow--share-mode .elementor-slideshow__share-links .e-eicon-pinterest {
    fill: #bd081c
}

.elementor-slideshow--share-mode .elementor-slideshow__share-links .e-eicon-download-bold {
    fill: #9da5ae
}

.elementor-slideshow--share-mode .eicon-share-arrow {
    z-index: 2
}

.animated {
    animation-duration: 1.25s
}

.animated.animated-slow {
    animation-duration: 2s
}

.animated.animated-fast {
    animation-duration: .75s
}

.animated.infinite {
    animation-iteration-count: infinite
}

.animated.reverse {
    animation-direction: reverse;
    animation-fill-mode: forwards
}

@media (prefers-reduced-motion:reduce) {
    .animated {
        animation: none
    }
}

.elementor-shape {
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr
}

.elementor-shape-top {
    top: -1px
}

.elementor-shape-top:not([data-negative=false]) svg {
    z-index: -1
}

.elementor-shape-bottom {
    bottom: -1px
}

.elementor-shape-bottom:not([data-negative=true]) svg {
    z-index: -1
}

.elementor-shape[data-negative=false].elementor-shape-bottom,
.elementor-shape[data-negative=true].elementor-shape-top {
    transform: rotate(180deg)
}

.elementor-shape svg {
    display: block;
    width: calc(100% + 1.3px);
    position: relative;
    left: 50%;
    transform: translateX(-50%)
}

.elementor-shape .elementor-shape-fill {
    fill: #fff;
    transform-origin: center;
    transform: rotateY(0deg)
}

#wp-admin-bar-elementor_edit_page>.ab-item:before {
    content: "\e813";
    font-family: eicons;
    top: 3px;
    font-size: 18px
}

#wp-admin-bar-elementor_edit_page .ab-submenu .ab-item {
    display: flex;
    width: 200px
}

#wp-admin-bar-elementor_edit_page .elementor-edit-link-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%
}

#wp-admin-bar-elementor_edit_page .elementor-edit-link-type {
    background: #3f444b;
    font-size: 11px;
    line-height: 9px;
    margin-top: 6px;
    padding: 4px 8px;
    border-radius: 3px
}

#wp-admin-bar-elementor_inspector>.ab-item:before {
    content: "\f348";
    top: 2px
}

#wpadminbar * {
    font-style: normal
}

.page-template-elementor_canvas.elementor-page:before {
    display: none
}

.elementor-post__thumbnail__link {
    transition: none
}

#left-area ul.elementor-icon-list-items,
.elementor-edit-area .elementor-element ul.elementor-icon-list-items,
.elementor .elementor-element ul.elementor-icon-list-items {
    padding: 0
}

.e--ua-appleWebkit.rtl {
    --flex-right: flex-start
}

.e--ua-appleWebkit .elementor-share-buttons--align-right,
.e--ua-appleWebkit .elementor-widget-social-icons.e-grid-align-right {
    --justify-content: var(--flex-right, flex-end)
}

.e--ua-appleWebkit .elementor-share-buttons--align-center,
.e--ua-appleWebkit .elementor-widget-social-icons.e-grid-align-center {
    --justify-content: center
}

.e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-center .elementor-grid,
.e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-justify .elementor-grid,
.e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-right .elementor-grid,
.e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-center .elementor-grid,
.e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-right .elementor-grid {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: var(--justify-content, space-between);
    margin-left: calc(-.5 * var(--grid-column-gap));
    margin-right: calc(-.5 * var(--grid-column-gap))
}

.e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-center .elementor-grid-item,
.e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-justify .elementor-grid-item,
.e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-right .elementor-grid-item,
.e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-center .elementor-grid-item,
.e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-right .elementor-grid-item {
    margin-left: calc(.5 * var(--grid-column-gap));
    margin-right: calc(.5 * var(--grid-column-gap))
}

.e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-left .elementor-grid,
.e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-left .elementor-grid {
    display: inline-block
}

.e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-left .elementor-grid,
.e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-left .elementor-grid-item,
.e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-left .elementor-grid,
.e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-left .elementor-grid-item {
    margin-left: 0;
    margin-right: 0
}

@media (max-width:1024px) {

    .e--ua-appleWebkit .elementor-share-buttons-tablet--align-right,
    .e--ua-appleWebkit .elementor-widget-social-icons.e-grid-align-tablet-right {
        --justify-content: var(--flex-right, flex-end)
    }

    .e--ua-appleWebkit .elementor-share-buttons-tablet--align-center,
    .e--ua-appleWebkit .elementor-widget-social-icons.e-grid-align-tablet-center {
        --justify-content: center
    }

    .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-tablet-center .elementor-grid,
    .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-tablet-justify .elementor-grid,
    .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-tablet-right .elementor-grid,
    .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-tablet-center .elementor-grid,
    .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-tablet-right .elementor-grid {
        width: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: var(--justify-content, space-between);
        margin-left: calc(-.5 * var(--grid-column-gap));
        margin-right: calc(-.5 * var(--grid-column-gap))
    }

    .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-tablet-center .elementor-grid-item,
    .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-tablet-justify .elementor-grid-item,
    .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-tablet-right .elementor-grid-item,
    .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-tablet-center .elementor-grid-item,
    .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-tablet-right .elementor-grid-item {
        margin-left: calc(.5 * var(--grid-column-gap));
        margin-right: calc(.5 * var(--grid-column-gap))
    }

    .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons-tablet--align-left .elementor-grid,
    .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-tablet-left .elementor-grid {
        display: inline-block
    }

    .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons-tablet--align-left .elementor-grid,
    .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons-tablet--align-left .elementor-grid-item,
    .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-tablet-left .elementor-grid,
    .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-tablet-left .elementor-grid-item {
        margin-left: 0;
        margin-right: 0
    }
}

@media (max-width:767px) {

    .e--ua-appleWebkit .elementor-share-buttons-mobile--align-right,
    .e--ua-appleWebkit .elementor-widget-social-icons.e-grid-align-mobile-right {
        --justify-content: var(--flex-right, flex-end)
    }

    .e--ua-appleWebkit .elementor-share-buttons-mobile--align-center,
    .e--ua-appleWebkit .elementor-widget-social-icons.e-grid-align-mobile-center {
        --justify-content: center
    }

    .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-mobile-center .elementor-grid,
    .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-mobile-justify .elementor-grid,
    .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-mobile-right .elementor-grid,
    .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-mobile-center .elementor-grid,
    .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-mobile-right .elementor-grid {
        width: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: var(--justify-content, space-between);
        margin-left: calc(-.5 * var(--grid-column-gap));
        margin-right: calc(-.5 * var(--grid-column-gap))
    }

    .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-mobile-center .elementor-grid-item,
    .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-mobile-justify .elementor-grid-item,
    .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-mobile-right .elementor-grid-item,
    .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-mobile-center .elementor-grid-item,
    .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-mobile-right .elementor-grid-item {
        margin-left: calc(.5 * var(--grid-column-gap));
        margin-right: calc(.5 * var(--grid-column-gap))
    }

    .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons-mobile--align-left .elementor-grid,
    .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-mobile-left .elementor-grid {
        display: inline-block
    }

    .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons-mobile--align-left .elementor-grid,
    .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons-mobile--align-left .elementor-grid-item,
    .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-mobile-left .elementor-grid,
    .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-mobile-left .elementor-grid-item {
        margin-left: 0;
        margin-right: 0
    }
}

@media (max-width:767px) {

    .elementor .elementor-hidden-mobile,
    .elementor .elementor-hidden-phone {
        display: none
    }
}

@media (min-width:768px) and (max-width:880px) {
    .elementor .elementor-hidden-mobile_extra {
        display: none
    }
}

@media (min-width:881px) and (max-width:1024px) {
    .elementor .elementor-hidden-tablet {
        display: none
    }
}

@media (min-width:1025px) and (max-width:1200px) {
    .elementor .elementor-hidden-tablet_extra {
        display: none
    }
}

@media (min-width:1201px) and (max-width:1366px) {
    .elementor .elementor-hidden-laptop {
        display: none
    }
}

@media (min-width:1367px) and (max-width:99999px) {
    .elementor .elementor-hidden-desktop {
        display: none
    }
}

@media (min-width:1) {
    .elementor .elementor-hidden-widescreen {
        display: none
    }
}

.elementor-widget-text-path {
    font-size: 20px;
    text-align: var(--alignment, start)
}

.elementor-widget-text-path svg {
    width: var(--width);
    max-width: 100%;
    height: auto;
    overflow: visible;
    word-spacing: var(--word-spacing);
    transform: rotate(var(--rotate, 0)) scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1))
}

.elementor-widget-text-path svg path {
    /* vector-effect: non-scaling-stroke; */
    fill: var(--path-fill, transparent);
    stroke: var(--stroke-color, transparent);
    stroke-width: var(--stroke-width, 1px);
    transition: var(--stroke-transition) stroke, var(--stroke-transition) fill
}

.elementor-widget-text-path svg:hover path {
    --path-fill: var(--path-fill-hover);
    --stroke-color: var(--stroke-color-hover);
    --stroke-width: var(--stroke-width-hover)
}

.elementor-widget-text-path svg text {
    --fill: var(--text-color);
    fill: var(--fill);
    direction: var(--direction, ltr);
    transition: var(--transition) stroke, var(--transition) stroke-width, var(--transition) fill
}

.elementor-widget-text-path svg text:hover {
    --color: var(--text-color-hover, var(--text-color));
    --fill: var(--color);
    color: var(--color)
}

.elementor-widget-n-tabs {
    --n-tabs-color-accent-fallback: #61ce70;
    --n-tabs-color-secondary-fallback: #54595f;
    --n-tabs-default-padding-block: 15px;
    --n-tabs-default-padding-inline: 35px;
    --n-tabs-background-color: transparent;
    --n-tabs-display: flex;
    --n-tabs-direction: column;
    --n-tabs-gap: 10px;
    --n-tabs-heading-display: flex;
    --n-tabs-heading-direction: row;
    --n-tabs-heading-grow: initial;
    --n-tabs-heading-justify-content: center;
    --n-tabs-heading-width: initial;
    --n-tabs-heading-overflow-x: initial;
    --n-tabs-heading-wrap: nowrap;
    --n-tabs-border-width: 1px;
    --n-tabs-border-color: #d5d8dc;
    --n-tabs-content-display: flex;
    --n-tabs-title-color: var(--e-global-color-secondary, var(--n-tabs-color-secondary-fallback));
    --n-tabs-title-color-hover: #fff;
    --n-tabs-title-color-active: #fff;
    --n-tabs-title-background-color: #f1f2f3;
    --n-tabs-title-background-color-hover: var(--e-global-color-accent, var(--n-tabs-color-accent-fallback));
    --n-tabs-title-background-color-active: var(--e-global-color-accent, var(--n-tabs-color-accent-fallback));
    --n-tabs-title-width: initial;
    --n-tabs-title-height: initial;
    --n-tabs-title-font-size: 1rem;
    --n-tabs-title-white-space: initial;
    --n-tabs-title-justify-content-toggle: initial;
    --n-tabs-title-align-items-toggle: center;
    --n-tabs-title-justify-content: center;
    --n-tabs-title-align-items: center;
    --n-tabs-title-text-align: center;
    --n-tabs-title-direction: row;
    --n-tabs-title-gap: 10px;
    --n-tabs-title-flex-grow: 0;
    --n-tabs-title-flex-basis: content;
    --n-tabs-title-flex-shrink: initial;
    --n-tabs-title-order: initial;
    --n-tabs-title-padding-top: var(--n-tabs-default-padding-block);
    --n-tabs-title-padding-bottom: var(--n-tabs-default-padding-block);
    --n-tabs-title-padding-left: var(--n-tabs-default-padding-inline);
    --n-tabs-title-padding-right: var(--n-tabs-default-padding-inline);
    --n-tabs-title-border-radius: initial;
    --n-tabs-title-transition: 0.3s;
    --n-tabs-icon-color: var(--e-global-color-secondary, var(--n-tabs-color-secondary-fallback));
    --n-tabs-icon-color-hover: var(--n-tabs-title-color-hover);
    --n-tabs-icon-color-active: #fff;
    --n-tabs-icon-gap: 5px;
    width: 100%;
    max-width: 100%;
    --n-tabs-title-padding-inline-start: var(--n-tabs-title-padding-left);
    --n-tabs-title-padding-inline-end: var(--n-tabs-title-padding-right);
    --n-tabs-title-padding-block-start: var(--n-tabs-title-padding-top);
    --n-tabs-title-padding-block-end: var(--n-tabs-title-padding-bottom)
}

body.rtl .elementor-widget-n-tabs {
    --n-tabs-title-padding-inline-start: var(--n-tabs-title-padding-right);
    --n-tabs-title-padding-inline-end: var(--n-tabs-title-padding-left)
}

.elementor-widget-n-tabs .e-n-tabs {
    display: var(--n-tabs-display);
    flex-direction: var(--n-tabs-direction);
    gap: var(--n-tabs-gap);
    text-align: start;
    min-width: 0
}

.elementor-widget-n-tabs .e-n-tabs-heading {
    display: var(--n-tabs-heading-display);
    flex-basis: var(--n-tabs-heading-width);
    flex-direction: var(--n-tabs-heading-direction);
    flex-shrink: 0;
    justify-content: var(--n-tabs-heading-justify-content);
    gap: var(--n-tabs-title-gap);
    overflow-x: var(--n-tabs-heading-overflow-x);
    flex-wrap: var(--n-tabs-heading-wrap);
    -ms-overflow-style: none;
    scrollbar-width: none
}

.elementor-widget-n-tabs .e-n-tabs-heading::-webkit-scrollbar {
    display: none
}

.elementor-widget-n-tabs .e-n-tabs-heading.e-scroll {
    cursor: grabbing;
    cursor: -webkit-grabbing
}

.elementor-widget-n-tabs .e-n-tabs-heading.e-scroll-active {
    position: relative
}

.elementor-widget-n-tabs .e-n-tabs-heading.e-scroll-active:before {
    content: "";
    position: absolute;
    inset-block: 0;
    inset-inline: -1000vw;
    z-index: 2
}

.elementor-widget-n-tabs .e-n-tabs-content {
    display: var(--n-tabs-content-display);
    flex-grow: 1;
    min-width: 0
}

.elementor-widget-n-tabs .e-n-tabs-content>.e-con:not(.e-active) {
    display: none
}

.elementor-widget-n-tabs .e-n-tabs:not(.e-activated)>.e-n-tabs-content>.e-con:first-child {
    display: flex
}

.elementor-widget-n-tabs .e-n-tab-title {
    background-color: initial;
    border-style: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    display: flex;
    align-items: var(--n-tabs-title-align-items-toggle, var(--n-tabs-title-align-items));
    flex-direction: var(--n-tabs-title-direction);
    justify-content: var(--n-tabs-title-justify-content-toggle, var(--n-tabs-title-justify-content));
    gap: var(--n-tabs-icon-gap);
    border-width: var(--n-tabs-border-width);
    position: relative;
    flex-grow: var(--n-tabs-title-flex-grow);
    flex-basis: var(--n-tabs-title-flex-basis);
    flex-shrink: var(--n-tabs-title-flex-shrink);
    padding-block-start: var(--n-tabs-title-padding-block-start);
    padding-inline-end: var(--n-tabs-title-padding-inline-end);
    padding-block-end: var(--n-tabs-title-padding-block-end);
    padding-inline-start: var(--n-tabs-title-padding-inline-start);
    border-radius: var(--n-tabs-title-border-radius);
    height: var(--n-tabs-title-height);
    width: var(--n-tabs-title-width);
    white-space: var(--n-tabs-title-white-space);
    transition: background var(--n-tabs-title-transition), color var(--n-tabs-title-transition), border var(--n-tabs-title-transition), box-shadow var(--n-tabs-title-transition), text-shadow var(--n-tabs-title-transition), stroke var(--n-tabs-title-transition), stroke-width var(--n-tabs-title-transition), -webkit-text-stroke-width var(--n-tabs-title-transition), -webkit-text-stroke-color var(--n-tabs-title-transition), transform var(--n-tabs-title-transition)
}

.elementor-widget-n-tabs .e-n-tab-title:focus:not(:focus-visible) {
    outline: none
}

.elementor-widget-n-tabs .e-n-tab-title span i,
.elementor-widget-n-tabs .e-n-tab-title span svg {
    transition: color var(--n-tabs-title-transition), fill var(--n-tabs-title-transition)
}

.elementor-widget-n-tabs .e-n-tab-title-text {
    display: flex;
    align-items: center;
    font-size: var(--n-tabs-title-font-size);
    text-align: var(--n-tabs-title-text-align)
}

.elementor-widget-n-tabs .e-n-tab-title .e-n-tab-icon {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-shrink: 0;
    order: var(--n-tabs-icon-order);
    overflow: hidden
}

.elementor-widget-n-tabs .e-n-tab-title .e-n-tab-icon i {
    font-size: var(--n-tabs-icon-size, var(--n-tabs-title-font-size))
}

.elementor-widget-n-tabs .e-n-tab-title .e-n-tab-icon svg {
    width: var(--n-tabs-icon-size, var(--n-tabs-title-font-size));
    height: var(--n-tabs-icon-size, var(--n-tabs-title-font-size))
}

.elementor-widget-n-tabs .e-n-tab-title .e-n-tab-icon:empty {
    display: none
}

.elementor-widget-n-tabs .e-n-tab-title[aria-selected=false] {
    background-color: var(--n-tabs-title-background-color)
}

.elementor-widget-n-tabs .e-n-tab-title[aria-selected=false],
.elementor-widget-n-tabs .e-n-tab-title[aria-selected=false] a {
    color: var(--n-tabs-title-color)
}

.elementor-widget-n-tabs .e-n-tab-title[aria-selected=false] .e-n-tab-icon i {
    color: var(--n-tabs-icon-color)
}

.elementor-widget-n-tabs .e-n-tab-title[aria-selected=false] .e-n-tab-icon svg {
    fill: var(--n-tabs-icon-color)
}

.elementor-widget-n-tabs .e-n-tab-title[aria-selected=false] .e-n-tab-icon i:last-child,
.elementor-widget-n-tabs .e-n-tab-title[aria-selected=false] .e-n-tab-icon svg:last-child {
    transform: translateY(-100vh);
    height: 0;
    opacity: 0
}

.elementor-widget-n-tabs .e-n-tab-title[aria-selected=true],
.elementor-widget-n-tabs .e-n-tab-title[aria-selected=true] a {
    color: var(--n-tabs-title-color-active)
}

.elementor-widget-n-tabs .e-n-tab-title[aria-selected=true] .e-n-tab-icon i {
    color: var(--n-tabs-icon-color-active)
}

.elementor-widget-n-tabs .e-n-tab-title[aria-selected=true] .e-n-tab-icon svg {
    fill: var(--n-tabs-icon-color-active)
}

.elementor-widget-n-tabs .e-n-tab-title[aria-selected=true] .e-n-tab-icon i:first-child,
.elementor-widget-n-tabs .e-n-tab-title[aria-selected=true] .e-n-tab-icon svg:first-child {
    transform: translateY(-100vh);
    height: 0;
    opacity: 0
}

.elementor-widget-n-tabs .e-n-tab-title[aria-selected=true][class*=elementor-animation-]:active,
.elementor-widget-n-tabs .e-n-tab-title[aria-selected=true][class*=elementor-animation-]:focus,
.elementor-widget-n-tabs .e-n-tab-title[aria-selected=true][class*=elementor-animation-]:hover {
    transform: none;
    animation: initial
}

.elementor-widget-n-tabs [data-touch-mode=false] .e-n-tab-title[aria-selected=false]:hover,
.elementor-widget-n-tabs [data-touch-mode=false] .e-n-tab-title[aria-selected=false]:hover a {
    color: var(--n-tabs-title-color-hover)
}

.elementor-widget-n-tabs [data-touch-mode=false] .e-n-tab-title[aria-selected=false]:hover .e-n-tab-icon i {
    color: var(--n-tabs-icon-color-hover)
}

.elementor-widget-n-tabs [data-touch-mode=false] .e-n-tab-title[aria-selected=false]:hover .e-n-tab-icon svg {
    fill: var(--n-tabs-icon-color-hover)
}

.elementor-widget-n-tabs [data-touch-mode=true] .e-n-tab-title[aria-selected=false]:hover,
.elementor-widget-n-tabs [data-touch-mode=true] .e-n-tab-title[aria-selected=false]:hover a {
    color: var(--n-tabs-title-color-active)
}

.elementor-widget-n-tabs [data-touch-mode=true] .e-n-tab-title[aria-selected=false]:hover .e-n-tab-icon i {
    color: var(--n-tabs-icon-color-active)
}

.elementor-widget-n-tabs [data-touch-mode=true] .e-n-tab-title[aria-selected=false]:hover .e-n-tab-icon svg {
    fill: var(--n-tabs-icon-color-active)
}

.elementor-widget-n-tabs [data-touch-mode=true] .e-n-tab-title[aria-selected=false]:hover .e-n-tab-icon i:first-child,
.elementor-widget-n-tabs [data-touch-mode=true] .e-n-tab-title[aria-selected=false]:hover .e-n-tab-icon svg:first-child {
    transform: translateY(-100vh);
    height: 0;
    opacity: 0
}

.elementor-widget-n-tabs [data-touch-mode=true] .e-n-tab-title[aria-selected=false]:hover[class*=elementor-animation-]:active,
.elementor-widget-n-tabs [data-touch-mode=true] .e-n-tab-title[aria-selected=false]:hover[class*=elementor-animation-]:focus,
.elementor-widget-n-tabs [data-touch-mode=true] .e-n-tab-title[aria-selected=false]:hover[class*=elementor-animation-]:hover {
    transform: none;
    animation: initial
}

.elementor-widget-n-tabs [data-touch-mode=true] .e-n-tab-title[aria-selected=false]:hover .e-n-tab-icon i:last-child,
.elementor-widget-n-tabs [data-touch-mode=true] .e-n-tab-title[aria-selected=false]:hover .e-n-tab-icon svg:last-child {
    transform: none;
    height: auto;
    opacity: 1
}

.elementor .elementor-element.elementor-widget-n-tabs>.elementor-widget-container>.e-n-tabs[data-touch-mode=false]>.e-n-tabs-heading .e-n-tab-title[aria-selected=false]:hover {
    background-color: var(--n-tabs-title-background-color-hover);
    background-image: none
}

.elementor .elementor-element.elementor-widget-n-tabs>.elementor-widget-container>.e-n-tabs>.e-n-tabs-heading .e-n-tab-title[aria-selected=true],
.elementor .elementor-element.elementor-widget-n-tabs>.elementor-widget-container>.e-n-tabs[data-touch-mode=true]>.e-n-tabs-heading .e-n-tab-title[aria-selected=false]:hover {
    background-color: var(--n-tabs-title-background-color-active);
    background-image: none
}

@media (max-width:767px) {
    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-mobile {
        --n-tabs-direction: column;
        --n-tabs-heading-display: contents;
        --n-tabs-content-display: contents
    }

    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-mobile .e-n-tabs {
        gap: 0
    }

    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-mobile .e-n-tabs-content>.e-con {
        order: var(--n-tabs-title-order)
    }

    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-mobile .e-n-tab-title {
        order: var(--n-tabs-title-order);
        width: auto
    }

    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-mobile .e-n-tab-title:not(:first-child) {
        margin-block-start: var(--n-tabs-title-gap)
    }

    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-mobile .e-n-tab-title[aria-selected=true] {
        margin-block-end: var(--n-tabs-gap)
    }
}

@media (max-width:880px) {
    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-mobile_extra {
        --n-tabs-direction: column;
        --n-tabs-heading-display: contents;
        --n-tabs-content-display: contents
    }

    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-mobile_extra .e-n-tabs {
        gap: 0
    }

    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-mobile_extra .e-n-tabs-content>.e-con {
        order: var(--n-tabs-title-order)
    }

    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-mobile_extra .e-n-tab-title {
        order: var(--n-tabs-title-order);
        width: auto
    }

    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-mobile_extra .e-n-tab-title:not(:first-child) {
        margin-block-start: var(--n-tabs-title-gap)
    }

    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-mobile_extra .e-n-tab-title[aria-selected=true] {
        margin-block-end: var(--n-tabs-gap)
    }
}

@media (max-width:1024px) {
    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-tablet {
        --n-tabs-direction: column;
        --n-tabs-heading-display: contents;
        --n-tabs-content-display: contents
    }

    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-tablet .e-n-tabs {
        gap: 0
    }

    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-tablet .e-n-tabs-content>.e-con {
        order: var(--n-tabs-title-order)
    }

    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-tablet .e-n-tab-title {
        order: var(--n-tabs-title-order);
        width: auto
    }

    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-tablet .e-n-tab-title:not(:first-child) {
        margin-block-start: var(--n-tabs-title-gap)
    }

    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-tablet .e-n-tab-title[aria-selected=true] {
        margin-block-end: var(--n-tabs-gap)
    }
}

@media (max-width:1200px) {
    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-tablet_extra {
        --n-tabs-direction: column;
        --n-tabs-heading-display: contents;
        --n-tabs-content-display: contents
    }

    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-tablet_extra .e-n-tabs {
        gap: 0
    }

    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-tablet_extra .e-n-tabs-content>.e-con {
        order: var(--n-tabs-title-order)
    }

    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-tablet_extra .e-n-tab-title {
        order: var(--n-tabs-title-order);
        width: auto
    }

    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-tablet_extra .e-n-tab-title:not(:first-child) {
        margin-block-start: var(--n-tabs-title-gap)
    }

    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-tablet_extra .e-n-tab-title[aria-selected=true] {
        margin-block-end: var(--n-tabs-gap)
    }
}

@media (max-width:1366px) {
    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-laptop {
        --n-tabs-direction: column;
        --n-tabs-heading-display: contents;
        --n-tabs-content-display: contents
    }

    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-laptop .e-n-tabs {
        gap: 0
    }

    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-laptop .e-n-tabs-content>.e-con {
        order: var(--n-tabs-title-order)
    }

    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-laptop .e-n-tab-title {
        order: var(--n-tabs-title-order);
        width: auto
    }

    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-laptop .e-n-tab-title:not(:first-child) {
        margin-block-start: var(--n-tabs-title-gap)
    }

    .elementor.elementor .elementor-widget-n-tabs.e-n-tabs-laptop .e-n-tab-title[aria-selected=true] {
        margin-block-end: var(--n-tabs-gap)
    }
}

.elementor-widget-n-accordion {
    --n-accordion-title-font-size: 20px;
    --n-accordion-title-flex-grow: initial;
    --n-accordion-title-justify-content: initial;
    --n-accordion-title-icon-order: -1;
    --n-accordion-border-width: 1px;
    --n-accordion-border-color: #d5d8dc;
    --n-accordion-border-style: solid;
    --n-accordion-item-title-flex-grow: initial;
    --n-accordion-item-title-space-between: 0px;
    --n-accordion-item-title-distance-from-content: 0px;
    --n-accordion-padding: 10px;
    --n-accordion-border-radius: 0px;
    --n-accordion-icon-size: 15px;
    --n-accordion-title-normal-color: #1f2124;
    --n-accordion-title-hover-color: #1f2124;
    --n-accordion-title-active-color: #1f2124;
    --n-accordion-icon-normal-color: var(--n-accordion-title-normal-color);
    --n-accordion-icon-hover-color: var(--n-accordion-title-hover-color);
    --n-accordion-icon-active-color: var(--n-accordion-title-active-color);
    --n-accordion-icon-gap: 0 10px;
    width: 100%
}

.elementor-widget-n-accordion .e-n-accordion details>summary::-webkit-details-marker {
    display: none
}

.elementor-widget-n-accordion .e-n-accordion-item {
    display: flex;
    flex-direction: column;
    position: relative
}

.elementor-widget-n-accordion .e-n-accordion-item:not(:last-child) {
    margin-block-end: var(--n-accordion-item-title-space-between)
}

.elementor-widget-n-accordion .e-n-accordion-item-title,
:where(.elementor-widget-n-accordion .e-n-accordion-item>.e-con) {
    border: var(--n-accordion-border-width) var(--n-accordion-border-style) var(--n-accordion-border-color)
}

.elementor-widget-n-accordion .e-n-accordion-item-title {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: var(--n-accordion-padding);
    justify-content: var(--n-accordion-title-justify-content);
    border-radius: var(--n-accordion-border-radius);
    flex-grow: var(--n-menu-title-flex-grow);
    gap: var(--n-accordion-icon-gap);
    color: var(--n-accordion-title-normal-color);
    align-items: center;
    cursor: pointer
}

.elementor-widget-n-accordion .e-n-accordion-item-title-header {
    display: flex
}

.elementor-widget-n-accordion .e-n-accordion-item-title-header h1,
.elementor-widget-n-accordion .e-n-accordion-item-title-header h2,
.elementor-widget-n-accordion .e-n-accordion-item-title-header h3,
.elementor-widget-n-accordion .e-n-accordion-item-title-header h4,
.elementor-widget-n-accordion .e-n-accordion-item-title-header h5,
.elementor-widget-n-accordion .e-n-accordion-item-title-header h6,
.elementor-widget-n-accordion .e-n-accordion-item-title-header p {
    margin-block-start: 0;
    margin-block-end: 0
}

.elementor-widget-n-accordion .e-n-accordion-item-title-text {
    font-size: var(--n-accordion-title-font-size);
    align-items: center
}

.elementor-widget-n-accordion .e-n-accordion-item-title-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    order: var(--n-accordion-title-icon-order);
    position: relative;
    width: -moz-fit-content;
    width: fit-content
}

.elementor-widget-n-accordion .e-n-accordion-item-title-icon span {
    height: var(--n-accordion-icon-size);
    width: auto
}

.elementor-widget-n-accordion .e-n-accordion-item-title-icon span>i {
    color: var(--n-accordion-icon-normal-color);
    font-size: var(--n-accordion-icon-size)
}

.elementor-widget-n-accordion .e-n-accordion-item-title-icon span>svg {
    fill: var(--n-accordion-icon-normal-color);
    height: var(--n-accordion-icon-size)
}

.elementor-widget-n-accordion .e-n-accordion-item-title>span {
    cursor: pointer
}

.elementor-widget-n-accordion .e-n-accordion-item[open] .e-n-accordion-item-title {
    margin-block-end: var(--n-accordion-item-title-distance-from-content);
    color: var(--n-accordion-title-active-color)
}

.elementor-widget-n-accordion .e-n-accordion-item[open] .e-n-accordion-item-title-icon .e-opened {
    display: flex
}

.elementor-widget-n-accordion .e-n-accordion-item[open] .e-n-accordion-item-title-icon .e-closed {
    display: none
}

.elementor-widget-n-accordion .e-n-accordion-item[open] .e-n-accordion-item-title-icon span>i {
    color: var(--n-accordion-icon-active-color)
}

.elementor-widget-n-accordion .e-n-accordion-item[open] .e-n-accordion-item-title-icon span>svg {
    fill: var(--n-accordion-icon-active-color)
}

.elementor-widget-n-accordion .e-n-accordion-item:not([open]):hover .e-n-accordion-item-title {
    color: var(--n-accordion-title-hover-color)
}

.elementor-widget-n-accordion .e-n-accordion-item:not([open]):hover .e-n-accordion-item-title-icon span>i {
    color: var(--n-accordion-icon-hover-color)
}

.elementor-widget-n-accordion .e-n-accordion-item:not([open]):hover .e-n-accordion-item-title-icon span>svg {
    fill: var(--n-accordion-icon-hover-color)
}

.elementor-widget-n-accordion .e-n-accordion-item .e-n-accordion-item-title-icon .e-opened {
    display: none
}

.elementor-widget-n-accordion .e-n-accordion-item .e-n-accordion-item-title-icon .e-closed {
    display: flex
}

.elementor-widget-n-accordion .e-n-accordion-item .e-n-accordion-item-title-icon span>svg {
    fill: var(--n-accordion-icon-normal-color)
}

.elementor-widget-n-accordion .e-n-accordion-item .e-n-accordion-item-title-icon span>i {
    color: var(--n-accordion-icon-normal-color)
}

.elementor-widget-n-accordion .e-n-accordion-item>span {
    cursor: pointer
}

.e-link-in-bio {
    --e-link-in-bio-border-color: transparent;
    --e-link-in-bio-border-style: none;
    --e-link-in-bio-border-width: 0;
    --e-link-in-bio-container-height: auto;
    --e-link-in-bio-container-width: 360px;
    --e-link-in-bio-content-align-h: center;
    --e-link-in-bio-content-align-v: center;
    --e-link-in-bio-content-width: 280px;
    --e-link-in-bio-gutter-block-end: 45px;
    --e-link-in-bio-gutter-block-start: 38px;
    --e-link-in-bio-gutter-inline: 40px;
    --e-link-in-bio-identity-image-cover-border-bottom-width: 0;
    --e-link-in-bio-identity-image-cover-border-color: transparent;
    --e-link-in-bio-identity-image-cover-border-style: none;
    --e-link-in-bio-identity-image-cover-height: 170px;
    --e-link-in-bio-identity-image-profile-border-color: transparent;
    --e-link-in-bio-identity-image-profile-border-radius: 50%;
    --e-link-in-bio-identity-image-profile-border-style: none;
    --e-link-in-bio-identity-image-profile-border-width: 0;
    --e-link-in-bio-identity-image-profile-width: 115px;
    --e-link-in-bio-heading-color: inherit;
    --e-link-in-bio-title-color: inherit;
    --e-link-in-bio-about-heading-color: inherit;
    --e-link-in-bio-description-color: #324a6d;
    --e-link-in-bio-icon-background-color: transparent;
    --e-link-in-bio-icon-border-color: transparent;
    --e-link-in-bio-icon-border-style: none;
    --e-link-in-bio-icon-border-width: 0;
    --e-link-in-bio-icon-color: inherit;
    --e-link-in-bio-icon-columns: 3;
    --e-link-in-bio-icon-gap: 20px 29px;
    --e-link-in-bio-icon-size: 25px;
    --e-link-in-bio-ctas-background-color: #467ff7;
    --e-link-in-bio-ctas-border-color: transparent;
    --e-link-in-bio-ctas-border-radius: 20px;
    --e-link-in-bio-ctas-border-style: none;
    --e-link-in-bio-ctas-border-width: 2px;
    --e-link-in-bio-ctas-gap: 22px;
    --e-link-in-bio-ctas-padding-block-end: 17px;
    --e-link-in-bio-ctas-padding-block-start: 17px;
    --e-link-in-bio-ctas-padding-inline-end: 20px;
    --e-link-in-bio-ctas-padding-inline-start: 20px;
    --e-link-in-bio-ctas-text-color: #fff;
    --background-overlay-opacity: 0.5;
    align-items: var(--e-link-in-bio-content-align-h);
    border: var(--e-link-in-bio-border-width) var(--e-link-in-bio-border-style) var(--e-link-in-bio-border-color);
    display: flex;
    flex-direction: column;
    justify-content: var(--e-link-in-bio-content-align-v);
    margin-inline: auto;
    max-width: 100%;
    min-height: var(--e-link-in-bio-container-height);
    padding: var(--e-link-in-bio-gutter-block-start) var(--e-link-in-bio-gutter-inline) var(--e-link-in-bio-gutter-block-end);
    position: relative;
    width: var(--e-link-in-bio-container-width)
}

.e-link-in-bio.has-border {
    --e-link-in-bio-border-style: solid
}

@media (max-width:767px) {
    .e-link-in-bio.-is-full-height-mobile {
        --e-link-in-bio-container-height: 100vh
    }

    @supports (height:100dvh) {
        .e-link-in-bio.-is-full-height-mobile {
            --e-link-in-bio-container-height: 100dvh
        }
    }
}

@media (max-width:880px) {
    .e-link-in-bio.-is-full-height-mobile_extra {
        --e-link-in-bio-container-height: 100vh
    }

    @supports (height:100dvh) {
        .e-link-in-bio.-is-full-height-mobile_extra {
            --e-link-in-bio-container-height: 100dvh
        }
    }
}

@media (max-width:1024px) {
    .e-link-in-bio.-is-full-height-tablet {
        --e-link-in-bio-container-height: 100vh
    }

    @supports (height:100dvh) {
        .e-link-in-bio.-is-full-height-tablet {
            --e-link-in-bio-container-height: 100dvh
        }
    }
}

@media (max-width:1200px) {
    .e-link-in-bio.-is-full-height-tablet_extra {
        --e-link-in-bio-container-height: 100vh
    }

    @supports (height:100dvh) {
        .e-link-in-bio.-is-full-height-tablet_extra {
            --e-link-in-bio-container-height: 100dvh
        }
    }
}

@media (max-width:1366px) {
    .e-link-in-bio.-is-full-height-laptop {
        --e-link-in-bio-container-height: 100vh
    }

    @supports (height:100dvh) {
        .e-link-in-bio.-is-full-height-laptop {
            --e-link-in-bio-container-height: 100dvh
        }
    }
}

@media (max-width:99999px) {
    .e-link-in-bio.-is-full-height-desktop {
        --e-link-in-bio-container-height: 100vh
    }

    @supports (height:100dvh) {
        .e-link-in-bio.-is-full-height-desktop {
            --e-link-in-bio-container-height: 100dvh
        }
    }
}

@media (max-width:99999px) {
    .e-link-in-bio.-is-full-height-widescreen {
        --e-link-in-bio-container-height: 100vh
    }

    @supports (height:100dvh) {
        .e-link-in-bio.-is-full-height-widescreen {
            --e-link-in-bio-container-height: 100dvh
        }
    }
}

.e-link-in-bio.is-full-width {
    --e-link-in-bio-container-width: 100%
}

.e-link-in-bio__bg {
    display: grid;
    inset: 0;
    position: absolute;
    z-index: 0
}

.e-link-in-bio__bg-overlay {
    opacity: var(--background-overlay-opacity)
}

.e-link-in-bio__content {
    color: #1c2448;
    display: flex;
    flex-direction: column;
    font-family: var(--e-global-typography-text-font-family, "Poppins"), Sans-serif;
    max-width: 100%;
    text-align: center;
    width: var(--e-link-in-bio-content-width);
    z-index: 1
}

.e-link-in-bio__content * {
    word-wrap: break-word
}

.e-link-in-bio__identity {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    margin-block-end: 14px;
    margin-block-start: calc(var(--e-link-in-bio-gutter-block-start) * -1)
}

.e-link-in-bio__identity .e-link-in-bio__identity-image {
    display: flex;
    position: relative
}

.e-link-in-bio__identity .e-link-in-bio__identity-image-element {
    display: block;
    flex: 1 1 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.e-link-in-bio__identity .e-link-in-bio__identity-image-cover {
    align-self: start;
    border: 0 var(--e-link-in-bio-identity-image-cover-border-style) var(--e-link-in-bio-identity-image-cover-border-color);
    border-bottom: var(--e-link-in-bio-identity-image-cover-border-bottom-width) var(--e-link-in-bio-identity-image-cover-border-style) var(--e-link-in-bio-identity-image-cover-border-color);
    grid-column: 1;
    grid-row: 1;
    margin-inline: calc(var(--e-link-in-bio-gutter-inline) * -1);
    max-height: var(--e-link-in-bio-identity-image-cover-height);
    overflow: hidden;
    width: calc(100% + var(--e-link-in-bio-gutter-inline) * 2)
}

.e-link-in-bio__identity .e-link-in-bio__identity-image-cover.has-border {
    --e-link-in-bio-identity-image-cover-border-style: solid
}

.e-link-in-bio__identity .e-link-in-bio__identity-image-profile {
    align-self: center;
    aspect-ratio: 1;
    border-radius: var(--e-link-in-bio-identity-image-profile-border-radius);
    border: var(--e-link-in-bio-identity-image-profile-border-width) var(--e-link-in-bio-identity-image-profile-border-style) var(--e-link-in-bio-identity-image-profile-border-color);
    grid-column: 1;
    grid-row: 1;
    overflow: hidden;
    margin-block-start: var(--e-link-in-bio-gutter-block-start);
    margin-block-end: 17px;
    margin-inline: auto;
    max-width: 100%;
    width: var(--e-link-in-bio-identity-image-profile-width)
}

.e-link-in-bio__identity .e-link-in-bio__identity-image-profile.has-border {
    --e-link-in-bio-identity-image-profile-border-style: solid
}

.e-link-in-bio__identity .e-link-in-bio__identity-image-profile.has-style-square {
    --e-link-in-bio-identity-image-profile-border-radius: 0
}

.e-link-in-bio__identity .e-link-in-bio__identity-image-profile .e-link-in-bio__identity-image-element {
    aspect-ratio: inherit
}

.e-link-in-bio__identity .e-link-in-bio__identity-image-cover+.e-link-in-bio__identity-image-profile {
    margin-block-start: 17px
}

.e-link-in-bio__bio>* {
    margin-block: 0
}

.e-link-in-bio__heading {
    color: var(--e-link-in-bio-heading-color);
    font-size: 36px;
    font-weight: 600;
    line-height: 42px
}

.e-link-in-bio__about-heading {
    color: var(--e-link-in-bio-about-heading-color);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px
}

.e-link-in-bio__title {
    color: var(--e-link-in-bio-title-color);
    font-size: 20px;
    font-weight: 500;
    line-height: 35px
}

.e-link-in-bio__description {
    color: var(--e-link-in-bio-description-color);
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    margin-block-start: 20px
}

.e-link-in-bio__bio--footer {
    margin-block-start: 34px
}

.e-link-in-bio__bio--footer .e-link-in-bio__description {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    margin-block-start: 0
}

.e-link-in-bio__bio--footer .e-link-in-bio__about-heading+.e-link-in-bio__description {
    margin-block-start: 3px
}

.e-link-in-bio__icons {
    display: flex;
    flex-flow: row wrap;
    gap: var(--e-link-in-bio-icon-gap);
    justify-content: center;
    margin-block-start: 20px
}

.e-link-in-bio__icon {
    display: flex
}

.e-link-in-bio__icon.has-size-medium {
    --e-link-in-bio-icon-size: 30px
}

.e-link-in-bio__icon.has-size-large {
    --e-link-in-bio-icon-size: 35px
}

.e-link-in-bio__icon .e-link-in-bio__icon-link {
    align-items: center;
    color: inherit;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column
}

.e-link-in-bio__icon .e-link-in-bio__icon-link:active,
.e-link-in-bio__icon .e-link-in-bio__icon-link:focus,
.e-link-in-bio__icon .e-link-in-bio__icon-link:hover {
    color: inherit
}

.e-link-in-bio__icon .e-link-in-bio__icon-svg {
    align-items: center;
    color: var(--e-link-in-bio-icon-color);
    display: flex;
    justify-content: center
}

.e-link-in-bio__icon svg {
    fill: currentColor;
    height: var(--e-link-in-bio-icon-size)
}

.e-link-in-bio__icon .e-link-in-bio__icon-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center
}

.e-link-in-bio__ctas {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto;
    gap: var(--e-link-in-bio-ctas-gap);
    margin-block-start: 31px
}

.e-link-in-bio__ctas.has-type-link {
    --e-link-in-bio-ctas-gap: 10px;
    justify-items: center
}

.e-link-in-bio__ctas.has-type-divider {
    --e-link-in-bio-ctas-gap: 0
}

.e-link-in-bio__ctas .e-link-in-bio__cta {
    display: flex;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px
}

.e-link-in-bio__ctas .e-link-in-bio__cta,
.e-link-in-bio__ctas .e-link-in-bio__cta:active,
.e-link-in-bio__ctas .e-link-in-bio__cta:focus,
.e-link-in-bio__ctas .e-link-in-bio__cta:hover {
    color: var(--e-link-in-bio-ctas-text-color)
}

.e-link-in-bio__ctas .e-link-in-bio__cta-image {
    flex: 0 0 min(50%, 140px)
}

.e-link-in-bio__ctas .e-link-in-bio__cta-image-element {
    aspect-ratio: 140/100;
    display: block;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%
}

.e-link-in-bio__ctas .e-link-in-bio__cta-text {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    justify-content: center
}

.e-link-in-bio__ctas .e-link-in-bio__cta.is-type-button {
    border-radius: var(--e-link-in-bio-ctas-border-radius);
    overflow: hidden
}

.e-link-in-bio__ctas .e-link-in-bio__cta.is-type-button.has-border {
    --e-link-in-bio-ctas-border-style: solid;
    border: var(--e-link-in-bio-ctas-border-width) var(--e-link-in-bio-ctas-border-style) var(--e-link-in-bio-ctas-border-color)
}

.e-link-in-bio__ctas .e-link-in-bio__cta.is-type-button.has-corners-rounded {
    --e-link-in-bio-ctas-border-radius: 20px
}

.e-link-in-bio__ctas .e-link-in-bio__cta.is-type-button.has-corners-round {
    --e-link-in-bio-ctas-border-radius: 50px
}

.e-link-in-bio__ctas .e-link-in-bio__cta.is-type-button.has-corners-sharp {
    --e-link-in-bio-ctas-border-radius: 0
}

.e-link-in-bio__ctas .e-link-in-bio__cta.is-type-button .e-link-in-bio__cta-text {
    background-color: var(--e-link-in-bio-ctas-background-color);
    padding-block-end: var(--e-link-in-bio-ctas-padding-block-end);
    padding-block-start: var(--e-link-in-bio-ctas-padding-block-start);
    padding-inline-end: var(--e-link-in-bio-ctas-padding-inline-end);
    padding-inline-start: var(--e-link-in-bio-ctas-padding-inline-start)
}

.e-link-in-bio__ctas .e-link-in-bio__cta.is-type-link {
    --e-link-in-bio-ctas-text-color: #467ff7;
    font-weight: 700;
    margin-block: 17px;
    justify-content: center
}

.e-link-in-bio .e-link-in-bio__content .e-link-in-bio__ctas .e-link-in-bio__cta.is-type-link {
    text-decoration: underline
}

.e-link-in-bio-var-3 {
    --e-link-in-bio-gutter-block-end: 20px;
    --e-link-in-bio-ctas-border-radius: 0;
    --e-link-in-bio-ctas-gap: 8px
}

.e-link-in-bio-var-3 .e-link-in-bio__ctas .e-link-in-bio__cta.has-border {
    border: none
}

.e-link-in-bio-var-3 .e-link-in-bio__ctas .e-link-in-bio__cta.has-border .e-link-in-bio__cta-image {
    --e-link-in-bio-ctas-border-style: solid;
    border: var(--e-link-in-bio-ctas-border-width) var(--e-link-in-bio-ctas-border-style) var(--e-link-in-bio-ctas-border-color)
}

.e-link-in-bio-var-3 .e-link-in-bio__ctas .e-link-in-bio__cta.has-corners-round,
.e-link-in-bio-var-3 .e-link-in-bio__ctas .e-link-in-bio__cta.has-corners-rounded {
    --e-link-in-bio-ctas-border-radius: 0
}

.e-link-in-bio-var-4 {
    --e-link-in-bio-ctas-text-color: #1c2448;
    --e-link-in-bio-ctas-background-color: transparent;
    --e-link-in-bio-ctas-divider-color: #1c2448;
    --e-link-in-bio-ctas-divider-width: 1px;
    --e-link-in-bio-ctas-divider-style: solid;
    --e-link-in-bio-ctas-padding-inline-end: 16px;
    --e-link-in-bio-ctas-padding-inline-start: 16px
}

.e-link-in-bio-var-4 .e-link-in-bio__ctas {
    grid-gap: 0;
    margin-block-end: 28px;
    margin-block-start: 28px
}

.e-link-in-bio-var-4 .e-link-in-bio__ctas .e-link-in-bio__cta {
    border-bottom: var(--e-link-in-bio-ctas-divider-width) var(--e-link-in-bio-ctas-divider-style) var(--e-link-in-bio-ctas-divider-color);
    font-size: 24px;
    font-weight: 600;
    line-height: 42px
}

.e-link-in-bio-var-4 .e-link-in-bio__ctas .e-link-in-bio__cta.has-corners-rounded,
.e-link-in-bio-var-5 {
    --e-link-in-bio-ctas-border-radius: 0
}

.e-link-in-bio-var-5 {
    --e-link-in-bio-ctas-gap: 20px 15px;
    --e-link-in-bio-ctas-padding-block-end: 5px;
    --e-link-in-bio-ctas-padding-block-start: 5px;
    --e-link-in-bio-ctas-padding-inline-end: 7px;
    --e-link-in-bio-ctas-padding-inline-start: 7px
}

.e-link-in-bio-var-5 .e-link-in-bio__ctas.has-type-button {
    grid-template-columns: repeat(2, minmax(0, 100px));
    grid-template-rows: auto;
    justify-content: center
}

.e-link-in-bio-var-5 .e-link-in-bio__ctas .e-link-in-bio__cta.has-border {
    border: none
}

.e-link-in-bio-var-5 .e-link-in-bio__ctas .e-link-in-bio__cta.has-border .e-link-in-bio__cta-image {
    --e-link-in-bio-ctas-border-style: solid;
    border: var(--e-link-in-bio-ctas-border-width) var(--e-link-in-bio-ctas-border-style) var(--e-link-in-bio-ctas-border-color)
}

.e-link-in-bio-var-5 .e-link-in-bio__ctas .e-link-in-bio__cta.has-corners-round,
.e-link-in-bio-var-5 .e-link-in-bio__ctas .e-link-in-bio__cta.has-corners-rounded {
    --e-link-in-bio-ctas-border-radius: 0
}

.e-link-in-bio-var-5 .e-link-in-bio__ctas .e-link-in-bio__cta.is-type-button {
    flex-direction: column;
    font-size: 14px
}

.e-link-in-bio-var-5 .e-link-in-bio__ctas .e-link-in-bio__cta.is-type-button .e-link-in-bio__cta-image {
    flex: 0 0 auto;
    margin-bottom: 4px
}

.e-link-in-bio-var-5 .e-link-in-bio__ctas .e-link-in-bio__cta.is-type-button .e-link-in-bio__cta-image-element {
    aspect-ratio: 1;
    height: auto
}

.e-link-in-bio-var-7 {
    --e-link-in-bio-icon-background-color: #467ff7;
    --e-link-in-bio-icon-color: #fff;
    --e-link-in-bio-icon-gap: 20px 10px
}

.e-link-in-bio-var-7 .e-link-in-bio__identity .e-link-in-bio__identity-image-cover {
    height: var(--e-link-in-bio-identity-image-cover-height, auto)
}

.e-link-in-bio-var-7 .e-link-in-bio__icons {
    align-items: start;
    display: grid;
    flex-flow: unset;
    grid-auto-flow: rows;
    grid-template-columns: repeat(var(--e-link-in-bio-icon-columns, 3), minmax(0, 1fr));
    grid-template-rows: auto;
    justify-content: unset;
    justify-items: center;
    margin-block-start: 34px
}

.e-link-in-bio-var-7 .e-link-in-bio__icon-svg {
    aspect-ratio: 1;
    background-color: var(--e-link-in-bio-icon-background-color);
    border-radius: 100%;
    height: calc(var(--e-link-in-bio-icon-size) + 30px);
    padding: 15px
}

.e-link-in-bio-var-7 .e-link-in-bio__icon {
    --e-link-in-bio-icon-border-style: solid
}

.e-link-in-bio-var-7 .e-link-in-bio__icon.has-border .e-link-in-bio__icon-svg {
    border: var(--e-link-in-bio-icon-border-width) var(--e-link-in-bio-icon-border-style) var(--e-link-in-bio-icon-border-color);
    height: calc(var(--e-link-in-bio-icon-size) + 30px + var(--e-link-in-bio-icon-border-width) * 2)
}

.e-link-in-bio-var-7 .e-link-in-bio__ctas {
    margin-block-start: 34px
}

.e-contact-buttons {
    --e-contact-buttons-chat-box-width: 360px;
    --e-contact-buttons-size-small: 65px;
    --e-contact-buttons-size-medium: 75px;
    --e-contact-buttons-size-large: 85px;
    --e-contact-buttons-dot: red;
    --e-contact-buttons-dot-size: 20px;
    --e-contact-buttons-profile-dot-bg: #39aa59;
    --e-contact-buttons-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.25);
    --e-contact-buttons-border-radius: 20px;
    --e-contact-button-chat-button-animation-duration: 800ms;
    --e-contact-button-chat-button-animation-delay: 0;
    --e-contact-buttons-chat-box-animation-duration: 800ms;
    --e-contact-buttons-button-bg: #467ff7;
    --e-contact-buttons-button-bg-hover: #1c2448;
    --e-contact-buttons-button-icon: #fff;
    --e-contact-buttons-button-icon-hover: #fff;
    --e-contact-buttons-top-bar-bg: #1c2448;
    --e-contact-buttons-top-bar-name: #fff;
    --e-contact-buttons-top-bar-title: #fff;
    --e-contact-buttons-close-button-color: #fff;
    --e-contact-buttons-message-bubble-name: #000;
    --e-contact-buttons-message-bubble-body: #000;
    --e-contact-buttons-message-bubble-time: #000;
    --e-contact-buttons-message-bubble-bubble-bg: #fff;
    --e-contact-buttons-message-bubble-chat-bg: #c8d5dc;
    --e-contact-buttons-send-button-icon: #fff;
    --e-contact-buttons-send-button-bg: #467ff7;
    --e-contact-buttons-send-button-icon-hover: #fff;
    --e-contact-buttons-send-button-bg-hover: #1c2448;
    --e-contact-buttons-chat-box-bg: #fff;
    position: fixed;
    bottom: 20px;
    inset-inline-end: 25px;
    width: var(--e-contact-buttons-chat-box-width);
    z-index: 99
}

@media (max-width:767px) {
    .e-contact-buttons {
        inset-inline-end: 0
    }
}

.e-contact-buttons.has-alignment-start {
    inset-inline-end: 0;
    inset-inline-start: 25px
}

@media (max-width:767px) {
    .e-contact-buttons.has-alignment-start {
        inset-inline-start: 0
    }
}

.e-contact-buttons.has-platform-whatsapp {
    --e-contact-buttons-button-bg: #25d366;
    --e-contact-buttons-button-bg-hover: #075e54;
    --e-contact-buttons-button-icon: #fff;
    --e-contact-buttons-button-icon-hover: #fff;
    --e-contact-buttons-top-bar-bg: #075e54;
    --e-contact-buttons-top-bar-name: #fff;
    --e-contact-buttons-top-bar-title: #fff;
    --e-contact-buttons-close-button-color: #fff;
    --e-contact-buttons-message-bubble-body: #000;
    --e-contact-buttons-message-bubble-time: #000;
    --e-contact-buttons-message-bubble-name: #000;
    --e-contact-buttons-message-bubble-bubble-bg: #fff;
    --e-contact-buttons-message-bubble-chat-bg: #ece5dd;
    --e-contact-buttons-send-button-icon: #fff;
    --e-contact-buttons-send-button-bg: #25d366;
    --e-contact-buttons-send-button-icon-hover: #fff;
    --e-contact-buttons-send-button-bg-hover: #075e54;
    --e-contact-buttons-chat-box-bg: #fff
}

.e-contact-buttons.has-platform-skype {
    --e-contact-buttons-button-bg: #00aff0;
    --e-contact-buttons-button-bg-hover: #0d72cf;
    --e-contact-buttons-button-icon: #fff;
    --e-contact-buttons-button-icon-hover: #fff;
    --e-contact-buttons-top-bar-bg: #0d72cf;
    --e-contact-buttons-top-bar-name: #fff;
    --e-contact-buttons-top-bar-title: #fff;
    --e-contact-buttons-close-button-color: #fff;
    --e-contact-buttons-message-bubble-body: #000;
    --e-contact-buttons-message-bubble-time: #000;
    --e-contact-buttons-message-bubble-name: #000;
    --e-contact-buttons-message-bubble-bubble-bg: #fff;
    --e-contact-buttons-message-bubble-chat-bg: #cdf7ff;
    --e-contact-buttons-send-button-icon: #fff;
    --e-contact-buttons-send-button-bg: #00aff0;
    --e-contact-buttons-send-button-icon-hover: #fff;
    --e-contact-buttons-send-button-bg-hover: #0d72cf;
    --e-contact-buttons-chat-box-bg: #fff
}

.e-contact-buttons.has-platform-messenger {
    --e-contact-buttons-button-bg: #168aff;
    --e-contact-buttons-button-bg-hover: #168aff;
    --e-contact-buttons-button-icon: #fff;
    --e-contact-buttons-button-icon-hover: #fff;
    --e-contact-buttons-top-bar-bg: #168aff;
    --e-contact-buttons-top-bar-name: #fff;
    --e-contact-buttons-top-bar-title: #fff;
    --e-contact-buttons-close-button-color: #fff;
    --e-contact-buttons-message-bubble-body: #000;
    --e-contact-buttons-message-bubble-time: #000;
    --e-contact-buttons-message-bubble-name: #000;
    --e-contact-buttons-message-bubble-bubble-bg: #fff;
    --e-contact-buttons-message-bubble-chat-bg: #f0f0f0;
    --e-contact-buttons-send-button-icon: #fff;
    --e-contact-buttons-send-button-bg: #168aff;
    --e-contact-buttons-send-button-icon-hover: #fff;
    --e-contact-buttons-send-button-bg-hover: #168aff;
    --e-contact-buttons-chat-box-bg: #fff
}

.e-contact-buttons.has-platform-viber {
    --e-contact-buttons-button-bg: #7360f2;
    --e-contact-buttons-button-bg-hover: #4e4879;
    --e-contact-buttons-button-icon: #fff;
    --e-contact-buttons-button-icon-hover: #fff;
    --e-contact-buttons-top-bar-bg: #4e4879;
    --e-contact-buttons-top-bar-name: #fff;
    --e-contact-buttons-top-bar-title: #fff;
    --e-contact-buttons-close-button-color: #fff;
    --e-contact-buttons-message-bubble-body: #000;
    --e-contact-buttons-message-bubble-time: #000;
    --e-contact-buttons-message-bubble-name: #000;
    --e-contact-buttons-message-bubble-bubble-bg: #fff;
    --e-contact-buttons-message-bubble-chat-bg: #e5e1ff;
    --e-contact-buttons-send-button-icon: #fff;
    --e-contact-buttons-send-button-bg: #7360f2;
    --e-contact-buttons-send-button-icon-hover: #fff;
    --e-contact-buttons-send-button-bg-hover: #4e4879;
    --e-contact-buttons-chat-box-bg: #fff
}

.e-contact-buttons.has-corners-rounded {
    --e-contact-buttons-border-radius: 20px
}

.e-contact-buttons.has-corners-round {
    --e-contact-buttons-border-radius: 50px
}

.e-contact-buttons.has-corners-sharp {
    --e-contact-buttons-border-radius: 0
}

.e-contact-buttons:not(.has-animations) .e-contact-buttons__content-wrapper.hidden {
    display: none
}

.e-contact-buttons.has-animations .e-contact-buttons__content-wrapper.hidden {
    display: block;
    visibility: hidden;
    transition: var(--e-contact-buttons-chat-box-animation-duration)
}

.e-contact-buttons.has-animations .e-contact-buttons__content-wrapper.animated-wrapper {
    visibility: hidden;
    opacity: 0;
    transform: none;
    animation: e-contact-buttons-close var(--e-contact-buttons-chat-box-animation-duration)
}

.e-contact-buttons__content {
    border-radius: var(--e-contact-buttons-border-radius);
    box-shadow: var(--e-contact-buttons-box-shadow);
    font-family: var(--e-global-typography-text-font-family, "Poppins"), Sans-serif;
    margin-block-end: 20px;
    overflow: hidden
}

.e-contact-buttons__content.animated {
    animation-duration: var(--e-contact-buttons-chat-box-animation-duration)
}

.e-contact-buttons__top-bar {
    align-items: center;
    background-color: var(--e-contact-buttons-top-bar-bg);
    display: flex;
    gap: 20px;
    padding: 20px
}

.e-contact-buttons__top-bar-name {
    color: var(--e-contact-buttons-top-bar-name);
    font-size: 24px;
    font-weight: 700;
    margin-block-end: 0
}

.e-contact-buttons__top-bar-title {
    color: var(--e-contact-buttons-top-bar-title);
    font-size: 20px;
    margin-block-end: 0
}

.e-contact-buttons__profile-image {
    align-items: center;
    display: flex;
    position: relative
}

.e-contact-buttons__profile-image img {
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover
}

.e-contact-buttons__profile-image.has-size-small img {
    height: var(--e-contact-buttons-size-small);
    width: var(--e-contact-buttons-size-small)
}

.e-contact-buttons__profile-image.has-size-medium img {
    height: var(--e-contact-buttons-size-medium);
    width: var(--e-contact-buttons-size-medium)
}

.e-contact-buttons__profile-image.has-size-large img {
    height: var(--e-contact-buttons-size-large);
    width: var(--e-contact-buttons-size-large)
}

.e-contact-buttons__profile-image.has-dot:after {
    background-color: var(--e-contact-buttons-profile-dot-bg);
    border: 3px solid var(--e-contact-buttons-top-bar-bg);
    border-radius: 50%;
    bottom: 5px;
    content: "";
    height: 20px;
    position: absolute;
    right: 0;
    width: 20px
}

.e-contact-buttons__close-button {
    padding: 0;
    position: absolute;
    inset-inline-end: 20px;
    top: 20px
}

.e-contact-buttons__close-button,
.e-contact-buttons__close-button:focus,
.e-contact-buttons__close-button:hover {
    background: none;
    border: 0;
    color: var(--e-contact-buttons-close-button-color)
}

.e-contact-buttons__chat-button-container {
    display: flex;
    justify-content: flex-end
}

@media (max-width:767px) {
    .e-contact-buttons__chat-button-container {
        inset-inline-end: 25px;
        position: relative
    }
}

.e-contact-buttons.has-alignment-start .e-contact-buttons__chat-button-container {
    justify-content: flex-start
}

@media (max-width:767px) {
    .e-contact-buttons.has-alignment-start .e-contact-buttons__chat-button-container {
        inset-inline-end: unset;
        inset-inline-start: 25px
    }
}

.e-contact-buttons__chat-button {
    align-items: center;
    background-color: var(--e-contact-buttons-button-bg);
    border-radius: 50%;
    border: 0;
    display: flex;
    justify-content: center;
    position: relative
}

.e-contact-buttons__chat-button.animated {
    animation-delay: var(--e-contact-button-chat-button-animation-delay);
    animation-duration: var(--e-contact-button-chat-button-animation-duration)
}

.e-contact-buttons__chat-button svg {
    fill: var(--e-contact-buttons-button-icon)
}

.e-contact-buttons__chat-button:focus,
.e-contact-buttons__chat-button:hover {
    background-color: var(--e-contact-buttons-button-bg-hover)
}

.e-contact-buttons__chat-button:focus svg,
.e-contact-buttons__chat-button:hover svg {
    fill: var(--e-contact-buttons-button-icon-hover)
}

.e-contact-buttons__chat-button.has-dot:after {
    background-color: var(--e-contact-buttons-dot);
    border-radius: 50%;
    content: "";
    height: var(--e-contact-buttons-dot-size);
    position: absolute;
    right: 0;
    top: 0;
    width: var(--e-contact-buttons-dot-size)
}

.e-contact-buttons__chat-button.has-size-small {
    height: var(--e-contact-buttons-size-small);
    width: var(--e-contact-buttons-size-small)
}

.e-contact-buttons__chat-button.has-size-medium {
    height: var(--e-contact-buttons-size-medium);
    width: var(--e-contact-buttons-size-medium)
}

.e-contact-buttons__chat-button.has-size-large {
    height: var(--e-contact-buttons-size-large);
    width: var(--e-contact-buttons-size-large)
}

.e-contact-buttons__message-bubble {
    background-color: var(--e-contact-buttons-message-bubble-chat-bg);
    padding: 20px;
    padding-inline-start: 40px
}

.e-contact-buttons__message-bubble.has-typing-animation .e-contact-buttons__bubble-container {
    height: 0;
    opacity: 0;
    visibility: hidden
}

.e-contact-buttons__bubble {
    background-color: var(--e-contact-buttons-message-bubble-bubble-bg);
    border-radius: 15px;
    padding: 20px;
    position: relative
}

.e-contact-buttons__bubble:after {
    border-left: 0 solid transparent;
    border-bottom: 40px solid transparent;
    border-right: 40px solid var(--e-contact-buttons-message-bubble-bubble-bg);
    border-top: 0 solid transparent;
    content: "";
    height: 0;
    position: absolute;
    inset-inline-start: -20px;
    top: 0;
    transform: rotate(0);
    width: 0
}

.e-contact-buttons__message-bubble-name {
    color: var(--e-contact-buttons-message-bubble-name);
    font-size: 20px;
    line-height: 25px;
    font-weight: 600;
    margin-block-end: 8px
}

.e-contact-buttons__message-bubble-body {
    color: var(--e-contact-buttons-message-bubble-body);
    font-size: 20px;
    line-height: 25px;
    margin-block-end: 8px
}

.e-contact-buttons__message-bubble-time {
    color: var(--e-contact-buttons-message-bubble-time);
    font-size: 20px;
    line-height: 25px;
    font-weight: 600;
    text-align: end
}

.e-contact-buttons__powered-container {
    padding: 20px 20px 0;
    text-align: center
}

.e-contact-buttons__powered-text {
    color: #000;
    font-size: 16px;
    font-weight: 500
}

.elementor .e-contact-buttons__powered-text {
    text-decoration: underline
}

.e-contact-buttons__dots-container {
    background-color: var(--e-contact-buttons-message-bubble-bubble-bg);
    border-radius: 15px;
    display: inline-flex;
    padding: 10px 12px
}

.e-contact-buttons__dot {
    animation: e-contact-buttons-typing-jump 1s infinite;
    background-color: var(--e-contact-buttons-message-bubble-name);
    border-radius: 50%;
    display: inline-block;
    height: 7px;
    margin-left: auto;
    margin-right: 3px;
    position: relative;
    width: 7px
}

.e-contact-buttons__dot-1 {
    animation-delay: .2s
}

.e-contact-buttons__dot-2 {
    animation-delay: .4s
}

.e-contact-buttons__dot-3 {
    animation-delay: .6s
}

.e-contact-buttons__send-button {
    background-color: var(--e-contact-buttons-chat-box-bg);
    padding: 20px
}

.e-contact-buttons__send-cta {
    align-items: center;
    background-color: var(--e-contact-buttons-send-button-bg);
    border-radius: 30px;
    color: var(--e-contact-buttons-send-button-icon);
    display: flex;
    font-size: 18px;
    font-weight: 500;
    justify-content: center;
    padding: 10px;
    text-align: center;
    transition: all .3s;
    width: 100%
}

.e-contact-buttons__send-cta svg {
    fill: var(--e-contact-buttons-send-button-icon);
    height: 28px;
    margin-inline-end: 8px;
    width: 28px
}

.e-contact-buttons__send-cta:focus,
.e-contact-buttons__send-cta:hover {
    background-color: var(--e-contact-buttons-send-button-bg-hover);
    color: var(--e-contact-buttons-send-button-icon-hover);
    transition: all .3s
}

.e-contact-buttons__send-cta:focus svg,
.e-contact-buttons__send-cta:hover svg {
    fill: var(--e-contact-buttons-send-button-icon-hover)
}

.e-contact-buttons__content.visible .e-contact-buttons__message-bubble.has-typing-animation .e-contact-buttons__dots-container {
    animation-delay: 0;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-name: e-contact-buttons-disappear
}

.e-contact-buttons__content.visible .e-contact-buttons__message-bubble.has-typing-animation .e-contact-buttons__bubble-container {
    animation-delay: 2s;
    animation-duration: .1s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-name: e-contact-buttons-appear
}

@keyframes e-contact-buttons-typing-jump {
    0% {
        bottom: 0
    }

    20% {
        bottom: 5px
    }

    40% {
        bottom: 0
    }
}

@keyframes e-contact-buttons-appear {
    0% {
        height: 0;
        opacity: 0;
        visibility: hidden
    }

    to {
        height: auto;
        opacity: 1;
        visibility: visible
    }
}

@keyframes e-contact-buttons-disappear {
    0% {
        display: inline-flex
    }

    to {
        display: none
    }
}

@keyframes e-contact-buttons-close {

    0%,
    99.99% {
        visibility: visible;
        opacity: 1
    }

    to {
        visibility: hidden;
        opacity: 0;
        transform: none
    }
}