body{
  overflow: auto;
}

.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.txa_sticky_header:is(.kd-header-1-area) {
  top: 0px !important;
}

.kd-footer-1-contact .item{
  text-align: left;
}

.kd-footer-1-menu li{
  text-align: left;
}

.kd-header-1-top-contact-item .subtitle{
  text-align: left;
}

.kd-search-btn-1{
  border-radius: 40px !important;
}

.breadcrumb-area{
  padding-top: 100px;
}

section.elementor-section.elementor-top-section.elementor-element.elementor-element-c124d77.elementor-section-boxed.elementor-section-height-default.elementor-section-height-default {
  padding: 50px 0;
}
.breadcrumb-area{
  padding-top: 100px;
  margin-bottom: 50px;
}

.elementor-section{
  padding-bottom: 50px 0;
}

.kd-cta-1-action .action-link{
  font-size: 24px;
}
.font-c-40{
  font-size: 40px !important;
}
.font-c-24{
  font-size: 24px !important;
}
.font-c-20{
  font-size: 20px !important;
}
.custom-flex-box-2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}
.custom-flex-box-1{
  display: flex;
  align-items: center;
}
.tutor-accordion-item-header::after {
  content: "";
}
.cost-table-design th,
.cost-table-design td{
  border: 1px solid #000;
  padding: 5px;
}

/* 
  mobile-menu-css-start
*/
#mobile-menu-sec{
  display: none;
} 
.mobile-menu {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
}
.mobile-menu ::-webkit-scrollbar {
  display: none;
}
.mobile-menu-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  display: flex;
  flex-direction: column;
}
.mobile-menu-bg span {
  flex-wrap: wrap;
  display: block;
  background-color: var(--kd-clr-pr-1);
  width: 100%;
}
.mobile-menu-bg span:nth-child(1) {
  height: 50%;
}
.mobile-menu-bg span:nth-child(2) {
  height: 50%;
}
.mobile-menu-wrap {
  height: 100%;
  padding: 60px 200px;
  overflow-y: scroll;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .mobile-menu-wrap {
    padding: 60px 100px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .mobile-menu-wrap {
    padding: 60px 50px;
  }
}
@media (max-width: 767px) {
  .mobile-menu-wrap {
    padding: 40px 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mobile-menu-wrap {
    padding: 60px 40px;
  }
}
.mobile-menu-logo-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
.mobile-menu-logo-wrap .mobile-menu-logo {
  max-width: 180px;
}
.mobile-menu-close {
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}
.mobile-menu-close i {
  line-height: 1;
  display: inline-block;
  transition: 300ms ease-in-out;
}
.mobile-menu-close:hover {
  color: var(--kd-clr-sd-1);
}
.mobile-menu-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .mobile-menu-row {
    grid-template-columns: 1fr;
    gap: 100px;
  }
}
.mobile-menu-right {
  height: 460px;
  overflow: scroll;
  padding: 30px 0;
  margin-top: -30px;
}

.mobile-menu-search-form-1 input {
  height: 60px;
  border-radius: 5px;
  padding: 0 30px;
  background: #fff;
  font-family: var(--kd-font-pr-1);
  width: 100%;
  border: none;
  outline: none;
  color: #000;
  margin-bottom: 10px;
}
.mobile-menu-search-form-1 .form-btn {
  border: none;
  outline: none;
  background-color: #fff;
  border-radius: 5px;
  padding: 12px 20px;
  color: #000;
  transition: 300ms var(--cube-1);
  text-transform: capitalize;
  font-family: var(--kd-font-pr-1);
}
.mobile-menu-search-form-1 .form-btn:hover {
  background-color: var(--kd-clr-sd-1);
  color: #000;
  transform: scale(95%);
}

.mobile-menu-social-1 {
  gap: 25px;
}
.mobile-menu-social-1 a {
  display: inline-block;
  font-size: 22px;
  line-height: 1;
  color: #fff;
  opacity: 0.8;
  transition: 300ms var(--cube-1);
}
.mobile-menu-social-1 a:hover {
  color: var(--kd-clr-sd-1);
  opacity: 1;
}

.mobile-menu-logo-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 20px;
  background-color: #fff;
  border-radius: 50px;
}
.mobile-menu-close {
  font-size: 30px;
  color: #e31e24 !important;
  cursor: pointer;
}

#mobile-menu-sec-v{
  display: block;
}
#mobile-menu-sec-h{
  display: none;
}

@media (max-width: 767px){
  .mob-d-none{
    display: none !important;
  }
  .breadcrumb-area {
    padding: 0;
    height: 107px;
    margin-top: 146px;
  }
  .blog-page-sidebar .sidebar-category .inks, .widget_categories ul li a {
      border-radius: 0;
  }
  .kd-price-1-tabs-btn .nav-link{
    font-size: 20px !important;
  }
  .kd-price-1-left{
    padding: 12px;
  }
}

.left-img.d-flex{
  max-width: 100% !important;
  align-items: center;
}

.left-img.d-flex p{
  color: #FFF;
}
.left-img.d-flex img{
  width: 300px;
  padding: 10px;
}

.kd-hero-1-social .link::after,
.kd-hero-1-slider-btn .slider-btn::after{
  content: none !important;
}

.kd-search-btn-1{
  width: 130px !important;
}
.kd-search-btn-1 i{
  font-size: 16px;
  margin-right: 5px;
}

.big-box-btn{
  background: transparent;
  width: 100%;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-contenter {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 50%;
}

.modal-header{
  display: flex;
  justify-content: space-between;
}

.custom-dropdown{
  width: max-content;
  max-width: 840px;
  height: 210px !important;
  display: flex !important;
  flex-wrap: wrap;
  padding: 0 !important;
}

.custom-dropdown .menu-item{
  width: 140px !important;
  height: 70px !important;
  text-align: center;
}
.custom-dropdown .menu-item img{
  margin-bottom: 5px;
  border-radius: 5px;
}

.whatsapp-float-btn{
  position: fixed;
  bottom: 30px;
  right: 250px;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-color: #25D366;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  box-shadow: 0 0 0 rgba(204,169,44, 0.4);
  animation: pulse 2s infinite;
}

.whatsapp-float-btn i{
  color: #fff;
  font-size: 32px;
}

.con-float-btn{
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 200px;
  height: 50px;
  border-radius: 100px;
  background-color: #e31e24;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  box-shadow: 0 0 0 rgba(204,169,44, 0.4);
  animation: shadow-pulse 1s infinite;
  padding: 5px;
}
.con-float-btn a{
  display: block;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.con-float-img{
  position: fixed;
  bottom: 30px;
  z-index: 9;
  right: 30px;
  width: 200px;
  cursor: pointer;
}
.con-float-btn p{
  margin: 0;
  color: #fff;
  font-size: 18px;
  margin-left: 10px;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    box-shadow: 0 0 0 0 rgba(204,169,44, 0.2);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}

@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
  }
}

.con-float-btn i{
  color: #fff;
  font-size: 32px;
}

.test-rating i{
  color: #fcc922;
}

.test-review a{
  color: #e31e24;
}

/* CollegeSearch.js */

.college-search-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.filters-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.filter-input,
.filter-select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-width: 200px;
}

.search-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #0056b3;
}

/* .results-section{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
} */

.college-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  gap: 20px;
  /* width: 30%; */
}

.college-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
}

.college-info {
  flex: 1;
}

.college-description {
  margin-top: 10px;
  color: #666;
}

.loading,
.error,
.no-results {
  text-align: center;
  padding: 20px;
  font-size: 18px;
}

.error {
  color: red;
}

.pageactive{
  background-color: #e31e24;
  color: #fff;
}

.college-search-container{
  margin-top: 200px;

}

.college-search-bar{
  width: 100%;
  display: flex;
  padding: 20px 0;
}

.college-search-input{
  outline: none;
  width: 100%;
}